import { CommonModule, KeyValue, NgOptimizedImage } from "@angular/common";
import { Component, ElementRef, Injector, Input, OnChanges, OnInit, Renderer2, SimpleChanges, ViewChild, inject, runInInjectionContext } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { CustomForm, CustomFormControl, CustomFormControlProps, CustomFormGroup, CustomFormGroupProps, TilbyMagicFormComponent, TilbyMapCoordinatesComponent, tilbyErrors } from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { FixMeLater, QRCodeElementType, QRCodeModule } from "angularx-qrcode";
import { restManager } from "app/ajs-upgraded-providers";
import { ConfigurationManagerService, EntityManagerService, ToolbarEventsService } from "src/app/core";
import { SettingsAlvoloStateService } from "../../../service/settings-alvolo.state.service";
import { BookingTimetableType, GeneralFormFields, TimeTableTypeAPI } from "../../../model/general-form-fields";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { AlvoloStatus } from "../../../model/alvolo-status";
import { AvailablePaymentsType } from "../../../model/available-payments-fields";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";

import {
    AlertDialogService,
    ConfirmDialogService,
    OpenDialogsService 
} from "src/app/dialogs";

import { PaymentsLinkToShow } from "../../../model/payments-link-toshow";
import { NexiDialogService } from "src/app/dialogs/payments-dialogs/nexi-dialog/nexi-dialog.service";
import { PayPalDialogService } from "src/app/dialogs/payments-dialogs/paypal-dialog/paypal-dialog.service";
import { SatispayDialogService } from "src/app/dialogs/payments-dialogs/satispay-dialog/satispay-dialog.service";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { SkeletonDirective } from "src/app/directives/skeleton.directive";
import { DevLogger } from "src/app/shared/dev-logger";
import { MatButtonToggleGroup, MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import * as JSZip from "jszip";
import { saveAs } from "file-saver";
import { CanvasElement } from "pdfmake/interfaces";
import { toCanvas } from "qrcode";
import { DomSanitizer } from "@angular/platform-browser";
import { MatRadioChange, MatRadioModule } from "@angular/material/radio";
import { MatTable, MatTableModule } from "@angular/material/table";
import { MatMenuModule } from "@angular/material/menu";
import { AddTimeSlotDialogStateService } from "src/app/dialogs/add-time-slot-dialog";
import { Rooms } from "tilby-models";
import { WeekDays } from "../../../enum/week-days-enum";
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { AddBookingsTimeDialogStateService } from "src/app/dialogs/add-bookings-time-dialog";
import { subscribeInComponent } from "@tilby/tilby-ui-lib/utilities";
import * as XLSX from 'xlsx';
const { apiKeys } = require('app/tilby.properties.json');
import {util} from 'app/ajs-upgraded-providers';
import { AlvoloUtilsService } from "src/app/features/settings/settings-alvolo/service/alvolo-utils.service";
import { EnvironmentConfig } from "src/environments/environment-config";

type RoomModel = {
    initial_room_id: number,
    final_room_id: number
}

@Component({
    selector: "app-settings-alvolo-content",
    templateUrl: "./settings-alvolo-content.component.html",
    styleUrls: ["./settings-alvolo-content.component.scss"],
    standalone: true,
    imports: [TilbyMagicFormComponent, NgOptimizedImage, MatIconModule, MatButtonModule, MatListModule, 
        MatCheckboxModule, MatButtonModule, CommonModule, FormsModule, ReactiveFormsModule ,QRCodeModule, 
        TranslateModule, NgxSkeletonLoaderModule, SkeletonDirective, MatButtonToggleModule, MatFormFieldModule, 
        MatInputModule, QRCodeModule, MatRadioModule, MatTableModule, MatMenuModule, MatOptionModule, MatSelectModule,
        TilbyMapCoordinatesComponent ]
    })
export class SettingsAlvoloContentComponent implements OnChanges, OnInit {
    @Input() option!: { key: number; value: string };
    @ViewChild("parent") parent!: ElementRef<FixMeLater>;
    @ViewChild("parentSelfOrdering") parentSelfOrdering!: FixMeLater;
    @ViewChild(MatTable) table!: MatTable<TimeTableTypeAPI>;
    @ViewChild(MatTable) bookingsTimeTable!: MatTable<BookingTimetableType>;

    private readonly alertDialogService = inject(AlertDialogService);
    private readonly alvoloUtilsService = inject(AlvoloUtilsService);
    private readonly toolbarEventsService = inject(ToolbarEventsService);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly addTimeSlotDialogStateService = inject(AddTimeSlotDialogStateService);
    private readonly addBookingsTimeDialogStateService = inject(AddBookingsTimeDialogStateService);
    private readonly settingsAlvoloStateService = inject(SettingsAlvoloStateService);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly restManager = inject(restManager);
    private readonly openDialogService = inject(OpenDialogsService);
    private readonly formBuilder = inject(FormBuilder);
    private readonly nexiDialogService = inject(NexiDialogService);
    private readonly satispayDialogService = inject(SatispayDialogService);
    private readonly paypalDialogService = inject(PayPalDialogService);
    private readonly translate = inject(TranslateService);
    private readonly injector = inject(Injector);
    private readonly util: any = inject(util);
    protected qrCode = "";
    protected qrCodeSelfOrdering!: string;
    generalShopInformationsFormGroup: CustomFormGroup<CustomForm<GeneralFormFields>> = this.settingsAlvoloStateService.createGeneralShopInformationsForm();
    elementType: QRCodeElementType = "canvas";
    shopSettingAlias = this.settingsAlvoloStateService.alias ?? this.configurationManagerService.getSetting('alvolo.general.alias');
    shopSettingKey = this.configurationManagerService.getSetting('alvolo.general.key');
    shopSettingPassword = this.configurationManagerService.getPreference('alvolo.password_init');
    shopStatus: AlvoloStatus = {
        payload: {
            sales_enabled: false,
            bookings_enabled: false
        },
        crc: ""
    };
    shopPreference!: GeneralFormFields;
    paymentsAvailablesForm!: FormGroup;
    available_payments: (string | AvailablePaymentsType)[] = [];
    paymentsLinksToShow: PaymentsLinkToShow = {
        app_stripe: false,
        app_nexi: false,
        paypal: false,
        satispay: false
    }
    isLoading: boolean = true;
    custom_codes: string = "";
    self_ordering_selected: string = "free_order";
    customCodesBtnIsVisible: boolean = false;
    alvolo_card_base_path = "assets/images/alvolo/self_ordering_qrcode.png";
    alvolo_card_base: string = "";
    centerImage!: HTMLImageElement;
    renderer: Renderer2 = inject(Renderer2);
    context: CanvasRenderingContext2D | null = null
    sanitizer: DomSanitizer = inject(DomSanitizer);
    tables_ids: number[] = [];
    rooms_ids: number[] = [];
    qrcodes_free_order_base64: string = "";
    qrcodes_tables: string[] = [];
    qrcodes_rooms: string[] = [];
    qrcodes_custom_codes: string[] = [];
    qrcodes_tables_base64: string[] = [];
    qrcodes_tables_base64_only_qrcodes: string[] = [];
    qrcodes_rooms_base64: string[] = [];
    qrcodes_custom_codes_base64: string[] = [];
    custom_codes_splited: string[] = [];
    table_and_room: string[] = [];
    generateCodeType!: string;
    generateCodeGraphic!: "vertical_placeholder" | "only_qrcode";
    rooms_and_tables: {Sala: string, Tavolo: string}[] = [];
    initial_room_id!: number | string;
    final_room_id!: number | string;
    initial_room = new FormControl('', []);
    final_room = new FormControl('', []);
    final_index!: number;
    timeout: any = null;
    value_to_low: boolean = false;
    value_to_high: boolean = false;
    roomsFormGroup = new CustomFormGroup<CustomForm<RoomModel>>({
        initial_room_id: new CustomFormControl(undefined, {validators: [Validators.required, Validators.min(1)]}, {...new CustomFormControlProps(), errors:[...tilbyErrors, {key: 'initialRoomGreaterThanFinalRoom', value: 'SETTINGS.ALVOLO.SELF_ORDERING.INITIAL_ROOM_ERROR'}], inputType:'number', label:'SETTINGS.ALVOLO.SELF_ORDERING.INITIAL_ROOM', id:'input-initial_room_id', class:"tw-w-full"}),
        final_room_id: new CustomFormControl(undefined, {validators: [Validators.required, Validators.min(1)]}, {...new CustomFormControlProps(), errors:[...tilbyErrors, {key: 'finalRoomLowerThanInitialRoom', value: 'SETTINGS.ALVOLO.SELF_ORDERING.FINAL_ROOM_ERROR'}], inputType:'number', label:'SETTINGS.ALVOLO.SELF_ORDERING.FINAL_ROOM', id:'input-final_room_id', class:"tw-w-full"})
    }, {}, {...new CustomFormGroupProps(), class:"tw-flex tw-flex-col tw-mt-3"});
    getQrCodesTablesFirstTime: boolean = true;
    disableDownloadButton: boolean = false;
    disableSubmitPaymentsButtonFetch: boolean = false;
    disableSubmitPaymentsButtonWait: boolean = false;
    disableSubmitSelfOrderingButtonWait: boolean = false;
    disableDuplicateButton: boolean = false;
    disableDeleteButton: boolean = false;
    disableMoveUpButton: boolean = false;
    disableMoveDownButton: boolean = false;
    radioButtonSelected: MatRadioChange | string | undefined;
    roomAndTable: Rooms[] = [];
    dataSource: TimeTableTypeAPI[] = this.settingsAlvoloStateService.shop_preference?.service_time!;
    displayedColumns = [
        'order',
        'name',
        'validity',
        'star',
    ];
    week_days = WeekDays;
    priceLists: { index: number; name: string | true }[] = [];
    priceListId!: number;
    priceList!: { index: number; name: string | true };
    lastSync!: string;
    isPublishing: boolean = false;
    lastPublishDay: string = "";
    lastPublishHour: string = "";
    isSessionBeta: boolean = false;
    tableReservationForm!: CustomFormGroup<any>;
    bookings_time!: BookingTimetableType[];
    password: string = "";
    city: string = "";
    country: string = "";
    address: string = "";
    google_maps_api = apiKeys.googleMaps;
    latitude!: number;
    longitude!: number;
    withoutAddress: boolean = true;
    latitudeAndLongitude: boolean = false;
    alvolo_bookings!: boolean;

    isSticky(buttonToggleGroup: MatButtonToggleGroup, id: string) {
        return (buttonToggleGroup.value || []).indexOf(id) !== -1;
    }

    ngOnInit(): void {
        try {
            const lastSyncPreference = this.configurationManagerService.getShopPreference('alvolo.last_sync');
            this.lastSync = lastSyncPreference ? lastSyncPreference : "";
        } catch(error) {
            DevLogger.log(error);
        }

        this.qrCode = `${this.alvoloUtilsService.getBaseUrl()}/${this.configurationManagerService.getSetting("alvolo.general.alias") || this.settingsAlvoloStateService.alias || this.shopSettingAlias}`;

        this.getShopStatus();
        this.getAlvoloCardBase64();
        this.getRoomAndTableIds();
        this.getQrCodesFreeOrder();   
        this.getPriceList();

        const modulesPermissions = this.configurationManagerService.getModulesStatus();

        this.alvolo_bookings = modulesPermissions.enabledModules['alvolo_bookings'] || false;
    }

    getAlvoloCardBase64() {
        fetch(this.alvolo_card_base_path)
        .then(response => response.blob())
        .then(blob => {
            const reader = new FileReader();
            reader.onloadend = () => {
                this.alvolo_card_base = reader.result as string;
            };
            reader.readAsDataURL(blob);
        })
        .catch(error => {
            DevLogger.log('Si è verificato un errore durante il recupero dell\'immagine:', error);
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.option.currentValue) {
            this.option = changes.option.currentValue;
        } else {
            this.option = {key: 0, value: "SETTINGS.ALVOLO.SUMMARY.STATE"};
            return;
        }

        (this.option.key !== 1) && this.toolbarEventsService.showButtonsBar$.next(false);
        switch (this.option.key) {
            case 0:
                this.getShopStatus();
                break;
            case 1:
                this.toolbarEventsService.showButtonsBar$.next(true);
                this.settingsAlvoloStateService.createToolbarButtons();
                this.settingsAlvoloStateService.patchValueForm();
                break;
            case 2:
                break;
            case 3:
                try {
                    const pricelistIdPreference = this.configurationManagerService.getShopPreference('alvolo.pricelist_id');
                    this.priceListId = pricelistIdPreference ? parseInt(pricelistIdPreference) : 0;
                } catch (error) {
                    DevLogger.log(error);
                }
                this.priceList = this.priceLists.filter(p => p.index === this.priceListId)[0];
                if(this.lastSync) {
                    this.lastPublishDay = this.formatDate(this.lastSync);
                    this.lastPublishHour = `${new Date(this.lastSync).getHours()}:${this.addZero(new Date(this.lastSync).getMinutes())}`;
                }

                if(!this.shopSettingKey) this.shopSettingKey = this.configurationManagerService.getSetting('alvolo.general.key');
                if(!this.shopSettingPassword) this.shopSettingPassword = this.configurationManagerService.getPreference('alvolo.password_init');
                break;
            case 4:
                this.toolbarEventsService.showButtonsBar$.next(true);
                this.toolbarEventsService.buttons$.next({
                    barButtons: [
                        {
                            isIt: () => true,
                            isDisable: () => false,
                            name: 'save_payments_form',
                            icon: () => 'check',
                            click: () => {
                                this.onSubmitAvailablePaymentsForm();
                            },
                        }
                    ],
                    panelButtons: []
                });

                try {
                    const paymentsLinksPreference = this.configurationManagerService.getPreference('alvolo.payments.links_to_show');
                    this.paymentsLinksToShow = paymentsLinksPreference ? JSON.parse(paymentsLinksPreference) : this.paymentsLinksToShow;
                    this.getPaymentPreferences();
                    this.settingsAlvoloStateService.getElementRef(this);
                } catch(error) {
                    DevLogger.log(error);
                }

                break;
            case 7:
                this.createTableReservationForm();
                this.bookings_time = this.settingsAlvoloStateService.getShopPreference()?.bookings_time || [];
                this.toolbarEventsService.showButtonsBar$.next(true);
                this.toolbarEventsService.buttons$.next({
                    barButtons: [
                        {
                            isIt: () => true,
                            isDisable: () => !this.alvolo_bookings,
                            name: 'save_table_reservation_form',
                            icon: () => 'check',
                            click: () => {
                                if(this.tableReservationForm.invalid || this.tableReservationForm.untouched) return;
                                const shop_preference = this.settingsAlvoloStateService.getShopPreference();

                                if (shop_preference) {
                                    shop_preference.bookings_days_to_show = this.bookings_days_to_show.value;
                                    shop_preference.bookings_delay = this.bookings_delay.value;
                                }

                                this.settingsAlvoloStateService.updateExistingShop(shop_preference!);
                                this.tableReservationForm.updateValueAndValidity();
                                this.tableReservationForm.markAsUntouched();
                                this.tableReservationForm.markAsPristine();
                            },
                        }
                    ],
                    panelButtons: []
                });
                break;
        }
    }

    ngAfterViewInit(): void {
        runInInjectionContext(
            this.injector, 
            () => {
                subscribeInComponent (
                    this.generalShopInformationsFormGroup.controls.addresses.valueChanges,
                    () => {
                        if(this.generalShopInformationsFormGroup.controls.addresses.controls.latitude.value && this.generalShopInformationsFormGroup.controls.addresses.controls.longitude.value) {
                            this.latitude = this.generalShopInformationsFormGroup.controls.addresses.controls.latitude.value;
                            this.longitude = this.generalShopInformationsFormGroup.controls.addresses.controls.longitude.value;

                            this.withoutAddress = false;
                            this.latitudeAndLongitude = true;
                            return;
                        };
                    }
                );
                subscribeInComponent (
                    this.settingsAlvoloStateService.$place,
                    () => {
                        this.settingsAlvoloStateService.$place.subscribe((place) => {
                            if(place) {
                                place.city && (this.city = place.locality);
                                place.country && (this.country = place.country);
                                place.address && (this.address = place.address);
                            }
                        })
                    }
                );
                subscribeInComponent(
                    this.roomsFormGroup.valueChanges,
                    () => {
                        if((this.roomsFormGroup.controls.initial_room_id.value && this.roomsFormGroup.controls.final_room_id.touched) > (this.roomsFormGroup.controls.initial_room_id.touched && this.roomsFormGroup.controls.final_room_id.value)) {
                            this.roomsFormGroup.controls.initial_room_id.setErrors({initialRoomGreaterThanFinalRoom: true})
                            this.roomsFormGroup.controls.final_room_id.setErrors({finalRoomLowerThanInitialRoom: true})
                        } else {
                            if((this.roomsFormGroup.controls.initial_room_id.value > this.roomsFormGroup.controls.final_room_id.value) && (this.roomsFormGroup.controls.initial_room_id.touched && this.roomsFormGroup.controls.final_room_id.touched)) {
                                this.roomsFormGroup.controls.initial_room_id.setErrors({initialRoomGreaterThanFinalRoom: true})
                                this.roomsFormGroup.controls.final_room_id.setErrors({finalRoomLowerThanInitialRoom: true})
                            } else {    
                                if (this.roomsFormGroup.controls.initial_room_id.value < 1 && this.roomsFormGroup.controls.final_room_id.touched) {
                                    this.roomsFormGroup.controls.initial_room_id.setErrors({min: true})
                                } else {
                                    this.roomsFormGroup.controls.initial_room_id.setErrors(null);
                                }
                                
                                if(this.roomsFormGroup.controls.final_room_id.value < 1 && this.roomsFormGroup.controls.initial_room_id.touched){
                                    this.roomsFormGroup.controls.final_room_id.setErrors({min: true})
                                } else {
                                    this.roomsFormGroup.controls.final_room_id.setErrors(null);
                                }
                            }
                        }
                    }
                )
            }
        )

        if(this.settingsAlvoloStateService.shop_preference?.addresses && this.settingsAlvoloStateService.shop_preference?.addresses.length > 0){
            this.city = this.settingsAlvoloStateService.shop_preference?.addresses[0]?.city || "Torino";
            this.country = this.settingsAlvoloStateService.shop_preference?.addresses[0]?.country || "IT";
            this.address = this.settingsAlvoloStateService.shop_preference?.addresses[0]?.address || "";
        }

        if(!this.qrCode){
            this.qrCode = `${this.alvoloUtilsService.getBaseUrl()}/${this.configurationManagerService.getSetting("alvolo.general.alias") || this.settingsAlvoloStateService.alias || this.shopSettingAlias}`;
        }

        this.getShopStatus();
    }

    async updateMap() {
        // Request needed libraries.
        const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
        const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
    
        const map = new Map(document.getElementById('map') as HTMLElement, {
            center: { lat: 37.4239163, lng: -122.0947209 },
            zoom: 14,
            mapId: '4504f8b37365c3d0',
        });
    
        new AdvancedMarkerElement({
            map,
            position: { lat: 37.4239163, lng: -122.0947209 },
        });
    }

    ngOnDestroy(): void {
        this.toolbarEventsService.showButtonsBar$.next(true);
    }

    formatDate(someDateTimeStamp: any) {
        let dt = new Date(someDateTimeStamp);
        let date = dt.getDate();
        let diffDays = new Date().getDate() - date;
        let diffMonths = new Date().getMonth() - dt.getMonth();
        let diffYears = new Date().getFullYear() - dt.getFullYear();

        if(diffYears === 0 && diffDays === 0 && diffMonths === 0){
            return "SETTINGS.ALVOLO.MANAGEMENT.TODAY";
        }else if(diffYears === 0 && diffDays === 1) {
            return "SETTINGS.ALVOLO.MANAGEMENT.YESTERDAY";
        }else {
            return dt.getFullYear()+'/' + (dt.getMonth()+1) + '/'+dt.getDate();
        }
    }

    getShopStatus() {
        this.settingsAlvoloStateService.shopStatus$.subscribe((status) => {
            this.shopStatus = status;
        })

        setTimeout(() => {
            this.isLoading = false;
        }, 2000);
    }

    getPriceList(){
        for (let idx = 1; idx <= 10; idx++) {
            if (!this.configurationManagerService.getPreference(`price_list_${idx}_hide`)
            ) {
                this.priceLists.push({
                    index: idx,
                    name:this.configurationManagerService.getPreference(`price_list_${idx}_name`) ||this.translate.instant("CASHREGISTER.TOPBAR.PRICE_LIST_START") +" " +idx,});
            }
        }
    }

    //QR CODES
    getQrCodesFreeOrder() {
        this.qrCodeSelfOrdering = `${this.initQrCode()}/takeaway/`;
        this.convertUrlToBase64AndBlob(this.qrCodeSelfOrdering, (base64) => {
            this.qrcodes_free_order_base64 = base64;
        });
    }

    getQrCodesTables(callback: Function) {
        this.qrcodes_tables = [];
        for(let table_id of this.tables_ids){
            this.qrCodeSelfOrdering = `${this.initQrCode()}/pickup/T-${table_id}/tavolo/${table_id}`;
            this.qrcodes_tables.push(this.qrCodeSelfOrdering);
            this.convertUrlToBase64AndBlob(this.qrCodeSelfOrdering, (base64) => {
                this.mergeAlvoloCardAndQrCode(true, false, base64, (overlappedImage) => {
                    this.qrcodes_tables_base64.push(overlappedImage);
                    this.qrcodes_tables_base64_only_qrcodes.push(base64);
                    if(this.qrcodes_tables_base64.length === this.tables_ids.length) {
                        this.disableDownloadButton = false;
                        callback(this.qrcodes_tables_base64);
                    }
                }, undefined, table_id);
            });   
        }
    }

    getQrCodesRooms() {
        for(const room_id of this.rooms_ids){
            this.qrCodeSelfOrdering = `${this.initQrCode()}/pickup/R-${room_id}/camera/${room_id}`;
            this.convertUrlToBase64AndBlob(this.qrCodeSelfOrdering, (base64) => {
                this.mergeAlvoloCardAndQrCode(false, true, base64, (overlappedImage) => {
                    this.qrcodes_rooms_base64.push(overlappedImage);
                }, room_id, undefined);
            });
        }
    }
    //END QR CODES

    get operative_mode() {
        return this.generalShopInformationsFormGroup?.controls.operative_mode;
    }

    //Available Payments Section
    getPaymentPreferences() {
        try {
            const shopPreference = this.configurationManagerService.getShopPreference("alvolo.shop");
            this.shopPreference = shopPreference ? JSON.parse(shopPreference) : "";
        } catch(error) {
            DevLogger.log(error);
        }
        if(this.shopPreference !== null) {
            this.available_payments = this.shopPreference.available_payments as Array<AvailablePaymentsType | string>; 
        }
        
        this.paymentsAvailablesForm = this.formBuilder.group({
            cash: [this.available_payments.includes('cash') || false],
            credit_card: [this.available_payments.includes('credit_card') || false],
            debit_card: [this.available_payments.includes('debit_card') || false],
            satispay: [this.available_payments.includes('satispay') || false],
            paypal: [this.available_payments.includes('paypal') || false],
            app_stripe: [this.available_payments.includes('app_stripe') || false],
            app_nexi: [this.available_payments.includes('app_nexi') || false],
            app_paypal: [this.available_payments.includes('app_paypal') || false],
            app_satispay: [this.available_payments.includes('app_satispay') || false],
            webapp_stripe: [this.available_payments.includes('webapp_stripe') || false],
            webapp_paypal: [this.available_payments.includes('webapp_paypal') || false],
            webapp_satispay: [this.available_payments.includes('webapp_satispay') || false],
            webapp_nexi: [this.available_payments.includes('webapp_nexi') || false],
        })
    }

    get cash() {
        return this.paymentsAvailablesForm?.get("cash")
    }
    get credit_card() {
        return this.paymentsAvailablesForm?.get("credit_card")
    }
    get debit_card() {
        return this.paymentsAvailablesForm?.get("debit_card")
    }
    get satispay() {
        return this.paymentsAvailablesForm?.get("satispay")
    }
    get paypal() {
        return this.paymentsAvailablesForm?.get("paypal")
    }
    get app_stripe() {
        return this.paymentsAvailablesForm?.get("app_stripe")
    }
    get app_nexi() {
        return this.paymentsAvailablesForm?.get("app_nexi")
    }
    get app_paypal() {
        return this.paymentsAvailablesForm?.get("app_paypal")
    }
    get app_satispay() {
        return this.paymentsAvailablesForm?.get("app_satispay")
    }
    get webapp_stripe() {
        return this.paymentsAvailablesForm?.get("webapp_stripe")
    }
    get webapp_paypal() {
        return this.paymentsAvailablesForm?.get("webapp_paypal")
    }
    get webapp_satispay() {
        return this.paymentsAvailablesForm?.get("webapp_satispay")
    }
    get webapp_nexi() {
        return this.paymentsAvailablesForm?.get("webapp_nexi")
    }

    async alvoloSync(){
        await this.restManager.getOne('alvolo/sync');
    }

    async onSubmitAvailablePaymentsForm(){
        let available_payments: string[] = [];
        Object.entries(this.paymentsAvailablesForm.value).forEach(([key, value]) => { if(value) available_payments.push(key)})

        if(!this.shopStatus) return;
        if(available_payments.length === 0) {
            this.alertDialogService.openDialog({ data: { messageLabel: 'SETTINGS.ALVOLO.PAYMENTS.NO_PAYMENTS_SELECTED' } });
            return;
        }
        this.disableSubmitPaymentsButtonFetch = true;
        this.shopPreference.available_payments = available_payments;
        await this.restManager.put('alvolo/api', 'shops', {payload: {shop: this.shopPreference}})
        .then((response: any) => {
            if(response){
                this.shopPreference.available_payments = available_payments;
                this.configurationManagerService.setShopPreference('alvolo.shop', JSON.stringify(this.shopPreference));                
                this.openDialogService.openSnackBarTilby('SETTINGS.ALVOLO.PAYMENTS.PAYMENTS_SAVED', 'MISC.OK', { duration: 3000 });  
                this.alvoloSync();

                this.paymentsLinksToShow.app_stripe = available_payments.includes("app_stripe");
                this.paymentsLinksToShow.app_nexi = available_payments.includes("app_nexi");
                this.paymentsLinksToShow.paypal = available_payments.includes("paypal");
                this.paymentsLinksToShow.satispay = available_payments.includes("satispay");

                this.configurationManagerService.setShopPreference('alvolo.payments.links_to_show', JSON.stringify(this.paymentsLinksToShow));
                this.disableSubmitPaymentsButtonFetch = false;
                this.disableSubmitPaymentsButtonWait = true;
            }
        })
        .catch((error: any) => {
            this.alertDialogService.openDialog({ data: { messageLabel: 'SETTINGS.ALVOLO.PAYMENTS.PAYMENTS_SAVING_ERROR' } });
            this.disableSubmitPaymentsButtonFetch = false;
            this.disableSubmitPaymentsButtonWait = true;
            this.getPaymentPreferences();
        })

        setTimeout(() => {
            this.disableSubmitPaymentsButtonWait = false;
        }, 4000)
    }

    configureAlvoloSatispay(event: any){
        event.preventDefault();
        this.satispayDialogService.openDialog({data: {title: 'SETTINGS.ALVOLO.PAYMENTS.PAYMENTS_CONFIGURATION.SATISPAY'}});
    }
    configureAlvoloPaypal(event: any){
        event.preventDefault();
        this.paypalDialogService.openDialog({data: {title: 'SETTINGS.ALVOLO.PAYMENTS.PAYMENTS_CONFIGURATION.PAYPAL'}});
    }
    configureAlvoloNexi(event: any){
        event.preventDefault();
        this.nexiDialogService.openDialog({data: {title: 'SETTINGS.ALVOLO.PAYMENTS.PAYMENTS_CONFIGURATION.NEXI'}});
    }

    redirectToStripeConfiguration(event: any, shopSettingKey: string | null, shopSettingAlias: string){
        event.preventDefault();
        const domain = EnvironmentConfig.environment === 'beta' ? 'heisenberg' : EnvironmentConfig.environment === 'stable' ? 'prof' : '';
        this.util.openExternalLink(`http://${domain}.api.alvolo.app/it/stripe-connect/?key=${shopSettingKey}&alias=${shopSettingAlias}`)
    }
    //End Available Payments Section

    //Start management section
    changePriceListId(priceList: {index: number, name: string}){
        this.priceListId = priceList.index;
        this.configurationManagerService.setShopPreference("alvolo.pricelist_id", priceList.index);
    }

    publish(){
        if(!this.priceListId) return;
        this.isPublishing = true;
        this.restManager.getOne('alvolo/sync')
            .then(() => {
                this.isPublishing = false;
                this.openDialogService.openSnackBarTilby('SETTINGS.ALVOLO.MANAGEMENT.PUBLICATION_COMPLETED', 'MISC.OK', { duration: 3000 });      
                this.configurationManagerService.setShopPreference('alvolo.last_sync', (new Date().toISOString()));
                this.lastSync = new Date().toISOString();
                this.lastPublishDay = this.formatDate(new Date());
                this.lastPublishHour = `${new Date().getHours()}:${this.addZero(new Date().getMinutes())}`;
            })
            .catch((error: any) => {
                this.openDialogService.openSnackBarTilby('SETTINGS.ALVOLO.MANAGEMENT.PUBLICATION_ERROR', 'MISC.OK', { duration: 3000 });  
                this.isPublishing = false;
            })
    }

    addZero(num: number) {
        if (num < 10) {
            return '0' + num;
        }
        return num;
    }

    copyText(label: string, field: HTMLInputElement) {
        if (field.value) {
            navigator.clipboard.writeText(field.value);
            this.openDialogService.openSnackBarTilby(`${this.translate.instant(label)} ${this.translate.instant('SETTINGS.ALVOLO.MANAGEMENT.COPY_TO_CLIPBOARD')}`, 'MISC.OK', { duration: 3000 });
        }
    }

    redirectToDashboard() {
        this.util.openExternalLink(this.alvoloUtilsService.getDashboardUrl());
    }

    async activateAlvoloCommunication() {
        await this.restManager.post('alvolo/set_webhooks', {});
    }
    //End management section

    //Management time slots
    addTimeSlot() {
        this.addTimeSlotDialogStateService.openDialog({data: {title: 'SETTINGS.ALVOLO.HOURS.ADD_TIME_SLOT'}}).then(() => this.reloadDataSource());
    }

    editTimeSlot(timeSlot: TimeTableTypeAPI, timeSlotToEditIndex?: number) {
        let index: number = timeSlotToEditIndex ?? this.dataSource.findIndex(t => t.name === timeSlot.name);
        this.addTimeSlotDialogStateService.openDialog({data: {title: 'SETTINGS.ALVOLO.HOURS.EDIT_TIME_SLOT', timeSlot, index}}).then(() => this.reloadDataSource());
    }

    moveUpTimeSlot(index: number) {
        const shop_preference = this.settingsAlvoloStateService.getShopPreference();
        const timeSlot = shop_preference?.service_time?.[index];
        shop_preference?.service_time?.splice(index, 1);
        shop_preference?.service_time?.splice(index-1, 0, timeSlot!);

        this.disableButtons(true);
        this.settingsAlvoloStateService.updateExistingShop(shop_preference!).then(() => {
            this.reloadDataSource();
        }).finally(() => {
            setTimeout(() => {
                this.disableButtons(false);
            }, 4000);
        })
    }

    moveDownTimeSlot(index: number) {
        const shop_preference = this.settingsAlvoloStateService.getShopPreference();
        const timeSlot = shop_preference?.service_time?.[index];
        shop_preference?.service_time?.splice(index, 1);
        shop_preference?.service_time?.splice(index+1, 0, timeSlot!);

        this.disableButtons(true);
        this.settingsAlvoloStateService.updateExistingShop(shop_preference!).then(() => {
            this.reloadDataSource();
        }).finally(() => {
            setTimeout(() => {
                this.disableButtons(false);
            }, 4000);
        })
    }

    duplicateTimeSlot(index: number) {
        const shop_preference = this.settingsAlvoloStateService.getShopPreference();
        const timeSlot = shop_preference?.service_time?.[index];
        shop_preference?.service_time?.splice(index, 0, timeSlot!)

        this.disableButtons(true);
        this.settingsAlvoloStateService.updateExistingShop(shop_preference!).then(() => {
            this.reloadDataSource();
        }).finally(() => {
            setTimeout(() => {
                this.disableButtons(false);
            }, 4000);
        })
    }

    async removeTimeSlot(index: number) {
        const answer = await this.confirmDialogService.openDialog({
            data: {
                messageLabel: 'SETTINGS.ALVOLO.HOURS.REMOVE_TIME_SLOT'
            }
        });

        if(!answer) {
            return;
        }

        const shop_preference = this.settingsAlvoloStateService.getShopPreference();
        shop_preference?.service_time?.splice(index, 1);   
        if(shop_preference?.service_time?.length === 0) {
            shop_preference?.service_time?.push({
                date: null,
                week_day: "",
                start: "",
                end: "",
                capacity: null,
                single_fraction_capacity: 0,
                single_order_capacity: 0,
                single_order_money_capacity: null,
                single_order_min_money_capacity: null,
                operative_mode: [],
                hourly_fractions: 0,
                max_orders_per_fraction: 0,
                max_distance: 0,
                delivery_options: {
                    delivery_price: {
                        pricelist_id: "",
                        price: 0,
                        vat_price: 0,
                        vat: 0
                    },
                    delivery_free_from: 0,
                    distance_based_delivery_price: {}
                },
                hidden_categories: {},
                hidden_products: {}
            })
        }

        try {
            this.disableButtons(true);
            await this.settingsAlvoloStateService.updateExistingShop(shop_preference!);
            this.reloadDataSource();
        } catch (error: any) {
            if (error.data.error.error.message.includes('At least one "service_time" timetable is required.')) {
                this.alertDialogService.openDialog({ data: { messageLabel: "SETTINGS.ALVOLO.HOURS.REMOVE_TIME_SLOT_ERROR" } });
            }
        } finally {
            setTimeout(() => {
                this.disableButtons(false);
            }, 4000);
        }
    }

    reloadDataSource() {
        this.dataSource = this.settingsAlvoloStateService.getShopPreference()?.service_time || [];
        this.table.renderRows();
    }
    //End Management time slots

    //Start QRCode
    private initQrCode() {
        return `${this.alvoloUtilsService.getBaseUrl()}/${this.shopSettingAlias}`;
    }

    saveAsImage(parent: FixMeLater) {
        let parentElement = null;

        if (this.elementType === "canvas") {
            parentElement = parent.qrcElement.nativeElement
                .querySelector("canvas")
                .toDataURL("image/png");
        } else if (this.elementType === "img" || this.elementType === "url") {
            parentElement =
                parent.qrcElement.nativeElement.querySelector("img").src;
        } else {
            alert("Set elementType to 'canvas', 'img' or 'url'.");
        }

        if (parentElement) {
            let blobData = this.convertBase64ToBlob(parentElement);
            const blob = new Blob([blobData], { type: "image/png" });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = "qrcode";
            link.click();
        }
    }

    redirectToAlvolo() {
        this.util.openExternalLink(`${this.alvoloUtilsService.getBaseUrl()}/${this.shopSettingAlias}`);
    }

    private convertBase64ToBlob(Base64Image: string) {
        // split into two parts
        const parts = Base64Image.split(";base64,");
        // hold the content type
        const imageType = parts[0].split(":")[1];
        // decode base64 string
        const decodedData = window.atob(parts[1]);
        // create unit8array of size same as row data length
        const uInt8Array = new Uint8Array(decodedData.length);
        // insert all character code into uint8array
        for (let i = 0; i < decodedData.length; ++i) {
            uInt8Array[i] = decodedData.charCodeAt(i);
        }
        // return blob image after conversion
        return new Blob([uInt8Array], { type: imageType });
    }
    //End QRCode Summary

    //Start SelfOrdering
    async downloadQrCodes() {
        this.disableDownloadButton = true;
        this.qrCodeSelfOrdering = "";
        this.disableSubmitSelfOrderingButtonWait = true;
        switch(this.generateCodeType){
            case "free_order":
                if(this.generateCodeGraphic === "vertical_placeholder"){
                    this.mergeAlvoloCardAndQrCode(false, false, this.qrcodes_free_order_base64, (overlappedImage) => {
                        this.downloadSingleQrCode(overlappedImage);
                    });
                } else if (this.generateCodeGraphic === "only_qrcode") {
                    this.downloadSingleQrCode(this.qrcodes_free_order_base64);
                }
                this.disableDownloadButton = false;
                break;
            case "tables":
                if(this.getQrCodesTablesFirstTime) {
                    this.getQrCodesTables(() => {                    
                        if(this.qrcodes_tables_base64.length !== this.tables_ids.length) return;
                        this.downloadQrCodesInZip('qrcodes_tables', this.generateCodeGraphic === "vertical_placeholder" ? this.qrcodes_tables_base64 : this.qrcodes_tables_base64_only_qrcodes);
                    });
                    this.getQrCodesTablesFirstTime = false;
                }else {
                    setTimeout(() => {
                        this.downloadQrCodesInZip('qrcodes_tables', this.generateCodeGraphic === "vertical_placeholder" ? this.qrcodes_tables_base64 : this.qrcodes_tables_base64_only_qrcodes);
                        this.disableDownloadButton = false;
                    }, 4000)
                }
                break;
            case "rooms":
                if(this.roomsFormGroup.invalid) {
                    this.disableDownloadButton = false;
                    this.disableSubmitSelfOrderingButtonWait = false;
                    return;
                }

                this.qrcodes_rooms_base64 = [];
                this.qrcodes_rooms = [];
                let index = this.roomsFormGroup.controls.initial_room_id.value === 1 ? 0 : this.roomsFormGroup.controls.initial_room_id.value!;
                this.final_index = this.roomsFormGroup.controls.initial_room_id.value === 1 ? this.roomsFormGroup.controls.final_room_id.value - 1 : this.roomsFormGroup.controls.final_room_id.value;
                let nested_index = index;

                for(index; index <= this.final_index; index++) {
                    this.qrCodeSelfOrdering = `${this.initQrCode()}/pickup/R-${index+1}/camera/${index+1}`;
                    this.qrcodes_rooms.push(this.qrCodeSelfOrdering);
                    await this.convertUrlToBase64AndBlob(this.qrCodeSelfOrdering, (base64) => {
                        if(this.generateCodeGraphic === 'vertical_placeholder') {                            
                            this.mergeAlvoloCardAndQrCode(false, true, base64, (overlappedImage) => {
                                this.qrcodes_rooms_base64.push(overlappedImage);
                            }, (this.roomsFormGroup.controls.initial_room_id.value! === 1) ? ++nested_index : nested_index++, undefined);
                        } else if (this.generateCodeGraphic === 'only_qrcode') {
                            this.qrcodes_rooms_base64.push(base64);
                        }
                    });
                }

                setTimeout(() => {
                    if(this.roomsFormGroup.controls.initial_room_id.value! === 1) { 
                        (this.roomsFormGroup.controls.final_room_id.value! === index) && this.downloadQrCodesInZip('qrcodes_rooms', this.qrcodes_rooms_base64);
                    }  else {
                        (this.roomsFormGroup.controls.final_room_id.value! === (index - 1)) && this.downloadQrCodesInZip('qrcodes_rooms', this.qrcodes_rooms_base64);
                    } 
                    this.disableDownloadButton = false;

                }, 2000);
                break;
            case "custom_codes":
                if(this.qrcodes_custom_codes_base64.length === 0) return;
                this.downloadQrCodesInZip('qrcodes_custom_codes', this.qrcodes_custom_codes_base64);
                this.disableDownloadButton = false;
                break;
        }

        setTimeout(() => {
            this.disableSubmitSelfOrderingButtonWait = false;
        }, 3000);
    }

    mergeAlvoloCardAndQrCode(qrcode_table: boolean = false, qrcode_room: boolean = false,  qrcode: string, callback: (result: string) => void, room_id?: number, table_id?: number) {

        let room_name = "";
        let table_name = "";

        if(qrcode_table) {
            for(let room of this.roomAndTable){
                for(let table of room.tables || []) {
                    if(table.id === table_id){
                        room_name = room.name;
                        table_name = table.name;
                        this.table_and_room.push(`${room_name}/${table_name}`);
                        this.rooms_and_tables.push({Sala: room.name, Tavolo: table.name})
                        break;
                    }
                }
            }
        }

        let canvas = document.createElement('canvas');
        let context = canvas.getContext('2d');
        let img1 = new Image();
        let img2 = new Image();
        img1.src = this.alvolo_card_base;
        img2.src = qrcode;

        img1.onload = () => {
            img2.onload = () => {
                canvas.width = img1.width;
                canvas.height = img1.height;
                
                context!.drawImage(img1, 0, 0);
                context!.drawImage(img2, 435, qrcode_table || qrcode_room ? 750 : 800, 550, 550);

                //tables
                (qrcode_table && !qrcode_room) && (context!.font = "60px arial");
                (qrcode_table && !qrcode_room) && context!.fillText(`${table_name}`, 620, 1320);
                (qrcode_table && !qrcode_room) && context!.fillText(`${room_name}`, 620, 1400);

                //rooms
                (!qrcode_table && qrcode_room) && (context!.font = "60px arial");
                (!qrcode_table && qrcode_room) && context!.fillText(`${room_id}`, 690, 1340);

                let overlappedImage = new Image();
                overlappedImage.src = canvas.toDataURL('image/png');
                callback(overlappedImage.src);
            };
        }
    }

    onCustomCodesChange(event: any) {
        clearTimeout(this.timeout);
        this.custom_codes_splited = [];
        this.timeout = setTimeout(() => {
            this.qrcodes_custom_codes_base64 = [];
            this.qrcodes_custom_codes = [];
            this.custom_codes_splited = event.target.value.split(/[\r\n]+/).filter((e: string) => e.trim().length > 0);
            for (const custom_code of this.custom_codes_splited) {
                this.qrCodeSelfOrdering = `${this.initQrCode()}/pickup/C-${custom_code}/posizione/${custom_code}`;
                this.qrcodes_custom_codes.push(this.qrCodeSelfOrdering);
                this.convertUrlToBase64AndBlob(this.qrCodeSelfOrdering, (base64) => {
                    if(this.generateCodeGraphic === 'vertical_placeholder') {   
                        this.mergeAlvoloCardAndQrCode(false, false, base64, (overlappedImage) => {
                            this.qrcodes_custom_codes_base64.push(overlappedImage);
                        })
                    }

                    if (this.generateCodeGraphic === 'only_qrcode') {
                        this.qrcodes_custom_codes_base64.push(base64);
                    }
                });
            };
        }, 50);
    }

    radioChange(event: MatRadioChange) {

        event.value ? this.radioButtonSelected = event.value : this.radioButtonSelected = undefined;

        if(event.value !== 'custom_codes'){
            this.custom_codes = "";
        } 
        
        if(event.value !== 'rooms'){
            this.initial_room_id = "";
            this.final_room_id = "";
            this.roomsFormGroup.controls.initial_room_id.reset();
            this.roomsFormGroup.controls.final_room_id.reset();
        }
    }

    radioChangeGraphic(event: MatRadioChange) {
        this.onCustomCodesChange({target: {value: this.custom_codes}});
    }

    async getRoomAndTableIds() {
        this.rooms_ids = [];
        this.tables_ids = [];

        this.roomAndTable = await this.entityManagerService.rooms.fetchCollectionOffline();

        for (const room of this.roomAndTable) {
            this.rooms_ids.push(room.id!);

            for(const table of room.tables || []) {
                this.tables_ids.push(table.id!);
            }
        }
    }

    downloadSingleQrCode(base64: string) {
        let blobData = this.convertBase64ToBlob(base64);
        const blob = new Blob([blobData], { type: "image/png" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "qrcode_free_order";
        link.click();
    }

    customCodes() {
        this.customCodesBtnIsVisible = true;
        this.qrCodeSelfOrdering = "";
        this.custom_codes = "";
    }

    generateQrCodeCustomCode(){
        if(!this.custom_codes) return;
        this.qrCodeSelfOrdering = `${this.initQrCode()}/pickup/C-${this.custom_codes}/posizione/${this.custom_codes}`;
    }

    downloadQrCodesInZip(title_zip: string, qrcodes_base64: string[]) {
        const jszip = new JSZip();
        for(let i = 0; i < qrcodes_base64.length; i++) {
            
            var binary = window.atob(qrcodes_base64[i].split(',')[1]);
            var array = [];
            
            for (let j = 0; j < binary.length; j++) {
                array.push(binary.charCodeAt(j));
            }
            let image = new Blob([new Uint8Array(array)], {
                type: 'image/png'
            });

            if(title_zip === "qrcodes_rooms") {
                jszip.file(`${i+1}.png`, image);
            } else if (title_zip === "qrcodes_tables") {
                if(!this.table_and_room[i].includes("ph_")) jszip.file(`${this.table_and_room[i]}.png`, image);
            } else if(title_zip === "qrcodes_custom_codes") {
                jszip.file(`${this.custom_codes_splited[i]}.png`, image);
            } else {
                jszip.file(`qrcode_${i+1}.png`, image);
            }

            if(i === (qrcodes_base64.length -1)){
                const { file, title } = this.generateExcelFile(
                    title_zip, 
                    title_zip === "qrcodes_tables" ? 'tavolo' : title_zip === "qrcodes_rooms" ? 'camera' : title_zip === "qrcodes_custom_codes" ? 'codice personalizzato' : '', 
                    title_zip === "qrcodes_tables" ? this.qrcodes_tables : title_zip === "qrcodes_rooms" ? this.qrcodes_rooms : title_zip === "qrcodes_custom_codes" ? this.qrcodes_custom_codes : [], 
                    title_zip === "qrcodes_tables" ? this.rooms_and_tables : title_zip === "qrcodes_custom_codes" ? this.custom_codes_splited : [],
                    title_zip === "qrcodes_rooms" ? this.final_index : 0,
                );
                jszip.file(title, file);
                jszip.generateAsync({ type: 'blob' })
                .then((content) => saveAs(content, title_zip+'.zip'));
            }
        }
    }

    //CONVERT URL INTO Base64 and Blob
    async convertUrlToBase64AndBlob(url: string, callback: (result: string) => void) {
        try {        
            const config = {
                color: {
                    dark: "#000000ff",
                    light: "#ffffffff",
                },
                errorCorrectionLevel: "M",
                margin: 4,
                scale: 4,
                version: '',
                width: 10,
            };
            const centerImageSrc = url;
            const centerImageHeight = 400;
            const centerImageWidth = 400;
            let context!: CanvasElement;

            const canvasElement = this.renderer.createElement("canvas");
            context = canvasElement.getContext("2d");
            return await this.toCanvas(canvasElement, config).then(() => {
                if (centerImageSrc && context) {
                    this.centerImage = new Image(centerImageWidth, centerImageHeight);
                    if (centerImageSrc !== this.centerImage.src) {
                        this.centerImage.src = centerImageSrc;
                    }
                    if (centerImageHeight !== this.centerImage.height) {
                        this.centerImage.height = centerImageHeight;
                    }
                    if (centerImageWidth !== this.centerImage.width) {
                        this.centerImage.width = centerImageWidth;
                    }
                    const centerImage = this.centerImage;
                    if (centerImage) {
                        centerImage.onload = () => {
                            this.context?.drawImage(centerImage, canvasElement.width / 2 - centerImageWidth / 2, canvasElement.height / 2 - centerImageHeight / 2, centerImageWidth, centerImageHeight);
                        };
                    }
                }
                const className = canvasElement.constructor.name;
                let urlImage = "";
                if (className === HTMLCanvasElement.name) {
                    urlImage = canvasElement.toDataURL("image/png");
                }
                if (className === HTMLImageElement.name) {
                    urlImage = canvasElement.src;
                }
                fetch(urlImage)
                .then((urlResponse) => {
                    callback(urlResponse.url);
                    return urlResponse.blob();
                })
                .then((blob) => URL.createObjectURL(blob))
                .then((url) =>  this.sanitizer.bypassSecurityTrustUrl(url))
            })
            .catch((error: any) => {
                DevLogger.log("Error when fetching image/png URL: " + error);
            });
        } catch (error) {
            DevLogger.log(error)
        }
    }

    toCanvas(canvas: CanvasElement, qrCodeConfig: any) {
        return new Promise((resolve, reject) => {
            toCanvas(canvas, this.qrCodeSelfOrdering, qrCodeConfig, (error: any) => {
                if (error) {
                    reject(error);
                }
                else {
                    resolve("success");
                }
            });
        });
    }
    //END CONVERT URL INTO Base64 and Blob

    //End SelfOrdering

    //Start Table Reservation

    createTableReservationForm() {
        const bookings_delay_label = [
            `30 ${this.translate.instant('SETTINGS.ALVOLO.TABLE_RESERVATION.BOOKINGS_DELAY_LABEL.MINUTES')}`, 
            `1 ${this.translate.instant('SETTINGS.ALVOLO.TABLE_RESERVATION.BOOKINGS_DELAY_LABEL.HOUR')}`, 
            `2 ${this.translate.instant('SETTINGS.ALVOLO.TABLE_RESERVATION.BOOKINGS_DELAY_LABEL.HOURS')}`, 
            `3 ${this.translate.instant('SETTINGS.ALVOLO.TABLE_RESERVATION.BOOKINGS_DELAY_LABEL.HOURS')}`, 
            `4 ${this.translate.instant('SETTINGS.ALVOLO.TABLE_RESERVATION.BOOKINGS_DELAY_LABEL.HOURS')}`, 
            `5 ${this.translate.instant('SETTINGS.ALVOLO.TABLE_RESERVATION.BOOKINGS_DELAY_LABEL.HOURS')}`, 
            `6 ${this.translate.instant('SETTINGS.ALVOLO.TABLE_RESERVATION.BOOKINGS_DELAY_LABEL.HOURS')}`, 
            `7 ${this.translate.instant('SETTINGS.ALVOLO.TABLE_RESERVATION.BOOKINGS_DELAY_LABEL.HOURS')}`, 
            `8 ${this.translate.instant('SETTINGS.ALVOLO.TABLE_RESERVATION.BOOKINGS_DELAY_LABEL.HOURS')}`, 
            `9 ${this.translate.instant('SETTINGS.ALVOLO.TABLE_RESERVATION.BOOKINGS_DELAY_LABEL.HOURS')}`, 
            `10 ${this.translate.instant('SETTINGS.ALVOLO.TABLE_RESERVATION.BOOKINGS_DELAY_LABEL.HOURS')}`, 
        `11 ${this.translate.instant('SETTINGS.ALVOLO.TABLE_RESERVATION.BOOKINGS_DELAY_LABEL.HOURS')}`, 
        `12 ${this.translate.instant('SETTINGS.ALVOLO.TABLE_RESERVATION.BOOKINGS_DELAY_LABEL.HOURS')}`, 
        `1 ${this.translate.instant('SETTINGS.ALVOLO.TABLE_RESERVATION.BOOKINGS_DELAY_LABEL.DAY')}`, 
        `2 ${this.translate.instant('SETTINGS.ALVOLO.TABLE_RESERVATION.BOOKINGS_DELAY_LABEL.DAYS')}`, 
        `3 ${this.translate.instant('SETTINGS.ALVOLO.TABLE_RESERVATION.BOOKINGS_DELAY_LABEL.DAYS')}`, 
        `4 ${this.translate.instant('SETTINGS.ALVOLO.TABLE_RESERVATION.BOOKINGS_DELAY_LABEL.DAYS')}`, 
        `5 ${this.translate.instant('SETTINGS.ALVOLO.TABLE_RESERVATION.BOOKINGS_DELAY_LABEL.DAYS')}`, 
        `6 ${this.translate.instant('SETTINGS.ALVOLO.TABLE_RESERVATION.BOOKINGS_DELAY_LABEL.DAYS')}`, 
        `1 ${this.translate.instant('SETTINGS.ALVOLO.TABLE_RESERVATION.BOOKINGS_DELAY_LABEL.WEEK')}`, 
        `2 ${this.translate.instant('SETTINGS.ALVOLO.TABLE_RESERVATION.BOOKINGS_DELAY_LABEL.WEEKS')}`, 
        `3 ${this.translate.instant('SETTINGS.ALVOLO.TABLE_RESERVATION.BOOKINGS_DELAY_LABEL.WEEKS')}`, 
        `1 ${this.translate.instant('SETTINGS.ALVOLO.TABLE_RESERVATION.BOOKINGS_DELAY_LABEL.MONTH')}`
    ];
        const bookings_delay_value = [30, 60, 120, 180, 240, 300, 360, 420, 480, 540, 600, 660, 720, 1440, 2880, 4320, 5760, 7200, 8640, 10080, 20160, 30240, 43200];

        const choices: KeyValue<string, number>[]  = bookings_delay_label.map((label, index) => ({key: label, value: bookings_delay_value[index]}));
        const shop_preference = this.settingsAlvoloStateService.getShopPreference();

        this.tableReservationForm = new CustomFormGroup({
            bookings_delay: new CustomFormControl(shop_preference?.bookings_delay ?? null, {}, {...new CustomFormControlProps(), readonly: !this.alvolo_bookings, inputType: 'select', inputChoices: choices, id:'bookings_delay', label: 'SETTINGS.ALVOLO.TABLE_RESERVATION.BOOKINGS_DELAY'}),
            bookings_days_to_show: new CustomFormControl(shop_preference?.bookings_days_to_show ?? null, {validators: [Validators.min(1)]}, {...new CustomFormControlProps(), readonly: !this.alvolo_bookings, inputType: 'number', id:'bookings_days_to_show', label: 'SETTINGS.ALVOLO.TABLE_RESERVATION.BOOKINGS_DAYS_TO_SHOW'}),
        })
    }

    addBookingTimeSlot() {
        this.addBookingsTimeDialogStateService.openDialog({data: {title: 'SETTINGS.ALVOLO.TABLE_RESERVATION.ADD_BOOKINGS_TIME'}}).then(() => this.reloadBookingsTimeSlot());
    }

    editBookingTimeSlot(bookingTime: BookingTimetableType, bookingTimeToEditIndex?: number) {
        let index: number = bookingTimeToEditIndex ?? this.bookings_time.findIndex(t => t.name === bookingTime.name);
        this.addBookingsTimeDialogStateService.openDialog({data: {title: 'SETTINGS.ALVOLO.TABLE_RESERVATION.ADD_BOOKINGS_TIME', bookingTime, index}}).then(() => this.reloadBookingsTimeSlot());
    }

    moveUpBookingTimeSlot(index: number) {
        const shop_preference = this.settingsAlvoloStateService.getShopPreference();
        const bookingTimeSlot = shop_preference?.bookings_time?.[index];
        shop_preference?.bookings_time?.splice(index, 1);
        shop_preference?.bookings_time?.splice(index-1, 0, bookingTimeSlot!);

        this.disableButtons(true);

        this.settingsAlvoloStateService.updateExistingShop(shop_preference!).then(() => {
            this.reloadBookingsTimeSlot();
        }).finally(() => {
            setTimeout(() => {
                this.disableButtons(false);
            }, 4000);
        })
    }

    moveDownBookingTimeSlot(index: number) {
        const shop_preference = this.settingsAlvoloStateService.getShopPreference();
        const bookingTimeSlot = shop_preference?.bookings_time?.[index];
        shop_preference?.bookings_time?.splice(index, 1);
        shop_preference?.bookings_time?.splice(index+1, 0, bookingTimeSlot!);

        this.disableButtons(true);

        this.settingsAlvoloStateService.updateExistingShop(shop_preference!).then(() => {
            this.reloadBookingsTimeSlot();
        }).finally(() => {
            setTimeout(() => {
                this.disableButtons(false);
            }, 4000);
        })
    }

    duplicateBookingTimeSlot(index: number) {
        const shop_preference = this.settingsAlvoloStateService.getShopPreference();
        const bookingTimeSlot = shop_preference?.bookings_time?.[index];
        shop_preference?.bookings_time?.splice(index, 0, bookingTimeSlot!)

        this.disableButtons(true);

        this.settingsAlvoloStateService.updateExistingShop(shop_preference!).then(() => {
            this.reloadBookingsTimeSlot();
        }).finally(() => {
            setTimeout(() => {
                this.disableButtons(false);
            }, 4000);
        })
    }

    async removeBookingTimeSlot(index: number){
        const answer = await this.confirmDialogService.openDialog({
            data: {
                messageLabel: 'SETTINGS.ALVOLO.TABLE_RESERVATION.REMOVE_BOOKINGS_TIME'
            }
        });
        
        if(!answer) {
            return;
        }

        const shop_preference = this.settingsAlvoloStateService.getShopPreference();
        shop_preference?.bookings_time?.splice(index, 1);            
        shop_preference?.bookings_time?.length === 0 && (shop_preference.bookings_time = null);
        
        try {
            this.disableButtons(true);

            await this.settingsAlvoloStateService.updateExistingShop(shop_preference!);
            this.reloadBookingsTimeSlot();
        } catch (error: any) {
            if(error.data.error.error.message.includes('At least one "service_time" timetable is required.')) {
                this.alertDialogService.openDialog({ data: { messageLabel: "SETTINGS.ALVOLO.TABLE_RESERVATION.REMOVE_TIME_SLOT_ERROR" } });
            }
        } finally {
            setTimeout(() => {
                this.disableButtons(false);
            }, 4000);
        }
    }

    reloadBookingsTimeSlot() {
        this.bookings_time = this.settingsAlvoloStateService.getShopPreference()?.bookings_time || [];
        if(this.bookings_time.length > 0) this.bookingsTimeTable.renderRows();
    }

    disableButtons(isDisabled: boolean) {
        this.disableMoveUpButton = isDisabled;
        this.disableMoveDownButton = isDisabled;
        this.disableDuplicateButton = isDisabled;
        this.disableDeleteButton = isDisabled;
    }

    get bookings_delay() {return this.tableReservationForm?.controls.bookings_delay; };
    get bookings_days_to_show() {return this.tableReservationForm?.controls.bookings_days_to_show; };
    //End Table Reservation

    //Generate file xls
    generateExcelFile(title: string, type: string, qrcodes: string[], data: any[], rows?: number) {
        let dataToSend: {Sala?: string, Tavolo?: string, Camera?: number, Codice?: string, Tipo: string, QrCode: string}[] = [];

        if(title === "qrcodes_tables") {
            data.forEach(({Sala, Tavolo}, index) => {
                if(!Tavolo.includes("ph_")) dataToSend.push({Sala, Tavolo, Tipo: type, QrCode: qrcodes[index]})
            });
        } else if (title === "qrcodes_rooms") {
            for(let i = 0; i<= rows!; i++) {
                dataToSend.push({Camera: i+1, Tipo: type, QrCode: qrcodes[i]})
            }
        } else if (title === "qrcodes_custom_codes") {
            data.forEach((custom_code, index) => {
                dataToSend.push({Codice: custom_code, Tipo: type, QrCode: qrcodes[index]})
            });
        }

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(dataToSend);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        const excelBuffer = XLSX.write(workbook, { type: 'array', bookType: 'xls' });
        const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        return {file: excelBlob, title: `${title}.xls`};
    }
    //End Generate file xls
}