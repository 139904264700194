import {
    CommonModule
} from "@angular/common";

import {
    Component,
    inject,
    Injectable
} from "@angular/core";

import {
    MatButtonModule
} from "@angular/material/button";

import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef
} from "@angular/material/dialog";

import {
    MatIconModule
} from "@angular/material/icon";

import {
    MatListModule
} from "@angular/material/list";

import {
    TranslateModule,
    TranslateService
} from "@ngx-translate/core";

import {
    TilbyDatePipe
} from "@tilby/tilby-ui-lib/pipes/tilby-date";

import {
    TilbyCurrencyPipe
} from "@tilby/tilby-ui-lib/pipes/tilby-currency";

import {
    BaseDialogService,
    NonNullableConfigData,
    TilbyDialogContentComponent,
    TilbyDialogToolbarComponent
} from "@tilby/tilby-ui-lib/components/tilby-dialog";

import {
    lastValueFrom
} from "rxjs";

import {
    CashMovements
} from "tilby-models";

type CashMovementDialogParams = {
    cashMovement: CashMovements
}

@Component({
    selector: "app-cash-movement-dialog",
    templateUrl: "cash-movement.dialog.component.html",
    standalone: true,
    imports: [
        CommonModule,
        MatListModule,
        TranslateModule,
        TilbyDatePipe,
        MatIconModule,
        MatButtonModule,
        TilbyCurrencyPipe,
        TilbyDialogToolbarComponent,
        TilbyDialogContentComponent
    ],
})
export class CashMovementDialogComponent {
    private readonly dialogRef = inject(MatDialogRef);
    private readonly translate = inject(TranslateService)
    protected readonly data: CashMovementDialogParams = inject(MAT_DIALOG_DATA);
    protected readonly title = <string>this.translate.instant('DIALOG.CASH_MOVEMENT.TITLE');

    get historyObj() {
        return this.data;
    }

    cancel() {
        this.dialogRef.close({ cancel: true });
    }

    getMovementType = (movement_type: string) => {
        switch(movement_type) {
            case 'income' :
                return this.translate.instant('HISTORY.CASH_MOVEMENTS.INCOME');
            case 'outcome' :
                return this.translate.instant('HISTORY.CASH_MOVEMENTS.OUTCOME');
        }
    };
}

@Injectable({
    providedIn: 'root',
})
export class CashMovementDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public async openDialog(config: NonNullableConfigData<CashMovementDialogParams>): Promise<void> {
        const dialogConfig: NonNullableConfigData<CashMovementDialogParams> = {
            ...this.switchMobileDesktopDimensions({ width: '800px' }),
            ...config
        };

        return lastValueFrom(this.dialogRef.open(CashMovementDialogComponent, dialogConfig).afterClosed());
    }
}