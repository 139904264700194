import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, inject} from '@angular/core';
import {AgGridAngular} from 'ag-grid-angular';
import {
    ColDef,
    ColumnApi,
    ExcelStyle,
    FilterChangedEvent,
    FirstDataRenderedEvent,
    GetRowIdFunc,
    GetRowIdParams,
    GridApi,
    GridReadyEvent,
    HeaderValueGetterParams,
    IRowNode,
    IServerSideDatasource,
    IsRowSelectable,
    ModelUpdatedEvent,
    RowClickedEvent,
    RowNode,
    RowNodeTransaction,
    ServerSideTransactionResult,
    SideBarDef,
    SortChangedEvent,
    StatusPanelDef,
    ToolPanelVisibleChangedEvent,
    ValueFormatterParams
} from 'ag-grid-community';
import 'ag-grid-enterprise';
import {GridClickableButtonComponent} from './grid-clickable-button/grid-clickable-button.component';

import {
    DataExport,
    GridPanel,
    KeysOfUnion,
    PromotionsFe,
    TableData,
    TableRow,
    TableRowUpdatable
} from '../../model/model';

import {
    AlertDialogService,
    ConfirmDialogService,
} from 'src/app/dialogs';

import {first, Observable, tap} from 'rxjs';
import {FormControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {GridTraductions} from 'src/app/models/grid-traductions.model';
import {mobileCheck} from 'src/utilities';
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';
import {Feature, Rest} from 'src/app/models';
import {GridSaveUserPreferenceComponent} from './grid-save-user-preference/grid-save-user-preference.component';
import {
    GridTraductionsService,
    queryFilteredOrdered_FEtoBEConverter
} from './utils';
import {ClickableButton, GridCommon, GridFixedButtons} from './grid.model';
import {autobind} from 'src/app/models/decorators.model';
import {OnDestroyService} from 'src/app/core/services/on-destroy.service';
import {GridService} from './grid.service';
import {ConfigurationManagerService} from 'src/app/core/services/configuration-manager/configuration-manager.service';
import GridUtils, {filterType} from './utils/grid-utils';
import {CellClassParams} from 'ag-grid-community/dist/lib/entities/colDef';
import {ProcessCellForExportParams} from "ag-grid-community/dist/lib/interfaces/exportParams";
import {SkeletonGridRowComponent} from "../../../core/components/skeleton-loaders";
import { GridStatusBarComponent } from './grid-status-bar/grid-status-bar.component';
import {SuppliersFields} from "../../../features";
import {CustomersFields} from "../../../features/customers";
import { DevLogger } from '../../dev-logger';
import { TilbyCurrencyPipe } from '@tilby/tilby-ui-lib/pipes/tilby-currency';
import { restManager } from "../../../../../app/ajs-upgraded-providers";

let filterValue = '';


@Component({
    selector: 'app-grid-component',
    template: '',
    host: {class: 'tw-relative'},
})
export abstract class GridComponent implements OnInit, ClickableButton, GridCommon, OnDestroy {
    protected readonly _snackBar = inject(MatSnackBar);
    private readonly alertDialogService = inject(AlertDialogService);
    private readonly configurationManager = inject(ConfigurationManagerService);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    protected readonly gridService = inject(GridService);
    private readonly gridTraductionsService = inject(GridTraductionsService);
    private readonly onDestroyService = inject(OnDestroyService);
    private readonly restMangerService = inject(restManager);
    private readonly translateService = inject(TranslateService);

    //just have to exist for template (serverside overwrite them)
    protected readonly datasource?: IServerSideDatasource;
    protected readonly cacheBlockSize: any;
    protected readonly loadingCellRenderer: any = SkeletonGridRowComponent;
    public isRowSelectable: IsRowSelectable = (params: IRowNode<TableRow>) => this.isCheckable.call(this, params.data!);
    @Input() feature?: Feature;
    @Input() idField?: string;
    @Input() fixedButtons: GridFixedButtons = {
        checkbox: {visible: true, lockPosition: 'left'},
        customButton: {visible: true, cellRenderer: GridClickableButtonComponent, lockPosition: 'right'}
    }
    @Input() exportFormatterOptions? = ({column, value}: ProcessCellForExportParams) => {
        return value
    };
    @Input() selectRowUIEnabled?: boolean = false;
    @Output() areRowsSelected = new EventEmitter<boolean>();
    @Output() rowClicked = new EventEmitter<RowClickedEvent>();
    @Output() clickableButton = new EventEmitter<Rest>();
    @Output() clickableAction = new EventEmitter<any>();
    @Output() updateChange = new EventEmitter<TableRowUpdatable[]>();
    @Output() deleteChange = new EventEmitter<number[]>();
    @Output() gridReadyEmitter = new EventEmitter<void>();
    @Output() firstDataRenderedEmitter = new EventEmitter<void>();
    @Output() sortChangedEmitter = new EventEmitter<SortChangedEvent>();
    @Output() filterChangedEmitter = new EventEmitter<FilterChangedEvent>();

    /**
     Give access to Ag-grid functions
     */
    @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
    gridApi?: GridApi;
    gridColumnApi!: ColumnApi;
    context = {componentParent: this};
    headerAction = 'Actions';
    idDefaultLanguage = "en";
    localText?: GridTraductions;
    isMobile = false;
    // orientation: OrientationLockType = "landscape";
    isToastShow = false;
    toastSaveUserPreference?: MatSnackBarRef<any>;
    // For accessing the Grid's API

    columnsControl = new FormControl()
    columnDefsInitial: ColDef[] = [];
    // Data that gets displayed in the grid
    _tableData!: TableData;
    get tableData() {
        return this._tableData;
    }

    gridUtils = new GridUtils();

    @Input() set tableData(value: TableData) {
        this._tableData = value;
    }

    protected log(...args: any[]){
        DevLogger.log('GRID_SERVER_SIDE_COMPONENT',...args);
    }
    protected error(...args: any[]){
        DevLogger.error('GRID_SERVER_SIDE_COMPONENT',...args);
    }

    initTable() {
        this.columnDefs = [];
        const {checkbox, customButton} = this.fixedButtons;
        Object.entries(this._tableData.dataType).forEach(([k, v]) => {
            this.columnDefs.push({
                field: k,
                headerClass: "header-with-icon",
                sortable: (!!v && typeof v === 'object') ? v?.sortable : this.sortingsMenuDisabled(k),
                ...(filterType(k, v, this.formatterValue.bind(this))),
                headerValueGetter: this.localizeHeader.bind(this),
                valueFormatter: this.formatterValue.bind(this),
                cellClass: this.formatterExcel.bind(this),
                suppressMenu: this.filtersMenuDisabled(k)
            });
        });
        if (checkbox.visible) {
            this.columnDefs.unshift({
                colId: 'checkbox',
                headerCheckboxSelection: false,
                checkboxSelection: true,
                showDisabledCheckboxes: true,
                // headerComponent: CustomGridCheckboxAllComponent,
                lockVisible: true,
                pinned: checkbox.lockPosition,
                minWidth: 50,
                maxWidth: 55,
                lockPosition: checkbox.lockPosition,
                filter: false,
                sortable: false,
                suppressMenu: true,
                suppressMovable: true,
                enableRowGroup: false,
                enablePivot: false,
                resizable: false
            });
        }
        if (customButton.visible) {
            this.columnDefs.push({
                headerName: this.headerAction,
                field: this.headerAction,
                cellRenderer: customButton.cellRenderer,
                lockVisible: customButton.visible,
                colId: 'params',
                editable: false,
                minWidth: 62,
                maxWidth: 70,
                resizable: false,
                // headerClass: "actionsM",
                // cellClass: "actionsC",
                lockPosition: customButton.lockPosition,
                pinned: customButton.lockPosition,
                filter: false,
                sortable: false,
                suppressMenu: true,
                suppressMovable: true,
                enableRowGroup: false,
                enablePivot: false,
                headerValueGetter: this.localizeHeader.bind(this)
            });
        }
        this.localText = this.getGridLanguage();
        this.debugLog('GRID COLUMN DEFS', this.columnDefs);
    }

    // Each Column Definition results in one Column.
    columnDefs: ColDef[] = [];

    // DefaultColDef sets props common to all Columns
    defaultColDef: ColDef = {
        flex: 1,
        resizable: true,
        minWidth: 100,
        sortable: true,
        filter: true,
        filterParams: {
            buttons: ['reset', 'apply'],
            closeOnApply: true,
            suppressAndOrCondition: true,
            excelMode: 'windows'
        },
        suppressMovable: true,
        menuTabs: ['filterMenuTab'],
        // allow every column to be aggregated
        enableValue: false,
        // allow every column to be grouped
        enableRowGroup: false,
        // allow every column to be pivoted
        enablePivot: false,
        cellRenderer: this.loadingCellRenderer
    };

    rowClassRules: any;
    private previousNodeSelected?: IRowNode;
    private nodeSelected?: IRowNode;
    private gridRowsRendered = false;
    private resetSelection = false;
    overlayNoRowsTemplate = '<div class="no-rows-to-show" translate>No Results To Show</div>';

    onModelUpdated(event: ModelUpdatedEvent) {
        this.agGrid.firstDataRendered.pipe(first()).subscribe(_ => this.gridRowsRendered = true);
        if (this.gridRowsRendered) {
            event.api.getDisplayedRowCount() === 0
                ? event.api.showNoRowsOverlay()
                : event.api.hideOverlay();
        }

    };

    sideBar: SideBarDef | string | string[] | boolean | null = {
        toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                minWidth: 340,
                width: 340,
                maxWidth: 400,
                toolPanelParams: {
                    suppressRowGroups: true,
                    suppressValues: true,
                    suppressPivots: true,
                    suppressPivotMode: true,
                },
            },
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
                minWidth: 340,
                maxWidth: 400,
                width: 340,
            },
        ],
        position: 'left',
        hiddenByDefault: true,
        defaultToolPanel: 'Filters',
    };

    statusBar: {
            statusPanels: StatusPanelDef[];
        } = {
        statusPanels: [
            {
                statusPanel: GridStatusBarComponent,
            }
        ]
    }

    public excelStyles: ExcelStyle[] = [
        {
            id: 'dateFormat',
            dataType: 'DateTime',
            numberFormat: {
                format: 'DD/MM/YY HH:mm',
            },
        },
        {
            id: 'currencyFormat',
            numberFormat: {
                format: '#,##0.00 ' + TilbyCurrencyPipe.currency.symbol_native,
            },
        },
        {
            id: 'percentuageFormat',
            numberFormat: {
                format: '0%',
            },
        }
    ]

    private debugLog(...args: any[]) {
        DevLogger.debug('[ GridComponent ] ',...args);
    }

    getRowId: GetRowIdFunc = (params: GetRowIdParams) => params.data?.uuid || params.data?.[this.idField || 'id'];
    ngOnInit() {
        this.columnDefsInitial = this.columnDefs;
        this.columnsControl.setValue([...this.columnDefsInitial.map(c => c.field)]);
        this.columnsControl.valueChanges.pipe(this.onDestroyService.takeUntilDestroy, tap(this.debugLog)).subscribe(columnsToShow => this.columnDefs = this.columnDefsInitial.filter(c => columnsToShow.indexOf(c.field) > -1));
    }

    setColumnsPreferences() {
        let columnsPreferenceDefault = this.feature && this.gridUtils.getConfiguration(`grid.${this.feature}.${this.getMobile()}.columns`, this.feature, 0);

        let columnStatePreference = this.configurationManager.getPreference(`grid.${this.feature}.${this.getMobile()}.columns`) || undefined;

        if (!columnStatePreference) {
            columnStatePreference = columnsPreferenceDefault;
        }

        let columnStatePreferenceObj = JSON.parse(columnStatePreference || '[]');
        columnStatePreferenceObj ? this.gridColumnApi.applyColumnState({
            state: columnStatePreferenceObj,
            applyOrder: true
        }) : this.gridColumnApi.resetColumnState();
    }

    onToolPanelVisible(event: ToolPanelVisibleChangedEvent) {
        if (!event.source)
            this.gridApi?.setSideBarVisible(false);
    }

    // Example load data from sever
    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.rowClassRules = {'ag_grid-row-is-deleting tw-pointer-events-none': (params: any) => this.gridService.tableProperties.idsDeleting.indexOf(params.data?.id) > -1};
        this.translateService.onLangChange.pipe(this.onDestroyService.takeUntilDestroy).subscribe(() => {
            this.gridApi?.refreshHeader();
            this.gridApi?.refreshToolPanel();
        })
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.gridApi.refreshHeader();
        this.gridApi.refreshToolPanel();
        if (this.agGrid.gridOptions) {
            this.agGrid.gridOptions.suppressRowHoverHighlight = true;
            this.agGrid.gridOptions.suppressCellFocus = true;
        } else {
            console.error('this.agGrid.gridOptions undefined')
        }
        this.gridReadyEmitter.emit();
    }

    onFirstDataRendered(params: FirstDataRenderedEvent) {
        this.firstDataRenderedEmitter.emit();
    }

    onFilterChanged($event: FilterChangedEvent) {
        this.filterChangedEmitter.emit($event);
    }

    getRowClass = (params: any) => {
        if (this.selectRowUIEnabled) {
            const rowNode = params.node;
            if (!this.resetSelection && rowNode === this.nodeSelected) {
              return 'ag-row-background__selected';
            }
            return 'ag-row-background__unselected';
        }
    }

    gridReady(nodeSelected: ((itemsRowAdded: any) => IRowNode | undefined)) {
        // @ts-ignore
        this.gridService.tableProperties.gridIstances[this.agGrid?._nativeElement.id] = this.agGrid;
// eg. this.gridService.customers$
        (this.gridService[`${this.feature!}$`].flash as Observable<any>)
            .pipe(this.onDestroyService.takeUntilDestroy)
            .subscribe((itemsRow) => {
                this.debugLog('COMBINE READY');
                const {api: gridApi} = this.agGrid;
                const {isNew = false, ...itemsRowAdded} = itemsRow || {};
                setTimeout(() => {
                    this.debugLog('GRID_OPTIONS', this.agGrid.gridOptions);
                    let counter = 0;
                    while (((!this.nodeSelected || this.nodeSelected.id != itemsRowAdded.id) && counter <= 200)) {
                        this.nodeSelected = nodeSelected(itemsRowAdded);
                        this.debugLog(counter, 'WHILE', this.nodeSelected);
                        counter++;
                    }
                    if (isNew) {
                        this.nodeSelected = this.nodeSelected || nodeSelected(itemsRowAdded);
                        setTimeout(_ => gridApi.ensureIndexVisible(this.nodeSelected?.rowIndex || 0, 'bottom'))
                    }
                    if (itemsRow) this.nodeSelected?.setData(itemsRowAdded); //update grid rendering
                    // 2023-06-19 - Remove flash in grid
                    // setTimeout(_ => (!!this.nodeSelected) && gridApi.flashCells({
                    //     fadeDelay: 1000,
                    //     rowNodes: [this.nodeSelected],
                    // }));
                });
            })
    }


    //--------DEVICE AND ORIENTATION-------------

    // getOrientation(): string {
    //     return this.orientation.includes('landscape') ? 'landscape' : 'portrait';
    // }

    getMobile(): string {
        return mobileCheck() ? 'mobile' : 'tablet';
    }

    //---------------COLUMNS---------------------

    onColumnMoved(params: any) {
        if (params.source !== 'uiColumnMoved' && params.source !== 'toolPanelUi') {
            this.setColumnsPreferences();
        } else {
            if (!this.isToastShow) {
                this.showUserPreferenceToast();
            }
        }

    }

    onColumnVisible(params: any) {
        if (!this.isToastShow && params.source === "toolPanelUi") {
            this.showUserPreferenceToast();
        }
    }

    onColumnSortChanged(params: any) {
        this.sortChangedEmitter.emit(params);
        if (!this.isToastShow && params.source === "uiColumnSorted") {
            this.showUserPreferenceToast();
        }
    }

    onColumnResized(params: any) {
        if (!this.isToastShow && params.source === "uiColumnDragged" && params.finished) {
            this.showUserPreferenceToast();
        }
    }

    showUserPreferenceToast() {
        this.isToastShow = !this.isToastShow;
        this.toastSaveUserPreference = this._snackBar.openFromComponent(GridSaveUserPreferenceComponent, {
            verticalPosition: "bottom",
            horizontalPosition: "left",
            panelClass:['mat-snack-bar-tilby']
        });

        this.toastSaveUserPreference.afterDismissed().subscribe((event) => {
            if (!event.dismissedByAction) {
                this.setColumnsPreferences();
            }
            this.isToastShow = !this.isToastShow;
        });

        this.toastSaveUserPreference.onAction().subscribe(() => {
            let columnState = JSON.stringify(this.gridColumnApi.getColumnState());
            this.configurationManager.setUserPreference(this.getConfigurationColumnsPreferences(), columnState);
        });
    }

    getConfigurationColumnsPreferences(): `grid.${string}.${string}.columns` {
        // return `grid.${this.feature}.${this.getMobile()}.${this.getOrientation()}.columns`;
        return `grid.${this.feature}.${this.getMobile()}.columns`;
    }

    async openDialogSaveColumnsPreference() {
        const res = await this.confirmDialogService.openDialog({
            data: {
                messageLabel: 'DIALOG.SAVE_SHOP_PREFERENCE.TITLE',
                confirmLabel: 'DIALOG.SAVE_SHOP_PREFERENCE.CONFIRM',
                cancelLabel: 'DIALOG.SAVE_SHOP_PREFERENCE.CANCEL'
            }
        });

        if(res) {
            this.saveShopColumnsPreference();
        }
    }

    @autobind
    saveShopColumnsPreference() {
        let columnState = JSON.stringify(this.gridColumnApi.getColumnState());
        this.configurationManager.setShopPreference(this.getConfigurationColumnsPreferences(), columnState);
    }

    onFilterTextBoxChanged($event?: string) {
        this.gridApi?.setIsExternalFilterPresent(() => true);
        if(this.agGrid.rowModelType==='clientSide'){
            this.gridApi?.setDoesExternalFilterPass((node) =>
            //filtro solo colonne visibili
                !!Object.keys(this.tableData.dataType).filter(colVis => node?.data[colVis]?.toString().toLowerCase().indexOf(filterValue.toLowerCase()) > -1).pop());
            //filtro tutte le colonne anche invisibili
                //Object.values(node?.data).some(col => col && col.toString().toLowerCase().indexOf(filterValue.toLowerCase()) > -1));
        }
        else {
            this.debugLog('SEARCH ON SERVERSIDE GRID');
            this.gridApi?.setFilterModel(null);
        }
        filterValue = $event || '';
        this.gridApi?.onFilterChanged();
    }

    onRowOrExternalClicked($event: RowClickedEvent | RowNode) {
        this.resetSelection = false;
        this.previousNodeSelected = this.nodeSelected;
        this.nodeSelected = ('node' in $event) ? ($event as RowClickedEvent).node : $event as RowNode;
        if (this.previousNodeSelected) {
          this.agGrid.gridOptions?.api?.redrawRows({ rowNodes: [this.previousNodeSelected] });
        }
        this.agGrid.gridOptions?.api?.redrawRows({ rowNodes: [this.nodeSelected] });
        if ('node' in $event) {
          this.rowClicked.emit($event as RowClickedEvent);
        }
    }

    resetUIRows() {
        this.resetSelection = true;
        if (this.nodeSelected) this.agGrid.gridOptions?.api?.redrawRows({ rowNodes: [this.nodeSelected] });
        this.previousNodeSelected = undefined;
        this.nodeSelected = undefined;
    }

    //---------------EXPORT---------------------

    onBtnExport($event?: DataExport,tableToCall?:string,extraQueryParams?:Record<string,any>) {
        if ($event) {
            let rowsSelected = !!this.gridApi?.getSelectedRows().length;
            return rowsSelected ? this.clientSideExport($event, rowsSelected) : this.serverSideExport($event,tableToCall,extraQueryParams);
        }
    }

    public getSortFilterQuery():Record<string, string> {
        const filters = this.agGrid.api.getFilterModel();
        const sortBy = this.agGrid.columnApi.getColumnState().find(col=>!!col.sort);
        const filterBy = Object.keys(filters).reduce((acc, colName) => ({
                ...acc,
                ...queryFilteredOrdered_FEtoBEConverter(acc, colName as KeysOfUnion<TableRow>, filters[colName], this.tableData.dataType)
            }),
            {} as any);
        this.log('GET_SORT_FILTER_QUERY', {filters,sortBy});
        return {
            // ...((sortBy?.colId) && {[`orderby_${sortBy?.sort}`]: sortBy?.colId}), SORTBY_DISATTIVATO (Non implementato a BE)
            ...filterBy
        };
    }

    async serverSideExport($event?: DataExport,tableToCall?:string,extraQueryParams?:Record<string,any>) {
        if(!$event) {
            return this.error('Something went wrong!');
        }
        this.setGridExportUserPreferenceByFeature($event);
        const {data=[],type} = $event ||{};
        const format = type==='excel'?'xlsx':'csv';
        const columnsName = data.reduce((s,a) => [ ...s, ...(a.isChecked?[a.colField]:[])] as string[],[] as string[]).join(',');  //filter(col => col.isChecked).map(col => col.colField)

        const query= this.getSortFilterQuery();

        this.log('SERVER_SIDE_EXPORT', {columnsName,format,query});
        let messageToBePrinted='';
        try{
            await this.restMangerService.post(`massive_export/file`,undefined, {...query, table:tableToCall||this.feature, columns:columnsName, format, ...extraQueryParams});
            messageToBePrinted='DONE';
        }
        catch(error){
            messageToBePrinted='ERROR';
        }
        finally {
            this.alertDialogService.openDialog({data:{messageLabel:`DIALOG.SERVER_SIDE_EXPORT.${messageToBePrinted}`}});
        }
    }

    clientSideExport($event: DataExport, rowsSelected: boolean) {
        this.setGridExportUserPreferenceByFeature($event);

        let exportData = {
            columnKeys: $event.data.map(h => h.colField),
            onlySelected: rowsSelected,
            processCellCallback: this.exportFormatterOptions
        }
        $event.type === 'csv' ? this.gridApi?.exportDataAsCsv(exportData) : this.gridApi?.exportDataAsExcel(exportData);
    }

    protected setGridExportUserPreferenceByFeature($event: DataExport){
        let json = {columns: $event.data.map(col => col.colField)}
        this.configurationManager.setUserPreference(`grid.${this.feature}.export`, JSON.stringify(json));
    }

    //------------TRANSACTION-------------------

    @autobind
    updateItems<T extends SuppliersFields|CustomersFields|PromotionsFe>(cellsToUpdated: T) {
        const updatedRows: TableRowUpdatable[] = [];
        this.gridApi?.forEachNode(rowNode => {
            if (rowNode.isSelected() === false) {
                return;
            }
            const updatedRow = {...rowNode.data, ...cellsToUpdated}
            rowNode.setData(updatedRow);
            updatedRows.push(updatedRow);
        });
        this.updateChange.emit(updatedRows);
    }

    printResult(res: RowNodeTransaction | ServerSideTransactionResult) {
        this.debugLog('---------------------------------------', res);
        if (res.add) {
            res.add.forEach((rowNode) => {
                this.debugLog('Added Row Node', rowNode);
            });
        }
        if (res.remove) {
            res.remove.forEach((rowNode) => {
                this.debugLog('Removed Row Node', rowNode);
            });
        }
        if (res.update) {
            res.update.forEach((rowNode) => {
                this.debugLog('Updated Row Node', rowNode);
            });
        }
    }

    //-----------------SIDEBAR------------------

    openToolPanel(panel: GridPanel) {
        const otherPanelOpened = this.gridApi?.getOpenedToolPanel() !== panel;
        if (!this.gridApi?.isSideBarVisible() || otherPanelOpened) {
            this.gridApi?.setSideBarVisible(true);
            this.gridApi?.openToolPanel(panel)
        } else this.gridApi?.setSideBarVisible(false);

    }

    advancedFilterSwitch(advFilter: boolean) {
        this.defaultColDef = {
            ...this.defaultColDef,
            menuTabs: advFilter
                ? ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab']
                : ['filterMenuTab']
        };
    }

    columnMovableSwitch(movColumns: boolean) {
        // this.defaultColDef = {
        //     ...this.defaultColDef,
        //     suppressMovable: !movColumns,
        // };

        // TODO: Temp solution - wait ticket ag-grid
        for (let i = 0; i < this.columnDefs.length; i++) {
            this.columnDefs[i]["suppressMovable"] = !movColumns;
        }
        this?.agGrid?.gridOptions?.api?.setColumnDefs(this.columnDefs);
        this.setColumnsPreferences();

        setTimeout(() => {
            document.getElementsByTagName('body')[0].classList?.[movColumns ? 'add' : 'remove']('grid-columns-movable');
          }, 200);
    }

    //-----------------TRANSLATE------------------

    localizeHeader(parameters: HeaderValueGetterParams): string {
        let headerTranslate;
        let headerIdentifier = this._tableData.headersTranslate.get((<ColDef>parameters.colDef)?.field || '');
        if (headerIdentifier) headerTranslate = (headerIdentifier.substring(0, 2) === '-s') ? this.configurationManager.getSetting(headerIdentifier.split(" ", 2)[1] as any) : this.translateService.instant(headerIdentifier);
        return headerTranslate ? headerTranslate : (<ColDef>parameters.colDef)?.field || '';
    }

    formatterValue(parameters: ValueFormatterParams): any {
        let valueFormatter = this._tableData.columnsFormatter?.get(parameters.colDef?.field || '');
        return valueFormatter ? valueFormatter(parameters) : undefined;
    }

    formatterExcel(parameters: CellClassParams): string {
        return this._tableData.columnsExcelFormatter?.get(parameters.colDef?.field || '');
    }

    filtersMenuDisabled(key: string): boolean | undefined {
        return typeof this._tableData.filtersMenuDisabled === 'boolean' ? this._tableData.filtersMenuDisabled : this._tableData.filtersMenuDisabled?.get(key) || undefined;
    }

    sortingsMenuDisabled(key: string): boolean | undefined {
        return typeof this._tableData.sortingsMenuDisabled === 'boolean' ? !this._tableData.sortingsMenuDisabled : !(this._tableData.sortingsMenuDisabled?.get(key) || undefined);
    }

    getGridLanguage(): GridTraductions {
        return this._tableData.language ? this._tableData.language : this.gridTraductionsService.getTraduction();
    }

    ngOnDestroy() {
        this.debugLog('GRID DESTROY');
        this.toastSaveUserPreference?.dismiss();
        if (this.gridApi) {
            // Set the API reference to null:
            this.gridApi = undefined;
        }
    }

    /**
     * Watch changes in grid selection
     * @returns All the selected rows
     */
    selectionChanged() {
        this.areRowsSelected.emit(!!this.gridApi?.getSelectedRows().length);
    }

    private isCheckable(k: TableRow): boolean {
        if (this.tableData.isCheckable) {
            return this.tableData.isCheckable(k) ?? true;
        }
        return true;
    }
}
