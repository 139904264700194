<div class='tw-relative tw-h-full' [ngClass]="{ 'tw-border-solid tw-border tw-border-gray-200 tw-rounded-lg' : (layoutActionButtons==='bill' && principalActionButton) || isLoading, 'tw-flex tw-justify-center tw-items-center': isLoading }">
    @if(isLoading) {
        <mat-spinner id="white-spinner" class="tw-h-10"></mat-spinner>
    } @else {
        @if(principalActionButton) {
            @if(fastPaymentsButtons.length) {
                <div
                    class="tw-flex tw-justify-between tw-p-5 tw-pb-10 tw-cursor-pointer"
                    [ngClass]="{ 'pay-button tw-rounded-t-lg': layoutActionButtons === 'bill', 'principal-disabled': principalActionButton.isDisable?.() }"
                    (click)="principalActionButton.click()"
                >
                    <span class="tw-text-2xl">{{ principalActionButton.name | translate }}</span>
                    @if(!isHidePrices){
                        <span class="tw-text-2xl">{{ sale?.final_amount || 0 | tilbyCurrency }}</span>
                    } @else {
                        <span class="tw-text-2xl">***</span>
                    }
                </div>
            } @else {
                <button mat-button
                    class="tw-flex tw-w-full tw-h-full tw-p-5 tw-pb-10 tw-cursor-pointer tw-rounded-lg tilby-body-background-primary-color principal-button"
                    [ngClass]="{ 'principal-disabled': principalActionButton.isDisable?.() }"
                    (click)="principalActionButton.click()"
                >
                    <div class="tw-flex tw-justify-between">
                        <span class="tw-text-2xl">{{ principalActionButton.name | translate }}</span>
                        @if(!isHidePrices){
                            <span class="tw-text-2xl">{{ sale?.final_amount || 0 | tilbyCurrency }}</span>
                        } @else {
                            <span class="tw-text-2xl">***</span>
                        }
                    </div>
                </button>
            }
        }
        <div [class.tw-h-full]="!principalActionButton" [ngClass]="{ 'tw-absolute tw-w-full tw-top-[71px] tw-rounded-lg ' : principalActionButton }">
            <mat-button-toggle-group [hideSingleSelectionIndicator]="true" appearance="standard" class="tw-transform tw-w-full tw-h-full tw-rounded-lg tw-text-sm tw-text-medium">
                @for(btn of actionButtons; track $index) {
                    <mat-button-toggle class="tw-truncate tw-w-full"
                                       (click)="btn.click()"
                                       [value]="btn.name"
                                       [disabled]="btn.isDisable?.()"
                                       [ngClass]="btn.class||''">
                        <div class="tw-flex tw-flex-col">
                        @if(btn.icon) {
                            <span class="material-symbols-rounded tw-mt-4">
                                @if(btn.badgeValue && btn.badgeValue !== -1) {
                                    <mat-icon class="badge" matBadgePosition="below after" [matBadge]="btn.badgeValue" matBadgeColor="accent" [fontIcon]="btn.icon()"/>
                                } @else {
                                    {{btn.icon()}}
                                }
                            </span>
                        }
                        <span class="tw-ml-2 tw-mr-2 tw-truncate tw-w-[calc(100%-1rem)]">{{btn.name| translate}}</span>
                        </div>
                    </mat-button-toggle>
                }
                @for(btn of fastPaymentsButtons; track $index){
                    <mat-button-toggle [ngClass]="!btn.isDisable?.() ? 'tilby-body-background-primary-color' : 'tilby-body-background-primary-color-disabled button-disabled'"
                                       class="tw-w-full"
                                       tilbyGestures (longPress)="longPress($event,btn)"
                                       (click)="click($event,btn)"
                                       (mousedown)="enableClick()"
                                       [disabled]="btn.isDisable?.()"
                                       [value]="btn.name"
                                       >
                        <div class="tw-whitespace-normal tw-leading-8 tw-pl-[10px] tw-pr-[10px] min-[700px]:tw-text-[20px]">
                            {{btn.name| translate}}
                        </div>
                    </mat-button-toggle>
                }
            </mat-button-toggle-group>
        </div>
    }
</div>
