import angular from 'angular';
import _ from 'lodash';

angular.module('digitalPayments').factory('cashmatic', cashmatic);

cashmatic.$inject = ["$mdDialog", "$translate", "$filter", "checkManager", "cashmaticDriver"];

function cashmatic($mdDialog, $translate, $filter, checkManager, cashmaticDriver) {
    cashmaticController.$inject = ["$scope", "amount", "paymentMethod", "transactionType"];

    function cashmaticController($scope, amount, paymentMethod, transactionType) {
        $scope.trSt = null;
        $scope.transactionType = transactionType;
        $scope.amount = amount;

        $scope.showConfirm = function() {
            return $scope.changeNotGiven || (transactionType === 'DEPOSIT' && $scope.trSt?.paid);
        }

        $scope.close = function(confirmTransaction) {
            if($scope.pendingTransaction) {
                cashmaticDriver.endTransaction();
                $mdDialog.cancel('CANCELED');
            } else if(_.get($scope.trSt, 'transactionStatus') === 'CLOSED') {
                if($scope.trSt.paid >= amount) {
                    $mdDialog.hide($scope.trSt);
                } else {
                    $mdDialog.cancel('CANCELED');
                }
            } else {
                if(!confirmTransaction) {
                    $scope.message = $translate.instant('DIGITAL_PAYMENTS.CASHDRAWERS.ABORTING');
                    $scope.inProgress = true;
                }

                cashmaticDriver.abortPayment(function() {}, function(error) {
                    $mdDialog.cancel('REQ_TIMEOUT');
                });
            }
        };

        $scope.onUpdate = function(trSt) {
            if(_.isNil($scope.trSt)) {
                $scope.message = null;
                $scope.inProgress = false;
                delete $scope.pendingTransaction;
            }

            if(trSt.transactionStatus === 'BUSY') {
                $scope.message = $translate.instant('DIGITAL_PAYMENTS.CASHDRAWERS.ANOTHER_OPEN_TRANSACTION', { drawer: 'Cashmatic' });
                $scope.inProgress = true;
                $scope.pendingTransaction = true;
            } else {
                $scope.trSt = trSt;
            }

            if($scope.transactionType === 'DEPOSIT') {
                $scope.trSt.toPay = $scope.amount;

                if($scope.trSt.paid >= $scope.amount) {
                    $scope.close(true);
                }
            }

            $scope.$applyAsync();
        };

        $scope.closePreviousTransaction = function() {
            cashmaticDriver.abortPayment(function() {
                $scope.message = $translate.instant('DIGITAL_PAYMENTS.CASHDRAWERS.FORCING');
                delete $scope.pendingTransaction;
                $scope.$applyAsync();
            }, function(error) {
                $mdDialog.cancel('REQ_TIMEOUT');
            });
        };

        cashmaticDriver.startTransaction(paymentMethod.schema_name, paymentMethod.bundle_name, {
            updateCallback: $scope.onUpdate,
            transactionType: $scope.transactionType
        });

        $scope.message = $translate.instant('DIGITAL_PAYMENTS.CASHDRAWERS.CONNECTING', { drawer: 'Cashmatic' });
        $scope.inProgress = true;

        cashmaticDriver.performPayment(amount, function(result) {
            cashmaticDriver.endTransaction();

            if(result.changeNotGiven && transactionType !== 'WITHDRAWAL') {
                $scope.trSt = result;
                $scope.message = $translate.instant('DIGITAL_PAYMENTS.CASHDRAWERS.CHANGE_NOT_GIVEN_ERROR');
                $scope.changeNotGiven = true;

                $scope.$applyAsync();
            } else {
                if(transactionType === 'DEPOSIT' && !result.paid) {
                    $mdDialog.cancel('CANCELED');
                } else {
                    $mdDialog.hide(result);
                }
            }
        }, function(error) {
            cashmaticDriver.endTransaction();

            if(error === 'REQ_TIMEOUT' && !$scope.trSt) {
                error = 'FIRST_REQ_TIMEOUT';
            }

            $mdDialog.cancel(error);
        });
    }

    var cashmatic = {
        payment: function(amount, options, transactionType) {
            const paymentMethod = options.paymentMethod;

            if (!paymentMethod.schema_name) {
                return Promise.reject($translate.instant('DIGITAL_PAYMENTS.CASHDRAWERS.SCHEMA_NAME_NOT_FOUND', { drawer: 'Cashmatic' }));
            }

            return $mdDialog.show({
                controller: cashmaticController,
                template: require('./cashmatic.html'),
                locals: {
                    amount: amount,
                    paymentMethod: _.cloneDeep(paymentMethod),
                    transactionType: transactionType || (amount > 0 ? 'PAYMENT' : 'REFUND')
                }
            }).then(function(transaction) {
                var transactionString = '';
                try { transactionString = JSON.stringify(transaction); } catch(e) {}

                const receiptTail = (amount > 0) ? [
                    "Dettaglio pagamento",
                    ``,
                    `Da pagare ${_.padStart($filter('sclCurrency')(transaction.toPay), 22, ' ')}`,
                    `Pagato ${$filter('sclCurrency')(transaction.paid).padStart(25, ' ')}`,
                    `Resto erogato ${$filter('sclCurrency')(transaction.changeGiven).padStart(18, ' ')}`,
                    `Resto non erogato ${$filter('sclCurrency')(transaction.changeNotGiven).padStart(14, ' ')}`
                ] : [
                    "Dettaglio reso",
                    ``,
                    `Contante da rendere ${_.padStart($filter('sclCurrency')(transaction.toPay), 12, ' ')}`,
                    `Contante erogato ${$filter('sclCurrency')(transaction.changeGiven).padStart(15, ' ')}`,
                    `Contante non erogato ${$filter('sclCurrency')(transaction.changeNotGiven).padStart(11, ' ')}`
                ];

                const paymentData = {
                    unclaimed: false,
                    payment_data: transactionString,
                    tail: receiptTail.join('\n')
                };

                return checkManager.getPreference('cashregister.register_cashdrawers_paid_amount') ? { ...paymentData, amount: transaction.paid } : paymentData;
            }, function(error) {
                throw 'CASHMATIC.' + error;
            });
        },
        refund: (amount, options) => cashmatic.payment(-amount, options),
        deposit: (amount, options) => cashmatic.payment(amount, options, 'DEPOSIT'),
        withdrawal: (amount, options) => cashmatic.payment(-amount, options, 'WITHDRAWAL')
    };

    return cashmatic;
}