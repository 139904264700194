<cdk-virtual-scroll-viewport class="tw-h-full tw-w-full" [itemSize]="52" [minBufferPx]="screenHeight" [maxBufferPx]="screenHeight">
    <div *cdkVirtualFor="let item of itemsSet; trackBy: trackByFunction; let last = last" class="tw-py-[1px]" [ngClass]="last ? customClass || '' : ''">
        <tilby-item-element
            [isHidePrices]="!showPrices"
            [item]="item"
            [showThumbnail]="showThumbnail"
            [showUnavailableStock]="showUnavailableStock"
            [priceIdentifier]="priceIdentifier"
            [priceWithCurrency]="priceWithCurrency"
            [truncatePriceWithDots]="truncatePriceWithDots"
            [getStockStatus]="getStockStatus"
            (itemTapped)="itemTap($event)"
            (itemLongPressed)="itemPress($event)"
        >
        </tilby-item-element>
    </div>
</cdk-virtual-scroll-viewport>
