<tilby-dialog-toolbar mat-dialog-title
    [title]="'DIALOG.CASH_MOVEMENT.TITLE'"
    [hideConfirm]="true"
/>
<tilby-dialog-content>
    <mat-list>
        <div class="tw-container tw-flex">
            <mat-list-item class="tw-h-max tw-pt-5 tw-pb-5">
                <div class="tw-pb-2" style="display: flex; flex-direction: column;">
                    <label class="tw-text-[12px]" translate="HISTORY.CASH_MOVEMENTS.DATE_AND_TIME"></label>
                    <span class="tw-pl-2">{{ data.cashMovement.date | tilbyDate : "dd/MM/yyyy" }},
                    {{ data.cashMovement.date | tilbyDate : "HH:mm" }}</span>
                </div>
                <div class="tw-pb-2" style="display: flex; flex-direction: column;">
                    <label class="tw-text-[12px]" translate="HISTORY.CASH_MOVEMENTS.TYPE"></label>
                    <span class="tw-pl-2">{{ getMovementType(data.cashMovement.type) }}</span>
                </div>
                <div *ngIf="data.cashMovement.sale_id" class="tw-pb-2" style="display: flex; flex-direction: column;">
                    <label class="tw-text-[12px]" translate="HISTORY.CASH_MOVEMENTS.SALE_ID"></label>
                    <span class="tw-pl-2">{{ data.cashMovement.sale_id }}</span>
                </div>
                <div class="tw-pb-2" style="display: flex; flex-direction: column;">
                    <label class="tw-text-[12px]" translate="HISTORY.CASH_MOVEMENTS.DESCRIPTION"></label>
                    <span class="tw-pl-2 tw-truncate">{{ data.cashMovement.description }}</span>
                </div>
            </mat-list-item>
            <mat-list-item class="tw-h-max tw-pt-5 tw-pb-5">
                <div class="tw-pb-2" style="display: flex; flex-direction: column;">
                    <label class="tw-text-[12px]" translate="HISTORY.CASH_MOVEMENTS.ACCOUNT"></label>
                    <span *ngIf="data.cashMovement.account === 'cash'" class="tw-pl-2" translate="HISTORY.TOPBAR_CASH_MOVEMENTS.CASHREGISTER"></span>
                    <span *ngIf="data.cashMovement.account === 'other'" class="tw-pl-2" translate="HISTORY.TOPBAR_CASH_MOVEMENTS.OUT_CASHREGISTER"></span>
                </div>
                <div *ngIf="data.cashMovement.payment_method_name" class="tw-pb-2" style="display: flex; flex-direction: column;">
                    <label class="tw-text-[12px]" translate="HISTORY.CASH_MOVEMENTS.PAYMENT_METHOD_NAME"></label>
                    <span class="tw-pl-2">{{ data.cashMovement.payment_method_name }}</span>
                </div>
                <div class="tw-pb-2" style="display: flex; flex-direction: column;">
                    <label class="tw-text-[12px]" translate="HISTORY.CASH_MOVEMENTS.AMOUNT"></label>
                    <span class="tw-pl-2">{{ data.cashMovement.amount | tilbyCurrency }}</span>
                </div>
            </mat-list-item>
        </div>
    </mat-list>
</tilby-dialog-content>
