import angular from 'angular';
import moment from 'moment-timezone';
import { v4 as generateUuid } from 'uuid';

import { countryCodes } from 'src/app/core/constants/country-codes';
import { blobToDataURL } from 'src/app/shared/data-conversion-utils';

import {
    padCenter,
    stringToLines

} from 'src/app/shared/string-utils';

import { TilbyCurrencyPipe } from '@tilby/tilby-ui-lib/pipes/tilby-currency';

import {
    keyBy,
    MathUtils
} from 'src/app/shared/utils';

import {
    ConfigurationManagerService,
    EntityManagerService,
    EnvironmentInfoService,
    OperatorManagerService,
    OperatorUser
} from 'src/app/core';

import { FiscalUtilsService } from 'app/modules/core/service/fiscal-utils/fiscal-utils';
import { SalePaymentService } from 'src/app/features';
import { RF } from 'src/app/models';
import { Departments, Sales, SalesDocuments, SalesItems } from 'tilby-models';
import { FiscalPayment } from 'src/app/shared/model/it-fiscal-printer.model';

const { tilbyVersion } = require('app/tilby.properties.json');

type progressiveOptions = {
    progressive?: string;
    progressive_prefix?: string;
    printer_prefix?: string;
}

type receiptTailOptions = {
    columns?: number;
    document_type?: string;
}

type retailForceDeviceConfig = {
    terminal_number?: string;
    client_id?: string;
    endpoint_url?: string;
    rf_version?: string;
}

type retailForceSetupConfig = {
    cloudApiKey: string;
    cloudApiSecret: string;
    identification: string;
    storeNumber: string;
    terminalNumber: string;
    endpointUrl?: string;
}

interface RetailForceDocumentType {
    documentType: RF.DocumentType;
    documentTypeCaption: string;
}

export class RetailForceProvider {
    private rfVersion: string | undefined;
    private rfClientVersion: string | undefined;

    public static documentTypes: Record<string, RetailForceDocumentType> = {
        RECEIPT: {
            documentType: RF.DocumentType.Receipt,
            documentTypeCaption: 'Receipt'
        },
        INVOICE: {
            documentType: RF.DocumentType.Invoice,
            documentTypeCaption: 'Invoice'
        },
        DELIVERY_NOTE: {
            documentType: RF.DocumentType.DeliveryNote,
            documentTypeCaption: 'DeliveryNote'
        },
        PAYOUT: {
            documentType: RF.DocumentType.PayOut,
            documentTypeCaption: 'PayOut'
        },
        PAYIN: {
            documentType: RF.DocumentType.PayIn,
            documentTypeCaption: 'PayIn'
        },
        PROFORMA_INVOICE: {
            documentType: RF.DocumentType.ProformaInvoice,
            documentTypeCaption: 'ProformaInvoice'
        },
        CUSTOMER_ORDER: {
            documentType: RF.DocumentType.CustomerOrder,
            documentTypeCaption: 'CustomerOrder'
        },
        PRELIMINARY_RECEIPT: {
            documentType: RF.DocumentType.PreliminaryReceipt,
            documentTypeCaption: 'PreliminaryReceipt'
        },
        LONG_TERM_ORDER: {
            documentType: RF.DocumentType.LongTermOrder,
            documentTypeCaption: 'LongTermOrder'
        },
        OPENING_BALANCE: {
            documentType: RF.DocumentType.OpeningBalance,
            documentTypeCaption: 'OpeningBalance'
        },
        END_OF_DAY: {
            documentType: RF.DocumentType.EndOfDay,
            documentTypeCaption: 'EndOfDay'
        },
        INVENTORY: {
            documentType: RF.DocumentType.Inventory,
            documentTypeCaption: 'Inventory'
        },
        PURCHASE: {
            documentType: RF.DocumentType.Purchase,
            documentTypeCaption: 'Purchase'
        },
        NULL_RECEIPT: {
            documentType: RF.DocumentType.NullReceipt,
            documentTypeCaption: 'NullReceipt'
        },
        MISCELLANEOUS_NON_FISCAL: {
            documentType: RF.DocumentType.MiscellaneousNonFiscal,
            documentTypeCaption: 'MiscellaneousNonFiscal'
        }
    };

    private retailForceTailStrings: Record<string, Record<string, string>> = {
        DE: {
            TSE_SERIAL: 'TSE Seriennummer:',
            TSE_TRANSACTION_START: 'TSE Start:',
            TSE_TRANSACTION_END: 'TSE Ende:',
            TSE_TRANSACTION_NUM: 'TSE Transaktionsnr.:',
            TSE_CERT: 'TSE Zertifikat:',
            TSE_IDENTIFICATION: 'TSE Identifikation:',
            TSE_SIGNATURE_COUNTER: 'TSE Signaturzähler:',
            TSE_HASH_ALGORITHM: 'TSE Algorithmus:',
            TSE_TIME_FORMAT: 'TSE Zeitformat:',
            TSE_SIGNATURE: 'TSE Signatur:',
            TSE_PUBLIC_KEY: 'TSE PublicKey:'
        },
        EN: {
            TSE_SERIAL: 'TSE Serial:',
            TSE_TRANSACTION_START: 'TSE Start:',
            TSE_TRANSACTION_END: 'TSE End:',
            TSE_TRANSACTION_NUM: 'TSE Transaction nr.:',
            TSE_CERT: 'TSE Certificate:',
            TSE_IDENTIFICATION: 'TSE Identification:',
            TSE_SIGNATURE_COUNTER: 'TSE Signature Counter:',
            TSE_HASH_ALGORITHM: 'TSE Algotithm:',
            TSE_TIME_FORMAT: 'TSE Time Format:',
            TSE_SIGNATURE: 'TSE Signature:',
            TSE_PUBLIC_KEY: 'TSE Public Key:'
        },
        FR: {
            SOFTWARE_VERSION: 'NF525 SW version',
            FISCAL_MARK: 'Code de signature',
            ORIGINAL_RECEIPT: 'Orig. Ticket',
            ORIGINAL_INVOICE: 'Orig. Facture',
            RECEIPT_NUMBER: 'Ticket N.',
            INVOICE_NUMBER: 'Facture N.',
            PRELIMINARY_NUMBER: 'Note N.',
            POSITION_NUMBERS: 'Nombre de lignes:',
            IDENTIFICATION: 'Société:',
            STORE_NUMBER: 'Filiale:',
            TERMINAL_NUMBER: 'Pos No.:',
            REPRINT_COUNT: 'Imprimés Numéro:'
        },
    };

    public static $inject = [
        'environmentInfo',
        'fiscalUtils',
        'salePayment',
        'checkManager',
        'errorsLogger',
        'restManager',
        'entityManager',
        'OperatorManager',
        'util'
    ]

    constructor(
        private environmentInfo: EnvironmentInfoService,
        private fiscalUtils: FiscalUtilsService,
        private salePayment: SalePaymentService,
        private configurationManager: ConfigurationManagerService,
        private errorsLogger: any,
        private restManager: any,
        private entityManager: EntityManagerService,
        private OperatorManager: OperatorManagerService,
        private util: any
    ) {
        this.retailForceTailStrings['AU'] = this.retailForceTailStrings['DE'];
    }

    private deviceUuidOverride: string | null = null;

    public setDeviceOverride(deviceUuid: string) {
        this.deviceUuidOverride = deviceUuid;
    }

    public clearDeviceOverride() {
        this.deviceUuidOverride = null;
    }

    public async getDeviceConfig(devUuid?: string): Promise<retailForceDeviceConfig> {
        if (this.environmentInfo.isWebApp() && !devUuid && !this.deviceUuidOverride) {
            return {};
        }

        const deviceUuid = devUuid || this.deviceUuidOverride || await this.environmentInfo.getDeviceMeta().then(deviceInfo => deviceInfo.client_uuid);

        if (!deviceUuid) {
            return {};
        }

        const deviceConfig = JSON.parse(this.configurationManager.getPreference(`retailforce.device_config.${deviceUuid}`)!) as retailForceDeviceConfig;

        return deviceConfig;
    };

    public setDeviceConfig(deviceUuid: string, deviceConfig: retailForceDeviceConfig) {
        this.configurationManager.setShopPreference(`retailforce.device_config.${deviceUuid}`, JSON.stringify(deviceConfig));

        return deviceConfig;
    };

    private async getRetailForceClientID() {
        const clientId = this.configurationManager.getPreference('retailforce.local')
            ? await this.getDeviceConfig().then(clientId => clientId.client_id)
            : this.configurationManager.getSettingUserFirst('retailforce.client_id');

        if (!clientId) {
            throw 'RETAILFORCE_NOT_CONFIGURED';
        }

        return clientId;
    };

    private getTilbyVersion() {
        return this.configurationManager.getSetting('retailforce.tilby_version_override') || <string>tilbyVersion;
    };

    private async getTerminalNumber() {
        return this.configurationManager.getPreference('retailforce.local')
            ? this.getDeviceConfig().then(deviceConfig => deviceConfig.terminal_number)
            : this.configurationManager.getSettingUserFirst('retailforce.terminal_number') || this.configurationManager.getPreference('retailforce.terminal_number');
    };

    private getSaleRetailForceDocument(sale?: Sales) {
        if (!sale) {
            return undefined;
        }

        const fiscalProviderDocument = sale.sale_documents?.find(doc => doc.document_type === 'fiscal_provider');

        if (fiscalProviderDocument?.meta?.fiscal_provider !== 'retailforce') {
            return undefined;
        }

        return fiscalProviderDocument;
    };

    private getAuditType = (auditData: any): string => auditData.type.replace('audit', '').charAt(0).toLowerCase() + auditData.type.replace('audit', '').slice(1);

    private getAuditInfo(auditData: any) {
        const auditType = this.getAuditType(auditData);
        let auditInfo: RF.AuditLogEntry | null = null;

        switch (auditType) {
            case 'applicationOffline':
                auditInfo = {
                    logEntryType: 'applicationEmergencyModeOn'
                };
                break;
            case 'applicationOnline':
                auditInfo = {
                    logEntryType: 'applicationEmergencyModeOff'
                };
                break;
            case 'applicationStartup':
                auditInfo = {
                    logEntryType: 'applicationStart',
                    message: auditData.appVersion
                };
                break;
            case 'cashMovementCreated':
                const cashMovement = auditData.cashMovement;

                if (cashMovement.type === 'outcome') {
                    auditInfo = {
                        logEntryType: 'documentTypeWithDrawal',
                        amount: Math.abs(cashMovement.amount),
                        identifier: cashMovement.id
                    };
                }
                break;
            case 'dailyReportCreated':
                auditInfo = {
                    logEntryType: 'fiscalReportClosingDay',
                };
                break;
            case 'documentPrintFailed':
                switch (auditData.error) {
                    case 'CONNECTION_ERROR':
                        auditInfo = {
                            logEntryType: 'printerUnavailable',
                            identifier: auditData.printerId
                        };
                        break;
                    default:
                        break;
                }
                break;
            case 'drawerOpen':
                auditInfo = {
                    logEntryType: 'drawerOpen'
                };
                break;
            case 'historyReprint':
                auditInfo = {
                    logEntryType: 'documentReprintOther',
                    ...this.getAuditSaleInfo(auditData.reprintedSale)
                };
                break;
            case 'newLoggedUser':
                auditInfo = {
                    logEntryType: 'userLogin',
                };
                break;
            case 'trainingModeStart':
                auditInfo = {
                    logEntryType: 'documentTrainingModeOn'
                };
                break;
            case 'saleItemRemoved':
                auditInfo = {
                    logEntryType: 'documentCancelLine',
                    ...this.getAuditRowItemInfo(auditData.removedSaleItem)
                };
                break;
            case 'saleItemUpdated':
                auditInfo = {
                    logEntryType: 'documentUpdateLine',
                    ...this.getAuditRowItemInfo(auditData.updatedSaleItem)
                };
                break;
            case 'saleOpened':
                auditInfo = {
                    logEntryType: 'documentResume',
                    ...this.getAuditSaleInfo(auditData.openedSale)
                };
                break;
            case 'saleParked':
                auditInfo = {
                    logEntryType: 'documentSuspend',
                    ...this.getAuditSaleInfo(auditData.parkedSale)
                };
                break;
            case 'saleClosed':
                const sale: Sales = auditData.closedSale;

                if (sale) {
                    const otherDocumentEmitted = sale.sale_documents?.find(document => (['generic_document'].includes(document.document_type)));

                    if (otherDocumentEmitted) {
                        auditInfo = {
                            logEntryType: 'documentTypeOther',
                            ...this.getAuditSaleInfo(sale)
                        };
                    }
                }
                break;
            case 'userSettingChanged':
                auditInfo = {
                    logEntryType: 'userRightsChange',
                    identifier: auditData.user?.username
                };
                break;
        }

        return auditInfo;
    };

    private async fetchClientVersion() {
        if (!this.rfVersion || !this.rfClientVersion) {
            const clientId = await this.getRetailForceClientID();

            this.rfVersion = await this.apiGetVersion();
            this.rfClientVersion = await this.apiGetClientVersion(clientId);
        }
    };

    private getAuditRowItemInfo = (rowItem: SalesItems) => ({
        amount: MathUtils.round(rowItem.final_price * rowItem.quantity),
        identier: rowItem.uuid,
    });

    private async getAuditSaleInfo(sale: Sales) {
        const result: Partial<RF.AuditLogEntry> = {};

        if (sale) {
            result.amount = sale.final_amount,
                result.identifier = sale.uuid

            const fiscalDocument = this.getSaleRetailForceDocument(sale);
            const { document: docContent } = this.getRFDocument(fiscalDocument);

            if (docContent) {
                result.documentGuid = docContent.documentGuid;
            }
        }

        return result;
    };

    private getOperatorUser(user?: any): RF.User {
        const opData = this.OperatorManager.getOperatorData(user);

        return {
            caption: opData.full_name,
            firstName: opData.first_name,
            id: String(opData.id),
            lastName: opData.last_name
        };
    };

    private async sendToRetailForce(endpoint: string[], method: string, data?: any, params?: any, responseType?: 'blob') {
        const isSendingDocument = (['POST', 'PUT'].includes(method) && endpoint[0] === 'transactions' && !['auditLog', 'validateDocument'].includes(endpoint[1]));

        if (endpoint[0] !== 'api') {
            endpoint.unshift('api', 'v1');
        }

        const endpointPath = endpoint.join('/');

        try {
            let response;

            if (this.configurationManager.getPreference('retailforce.local')) {
                const deviceConfig = await this.getDeviceConfig();

                let retailForceHost = deviceConfig.endpoint_url;

                if (!retailForceHost) {
                    throw 'RETAILFORCE_NOT_CONFIGURED';
                }

                if (!retailForceHost.endsWith('/')) {
                    retailForceHost += '/';
                }

                const requestOptions: any = { data: data, params: params, method: method };

                if (responseType === 'blob') {
                    requestOptions.responseType = responseType;
                }

                response = await this.fiscalUtils.sendRequest(`${retailForceHost}${endpointPath}`, requestOptions);

                return typeof response.data === 'string'
                    ? { message: response.data }
                    : response.data;
            } else {
                switch (method) {
                    case 'POST':
                        response = await this.restManager.post(`retailforce/${endpointPath}`, data, params);
                        break;
                    case 'PUT':
                        response = await this.restManager.put('retailforce', endpointPath, data, params);
                        break;
                    case 'DELETE':
                        response = await this.restManager.deleteOne('retailforce', endpointPath, params);
                        break;
                    case 'GET':
                    default:
                        response = await ((responseType === 'blob') ? this.restManager.downloadOne : this.restManager.getOne)('retailforce', endpointPath, params);
                        break;
                }

                return response;
            }
        } catch (err: any) {
            if (err.status === 422 && isSendingDocument) {
                // Call validateDocument in order to get the full error message
                const errors = await this.apiValidateDocument(data);

                this.errorsLogger.sendReport({
                    type: 'RetailForceValidationError',
                    content: {
                        errors: errors,
                        payload: data
                    }
                });

                throw errors;
            }

            throw err;
        }
    };

    // API Wrappers START

    private async apiCancelDocument(saleDocument: RF.Document): Promise<RF.FiscalResponse> {
        return this.sendToRetailForce(['transactions', 'cancelDocument'], 'POST', saleDocument);
    }

    private async apiCashpointClose(clientId: string, dailyClosingData: RF.Document, opData: OperatorUser): Promise<RF.FiscalResponse> {
        return this.sendToRetailForce(['closing', clientId, 'cashpointClose'], 'POST', dailyClosingData, { userId: opData.id, userCaption: opData.full_name });
    }

    private async apiClientId(licenseConsumerId: string, storeNumber: string, terminalNumber: string): Promise<string> {
        return this.sendToRetailForce(['management', 'clients', 'id'], 'GET', null, { licenseConsumerId, storeNumber, terminalNumber })
            .then(r => r.message)
            .catch(err => {
                if (err.status === 404) {
                    return null;
                }

                throw err;
            });
    }

    private async apiClientInitialize(startDocument: RF.Document): Promise<RF.FiscalResponse> {
        return this.sendToRetailForce(['management', 'clients', 'initialize'], 'POST', startDocument);
    }

    private async apiClientStatus(clientId: string): Promise<RF.FiscalClientStatus> {
        return this.sendToRetailForce(['information', 'client', clientId, 'status'], 'GET');
    }

    private async apiCloudClient(identification: string, cloudApiKey: string, cloudApiSecret: string, store_number: string, terminalNumber: string): Promise<string> {
        return this.sendToRetailForce(['management', 'clients', 'byCloud'], 'PUT', `"${cloudApiSecret}"`, { Type: 0, Identification: identification, cloudApiKey, storeNumber: store_number, terminalNumber }).then(r => r.message);
    }

    private async apiCloudConnect(clientId: string, cloudApiKey: string, cloudApiSecret: string): Promise<string> {
        return this.sendToRetailForce(['management', 'cloud', 'connect'], 'POST', `"${cloudApiSecret}"`, { clientId, cloudApiKey }).then(r => r.message);
    }

    private async apiCountryProperties(clientId: string): Promise<any> {
        return this.sendToRetailForce(['information', 'client', clientId, 'countryProperties'], 'GET');
    }

    private async apiCreateDocument(clientId: string, documentType: RF.DocumentType): Promise<RF.FiscalResponse> {
        return this.sendToRetailForce(['transactions', 'createDocument'], 'PUT', null, { uniqueClientId: clientId, documentType });
    }

    private async apiEndOfDayDocument(clientId: string): Promise<RF.Document> {
        return this.sendToRetailForce(['closing', clientId, 'endofdayDocument'], 'GET');
    }

    private async apiGetClientVersion(clientId: string): Promise<string> {
        return this.sendToRetailForce(['information', 'version', clientId], 'GET').then(r => r.message);
    }

    private async apiGetTaxFreeVat(clientId: string): Promise<RF.Vat> {
        return this.sendToRetailForce(['information', 'client', clientId, 'getTaxFreeVat'], 'GET');
    }

    private async apiGetTicketBaiDocument(clientId: string, documentNumber: string): Promise<Blob> {
        return this.sendToRetailForce(['management', 'spain', clientId, 'ticketBai', documentNumber], 'GET', null, null, 'blob');
    }

    private async apiGetVersion(): Promise<string> {
        return this.sendToRetailForce(['information', 'version'], 'GET').then(r => r.message);
    }

    private async apiLicenseConsumerId(identification: string): Promise<string> {
        return this.sendToRetailForce(['management', 'clients', 'licenseConsumerId'], 'GET', null, { Type: 0, identification })
            .then(r => r.message)
            .catch(err => {
                if (err.status === 404) {
                    return null;
                }

                throw err;
            });
    }

    private async apiReprintDocument(documentGuid: string, clientId: string, operatorData: any): Promise<RF.FiscalResponse> {
        return this.sendToRetailForce(['transactions', 'reprintDocument', clientId, documentGuid], 'PUT', operatorData);
    }

    private async apiRestoreByCloud(identification: string, cloudApiKey: string, cloudApiSecret: string, store_number: string, terminalNumber: string): Promise<string> {
        return this.sendToRetailForce(['management', 'clients', 'recovery', 'restoreByCloud'], 'POST', `"${cloudApiSecret}"`, { Type: 0, Identification: identification, cloudApiKey, storeNumber: store_number, terminalNumber }).then(r => r.message);
    }

    private async apiRevertDocument(saleDocument: RF.Document): Promise<RF.Document> {
        return this.sendToRetailForce(['transactions', 'revertDocument'], 'POST', saleDocument);
    }

    private async apiSendAuditLog(payload: RF.AuditLogEntry) {
        return this.sendToRetailForce(['transactions', 'auditLog'], 'POST', payload);
    }

    private async apiStartDocument(clientId: string): Promise<RF.Document> {
        return this.sendToRetailForce(['transactions', 'document', clientId, 'start'], 'GET');
    }

    private async apiStoreDocument(saleDocument: RF.Document): Promise<RF.FiscalResponse> {
        return this.sendToRetailForce(['transactions', 'storeDocument'], 'POST', saleDocument);
    }

    private async apiSupportedVatDefinitions(clientId: string): Promise<RF.Vat[]> {
        return this.sendToRetailForce(['information', 'client', clientId, 'supportedVatDefinitions'], 'GET');
    }

    private async apiValidateDocument(data: RF.Document): Promise<{ items: RF.DocumentValidationError[] }> {
        return this.sendToRetailForce(['transactions', 'validateDocument'], 'POST', data);
    }

    // API Wrappers END

    private async getTaxFreeVat() {
        const clientId = await this.getRetailForceClientID();

        return this.apiGetTaxFreeVat(clientId);
    };

    /**
     * @description creates a RetailForce document template
     * @param {object} sale the Tilby sale for the document (optional)
     * @returns {object} a RetailForce document template
     */
    private async getDocumentTemplate(sale?: Sales, documentType?: RetailForceDocumentType, options: progressiveOptions = {}) {
        const clientId = await this.getRetailForceClientID();

        const saleTemplate = sale || <Partial<Sales>>{
            uuid: generateUuid(),
            open_at: moment().toISOString() as any as Date
        };

        const operatorUser = this.getOperatorUser();

        const bookDate = new Date();
        bookDate.setMilliseconds(0);

        const progressive = options.progressive || '';
        const printerPrefix = options.printer_prefix || '';

        const documentTemplate: RF.Document = {
            allowedVatDeviation: 0.02,
            applicationVersion: this.getTilbyVersion(),
            bookDate: bookDate.toISOString(),
            cancellationDocument: false,
            // @ts-expect-error
            createDate: saleTemplate.open_at,
            customerCount: saleTemplate.covers,
            deliveryPrintCount: [... new Set(saleTemplate.sale_items?.filter(item => item.exit != null).map(item => item.exit))].length,
            documentGuid: saleTemplate.uuid!,
            documentId: saleTemplate.uuid!,
            documentNumber: moment(saleTemplate.open_at).format('YYYYMMDD') + String(Number(progressive)).padStart(10, '0'),
            documentNumberSeries: [String(options.progressive_prefix || ''), String(printerPrefix || '')].filter(Boolean).join('') || null,
            documentReference: undefined,
            isTraining: this.configurationManager.getPreference('retailforce.training_mode') ? true : false,
            printCount: 1,
            proformaPrintCount: Number(saleTemplate.printed_prebills) || 0,
            uniqueClientId: clientId,
            salesPerson: {
                id: String(saleTemplate.seller_id || operatorUser.id),
                caption: saleTemplate.seller_name || operatorUser.caption
            },
            user: operatorUser,
        };

        if (!documentType) {
            documentType = RetailForceProvider.documentTypes.RECEIPT;
        }

        if (documentType === RetailForceProvider.documentTypes.INVOICE) {
            documentTemplate.paymentTerms = {
                dueDateDays: 1,
                discount: 0,
                discountDueDays: 0,
                latePaymentPenaltyRate: 0
            };

            // @ts-expect-error
            documentTemplate.servicePeriodStart = saleTemplate.open_at;
            // @ts-expect-error
            documentTemplate.servicePeriodEnd = saleTemplate.open_at;
        }

        documentTemplate.documentType = documentType.documentType;
        documentTemplate.documentTypeCaption = documentType.documentTypeCaption;

        return documentTemplate;
    };

    /**
     * @description returns the RetailForce partner node from a Tilby sale
     * @param {object} sale the source Tilby sale
     * @returns {object} the RetailForce Partner node
     */
    private getDocumentPartner(sale: Sales) {
        const saleCustomer = sale.sale_customer;

        if (!saleCustomer || !saleCustomer.billing_street || !saleCustomer.billing_number || !saleCustomer.billing_zip || !saleCustomer.billing_city || !saleCustomer.billing_country) {
            return undefined;
        }

        return <RF.Partner>{
            id: saleCustomer.uuid!,
            caption: this.util.getCustomerCaption(saleCustomer),
            isBusiness: !!saleCustomer.company_name,
            partnerType: RF.PartnerType.Customer,
            partnerClassification: 'Customer',
            vatNumber: saleCustomer.vat_code,
            taxNumber: saleCustomer.tax_code,
            street: saleCustomer.billing_street,
            streetNumber: saleCustomer.billing_number,
            postalCode: saleCustomer.billing_zip,
            city: saleCustomer.billing_city,
            countryCode: countryCodes[saleCustomer.billing_country]?.alpha3
        };
    };

    /**
     * @description returns the RetailForce discounts node from a Tilby sale element (sale or sale_item)
     * @param {object} element the element to extract the discounts from
     * @param {object} partialPrice the starting partial price for the element
     * @returns the RetailForce discounts node
     */
    private getDiscounts(element: Sales | SalesItems, partialPrice: number) {
        const discounts: RF.Discount[] = [];
        let discountIdx = 0;

        element.price_changes?.sort((a, b) => a.index - b.index).forEach((priceChange) => {
            const pcAmount = this.fiscalUtils.getPriceChangeAmount(priceChange, partialPrice, 4);

            if (pcAmount != null) {
                partialPrice = MathUtils.round(partialPrice + MathUtils.round(pcAmount));

                discounts.push({
                    discountValue: -pcAmount,
                    caption: priceChange.description,
                    discountOrder: discountIdx++,
                    identifier: priceChange.description,
                    type: ['discount_perc', 'surcharge_perc', 'gift'].includes(priceChange.type) ? RF.DiscountType.Discount : RF.DiscountType.Allowance,
                    typeValue: ['surcharge_perc', 'surcharge_fix'].includes(priceChange.type) ? -priceChange.value : priceChange.value,
                    promotionKeys: 'promotion_id' in priceChange && priceChange.promotion_id ? [String(priceChange.promotion_id)] : []
                });
            }
        });

        return discounts;
    };

    /**
     * @description returns the RetailForce positions node from a Tilby sale
     * @param {object} sale the Tilby sale
     * @param {object} vatsMap a sale to RetailForce vat map (obtainable from getSaleVatsMap)
     * @returns the RetailForce positions node
     */
    private getDocumentPositions(sale: Sales, vatsMap: Record<string, number | undefined>, departmentsMap: Record<string, Departments>): (RF.DocumentPositionItem | RF.DocumentPositionSubTotal | RF.DocumentPositionTotal)[] {
        let positionNumber = 0;

        const itemPositions = this.fiscalUtils.extractSaleItems(sale).map((saleItem) => {
            const saleItemNetPrice = Number(saleItem.price / (1 + saleItem.vat_perc / 100));
            const additionalFields: Record<string, string> = {};

            let businessTransactionType = RF.BusinessTransactionType.Revenue;

            if(saleItem.department?.giftcard_type_uuid) {
                businessTransactionType = RF.BusinessTransactionType.MultiPurposeVoucher;
                additionalFields.VoucherId = saleItem.uuid!;
            }

            return <RF.DocumentPositionItem>{
                additionalFields: additionalFields,
                baseGrossValue: MathUtils.round(saleItem.price * saleItem.quantity),
                baseNetValue: MathUtils.round(saleItemNetPrice * saleItem.quantity),
                baseTaxValue: MathUtils.round((saleItem.price - saleItemNetPrice) * saleItem.quantity),
                businessTransactionType: businessTransactionType,
                cancellationPosition: false,
                createDate: saleItem.added_at,
                costPrice: saleItem.cost ?? null,
                discounts: this.getDiscounts(saleItem, MathUtils.round(saleItem.price * saleItem.quantity)),
                grossValue: MathUtils.round(saleItem.final_price * saleItem.quantity),
                gtin: saleItem.barcode,
                inHouse: !['take_away', 'delivery'].includes(sale.order_type!),
                itemCaption: saleItem.name,
                itemDateOfEntry: saleItem.added_at,
                itemId: saleItem.uuid,
                itemTaxType: departmentsMap[saleItem.department_id]?.sales_type === 'goods' ? 'deliveries' : 'services',
                itemType: departmentsMap[saleItem.department_id]?.sales_type === 'goods' ? RF.ItemType.Article : RF.ItemType.Service,
                netValue: MathUtils.round(saleItem.final_net_price * saleItem.quantity),
                positionNumber: positionNumber++,
                quantity: saleItem.quantity,
                quantityUnit: { id: null },
                taxValue: MathUtils.round((saleItem.final_price - saleItem.final_net_price) * saleItem.quantity),
                type: RF.DocumentPositionType.Item,
                useSubItemVatCalculation: true,
                vatIdentification: vatsMap[saleItem.vat_perc],
                vatPercent: saleItem.vat_perc
            };
        });

        const totalPositions: (RF.DocumentPositionSubTotal | RF.DocumentPositionTotal)[] = [];

        // Add subtotal if there are some sale price_changes to apply
        if (sale.price_changes?.length) {
            totalPositions.push({
                type: RF.DocumentPositionType.SubTotal,
                baseValue: sale.amount,
                value: sale.final_amount,
                discounts: this.getDiscounts(sale, MathUtils.round(sale.amount!)),
                positionNumber: positionNumber++,
                positionReference: undefined,
                cancellationPosition: false,
                additionalFields: {}
            });
        }

        totalPositions.push({
            type: RF.DocumentPositionType.Total,
            baseValue: sale.final_amount,
            value: sale.final_amount,
            positionNumber: positionNumber++,
            positionReference: undefined,
            rounding: MathUtils.round(sale.final_amount! - itemPositions.reduce((sum, item) => sum + item.grossValue, 0)),
            cancellationPosition: false,
            additionalFields: {}
        });

        return [...itemPositions, ...totalPositions];
    };

    /**
     * @description returns the RetailForce payment type from a Tilby payment
     * @param {object} payment the Tilby payment
     * @returns {string} the RetailForce payment type
     */

    // [ cash, ecCard, creditCard, singlePurposeVoucher, multiPurposeVoucher, paymentProvider, deposit, noCash, none ]
    private getPaymentType(payment: FiscalPayment): RF.PaymentType {
        switch (payment.method_type_id) {
            case 1: case 19: case 21: case 32: case 38: case 39: case 40: // Cash
                return 'cash';
            case 3: // Cheque
                return 'cheque';
            case 4: case 5: // Electronic payment
            case 11: case 13: case 14: case 15: case 27: case 30: case 31: case 35: case 37: // POS
                return 'creditCard';
            case 2: case 22: case 23: case 24: case 25: case 26: case 28: case 29: case 36: case 41:
                return 'noCash';
            case 8: // Bank transfer
                return 'bankAccount';
            case 16: // Prepaid credit
                return 'customerCard';
            case 18: // Satispay
                return 'mobilePhoneApps';
            case 6: case 10: case 20: case 33: case 34: // Tickets / multi-purpose vouchers
                return 'multiPurposeVoucher';
            default:
                return 'cash';
        }
    };

    /**
     * @description returns the RetailForce payments node from a Tilby sale
     * @param {object} sale the Tilby sale
     * @returns {object} the RetailForce payments node
     */
    private getDocumentPayments(sale: Sales, validVats: any) {
        const operatorUser = this.getOperatorUser();
        const currentCountry = this.configurationManager.getShopCountry();
        let paymentVatId: any, paymentVatPerc: any;

        switch (currentCountry) {
            case 'AT': case 'FR':
                [paymentVatId, paymentVatPerc] = [validVats.find((vatData: any) => vatData.vatPercents.includes(10)), 10];
                break;
            case 'DE': case 'ES':
                [paymentVatId, paymentVatPerc] = [validVats.find((vatData: any) => vatData.vatPercents.includes(7)), 7];
                break;
            default:
                break;
        }

        const payments = this.fiscalUtils.extractPayments(sale).map((payment) => {
            const result: RF.DocumentPayment = {
                amount: payment.amount,
                caption: payment.method_name,
                createDate: payment.date,
                currencyIsoCode: sale.currency,
                paymentType: this.getPaymentType(payment),
                salesPerson: operatorUser,
                uniqueReadablePaymentIdentifier: generateUuid(),
                user: operatorUser
            };

            if (result.paymentType === 'multiPurposeVoucher') {
                Object.assign(result, {
                    additionalFields: {
                        voucherId: payment.uuid || payment.code || '0000'
                    },
                    taxValue: paymentVatPerc ? MathUtils.round(Number(payment.amount / (1 + paymentVatPerc / 100) * (paymentVatPerc / 100))) : undefined,
                    vatIdentification: paymentVatId?.vatIdentification,
                    vatPercent: paymentVatPerc
                });
            }

            return result;
        });

        // Add sale change as a negative cash payment
        const saleChange = this.salePayment.getSaleChange(sale);

        if (saleChange) {
            payments.push({
                amount: -saleChange,
                caption: 'Change',
                createDate: new Date().toISOString(),
                currencyIsoCode: sale.currency,
                paymentType: 'cash',
                salesPerson: operatorUser,
                uniqueReadablePaymentIdentifier: generateUuid(),
                user: operatorUser
            });
        }

        return payments;
    };

    /**
     * @description returns a sale vat map for the current sale
     * @param {object} sale the Tilby sale
     * @returns {Promise} a promise (can be resolved with the vats map or rejected with an error code)
     */
    private async getSaleVatsMap(sale: Sales): Promise<[Record<string, number | undefined>, RF.Vat[]]> {
        const clientId = await this.getRetailForceClientID();

        const vatInfo = await this.apiSupportedVatDefinitions(clientId);
        // Check Vat compliancy
        const now = moment();

        const validVats = vatInfo.filter((vat) => (now.isBetween(moment(vat.validFrom), moment(vat.validTo))));
        const documentVats = [...new Set(sale.sale_items?.map((item) => item.vat_perc))].sort((a, b) => a - b);

        const vatsToUse = documentVats
            .map(vatValue => validVats.find(vatData => !!vatData.vatPercents?.includes(vatValue)))
            .map(vatData => vatData?.vatIdentification);

        if (vatsToUse.some(vat => vat == null)) {
            throw 'RETAILFORCE_INVALID_VATS';
        }

        return [Object.fromEntries(documentVats.map((vatValue, index) => [vatValue, vatsToUse[index]])), validVats];
    };


    /**
     * @description converts a Tilby sale to a RetailForce document
     * @param {object} sale the Tilby sale
     * @returns {Promise} a promise (can be resolved with the RetailForce document or rejected with an error code)
     */
    private async saleToDocument(sale: Sales, documentType?: RetailForceDocumentType, options?: progressiveOptions) {
        const [vatsMap, validVats] = await this.getSaleVatsMap(sale);
        const departments = await this.entityManager.departments.fetchCollectionOffline();
        const departmentsMap = keyBy(departments, d => d.id);

        const document = await this.getDocumentTemplate(sale, documentType, options);
        const documentPositions = this.getDocumentPositions(sale, vatsMap, departmentsMap);

        document.partner = this.getDocumentPartner(sale);
        document.positions = documentPositions;
        document.positionCount = documentPositions.length;

        if (documentType !== RetailForceProvider.documentTypes.INVOICE) {
            document.payments = this.getDocumentPayments(sale, validVats);
        }

        return document;
    };

    /**
     * @description Updates the sale_document with the information from the fiscal client
     * @param {object} saleDocument the original sale document
     * @param {object} documentSent the document sent to the fiscal client
     * @param {object} saleDocument the fiscal client response
     * @returns {array} the updated sale_document
     */
    private getUpdatedSaleDocument(saleDocument: SalesDocuments, documentSent: RF.Document, fiscalClientResponse: RF.FiscalResponse, options: progressiveOptions = {}): SalesDocuments[] {
        const returnDocument = {
            ...saleDocument,
            date: moment(fiscalClientResponse.requestCompletionTime).toISOString() as any as Date,
            document_content: this.saveRFDocument(documentSent, fiscalClientResponse),
            ...(options.progressive ? {
                sequential_number: Number(options.progressive),
                sequential_number_prefix: documentSent.documentNumberSeries ?? undefined
            } : {})
        }

        return [returnDocument];
    };

    private async getTicketBaiDocument(saleDocuments: SalesDocuments[], fiscalDocument: RF.Document) {
        // For spain fiscalizations try to retrieve TicketBai documents
        if (this.configurationManager.getShopCountry() !== 'ES') {
            return;
        }

        let tBaiDocument: Blob | null = null;

        const clientId = await this.getRetailForceClientID();

        // Try to retrieve TicketBai document (10 tries)
        for (let i = 0; i < 10 && !tBaiDocument; i++) {
            try {
                const response = await this.apiGetTicketBaiDocument(clientId, fiscalDocument.documentNumber);

                if (response) {
                    tBaiDocument = response;
                }
            } catch (err) {
                // Wait 3 seconds before trying again
                await new Promise(resolve => setTimeout(resolve, 3000));
            }
        }

        // Discard document if bigger than 100 kB
        if (!tBaiDocument || tBaiDocument.size > 100000) {
            return;
        }

        const tBaiDataUrl = await blobToDataURL(tBaiDocument, 'application/zip');

        // Add the document in base64 format
        saleDocuments.push({
            date: saleDocuments[0].date,
            document_content: tBaiDataUrl,
            document_type: 'attachment',
            meta: { mime_type: 'application/zip', file_name: `TicketBai ${fiscalDocument.documentNumber}.zip` },
            sequential_number: saleDocuments[0].sequential_number,
            sequential_number_prefix: saleDocuments[0].sequential_number_prefix
        });
    };

    private getRFDocument(saleDocument?: SalesDocuments) {
        const result: { document?: RF.Document, fiscalResponse?: RF.FiscalResponse } = {
            document: undefined,
            fiscalResponse: undefined
        };

        if (!saleDocument?.document_content) {
            return result;
        }

        try {
            const document = JSON.parse(saleDocument.document_content!) as ({ rfDocument: RF.Document, fiscalResponse: RF.FiscalResponse } | (RF.Document & RF.FiscalResponse));

            if ('rfDocument' in document) {
                result.document = document.rfDocument;
                result.fiscalResponse = document.fiscalResponse;
            } else { //Legacy format where document and fiscalResponse are in the same object
                result.document = document;
                result.fiscalResponse = document;
            }
        } catch {
        }

        return result;
    }

    private getFiscalInfo(document?: RF.Document, fiscalResponse?: RF.FiscalResponse) {
        return <Partial<RF.Document>>{
            fiscalDocumentNumber: fiscalResponse?.fiscalisationDocumentNumber ?? document?.fiscalDocumentNumber!,
            fiscalDocumentRevision: fiscalResponse?.AdditionalFields?.fiscalDocumentRevision,
            FiscalDocumentStartTime: fiscalResponse?.fiscalDocumentStartTime ?? document?.FiscalDocumentStartTime
        }
    }

    private saveRFDocument(rfDocument?: RF.Document, fiscalResponse?: RF.FiscalResponse) {
        return JSON.stringify({ rfDocument: rfDocument || null, fiscalResponse: fiscalResponse || null });
    }

    /**
     * Public methods
     */
    public async getVersion(): Promise<{ fpVersion: string | undefined; fpClientVersion: string | undefined }> {
        await this.fetchClientVersion();

        return {
            fpVersion: this.rfVersion,
            fpClientVersion: this.rfClientVersion
        };
    };

    public getSaleReference(sale: Sales): Partial<SalesItems> {
        const originalDocumentType = sale.sale_documents?.find(saleDocument => saleDocument.document_type !== 'fiscal_provider')?.document_type;
        const fpDocument = sale.sale_documents?.find(saleDocument => saleDocument.document_type === 'fiscal_provider');

        if (!fpDocument) {
            return { reference_sale_id: sale.id };
        }

        let { document: rfDocument } = this.getRFDocument(fpDocument);

        if (!rfDocument) {
            return { reference_sale_id: sale.id };
        }

        const currentCountry = this.configurationManager.getShopCountry();
        const countryStrings = this.retailForceTailStrings[currentCountry];
        const documentTypeId = rfDocument.documentType;
        let documentType;

        const result: Partial<SalesItems> = {
            reference_sale_id: sale.id,
            reference_sequential_number: Number(fpDocument.sequential_number),
            reference_date: fpDocument.date,
        };

        switch (currentCountry) {
            case 'FR':
                switch (documentTypeId) {
                    case RF.DocumentType.Receipt:
                        documentType = originalDocumentType === 'generic_invoice' ? 'INVOICE' : 'RECEIPT';
                        break;
                    case RF.DocumentType.Invoice:
                        documentType = 'INVOICE';
                        break;
                    default:
                        documentType = 'RECEIPT';
                        break;
                }

                const documentProgressive = moment(sale.open_at).format('YYYYMMDD') + String(fpDocument.sequential_number).padStart(10, '0');

                result.reference_text = `${countryStrings[`ORIGINAL_${documentType}`]} ${documentProgressive}`;
                break;
            default:
                break;
        }

        return result;
    };

    public async getReceiptTail(saleDocuments: SalesDocuments[], options: receiptTailOptions = {}) {
        const columns = options.columns || 46;
        const currentCountry = this.configurationManager.getShopCountry();
        let countryStrings = this.retailForceTailStrings[currentCountry] || this.retailForceTailStrings['EN'];

        const printInfoLine = (key: string, value: string) => (`${countryStrings[key]}${String(value).padStart(columns - String(countryStrings[key]).length, ' ')}`);

        const tailRows: string[] = [];
        let tailQr: string | undefined;
        let tailQrSize: number | undefined;

        const fiscalProviderDocument = saleDocuments.find(doc => doc.document_type === 'fiscal_provider');

        let { document: retailForceDocument, fiscalResponse } = this.getRFDocument(fiscalProviderDocument);

        if (retailForceDocument) {
            const documentTypeId = retailForceDocument.documentType;
            let documentType;

            switch (documentTypeId) {
                case RF.DocumentType.Receipt:
                    documentType = options.document_type === 'generic_invoice' ? 'INVOICE' : 'RECEIPT';
                    break;
                case RF.DocumentType.Invoice:
                    documentType = 'INVOICE';
                    break;
                case RF.DocumentType.PreliminaryReceipt:
                    documentType = 'PRELIMINARY';
                    break;
                default:
                    documentType = 'RECEIPT';
                    break;
            }

            switch (currentCountry) {
                case 'AT':
                    if (fiscalResponse?.printMessage) {
                        tailRows.push(...stringToLines(fiscalResponse.printMessage, columns));
                        tailRows.push(' ');
                    }

                    if (retailForceDocument.fiscalDocumentNumber) {
                        tailRows.push(padCenter(`Fiskaldokument Nr. ${retailForceDocument.fiscalDocumentNumber}`, columns, ' '));
                    }
                    break;
                case 'DE':
                    // Start header
                    tailRows.push(padCenter('******** Fiscal Information ********', columns, ' '));

                    // TSE Serial
                    if (fiscalResponse?.AdditionalFields?.TseSerial) {
                        tailRows.push(countryStrings['TSE_SERIAL']);
                        tailRows.push(...stringToLines(fiscalResponse.AdditionalFields.TseSerial, columns));
                    }

                    // TSE transaction start and end
                    if (fiscalResponse?.AdditionalFields?.TransactionStartTime) {
                        tailRows.push(printInfoLine('TSE_TRANSACTION_START', moment(fiscalResponse.AdditionalFields.TransactionStartTime * 1000).toISOString()));
                    }

                    if (fiscalResponse?.AdditionalFields?.TransactionEndTime) {
                        tailRows.push(printInfoLine('TSE_TRANSACTION_END', moment(fiscalResponse.AdditionalFields.TransactionEndTime * 1000).toISOString()));
                    }

                    // TSE transaction number
                    tailRows.push(printInfoLine('TSE_TRANSACTION_NUM', ''));

                    // TSE certificate
                    tailRows.push(printInfoLine('TSE_CERT', ''));

                    // TSE identification
                    if (fiscalResponse?.AdditionalFields?.TseIdentification) {
                        tailRows.push(printInfoLine('TSE_IDENTIFICATION', fiscalResponse.AdditionalFields.TseIdentification || ''));
                    }

                    // TSE signature counter
                    if (fiscalResponse?.AdditionalFields?.TseSignatureCounter) {
                        tailRows.push(printInfoLine('TSE_SIGNATURE_COUNTER', fiscalResponse.AdditionalFields.TseSignatureCounter));
                    }

                    // TSE algorithm
                    if (fiscalResponse?.AdditionalFields?.TseHashAlgorithm) {
                        tailRows.push(printInfoLine('TSE_HASH_ALGORITHM', fiscalResponse.AdditionalFields.TseHashAlgorithm));
                    }

                    // TSE time format
                    if (fiscalResponse?.AdditionalFields?.TseTimeFormat) {
                        tailRows.push(printInfoLine('TSE_TIME_FORMAT', fiscalResponse.AdditionalFields.TseTimeFormat));
                    }

                    // TSE signature
                    if (fiscalResponse?.signature) {
                        tailRows.push(countryStrings['TSE_SIGNATURE']);
                        tailRows.push(...stringToLines(fiscalResponse.signature, columns));
                    }

                    // TSE public key
                    if (fiscalResponse?.AdditionalFields?.TsePublicKey) {
                        tailRows.push(countryStrings['TSE_PUBLIC_KEY']);
                        tailRows.push(...stringToLines(fiscalResponse.AdditionalFields.TsePublicKey, columns));
                    }

                    // End header
                    tailRows.push(padCenter('****************************************', columns, ' '));
                    break;
                case 'FR':
                    // Document date
                    tailRows.push(padCenter(moment(fiscalProviderDocument?.date).format('DD-MM-YYYY HH:mm'), columns, ' '));

                    // Receipt Number
                    const receiptNumber = fiscalResponse?.AdditionalFields?.DocumentNumber?.split(':');

                    if (receiptNumber?.length >= 2) {
                        // Receipt/Invoice copy
                        tailRows.push(padCenter(`${countryStrings[`ORIGINAL_${documentType}`]} ${receiptNumber[receiptNumber.length - 1]}`, columns, ' '));
                        tailRows.push(padCenter(`${countryStrings[`${documentType}_NUMBER`]} ${receiptNumber[0]}`, columns, ' '));
                    } else {
                        // Receipt/Invoice
                        if (retailForceDocument.documentReference?.documentNumber) {
                            tailRows.push(padCenter(`${countryStrings[`ORIGINAL_${documentType}`]} ${retailForceDocument.documentReference.documentNumber}`, columns, ' '));
                        }

                        tailRows.push(padCenter(`${countryStrings[`${documentType}_NUMBER`]} ${retailForceDocument.documentNumber}`, columns, ' '));
                    }

                    // Fiscal Mark
                    if (fiscalResponse?.AdditionalFields?.FiscalMark) {
                        tailRows.push(padCenter(`${countryStrings['FISCAL_MARK']} - ${fiscalResponse.AdditionalFields.FiscalMark}`, columns, ' '));
                    }

                    // SW version
                    const appMajor = tilbyVersion.split('.')[0];
                    const rfMajor = this.rfVersion?.split('.')[0];
                    const rfClientMajor = this.rfClientVersion?.split('.')[0];

                    tailRows.push(padCenter(`${countryStrings['SOFTWARE_VERSION']} ${appMajor}-${rfMajor}-${rfClientMajor}`, columns, ' '));

                    // Number of positions
                    tailRows.push(printInfoLine('POSITION_NUMBERS', String(retailForceDocument.positions?.filter((pos) => pos.type === RF.DocumentPositionType.Item).length)));

                    // Company identification
                    tailRows.push(printInfoLine('IDENTIFICATION', this.configurationManager.getPreference('retailforce.identification') || ''));

                    // Store number
                    tailRows.push(printInfoLine('STORE_NUMBER', this.configurationManager.getPreference('retailforce.store_number') || ''));

                    // Terminal number
                    const terminalNumber = await this.getTerminalNumber();

                    if (terminalNumber) {
                        tailRows.push(printInfoLine('TERMINAL_NUMBER', terminalNumber));
                    }

                    // Reprint count
                    if (fiscalResponse?.AdditionalFields?.ReprintCount) {
                        const reprintCount = fiscalResponse.AdditionalFields.ReprintCount === -1 ? 1 : (fiscalResponse.AdditionalFields.ReprintCount || 1);
                        tailRows.push(printInfoLine('REPRINT_COUNT', String(reprintCount)));
                    }
                    break;
                case 'ES':
                    // Process signature string
                    const signature = (fiscalResponse?.signature || '').split('-');
                    let currentChunk = '';

                    for (let i = 0; i < signature?.length; i++) {
                        const chunk = signature[i];

                        if (currentChunk.length + (chunk.length + 1) > columns) {
                            if (currentChunk) {
                                tailRows.push(padCenter(currentChunk, columns, ' '));
                            }

                            currentChunk = '';
                        }

                        currentChunk += `${chunk}${i === (signature.length - 1) ? '' : '-'}`;
                    }

                    if (currentChunk) {
                        tailRows.push(padCenter(currentChunk, columns, ' '));
                    }
                    break;
            }

            const qrCode: string = fiscalResponse?.qrCode || fiscalResponse?.AdditionalFields?.QrCode;

            if (qrCode) {
                tailQrSize = Number(this.configurationManager.getPreference('retailforce.qr_code_size') || 6);

                if (tailQrSize) {
                    tailQr = qrCode;
                }
            }
        }

        return {
            tailRows: tailRows.join('\n'),
            tailQrCode: tailQr,
            tailQrCodeSize: tailQrSize
        };
    };

    public getProviderError(error: any): string {
        return error?.data?.Message ?? error;
    };

    /**
     * @desc creates a fiscalDocument from the Tilby sale and stores it in RetailForce
     *
     * @param sale The Tilby sale
     * @param documentType The documentType (can be RECEIPT or INVOICE)
     *
     * @return promise
     */
    public async storeFiscalDocument(sale: Sales, documentType: keyof typeof RetailForceProvider.documentTypes, options: progressiveOptions) {
        await this.fetchClientVersion();

        let documentTypeObj = RetailForceProvider.documentTypes[documentType];

        if (!documentTypeObj) {
            throw 'INVALID_RETAILFORCE_DOCUMENT_TYPE';
        }

        // Allow invoices only for bank transfer payments
        if (documentTypeObj === RetailForceProvider.documentTypes.INVOICE) {
            if (!sale.payments?.every(payment => payment.payment_method_type_id === 8)) {
                documentTypeObj = RetailForceProvider.documentTypes.RECEIPT;
            }
        }

        const documentData = await this.openFiscalDocument(sale, documentTypeObj);
        const { document: fiscalDocument, fiscalResponse } = this.getRFDocument(documentData);

        const saleDocument: RF.Document = {
            ...await this.saleToDocument(sale, documentTypeObj, options),
            ...this.getFiscalInfo(fiscalDocument, fiscalResponse)
        };

        if (documentType === 'PRELIMINARY_RECEIPT') {
            const preliminaryUuid = generateUuid();
            saleDocument.documentGuid = preliminaryUuid;
            saleDocument.documentId = preliminaryUuid;
        }

        const storedDocument = await this.apiStoreDocument(saleDocument);

        const saleDocuments = this.getUpdatedSaleDocument(documentData, saleDocument, storedDocument, options);

        await this.getTicketBaiDocument(saleDocuments, saleDocument);

        return saleDocuments;
    };

    /**
     * @desc Reprints a sale, increasing the document reprint counter
     *
     * @param sale The Tilby sale
     *
     * @return promise
     */
    public async reprintDocument(sale: Sales) {
        await this.fetchClientVersion();

        const clientId = await this.getRetailForceClientID();
        const fiscalDocument = this.getSaleRetailForceDocument(sale);

        if (!fiscalDocument) {
            throw 'NOT_A_RETAILFORCE_DOCUMENT';
        }

        const cProperties = await this.apiCountryProperties(clientId);

        // Skip if document reprint recording is not mandatory
        if (!cProperties.mustRecordDocumentReprint) {
            return [];
        }

        const { document: docContent, fiscalResponse: originalResponse } = this.getRFDocument(fiscalDocument);
        const documentGuid = docContent!.documentGuid;

        // Send request to RetailForce
        const reprintAnswer = await this.apiReprintDocument(documentGuid, clientId, this.getOperatorUser());

        //Patch reprint count if original is -1
        if (originalResponse?.AdditionalFields?.ReprintCount === -1 && reprintAnswer.AdditionalFields) {
            reprintAnswer.AdditionalFields.ReprintCount += 1;
        }

        return this.getUpdatedSaleDocument(fiscalDocument, docContent!, reprintAnswer);
    };

    /**
     * @desc creates a fiscalDocument from the Tilby sale and stores it in RetailForce
     *
     * @param sale The Tilby sale
     * @param documentType The documentType (can be RECEIPT or INVOICE)
     *
     * @return promise
     */
    public async voidFiscalDocument(voidSale: Sales, originalProviderDocument: SalesDocuments, options: progressiveOptions) {
        await this.fetchClientVersion();

        const { document: originalFiscalDocument } = this.getRFDocument(originalProviderDocument);

        if (!originalFiscalDocument) {
            throw 'MISSING_ORIGINAL_DOCUMENT';
        }

        const documentTypeObj = Object.values(RetailForceProvider.documentTypes).find((docType) => docType.documentType === originalFiscalDocument.documentType);

        const documentData = await this.openFiscalDocument(voidSale, documentTypeObj);

        let { document: voidDocument, fiscalResponse } = this.getRFDocument(documentData);

        const revertedDocument = await this.apiRevertDocument(originalFiscalDocument);

        const documentReference: RF.DocumentReference = {
            documentBookDate: originalFiscalDocument.bookDate,
            documentId: originalFiscalDocument.documentId,
            documentGuid: originalFiscalDocument.documentGuid,
            referenceType: RF.ReferenceType.Cancellation,
            documentNumber: originalFiscalDocument.documentNumber,
            documentNumberSeries: originalFiscalDocument.documentNumberSeries,
            fiscalDocumentNumber: originalFiscalDocument.fiscalDocumentNumber,
        };

        const documentTemplate = await this.getDocumentTemplate(voidSale, documentTypeObj, options);

        voidDocument = {
            ...voidDocument,
            ...documentTemplate,
            ...{
                partner: revertedDocument.partner,
                positions: revertedDocument.positions,
                positionCount: revertedDocument.positionCount,
                payments: revertedDocument.payments
            },
            ...{
                cancellationDocument: true,
                documentReference: documentReference,
            },
            ...this.getFiscalInfo(voidDocument, fiscalResponse)
        };

        const voidResult = await this.apiStoreDocument(voidDocument);

        const saleDocuments = this.getUpdatedSaleDocument(documentData, voidDocument, voidResult, options);

        await this.getTicketBaiDocument(saleDocuments, voidDocument);

        return saleDocuments;
    };

    /**
     * @desc creates a fiscalDocument from the Tilby sale and stores it in RetailForce
     *
     * @param sale The Tilby sale
     * @param documentType The documentType (can be RECEIPT or INVOICE)
     *
     * @return promise
     */
    public async refundFiscalDocument(refundSale: Sales, originalProviderDocument: SalesDocuments, options: progressiveOptions) {
        await this.fetchClientVersion();

        if (!refundSale.sale_parent_id) {
            throw 'MISSING_SALE_PARENT_ID';
        }

        const originalSale = await this.entityManager.sales.fetchOneOnline(refundSale.sale_parent_id);

        if (!originalSale) {
            throw 'MISSING_ORIGINAL_SALE';
        }

        const fiscalProviderDocument = this.getSaleRetailForceDocument(originalSale);

        if (!fiscalProviderDocument) {
            throw 'MISSING_ORIGINAL_DOCUMENT';
        }

        let { document: originalFiscalDocument } = this.getRFDocument(fiscalProviderDocument);

        if (!originalFiscalDocument) {
            throw 'INVALID_ORIGINAL_DOCUMENT';
        }

        const documentTypeObj = Object.values(RetailForceProvider.documentTypes).find((docType) => docType.documentType === originalFiscalDocument.documentType);

        const documentData = await this.openFiscalDocument(refundSale, documentTypeObj);

        const { document: fiscalDocument, fiscalResponse } = this.getRFDocument(documentData);

        const saleDocument = {
            ...(await this.saleToDocument(refundSale, documentTypeObj, options)),
            ...this.getFiscalInfo(fiscalDocument, fiscalResponse)
        };

        for (const position of saleDocument.positions.filter((position) => position.type === RF.DocumentPositionType.Item)) {
            const originalPosition = originalFiscalDocument.positions.find((originalPos) => 'itemId' in originalPos && 'itemId' in position && originalPos.itemId === position.itemId);

            if (!originalPosition) {
                throw 'MISSING_ORIGINAL_POSITION';
            }

            Object.assign(position, <Partial<RF.DocumentPositionItem>>{
                cancellationPosition: true,
                positionReference: {
                    documentBookDate: originalFiscalDocument.bookDate,
                    documentId: originalFiscalDocument.documentId,
                    documentGuid: originalFiscalDocument.documentGuid,
                    documentNumber: originalFiscalDocument.documentNumber,
                    documentNumberSeries: originalFiscalDocument.documentNumberSeries,
                    positionNumber: originalPosition.positionNumber,
                    referenceType: RF.ReferenceType.Cancellation
                }
            });
        }

        Object.assign(saleDocument, fiscalDocument);

        const storedDocument = await this.apiStoreDocument(saleDocument);

        const saleDocuments = this.getUpdatedSaleDocument(documentData, saleDocument, storedDocument, options);

        await this.getTicketBaiDocument(saleDocuments, saleDocument);

        return saleDocuments;
    };

    /**
     * @desc cancels the fiscalDocument related to a Tilby sale in retailForce
     *
     * @param sale The Tilby sale
     *
     * @return promise
     */
    public async cancelFiscalDocument(sale: Sales) {
        const documentData = await this.openFiscalDocument(sale);
        const { document: fiscalDocument } = this.getRFDocument(documentData);

        const saleDocument = await this.saleToDocument(sale);
        delete saleDocument.payments;

        Object.assign(saleDocument, {
            documentNumber: `${moment(sale.open_at).format('YYYYMMDDHHmmSS')}0000`,
            documentNumberSeries: 'DEL'
        });

        Object.assign(saleDocument, fiscalDocument);

        const cancelResult = await this.apiCancelDocument(saleDocument);

        return this.getUpdatedSaleDocument(documentData, saleDocument, cancelResult);
    };

    /**
     * @desc performs a daily closing
     *
     * @return promise (resolved with the closing document or rejected with an error)
     */
    public async dailyClosing() {
        const clientId = await this.getRetailForceClientID();
        const opData = this.OperatorManager.getOperatorData();

        const dailyClosingData = await this.apiEndOfDayDocument(clientId);
        const documentNumber = dailyClosingData.documentNumber?.split('_').reverse().slice(0, 2); // ['YYYYMMDD', 'N']
        const dailyClosingNumber = moment(documentNumber[0]).format('YYMMDD') + String(documentNumber[1]).padStart(2, '0');

        await this.apiCashpointClose(clientId, dailyClosingData, opData);

        return ({
            date: dailyClosingData.createDate,
            meta: dailyClosingData,
            printer_serial: dailyClosingData.uniqueClientId,
            sequential_number: dailyClosingNumber
        });
    };

    /**
     * @desc sends a cash movement (pay-in/pay-out)
     * @param {number} amount to send (euro)
     * @return promise (returns the cash movement tail if resolved, otherwise rejects with an error)
     */
    public async sendCashMovement(amount: number, printerColumns: number): Promise<string> {
        const documentType = (amount > 0) ? RetailForceProvider.documentTypes.PAYIN : RetailForceProvider.documentTypes.PAYOUT;

        const vatInfo = await this.getTaxFreeVat();
        const saleDocument = await this.openFiscalDocument(undefined, documentType);
        const { document: fiscalDocumentData, fiscalResponse: initialResponse } = this.getRFDocument(saleDocument);
        const transactionAmount = Math.abs(amount);
        const documentTemplate = await this.getDocumentTemplate(undefined, documentType);

        const cashMovementPositions: RF.DocumentPositionBooking[] = [{
            type: RF.DocumentPositionType.Booking,
            caption: 'Cash movement',
            businessTransactionType: (amount > 0) ? RF.BusinessTransactionType.PayIn : RF.BusinessTransactionType.PayOut,
            vatIdentification: vatInfo.vatIdentification,
            vatPercent: 0.0,
            netValue: transactionAmount,
            grossValue: transactionAmount,
            taxValue: 0.0,
            positionNumber: 0,
            cancellationPosition: false
        }];

        const payments: RF.DocumentPayment[] = [{
            amount: transactionAmount,
            currencyIsoCode: TilbyCurrencyPipe.currency.code,
            foreignAmount: 0.0,
            foreignAmountExchangeRate: 0.0,
            paymentType: 'cash',
            uniqueReadablePaymentIdentifier: generateUuid()
        }];

        const fiscalDocument = {
            ...documentTemplate,
            ...fiscalDocumentData,
            ...{
                positions: cashMovementPositions,
                positionCount: cashMovementPositions.length,
                payments: payments
            },
            ...this.getFiscalInfo(fiscalDocumentData, initialResponse)
        };

        const fiscalResponse = await this.apiStoreDocument(fiscalDocument);

        printerColumns = printerColumns || 46;

        const printInfoLine = (name: string, value: string) => (`${name}${String(value).padStart(printerColumns - name.length, ' ')}`);

        const tailRows: string[] = [];

        // Start header
        tailRows.push(padCenter('******** Fiscal Information ********', printerColumns, ' '));

        // Transaction start and end
        tailRows.push(printInfoLine('Start Transaction:', moment(fiscalResponse.AdditionalFields?.TransactionStartTime * 1000).toISOString()));
        tailRows.push(printInfoLine('End Transaction:', moment(fiscalResponse.AdditionalFields?.TransactionEndTime * 1000).toISOString()));

        tailRows.push('');

        // Client ID
        const clientId = await this.getRetailForceClientID();

        tailRows.push('Cashbox Identification:');
        tailRows.push(clientId);

        // Receipt Identification
        tailRows.push(printInfoLine('Receipt Identification: ', ''));

        // Fiscal Signature
        tailRows.push('Fiscal Signature:');
        tailRows.push(...stringToLines(fiscalResponse.signature, printerColumns));

        // End header
        tailRows.push(padCenter('****************************************', printerColumns, ' '));

        return tailRows.join('\n');
    };

    public async configureProvider(configuration: retailForceSetupConfig): Promise<{ client_id: string }> {
        if (typeof configuration !== 'object' || configuration === null) {
            throw 'INVALID_CONFIGURATION';
        }

        let result;

        if (this.configurationManager.getPreference('retailforce.local')) {
            let clientId;

            try {
                //STEP 1: check if the client is already created locally
                const licenseConsumerId = await this.apiLicenseConsumerId(configuration.identification);

                if (licenseConsumerId) {
                    clientId = await this.apiClientId(licenseConsumerId, configuration.storeNumber, configuration.terminalNumber);
                }

                if (!clientId) {
                    clientId = await this.apiCloudClient(configuration.identification, configuration.cloudApiKey, configuration.cloudApiSecret, configuration.storeNumber, configuration.terminalNumber);
                }
            } catch (err: any) {
                if (err.status === 409) {
                    //Client is already configured, try to recover it
                    clientId = await this.apiRestoreByCloud(configuration.identification, configuration.cloudApiKey, configuration.cloudApiSecret, configuration.storeNumber, configuration.terminalNumber);

                    if (!clientId) {
                        throw err;
                    }
                } else {
                    throw err;
                }
            }

            //STEP 2, establish the connection to the client
            await this.apiCloudConnect(clientId, configuration.cloudApiKey, configuration.cloudApiSecret);

            //STEP 3, check if the client needs to be initialized
            const clientStatus = await this.apiClientStatus(clientId);

            switch (clientStatus.state) {
                case RF.FiscalClientState.initialized:
                    //Client is already initialized, nothing to do
                    break;
                case RF.FiscalClientState.notInitialized:
                    //INIT STEP 1, get the start document for the client
                    const startDocResp = await this.apiStartDocument(clientId);

                    //INIT STEP 2, send the start document to initialize the client
                    await this.apiClientInitialize(startDocResp);
                    break;
                case RF.FiscalClientState.decommissioned:
                    throw 'CLIENT_DECOMMISSIONED';
                default:
                    throw 'UNKWNOWN_CLIENT_STATE';
            }

            result = {
                client_id: clientId
            }
        } else {
            // Cloud Setup
            result = await this.restManager.post('retailforce/setup', {
                cloud_api_key: configuration.cloudApiKey,
                cloud_api_secret: configuration.cloudApiSecret,
                identification: configuration.identification,
                store_number: configuration.storeNumber,
                terminal_number: configuration.terminalNumber
            });
        }

        return result;
    };

    public async sendAuditLog(auditData: any) {
        const clientId = await this.getRetailForceClientID();
        const auditInfo = await this.getAuditInfo(auditData);

        if (!auditInfo) {
            return;
        }

        const payload = {
            recordId: auditData.id,
            uniqueClientId: clientId,
            message: '',
            user: this.getOperatorUser(auditData.user)
        };

        Object.assign(payload, auditInfo);

        return this.apiSendAuditLog(payload);
    };

    /**
     * @description openFiscalDocument
     * @param  {object} printer the printer to query
     * @return promise (document data on resolve, error_code on reject)
     */
    public async openFiscalDocument(sale?: Sales, documentType?: RetailForceDocumentType): Promise<SalesDocuments> {
        const fiscalDocument = this.getSaleRetailForceDocument(sale);

        if (fiscalDocument) {
            return fiscalDocument;
        }

        const clientId = await this.getRetailForceClientID();

        if (!documentType) {
            documentType = RetailForceProvider.documentTypes.RECEIPT;
        }

        if (documentType === RetailForceProvider.documentTypes.PRELIMINARY_RECEIPT) {
            return {
                meta: { fiscal_provider: 'retailforce' },
                document_content: this.saveRFDocument(),
                document_type: 'fiscal_provider'
            };
        }

        const fiscalResponse = await this.apiCreateDocument(clientId, documentType.documentType);

        if (fiscalResponse.fiscalisationDocumentNumber! < 0) {
            throw 'INVALID_DOCUMENT_NUMBER';
        }

        return {
            sequential_number: fiscalResponse.fiscalisationDocumentNumber,
            date: moment(fiscalResponse.fiscalisationDocumentNumber).toDate(),
            meta: { fiscal_provider: 'retailforce' },
            document_content: this.saveRFDocument(undefined, fiscalResponse),
            document_type: 'fiscal_provider'
        };
    };
}

angular.module('printers').service('RetailForceProvider', RetailForceProvider);