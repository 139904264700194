import angular from 'angular';
import { DocumentPrintHook } from 'src/app/shared/model/document-printer.model';
import {Sales} from "tilby-models";

class InvoicePlugin implements DocumentPrintHook {
    public isEnabled(): boolean {
        return true; //We don't need checks here
    }

    private checkInvoiceCustomer(sale: any): void {
        let customer = sale.sale_customer;

        if (!customer) {
            throw 'CUSTOMER_MISSING';
        }

        if ((!customer.company_name && !customer.first_name) || (!customer.company_name && !customer.last_name)) {
            throw 'CUSTOMER_MISSING_NAME';
        }

        if (!customer.billing_street || !customer.billing_zip || !customer.billing_city || !customer.billing_prov) {
            throw 'MISSING_BILLING_ADDRESS';
        }

        if (!customer.tax_code && !customer.vat_code) {
            throw 'MISSING_TAX_VAT_CODE';
        }
    }

    public async isPrintable(sale: Sales, printerDocumentData: any, options: any): Promise<void> {
        this.checkInvoiceCustomer(sale);
    }
};

angular.module('printers').service('invoicePlugin', InvoicePlugin);

InvoicePlugin.$inject = [];
