import angular from 'angular';

import {
    ConfigurationManagerService
} from 'src/app/core';

export class FiscalProviders {
    private fiscalProvidersMap: Record<string, string> = {
        retailforce: 'RetailForceProvider'
    };

    private docTypeBlacklist = new Set([
        'expense_report',
    ]);

    private providerDocumentTypes: Record<string, string> = {
        generic_receipt: 'RECEIPT',
        generic_invoice: 'INVOICE',
        preliminary_receipt: 'PRELIMINARY_RECEIPT'
    };

    public static $inject = [
        '$injector',
        'checkManager'
    ];

    constructor(
        private $injector: angular.auto.IInjectorService,
        private configurationManager: ConfigurationManagerService
    ) {
    }

    public getSupportedFiscalProviders(): string[] {
        return Object.keys(this.fiscalProvidersMap);
    }

    public getConfiguredFiscalProvider() {
        if (this.configurationManager.getPreference('retailforce.local') || this.configurationManager.getSettingUserFirst('retailforce.client_id')) {
            return this.$injector.get('RetailForceProvider');
        }

        return null;
    }

    public getFiscalProvider(providerName: string) {
        const providerServiceName = this.fiscalProvidersMap[providerName];

        if (providerServiceName) {
            return this.$injector.get(providerServiceName);
        }

        return null;
    }

    public getProviderDocumentType(docType: string) {
        if (this.docTypeBlacklist.has(docType)) {
            return 'DONT_SEND';
        }

        return this.providerDocumentTypes[docType] || null;
    }
}

angular.module('printers').service('FiscalProviders', FiscalProviders);