import angular from 'angular';
import moment from 'moment-timezone';
import _ from 'lodash';
import { EnvironmentConfig } from 'src/environments/environment-config';
import { countryCodesShort } from "@tilby/tilby-ui-lib/utilities";

angular.module('application', []).config(["$stateProvider", "$translatePartialLoaderProvider", function($stateProvider, $translatePartialLoaderProvider) {
    $translatePartialLoaderProvider.addPart('application');

    $stateProvider.state('app', {
        abstract: true,
        template: require('./application.html'),
        resolve: {
            initTranslations: ["$translate", "coreState", "environmentInfo", async function($translate, coreState, environmentInfo) {
                if(!EnvironmentConfig.enableLanguageTestMode) {
                    return new Promise((resolve, reject) => {
                        $translate.use($translate.preferredLanguage()).then(() => {
                            const testTranslation = 'UPDATER.UPDATE_READY';

                            if(environmentInfo.isAppleMobile() && $translate.instant(testTranslation) === testTranslation) {
                                coreState.restartApplication();
                            } else {
                                resolve();
                            }
                        });
                    });
                }
            }],
            platformChecks: ["$rootScope", "$window", "$timeout", "environmentInfo", "updateManager", "initTranslations", async function($rootScope, $window, $timeout, environmentInfo, updateManager, initTranslations) {
                if(environmentInfo.isElectronApp()) {
                    await new Promise((resolve, reject) => {
                        $window.require('electron').ipcRenderer.send('electron-update-check');

                        $window.require('electron').ipcRenderer.on('electron-update-available', (event, message) => {
                            $rootScope.stopWatchdog();

                            $timeout(function() {
                                reject('SCL_APP_UPDATE_ERROR');
                            }, 600000);

                            $rootScope.$broadcast('loader:changeStatus','backLoader', { mode: 'buffer', mainMessage: '', showLogo: true, updateMode: true });
                        });

                        $window.require('electron').ipcRenderer.on('electron-update-progress', (event, message) => {
                            $rootScope.$broadcast('loader:changeStatus','backLoader', { mode: 'buffer',  value: message.percent });
                        });

                        $window.require('electron').ipcRenderer.on('electron-update-error', (event, message) => {
                            reject('SCL_APP_UPDATE_ERROR');
                        });

                        $window.require('electron').ipcRenderer.on('electron-update-not-available', (event, message) => {
                            resolve();
                        });
                    });
                }

                let updateStatus = await updateManager.checkForUpdates();

                if(updateStatus.updateRequired) {
                    $rootScope.stopWatchdog();
                    throw 'SCL_UPDATE_REQUIRED';
                }

                $rootScope.resetWatchdog();
                $rootScope.showAppLoader();
            }],
            loginStatus: ["sessionManager", "platformChecks", async function(sessionManager, platformChecks) {
                let shopData;
                let userData;

                try {
                    shopData = await sessionManager.hasShopSession();
                    userData = await sessionManager.isLoggedIn();
                } catch(error) {}

                return {
                    loggedShop: !_.isNil(shopData),
                    loggedUser: !_.isNil(userData),
                    shopData: shopData,
                    userData: userData
                };
            }],
            initConnection: ["connection", async function(connection) {
                try {
                    await connection.isOnlinePing();
                } catch(error) {}
            }],
            login: ["loginStatus", "initConnection", "sessionManager", async function(loginStatus, initConnection, sessionManager) {
                var accessToken = sessionManager.checkAccessTokenFromUrl();

                if (loginStatus.loggedUser) {
                    if (accessToken && _.get(loginStatus, ["userData", "oauthdata", "access_token"]) !== accessToken) {
                        await sessionManager.deepLogoutForAccessTokenLogin();
                        await sessionManager.loginWithAccessToken(accessToken);

                        angular.copy({ loggedShop: false, loggedUser: false }, loginStatus); //Force login status to first login
                    }
                } else {
                    if (accessToken) {
                        await sessionManager.loginWithAccessToken(accessToken);
                    } else {
                        await sessionManager.login();
                    }

                    let userSession = await sessionManager.isLoggedIn();

                    //Check if we forced a login with another shop session stored
                    if(loginStatus.loggedShop && loginStatus.shopData.id !== userSession.shop.id) {
                        angular.copy({ loggedShop: false, loggedUser: false }, loginStatus);
                    }
                }
            }],
            privacyCheck: ["login", "$rootScope", "$window", "environmentInfo", async(login, $rootScope, $window, environmentInfo) => {
                if(environmentInfo.isAppleMobile()) {
                    $rootScope.stopWatchdog();

                    try {
                        await $window.cordova.plugins.idfa.requestPermission();
                    } catch(error) {}

                    $rootScope.resetWatchdog();
                }
            }],
            lastActiveSession: ["privacyCheck", "loginStatus", "sessionManager", "startup", function(privacyCheck, loginStatus, sessionManager, startup) {
                if(!loginStatus.loggedUser) {
                    startup.setUserEntitiesStatus('Pending');
                }

                if (loginStatus.loggedShop) {
                    startup.setShopEntitiesStatus('Pending', true);
                    return sessionManager.checkLastActiveSession();
                } else {
                    startup.setShopEntitiesStatus('Pending', false);

                    return {
                        shopExpired: false,
                    };
                }
            }],
            waitForConnection: ["lastActiveSession", "loginStatus", "$translate", "alertDialog", "connection", async function(lastActiveSession, loginStatus, $translate, alertDialog, connection) {
                if(!connection.isOnline()) {
                    //Check deactivation date
                    const deactivationDate = localStorage.getItem('shop::deactivationDate');

                    if(deactivationDate) {
                        if(new Date() > new Date(deactivationDate)) {
                            throw 'SCL_DEACTIVATED';
                        }
                    }
                }

                if (loginStatus.loggedShop && lastActiveSession.shopExpired) {
                    while (!connection.isOnline()) {
                        try {
                            await alertDialog.show($translate.instant('APPLICATION.STARTUP.OFFLINE_AND_INACTIVE'));
                        } catch (err) {
                            // Do nothing
                        }
                    }
                }
            }],
            resolvePendingOperations: ["waitForConnection", "entityManager", function(waitForConnection, entityManager) {
                return entityManager.resetSyncStatus();
            }],
            updateProgressive: ["waitForConnection", "restManager", "progressivesManager", async function(waitForConnection, restManager, progressivesManager) {
                try {
                    const progressive = await restManager.getList("progressive");
                    progressivesManager.updateProgressives(progressive);
                } catch(err) {
                    // Do nothing
                }
            }],
            loadUserSettings: ["resolvePendingOperations", "startup", async function(resolvePendingOperations, startup) {
                await startup.loadUserSettings();

                if(!startup.checkPendingUserSettings()) {
                    throw "SCL_USER_SETTINGS_LOAD_FAILED";
                }
            }],
            loadShopSettings: ["resolvePendingOperations", "startup", async function(resolvePendingOperations, startup) {
                await startup.loadShopSettings();

                if(!startup.checkPendingShopSettings()) {
                    throw "SCL_SHOP_SETTINGS_LOAD_FAILED";
                }
            }],
            initCheckManager: ["loadUserSettings", "loadShopSettings", "checkManager", async function(loadUserSettings, loadShopSettings, checkManager) {
                await checkManager.loadAllSettingsAndPreferences();
                await checkManager.setupLocale();
            }],
            registerDevice: ["initCheckManager", "startup", async function(initCheckManager, startup) {
                await startup.registerDevice();
            }],
            askForPin: ["registerDevice", "$rootScope", "sessionManager", "checkManager", "loginStatus", async function(registerDevice, $rootScope, sessionManager, checkManager, loginStatus) {
                const checkUserPin = checkManager.getPreference('users.ask_the_operator_open_app') && localStorage.getItem('login::skipPin') !== 'true';

                if(loginStatus.loggedUser && checkUserPin) {
                    $rootScope.stopWatchdog();
                    await sessionManager.switchUser();
                    $rootScope.resetWatchdog();
                }

                localStorage.removeItem('login::skipPin'); 
            }],
            loadShopData: ["askForPin","resolvePendingOperations", "lastActiveSession", "loginStatus", "initCheckManager", "startup", async function(askForPin, resolvePendingOperations, lastActiveSession, loginStatus, initCheckManager, startup) {
                await startup.loadShopCollections(lastActiveSession.shopLastOnline, lastActiveSession.shopExpired);

                if(!startup.checkPendingShopEntities()) {
                    throw "SCL_SHOP_ENTITIES_LOAD_FAILED";
                }
            }],
            user: ["login", "userActiveSession", "errorsLogger", "entityManager", async function(login, userActiveSession, errorsLogger, entityManager) {
                try {
                    let user = await entityManager.userSessions.getActiveSession();

                    if (user) {
                        userActiveSession.setSession(user);
                        return user;
                    }
                } catch(error) {
                    errorsLogger.warn("ERROR tables.js getActiveSession not working");
                }
            }],
            initDual: ["dual", function(dual) {
                return dual.init(); //init dual
            }]
        },
        controller: ["$scope", "$rootScope", "user", "loginStatus", "promotionEngine", "entityManager", "entityDictionariesStore", "sessionManager", "globalEvents", "IotManager", "WebServerService", "saleBatchPrint", "errorsLogger", function($scope, $rootScope, user, loginStatus, promotionEngine, entityManager, entityDictionariesStore, sessionManager, globalEvents, IotManager, WebServerService, saleBatchPrint, errorsLogger) {
            $scope.user = user;
            globalEvents.init($scope);
            $rootScope.cleanupWatchdog(); //Boot finished, disable watchdog
            entityDictionariesStore.init();
            promotionEngine.init();
            errorsLogger.setupLogCollection(); //Initialize logging system
            saleBatchPrint.init(); //Initialize sale batch print service

            IotManager.bootstrap();

            try{
                WebServerService.bootstrap();
            }catch(e){
                console.log(" error launching webserver", e);
            }

            $rootScope.$broadcast('startup:completed', { user: user, lastLoginStatus: loginStatus });

            entityManager.startAsyncRoutines(); //Start sync and clean routines
            sessionManager.updateLastActiveSession();
        }]
    })

    .state('app.home', {
        url: '/',
        views: {
            "appContent@app": {
                controller: ["$state", "$http", "$window", "$translate", "startup", "demoGreetingDialog", "alertDialog", "restManager", "checkManager", "user", "environmentInfo", "util", "confirmDialog", "editShopPreferenceDialogService", function($state, $http, $window, $translate, startup, demoGreetingDialog, alertDialog, restManager, checkManager, user, environmentInfo, util, confirmDialog, editShopPreferenceDialogService) {
                    if(environmentInfo.isMobileApp() && !checkManager.getSetting('general.disable_notch_fixes')) {
                        angular.element('body').addClass('notch-fixes');
                    }
                    var resumeState = $window.localStorage.getItem("resumeState");

                    if(resumeState) {
                        var resumeStateParams = $window.localStorage.getItem("resumeStateParams");

                        try {
                            resumeStateParams = JSON.parse(resumeStateParams);
                        } catch(e) {
                            resumeStateParams = undefined;
                        }

                        $window.localStorage.removeItem("resumeState");
                        $window.localStorage.removeItem("resumeStateParams");

                        $state.go(resumeState, resumeStateParams);
                    } else {
                        var startupState = startup.getStartupState();

                        if (startupState) {
                            $state.go("app." + startupState).then(function() {
                                if(_.startsWith(user.shop.name, 'demo_')) {
                                    demoGreetingDialog.show();
                                    $http.get('http://x.scloby.com/hs_timeline/', {
                                        params: {
                                            email: user.email || undefined,
                                            tipo: 'cassa',
                                            shop: user.shop.name,
                                            phone: user.phone || undefined
                                        }
                                    });
                                } else {
                                    (async () => {
                                        // Check billing-related data
                                        let billingData;

                                        try {
                                            billingData = await restManager.getOne('billing_data');
                                        } catch(error) {
                                            billingData = error?.data?.error?.message?.owner_data || null;
                                        }

                                        if(billingData?.update_required) {
                                            await alertDialog.show($translate.instant('APPLICATION.BILLING_UPDATE_REQUIRED')).catch(err => {});

                                            $state.go('app.settings.my_subscription');
                                        }

                                        if(billingData?.deactivated_at) {
                                            localStorage.setItem('shop::deactivationDate', billingData.deactivated_at);

                                            /*
                                                Deactivation date is in format YYYY-MM-DD
                                                Show an alert if there are less than 15 days left
                                            */
                                            const deactivationDate = new Date(billingData.deactivated_at);
                                            const warningDate = new Date(deactivationDate).setDate(deactivationDate.getDate() - 15);

                                            if(new Date() > warningDate) {
                                                await alertDialog.show($translate.instant('APPLICATION.DEACTIVATION_IMMINENT', { deactivationDate: moment(deactivationDate).format('L') })).catch(err => {});
                                            }
                                        } else {
                                            localStorage.removeItem('shop::deactivationDate');
                                        }

                                        // Ask to download app if we are on web app
                                        if (environmentInfo.isWebApp()) {
                                            try {
                                                const answer = await confirmDialog.show($translate.instant('APPLICATION.DIALOG_DOWNLOAD_APP.TITLE'), {
                                                    yesLabel: $translate.instant('APPLICATION.DIALOG_DOWNLOAD_APP.DOWNLOAD'),
                                                    noLabel: $translate.instant('APPLICATION.DIALOG_DOWNLOAD_APP.REMEMBER_ME_LATER')
                                                });

                                                if(answer) {
                                                    var pathDownload = "https://tilby.com/download/";

                                                    if(navigator.userAgent.match(/(Android)/)) {
                                                        pathDownload = "https://play.google.com/store/apps/details?id=com.scloby.pos";
                                                    } else if(navigator.userAgent.match(/(iPhone|iPod|iPad)/)) {
                                                        pathDownload = "https://apps.apple.com/it/app/scloby-punto-cassa-fiscale/id922452353";
                                                    }

                                                    util.openExternalLink(pathDownload);
                                                }
                                            } catch(err) {}
                                        }

                                        //Check and ask for shop informations
                                        const generalPrefs = checkManager.getShopPreferences('general.');

                                        const shopRequiredInfo = ['shopname', 'company_name', 'vat_code', 'address_street', 'address_prov', 'address_zip', 'address_city', 'address_country'];
                                        const shopOptionalInfo = ['email', 'phone'];

                                        const missingInfo = shopRequiredInfo.filter((info) => !generalPrefs[`general.${info}`]);

                                        if(missingInfo.length) {
                                            await new Promise((resolve) => setTimeout(resolve, 2000));

                                            const inputData = {};

                                            for(const pref of [...shopRequiredInfo, ...shopOptionalInfo]) {
                                                inputData[pref] = generalPrefs[`general.${pref}`];
                                            }

                                            const res = await editShopPreferenceDialogService.openDialog(inputData);

                                            if(res) {
                                                const updateData = {
                                                    ...res,
                                                    address_country: countryCodesShort.filter(country => country.name === res.address_country)[0]?.code || 'IT'
                                                };

                                                await restManager.post('/billing_data/setup_shop', updateData).catch(() => {});
                                            }
                                        }
                                    })();
                                }
                            });
                        }
                    }
                }]
            }
        }
    })

    .state('update-required', {
        url: '/update-required',
        params: {
            updateUrl: null
        },
        template: require('./component/update-required/update-required.html'),
        controller: ["$scope", "updateManager", function($scope, updateManager) {
            $scope.updateInfo = updateManager.getLatestUpdateInfo() ?? {};
            $scope.updateProgress = null;

            $scope.updateNow = async () => {
                await updateManager.updateNow((progress) => {
                    $scope.updateProgress = progress;
                    $scope.$applyAsync();
                });

                $scope.updateProgress = null;
            };
        }]
    })

    .state('app.lockScreen', {
        url: '/locked',
        views: {
            "appContent@app": {
                controller: ["$scope", "$rootScope", "$state", "sessionManager", function($scope, $rootScope, $state, sessionManager) {
                    sessionManager.lockScreen();
                    $rootScope.stopWatchdog();

                    $scope.$on("unlock-screen", function() {
                        $state.go("app.home");
                    });
                }]
            }
        }
    })

    //This state is used when the shop is deactivated. All of the data are removed
    .state('deactivated', {
        url: '/deactivated',
        controller: ["$rootScope", "$translate", "sessionManager", "alertDialog", function($rootScope, $translate, sessionManager, alertDialog) {
            $rootScope.stopWatchdog();
            alertDialog.show($translate.instant('ERROR_STATES.DEACTIVATED'), { blocking: true }).then(function() {
                sessionManager.logoutCloseSessionsDeep(false);
            });
        }]
    })

    .state('reset', {
        url: '/reset',
        params: {
            askConfirm: true
        },
        controller: ["confirmDialog", "environmentInfo", "$rootScope", "$state", "$stateParams", "coreState", function(confirmDialog, environmentInfo, $rootScope, $state, $stateParams, coreState) {
            $rootScope.stopWatchdog();

            var performReset = function() {
                if(environmentInfo.isElectronApp()) {
                    window.require('electron').ipcRenderer.invoke("reset-app");
                } else {
                    window.indexedDB.deleteDatabase('scloby').onsuccess = () => coreState.restartApplication();
                }
            };

            if($stateParams.askConfirm) {
                confirmDialog.show("Reimpostare completamente l'applicazione?").then(function(answer) {
                    if(answer) {
                        performReset();
                    } else {
                        $state.go('app.home');
                    }
                });
            } else {
                performReset();
            }
        }]
    })

    .state('error', {
        url: '/error',
        params: {
            errorCode: null
        },
        controller: ["$rootScope", "alertDialog", "$stateParams", "$state", "$translate", "$window", "$timeout", "coreState", "util", function($rootScope, alertDialog, $stateParams, $state, $translate, $window, $timeout, coreState, util) {
            $rootScope.stopWatchdog();
            var errorMessage;
            var confirmLabel;
            var action;
            switch($stateParams.errorCode) {
                case 'SCL_USER_SETTINGS_LOAD_FAILED': case 'SCL_SHOP_SETTINGS_LOAD_FAILED': case 'SCL_SHOP_ENTITIES_LOAD_FAILED':
                    errorMessage = $translate.instant('ERROR_STATES.ENTITY_STARTUP_FAILED');
                    confirmLabel = $translate.instant('MISC.RETRY');
                    action = "RESTART";
                    break;
                case 'SCL_APP_UPDATE_ERROR':
                    errorMessage = $translate.instant('UPDATER.UPDATE_FAILED');
                    confirmLabel = $translate.instant('MISC.OK');
                    action = 'OPEN_UPDATE_URL';
                break;

                default: break;
            }
            if(errorMessage) {
                alertDialog.show(errorMessage,{confirmLabel} ).finally(async function() {
                    switch(action) {
                        case 'RESTART':
                            coreState.restartApplication();
                            break;
                        case 'OPEN_UPDATE_URL':
                            util.openExternalLink('https://tilby.com/download/');
                            $timeout(function() {
                                $window.close();
                            }, 2000);
                            break;
                        default: break;
                    }
                });
            } else {
                $state.go('app.home');
            }
        }]
    });
    /* Add New States Above */
}]);
