import angular from 'angular';
import _ from 'lodash';
import { v4 as generateUuid } from 'uuid';

angular.module('cashregister').factory('saleUtils', saleUtils);

saleUtils.$inject = ["entityManager", "util", "newSaleUtils"];

function saleUtils(entityManager, util, newSaleUtils) {
    const saleUtils = {
        getSaleTemplate: (options) => newSaleUtils.getSaleTemplate(options),
        calculateSalePrices: (targetSale) => newSaleUtils.calculateSalePrices(targetSale),
        calculateSaleItemsCosts: async (targetSale) => {
            let itemsUuids = _(targetSale.sale_items).map('variations').map((variation) => _.map(variation, 'linked_item_uuid')).flatten().reject(_.isNil).uniq().value();

            if(!_.isEmpty(itemsUuids)) {
                let items = await entityManager.items.fetchCollectionOffline({ uuid_in: itemsUuids });
                let itemsByUuid = _.keyBy(items, 'uuid');

                _.forEach(targetSale.sale_items, (saleItem) => {
                    let saleItemVariationCost = _(saleItem.variations)
                        .filter('linked_item_uuid')
                        .sumBy((variation) => _.chain(itemsByUuid).get([variation.linked_item_uuid, 'cost']).toFinite().value());

                    if(!_.isNil(saleItem.cost) || saleItemVariationCost) {
                        saleItem.cost = util.round(_.toFinite(saleItem.cost) + saleItemVariationCost);
                    }
                });
            }
        },
        getCleanSaleItem: (saleItem, keepPriceChanges) => {
            const cleanSaleItem = _.omit(saleItem, ['id', 'order_id', 'sale_id', 'created_at', 'updated_at', 'deleted_at']);

            //Keep price changes if asked, otherwise remove item price changes and use the final price as the base price
            if(keepPriceChanges) {
                Object.assign(cleanSaleItem, {
                    ingredients: saleUtils.getCleanSubEntity(cleanSaleItem.ingredients),
                    price_changes: saleUtils.getCleanSubEntity(cleanSaleItem.price_changes),
                    variations: saleUtils.getCleanSubEntity(cleanSaleItem.variations),
                });
            } else {
                Object.assign(cleanSaleItem, {
                    half_portion: null,
                    ingredients: [],
                    price_changes: [],
                    price: cleanSaleItem.final_price,
                    variations: [],
                });
            }

            return cleanSaleItem;
        },
        getCleanSubEntity: (subEntity) => _.map(subEntity, (row) => _.omit(row, ['id', 'order_id', 'order_item_id', 'sale_id', 'sale_item_id', 'created_at', 'updated_at', 'deleted_at']))
    };

    return saleUtils;
}
