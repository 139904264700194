<div class="tw-flex tw-flex-row" style="height: 100%;">
    <div class="tw-w-full">
        <mat-tab-group color="primary" animationDuration="0ms" style="height: 100%;" [selectedIndex]="getTabChange" (selectedTabChange)="tabPanelChange.emit($event.index)">
            <mat-tab label="{{'SETTINGS.USERS_MANAGEMENT.TABS.USER' | translate}}"><ng-content select="[Users]"></ng-content></mat-tab>
            <mat-tab label="{{'SETTINGS.USERS_MANAGEMENT.TABS.DEVICE_WAITER' | translate}}"><ng-content select="[QrCode_Waiter]"></ng-content></mat-tab>
            <mat-tab label="{{'SETTINGS.USERS_MANAGEMENT.TABS.DEVICES' | translate}}"><ng-content select="[Devices]"></ng-content></mat-tab>
            <mat-tab label="{{'SETTINGS.USERS_MANAGEMENT.TABS.STATIC_TOKEN' | translate}}"><ng-content select="[StaticToken]"></ng-content></mat-tab>
        </mat-tab-group>
    </div>
</div>
