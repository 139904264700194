import { $state } from 'app/ajs-upgraded-providers';
import {
    Component,
    inject,
} from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { CashMovements } from 'tilby-models';
import { CashMovementDialogService } from 'src/app/dialogs/cash-movement/cash-movement.dialog.component';
export interface CliccableActionCashMovementscard {action: 'details' | 'go-to-sale'; data: CashMovements | undefined}

@Component({
    selector: 'app-grid-clickable-cash-movements-button',
    templateUrl: './grid-clickable-button-cash-movements.component.html',
    styleUrls: ['./grid-clickable-button-cash-movements.component.scss']
})
export class GridClickableCashMovementsButtonComponent implements ICellRendererAngularComp {
    private readonly cashMovementDialogService = inject(CashMovementDialogService);
    private readonly state = inject($state);

    params!: ICellRendererParams<CashMovements>;

    agInit(params: ICellRendererParams<CashMovements>): void {
        this.params = params;
    }

    refresh(): boolean {
        return false;
    }

    async openDetailsDialog($event: any) {
        await this.cashMovementDialogService.openDialog({
            data: {
                cashMovement: this.params.data!
            },
        });
    }

    goToSale($event: any) {
        this.params.data!.sale_id && this.state.go("app.new.history.sale", {id: this.params.data!.sale_id});
    }

    preventSelection($event: MouseEvent) {
        $event.stopPropagation();
    }
}
