import {Component, OnInit, ViewChild, inject} from '@angular/core';
import { $stateParams, restManager} from "app/ajs-upgraded-providers";
import { ToolbarEventsService} from 'src/app/core';
import {
    AlertDialogService,
    GridDownloadDialogService,
    OpenDialogsService
} from 'src/app/dialogs';
import { SettingsUmStaticTokenComponent } from './tab-settings-um/settings-um-static-token/settings-um-static-token.component';
import { autobind } from "src/app/models/decorators.model";
import { DataExport } from "src/app/shared/model/model";

@Component({
  selector: 'app-settings-users-management',
  templateUrl: './settings-users-management.component.html',
  styleUrls: ['./settings-users-management.component.scss']
})
export class SettingsUsersManagementComponent implements OnInit {
    private readonly alertDialogService = inject(AlertDialogService);
    private readonly toolbarEventsService = inject(ToolbarEventsService);
    private readonly openDialogsService = inject(OpenDialogsService);
    private readonly restManagerService = inject(restManager);
    private readonly gridDownloadDialogService = inject(GridDownloadDialogService);


    @ViewChild('staticTokenComponent') staticTokenComponent!: SettingsUmStaticTokenComponent;
    private stateParams = inject($stateParams);
    tabChange: number = 0;

    tabPanel?: 0 | 1 | 2 | 3 = 0;

    ngOnInit(): void {
        this.createToolbarButtons();
        this.setModuleTitle();
        this.toolbarEventsService.searchBarValue$.next('');
        if(this.stateParams.tab) this.tabChange = this.stateParams.tab;
    }

    setModuleTitle() {
        this.toolbarEventsService.moduleTitle.next("USERS-MANAGEMENT");
    }

    changeTab(event: any) {
        this.createToolbarButtons(event);
    }

    createToolbarButtons(event: number = 0) {
        this.toolbarEventsService.buttons$.next({
            barButtons: [
                {
                    isIt: () => event === 3,
                    name: 'add_static_token',
                    icon: () => 'playlist_add',
                    click: _ => this.openDialogsService.addNewStaticTokenDialog().then((res) => res && this.addCallBack(res))
                }
            ],
            panelButtons: event === 3 ? [
                {
                    isIt: () => false,
                    name: 'export',
                    icon: () => 'download',
                    label: () => 'TOPBAR.ACTIONS.EXPORT',
                    click: async _ => await this.gridDownloadDialogService.openDialog('settings_um_static_token',{data: this.staticTokenComponent.tableData.map(tableData => ({...tableData,sortFilterQuery: this.staticTokenComponent.gridRef.getSortFilterQuery(),countSelectedRows:this.staticTokenComponent.gridRef.gridApi?.getSelectedRows().length}))},'static_token').then(res=>!!res&&this.onExport(res,'static_token'))
                },
            ] : []
        });
    }

    addCallBack(data: any) {
        this.restManagerService.post("permissions/static_tokens", {
            "client_id": data.client_id,
            "description": data.description
        })
        .then((staticToken: {token: string}) => {
            this.staticTokenComponent.refreshGrid();
            if (staticToken) {
                this.openDialogsService.copyNewStaticTokenDialog({data: staticToken});
            }
        }).catch((err : any) => {
            console.error(err);
            this.alertDialogService.openDialog({data: {messageLabel: 'SETTINGS.USERS_MANAGEMENT.ERROR_POST_TOKEN'}});
        });
    }

    linkCallBack(data: any) {
        this.alertDialogService.openDialog({data: {messageLabel: data}});
    }

    @autobind
    onExport(dataExport: DataExport,tableToCall?:string) {
        this.staticTokenComponent.gridRef?.onBtnExport(dataExport,tableToCall);
    }
}
