import {
    Component,
    computed,
    inject,
    signal,
} from '@angular/core';

import {
    filter,
    merge
} from 'rxjs';

import { ActiveSaleActionButtonsComponent } from "../active-sale-action-buttons";

import {
    PaymentMethods,
} from "tilby-models";

import {
    ConfigurationManagerService,
    EntityManagerService,
    PaymentMethodTypeClass,
    paymentMethodTypes,
    ScreenOrientationService
} from "src/app/core";

import { TranslateService } from '@ngx-translate/core';

import {
    BadgeInputButton,
    BaseInputButton,
    LongPressInputButton
} from '@tilby/tilby-ui-lib/models';

import {
    $state,
} from "app/ajs-upgraded-providers";

import {
    ConfirmDialogWithRememberService,
    ItemListSelectorDialogService,
    PendingPrintsDialogService,
    PrinterErrorFiscalDialogService
} from 'src/app/dialogs';

import {
    CommonModule,
} from '@angular/common';

import {
    ActiveSaleService,
    ActiveSaleStoreService,
    SalePrintingUtilsService,
    SaleUpdatesData
} from 'src/app/features/cashregister';

import { ActiveSalePaymentService } from 'src/app/features/cashregister/services/active-sale-payment.service';

import { SaleMode } from 'src/app/shared/model/cashregister.model';
import { Exit } from "@tilby/tilby-ui-lib/components/tilby-order";
import { GenericListDialogMessage } from "src/app/dialogs/generic-list-dialog/generic-list-dialog.model";
import { CashregisterKeyboardComponent } from "../../cashregister-keyboard";
import { KeyboardParentToAddNewItemToSaleComponent } from '../../../keyboard-parent-to-add-new-item-to-sale';
import { subscribeInComponent } from '@tilby/tilby-ui-lib/utilities';
import { DocumentPrinterOptions } from 'src/app/shared/model/document-printer.model';

@Component({
    selector: 'app-active-sale-action-buttons-wrapper',
    standalone: true,
    templateUrl: './active-sale-action-buttons-wrapper.component.html',
    imports: [
        ActiveSaleActionButtonsComponent,
        CommonModule,
        CashregisterKeyboardComponent
    ],
    providers: [ActiveSalePaymentService],
    styleUrls: ['./active-sale-action-buttons-wrapper.component.scss'],
})
export class ActiveSaleActionButtonsWrapperComponent extends KeyboardParentToAddNewItemToSaleComponent {
    private readonly state = inject($state);
    private readonly activeSalePaymentService = inject(ActiveSalePaymentService);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly translateService = inject(TranslateService);
    private readonly salePrintingUtilsService = inject(SalePrintingUtilsService);
    private readonly pendingPrintsDialogService = inject(PendingPrintsDialogService);
    private readonly confirmDialogWithRememberService = inject(ConfirmDialogWithRememberService);
    private readonly printerErrorFiscalService = inject(PrinterErrorFiscalDialogService);
    private readonly screenOrientationService = inject(ScreenOrientationService);
    private readonly itemListSelector = inject(ItemListSelectorDialogService);
    private readonly configurationManager = inject(ConfigurationManagerService);
    private readonly activate_skip_printing = this.configurationManager.isUserPermitted("skip_printing");
    
    protected sideKeyboard = this.cashregisterStateService.sideKeyboard;
    protected isMobilePortrait = this.screenOrientationService.isMobilePortrait;

    protected isLoading = computed(() =>
        this.activeSaleService.printOrderInProgress() ||
        this.activeSaleService.printDocumentInProgress() ||
        this.activeSaleService.paymentInProgress()
    );

    protected sale? = this.activeSaleService.isActiveSale() ? this.activeSaleService.currentSale : undefined;
    private hasPaidPayments = false

    private exitArray$ = this.cashregisterStateService.exitsArray$;
    private ordersExitEnabled = true; //this.configurationManagerService.getPreference('application.functions.orders.exit'); // TODO : Preference or Setting

    protected actionButtons: BadgeInputButton[] = this.calculateOrderButtonMobile('bill');
    protected fastPaymentsButtons: LongPressInputButton[] = [];
    protected layoutActionButtons: SaleMode = 'order';
    protected principalActionButton: BaseInputButton | undefined;
    protected actionButtonsHeight = this.calculateActionButtonsHeight();

    // Configurations
    private readonly acceptsPayments = this.configurationManagerService.isUserPermitted('cashregister.accept_payments');
    protected showPrices = this.configurationManagerService.isUserPermitted('show_prices_ui');

    private calculateActionButtonsHeight() {
        if (this.isMobilePortrait()) {
            return 'tw-h-full';
        }

        if(!this.principalActionButton) {
            return 'tw-h-[114px]';
        }

        return this.fastPaymentsButtons.length > 0 ? 'tw-h-[145px]' : 'tw-h-[72px]';
    }

    private getOrderActionButtons(): BadgeInputButton[] {
        const actionButtons = [ 
            {
                name: 'CASHREGISTER.ACTIVE_SALE.ACTION_BUTTONS.PARKING',
                icon: signal('save'),
                click: () => this.save(),
            },
            {
                name: 'CASHREGISTER.ACTIVE_SALE.ACTION_BUTTONS.SEND',
                icon: signal('send'),
                class: 'tilby-body-background-primary-color',
                click: () => this.send(),
            }
        ];

        if(this.activate_skip_printing) {
            actionButtons.splice(1, 0, 
                {
                    name: 'CASHREGISTER.ACTIVE_SALE.ACTION_BUTTONS.WITHOUT_SEND',
                    icon: signal('cancel_schedule_send'),
                    click: () => this.cancelScheduleSend(),
                }
            );
        }

        return actionButtons;
    }

    private async saleChanged(updates: SaleUpdatesData | DocumentPrinterOptions) {
        // Check for SaleUpdatesData instead of DocumentPrinterOptions
        if('hasPaidPayments' in updates) {
            this.sale = updates.currentSale;
            this.layoutActionButtons = updates.saleMode || 'bill';
            this.hasPaidPayments = updates.hasPaidPayments;
        }

        // Mobile Test
        if (this.isMobilePortrait()) {
            this.principalActionButton = undefined;
            this.fastPaymentsButtons = [];
            this.actionButtons = this.calculateOrderButtonMobile(this.layoutActionButtons);
        } else {
            switch (this.layoutActionButtons) {
                case 'order':
                    this.actionButtons = this.getOrderActionButtons();

                    this.principalActionButton = undefined;
                    this.fastPaymentsButtons = [];
                    break;
                case 'bill':
                    this.actionButtons = [];

                    const isEnrc = this.checkIsEnrc();
                    const hasAllSaleItemsPriceNegative = this.hasAllSaleItemsPriceNegative();

                    this.fastPaymentsButtons = this.calculateFastPaymentButtons(isEnrc, hasAllSaleItemsPriceNegative);

                    this.principalActionButton = {
                        name: 'CASHREGISTER.ACTIVE_SALE.ACTION_BUTTONS.PAY',
                        click: () => this.pay(),
                        icon: signal(''),
                        isDisable: signal(!this.sale || !this.acceptsPayments || hasAllSaleItemsPriceNegative || isEnrc)
                    };
                    break;
            }
        }

        this.actionButtonsHeight = this.calculateActionButtonsHeight();
    }

    constructor() {
        super();

        // Subscriptions
        subscribeInComponent(
            this.cashregisterStateService.addItemToSale$.pipe(filter(() => this.sideKeyboard())),
            (data) => data.item && this.addItemToSale(data.item, data.combination_id, data.quantity, data.barcode)
        );

        const cashregisterUpdates = merge(
            ActiveSaleService.printerDocumentDataUpdates$,
            ActiveSaleStoreService.saleUpdates$
        )

        subscribeInComponent(cashregisterUpdates, (updates) => this.saleChanged(updates));
    }

    // START - SALE CHANGED FUNCTIONS

    //MOBILE
    private calculateOrderButtonMobile(layout: SaleMode): BadgeInputButton[] {
        switch (layout) {
            case 'order':
                return <BadgeInputButton[]>[
                    ...(this.ordersExitEnabled && [
                        {
                            name: 'CASHREGISTER.ACTIVE_SALE.ACTION_BUTTONS.EXIT',
                            icon: signal('move_item'),
                            click: () => this.exit(),
                            badgeValue: -1,
                        },
                    ] || []),
                    ...this.getOrderActionButtons()
                ];
            case 'bill':
                const saleDisableSignal = signal(!this.sale);

                return <BadgeInputButton[]>[
                    {
                        name: 'CASHREGISTER.ACTIVE_SALE.ACTION_BUTTONS.PAY',
                        icon: signal('receipt_long'),
                        click: () => this.pay(),
                        isDisable: signal(!this.sale || !this.acceptsPayments),
                        class: !this.acceptsPayments ? 'button-disabled' : '',
                    }, {
                        name: 'CASHREGISTER.ACTIVE_SALE.ACTION_BUTTONS.SPLIT',
                        icon: signal('call_split'),
                        click: () => this.callSplit(),
                        isDisable: saleDisableSignal,
                    }, {
                        name: 'CASHREGISTER.ACTIVE_SALE.ACTION_BUTTONS.TRANSFER',
                        icon: signal('move_up'),
                        click: () => this.modifySale(),
                        isDisable: saleDisableSignal,
                    },
                    ...(this.ordersExitEnabled && [
                        {
                            name: 'CASHREGISTER.ACTIVE_SALE.ACTION_BUTTONS.EXIT',
                            icon: signal('move_item'),
                            click: () => this.exit(),
                            badgeValue: -1,
                            isDisable: saleDisableSignal,
                        },
                    ] || []),
                ];
            default:
                return [];
        }
    }
    /**
     *  Calculates the fast payment buttons based on the provided layout.
     *  @returns An array of fast payment buttons or undefined.
     */
    private calculateFastPaymentButtons(isEnrc: boolean, hasAllSaleItemsPriceNegative: boolean): LongPressInputButton[] {
        if (isEnrc) {
            return [
                {
                    name: 'CASHREGISTER.ACTIVE_SALE.CREATE_SUMMARY_INVOICE',
                    click: () => this.paySummary(),
                    longPress: () => undefined,
                    icon: signal('')
                }
            ];
        }

        if (hasAllSaleItemsPriceNegative) {
            return [
                {
                    name: 'CASHREGISTER.ACTIVE_SALE.RETURN_OR_CANCEL',
                    click: () => this.emitCreditNote(),
                    longPress: () => undefined,
                    icon: signal(''),
                    isDisable: signal(!this.acceptsPayments)
                }
            ];
        }

        const fastPayments: LongPressInputButton[] = [];

        const paymentDisableSignal = signal(!this.sale || !this.acceptsPayments || this.hasPaidPayments || hasAllSaleItemsPriceNegative || isEnrc);

        for (let i = 1; i <= 3; i++) {
            const fastPaymentClass = this.configurationManagerService.getPreference(`cashregister.fast_payment_${i}_type`);

            if (fastPaymentClass) {
                fastPayments.push({
                    name: this.configurationManagerService.getPreference(`cashregister.fast_payment_${i}_label`) || this.translateService.instant(`PAYMENT_CLASSES.${(<string>fastPaymentClass).toUpperCase()}`),
                    click: () => this.fastPayment(i, fastPaymentClass, 'click'),
                    longPress: () => this.fastPayment(i, fastPaymentClass, 'longPress'),
                    icon: signal(''),
                    isDisable: paymentDisableSignal,
                });
            }
        }

        if (fastPayments.length) {
            return fastPayments;
        }

        return [];
    }

    // END - SALE CHANGED FUNCTIONS

    // START - BUTTON ACTION FUNCTIONS
    private pay() {
        this.goToPayments();
    }

    private async save() {
        const { room_id, order_type } = this.activeSaleService.currentSale || {};

        //Saves and closes the current sale, so after this step currentSale is empty
        await this.activeSaleService.saveSale(undefined, true);

        if (this.configurationManagerService.getPreference("orders.open_tables_after_park")) {
            this.activeSaleService.goToTablesSelectedRoom(room_id, order_type);
        }
    }

    private goToTables() {
        const { order_type, room_id } = this.activeSaleService.currentSale || {};
        this.activeSaleService.goToTablesSelectedRoom(room_id, order_type);
    }

    private goToTablesAfterSend() {
        if (this.configurationManagerService.getPreference("orders.open_tables_after_send")) {
            this.goToTables();
        }
    }

    private goToTablesAfterSkipSend() {
        if (this.configurationManagerService.getPreference("orders.open_tables_after_skip_send")) {
            this.goToTables();
        }
    }

    private async cancelScheduleSend() {
        const skipPreference = "dialog.skip_printing.skip_question";
        const skipQuestion = this.configurationManagerService.getPreference(skipPreference);

        if (!skipQuestion) {
            const response = await this.confirmDialogWithRememberService.openDialog({
                data: {
                    textContent: 'CASHREGISTER.ACTIVE_SALE.SKIP_TRANSACTION_PRINTING_CONFIRM',
                    textToggle: 'CASHREGISTER.ACTIVE_SALE.REMEMBER_CHOICE',
                    classTextContent: 'tw-pb-4'
                }
            });

            if (!response.confirm) {
                return;
            }

            if (response.toggleChoice) {
                this.configurationManagerService.setUserPreference(skipPreference, true);
            }
        }

        await this.activeSaleService.skipTransactionsSend();
        this.goToTablesAfterSkipSend();
    }


    private async send() {
        const results = await this.activeSaleService.sendTransactions();

        if (this.salePrintingUtilsService.getPrintErrors(results).length) {
            this.pendingPrintsDialogService.openDialog();
        } else {
            this.goToTablesAfterSend();
        }
    }

    private openPrinterErrorDialog(error: any) {
        if(error != null) {
            this.printerErrorFiscalService.openDialog({
                data: {
                    error,
                    options: {
                        printerId: this.activeSaleService.printerDocumentData?.printer.id
                    }
                }
            });
        }
    }

    private async fastPayment(fastPaymentMethodIndex: number, fastPaymentMethod: PaymentMethodTypeClass, type: 'click' | 'longPress') {
        if (this.activeSaleService.paymentInProgress() || this.activeSaleService.lockPaymentButtons()) {
            return;
        }

        try {
            this.activeSaleService.paymentInProgress.set(true);

            await this.activeSalePaymentService.beforePayment();

            //Find payment method types for the button class
            const paymentMethodTypeIds = paymentMethodTypes.filter((methodType) => methodType.class === fastPaymentMethod).map((methodType) => methodType.id);

            if (!paymentMethodTypeIds.length) {
                return;
            }

            //Find available payment methods for the button class
            const paymentMethods = await this.entityManagerService.paymentMethods.fetchCollectionOffline({ payment_method_type_id_in: paymentMethodTypeIds, hidden: false });

            //Get user default payment method id for this button if exists
            const defaultPaymentMethodPreference = `cashregister.fastpayment_${fastPaymentMethodIndex}` as `cashregister.fastpayment_${number}`;
            let defaultPaymentMethodIdPref = this.configurationManagerService.getPreference(defaultPaymentMethodPreference);

            let targetPaymentMethod: PaymentMethods | undefined;

            //If there is a default id, check if the related payment method exists for this class
            if (defaultPaymentMethodIdPref) {
                const defaultPaymentMethodId = parseInt(defaultPaymentMethodIdPref) || 0;

                targetPaymentMethod = paymentMethods.find((paymentMethod) => paymentMethod.id === defaultPaymentMethodId);
            }

            //Show a selection dialog if we cannot find the default payment method or the user is forcing the manual selection
            if (!targetPaymentMethod || type == 'longPress') {
                if (paymentMethods.length > 1) {
                    await this.itemListSelector.openDialog({ data: { message: 'CASHREGISTER.ACTIVE_SALE.SELECT_PAYMENT', items: paymentMethods, options: { defaultElement: targetPaymentMethod }} })
                    .then((selectResult) => {
                        //Save the selection if the user wants so
                        if (selectResult.setAsDefault) {
                            this.configurationManagerService.setUserPreference(defaultPaymentMethodPreference, selectResult.element.id);
                        }

                        targetPaymentMethod = selectResult.element;
                    });
                } else {
                    targetPaymentMethod = paymentMethods[0];
                }
            }

            if (!targetPaymentMethod) {
                return;
            }

            const paymentAmountForCashType = targetPaymentMethod.payment_method_type_id == 1 ? this.cashregisterStateService.keyboardTotalValue() : undefined;

            if (!!paymentAmountForCashType && (this.activeSaleService.currentSale.final_amount || 0) > (paymentAmountForCashType || 0)) {
                return this.alertDialogService.openDialog({ data: { messageLabel: 'CASHREGISTER.ACTIVE_SALE.PAYMENT_AMOUNT_EXCEEDS_FINAL_AMOUNT' } });
            }

            await this.activeSaleService.fastPayment(targetPaymentMethod, paymentAmountForCashType);

            await this.activeSaleService.processSaleChange();
            await this.activeSalePaymentService.emitDocument();
        } catch (error) {
            this.openPrinterErrorDialog(error);
        } finally {
            //delete fastPaymentMethod.paying;
            this.activeSaleService.paymentInProgress.set(false);
        }
    }

    //MOBILE ONLY
    private async exit() {
        const exitSelected = (await this.openDialogsService.openGenericListDialog<GenericListDialogMessage, Exit>({ data: { title: { label: 'DIALOG.GENERIC_LIST_DIALOG.EXIT.TITLE' }, list: this.exitArray$.getValue() } }));

        if (exitSelected) {
            this.cashregisterStateService.setExit(exitSelected);
        }
    }
    private callSplit() {
        if (!this.activeSaleService.isActiveSale()) {
            return;
        }

        if (!this.sale?.sale_items?.length) {
            return this.alertDialogService.openDialog({
                data: {
                    messageLabel: 'CASHREGISTER.ACTIVE_SALE_MODEL.NO_ITEM_IN_SALE'
                }
            });
        }

        this.activeSaleService.splitSale();
    }
    private modifySale() {
        if (this.activeSaleService.isActiveSale()) {
            this.activeSaleService.editSale();
        }
    }

    // END - BUTTON ACTION FUNCTIONS

    // START - PAYMENT FUNCTIONS

    public async goToPayments() {
        try {
            await this.activeSalePaymentService.beforePayment();
            this.state.go('app.new.cashregister.content.payments', {});
            ActiveSaleService.activeSaleEvents$.next({ event: 'go-to-payments' });
        } catch (error) {
            this.openPrinterErrorDialog(error);
        }
    };
    // END - PAYMENT FUNCTIONS

    async emitCreditNote() {
        if (this.activeSaleService.paymentInProgress() || this.activeSaleService.lockPaymentButtons()) {
            return;
        }

        try {
            this.activeSaleService.paymentInProgress.set(true);
            //Handle payments reversal
            await this.activeSalePaymentService.beforePayment();
            await this.activeSaleService.processPayments();

            await this.activeSalePaymentService.emitDocument();
        } catch (error) {
            this.openPrinterErrorDialog(error);
        } finally {
            this.activeSaleService.paymentInProgress.set(false);
        }
    };

    async paySummary() {
        if (this.activeSaleService.paymentInProgress() || this.activeSaleService.lockPaymentButtons()) {
            return;
        }

        try {
            this.activeSaleService.paymentInProgress.set(true);

            await this.activeSalePaymentService.beforePayment();
            await this.activeSalePaymentService.emitDocument();
        } catch (error) {
            this.openPrinterErrorDialog(error);
        } finally {
            this.activeSaleService.paymentInProgress.set(false);
        }
    };

    checkIsEnrc(): boolean {
        // France does not have unclaimed summary invoices
        if(this.configurationManagerService.getShopCountry() === 'FR') {
            return false;
        }

        return (this.sale?.is_summary && ['summary_e_nrc', 'generic_invoice'].includes(this.activeSaleService.printerDocumentData?.document_type?.id || '') || false);
    }

    private hasAllSaleItemsPriceNegative(): boolean {
        if (!this.sale?.sale_items?.length) {
            return false;
        }

        if (this.sale.sale_items.every((item) => (item.price * item.quantity) === 0)) {
            return false;
        }

        return this.sale.sale_items.every((item) => (item.price * item.quantity) <= 0);
    }
}
