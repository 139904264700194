<tilby-dialog-toolbar mat-dialog-title
    [title]="title"
    [hideConfirm]="!form.valid"
    [hideCancel]="!!data.disableCancel"
    (confirm)="confirm()"
    class="tw-min-h-14"
/>
<tilby-dialog-content class="tw-w-full">
    @if(data.message) {
        <div class="tw-w-full tw-mb-3">
            <span>{{data.message}}</span>
        </div>
    }
    <tilby-magic-form
        [form]="form"
        (keydown.enter)="confirm()"
    />
</tilby-dialog-content>