import angular from 'angular';

import {
    ConfigurationManagerService,
    ConnectionService,
    EntityManagerService,
    EnvironmentInfoService,
    StartupQueriesService,
    UserActiveSessionManagerService
} from 'src/app/core';

import {
    PromptDialogService
} from 'src/app/dialogs';

import {
    MathUtils,
    asyncParallelLimit
} from 'src/app/shared/utils';

type StartupModule =
    | 'dashboard'
    | 'tables'
    | 'cashregister'
    | 'history'
    | 'stock'
    | 'items'
    | 'customers'
    | 'orders'
    | 'fidelity'
    | 'suppliers'
    | 'printers'
    | 'kiosk'

type EntityStatus = "OK" | "Pending" | "Fetching" | "Reloading" | "PendingReload";

type EntityManName = keyof EntityManagerService;

export class StartupService {
    static readonly shopCollections: EntityManName[] = [
        "activityCodes",
        "allergens",
        "bookings",
        "bookingShifts",
        "campaigns",
        "categories",
        "channels",
        "components",
        "customers",
        "departments",
        "devicePreferences",
        "entitySchemas",
        "fidelitiesPoints",
        "items",
        "nonfiscalDocuments",
        "paymentMethods",
        "printers",
        "prizes",
        "promotions",
        "rawMaterials",
        "rooms",
        "sales",
        "saleTransactions",
        "slaveDevices",
        "stock",
        "suppliers",
        "tickets",
        "vat"
    ];

    static readonly entitiesToReload: EntityManName[] = [
        "bookings",
        "bookingShifts",
        "campaigns",
        "channels",
        "departments",
        "devicePreferences",
        "fidelitiesPoints",
        "nonfiscalDocuments",
        "paymentMethods",
        "printers",
        "prizes",
        "promotions",
        "sales",
        "saleTransactions",
        "slaveDevices",
        "stock"
    ];

    static readonly userCollections: EntityManName[] = ["userSettings", "userPreferences"];
    static readonly shopSettingsEnt: EntityManName[] = ["shopSettings", "shopPreferences"];

    static readonly $inject = [
        "$rootScope",
        "$translate",
        "alertDialog",
        "checkManager",
        "connection",
        "entityManager",
        "environmentInfo",
        "newPromptDialog",
        "restManager",
        "sessionManager",
        "startupQueries",
        "userActiveSession"
    ];

    public constructor(
        private readonly $rootScope: any,
        private readonly $translate: any,
        private readonly alertDialog: any,
        private readonly configurationManager: ConfigurationManagerService,
        private readonly connection: ConnectionService,
        private readonly entityManagerService: EntityManagerService,
        private readonly environmentInfo: EnvironmentInfoService,
        private readonly promptDialog: PromptDialogService,
        private readonly restManager: any,
        private readonly sessionManager: any,
        private readonly startupQueries: StartupQueriesService,
        private readonly userActiveSession: UserActiveSessionManagerService
    ) {
    }

    /**
     * Retrieves the shop collections that need to be loaded.
     * Entities in the startup.entities_to_avoid setting will be avoided.
     *
     * @return {Array<string>} An array of shop collections to load.
     */
    private getShopCollectionsToLoad() {
        const entitiesToAvoid = this.configurationManager.getSettingUserFirst("startup.entities_to_avoid")
        let entitiesToAvoidArr: String[] = [];

        if (typeof entitiesToAvoid == "string") {
            entitiesToAvoidArr = entitiesToAvoid.split('|');
        }

        return StartupService.shopCollections.filter(collection => !entitiesToAvoidArr.includes(collection));
    };

    /**
     * Retrieves the status of the specified entity from local storage.
     *
     * @param {string} entityName - The name of the entity.
     * @return {string} The status of the entity, or an empty string if not found.
     */
    private getEntityStatus(entityName: string) {
        return window.localStorage.getItem(`initEntity::${entityName}`) || '';
    }

    /**
     * Sets the status of an entity.
     *
     * @param {string} entityName - The name of the entity.
     * @param {EntityStatus} status - The status to set for the entity.
     * @param {boolean} [changeIfMissing=false] - Whether to change the status only if it is missing.
     */
    private setEntityStatus(entityName: string, status: EntityStatus, changeIfMissing: boolean = false) {
        if (!changeIfMissing || !this.getEntityStatus(entityName)) {
            window.localStorage.setItem(`initEntity::${entityName}`, status);
        }
    }

    /**
     * Sets the status of all shop entities.
     *
     * @param {EntityStatus} status - The status to set for the entities.
     * @param {boolean} changeIfMissing - Flag indicating whether to change the status of missing entities.
     */
    public setShopEntitiesStatus(status: EntityStatus, changeIfMissing: boolean) {
        for (const eName of [...StartupService.shopCollections, ...StartupService.shopSettingsEnt]) {
            this.setEntityStatus(eName, status, changeIfMissing);
        }
    }

    /**
     * Sets the status of user entities.
     *
     * @param {EntityStatus} status - The status to set for the user entities.
     */
    public setUserEntitiesStatus(status: EntityStatus) {
        for (const eName of StartupService.userCollections) {
            this.setEntityStatus(`${eName}::${this.$rootScope.userActiveSession.id}`, status);
        }
    }

    /**
     * Checks if all shop entities are properly loaded.
     *
     * @return {boolean} True if there are no pending shop entities, false otherwise.
     */
    public checkPendingShopEntities() {
        return this.getShopCollectionsToLoad().every((eName) => !['Pending', 'Fetching'].includes(this.getEntityStatus(eName) || ''));
    }

    /**
     * Check if all shop settings are properly loaded.
     *
     * @return {boolean} Indicates whether there are any pending shop settings.
     */
    public checkPendingShopSettings() {
        return StartupService.shopSettingsEnt.every((eName) => !['Pending', 'Fetching'].includes(this.getEntityStatus(eName) || ''));
    }

    /**
     * Checks if all user entities are properly loaded.
     *
     * @return {boolean} Returns true if there are no pending or fetching user settings, otherwise returns false.
     */
    public checkPendingUserSettings() {
        return StartupService.userCollections.every((eName) => !['Pending', 'Fetching'].includes(this.getEntityStatus(`${eName}::${this.$rootScope.userActiveSession.id}`) || ''));
    }

    /**
     * Loads the user settings.
     *
     * @return {Promise<void>} Returns a promise that resolves when the user settings are loaded.
     */
    public loadUserSettings() {
        return this.entityManagerService.loadSettings(StartupService.userCollections, true);
    }

    /**
     * Loads the shop settings.
     *
     * @return {Promise<void>} Returns a promise that resolves when the shop settings are loaded.
     */
    public loadShopSettings() {
        return this.entityManagerService.loadSettings(StartupService.shopSettingsEnt, true);
    }

    /**
     * Registers the device to the server. If the device is not already registered,
     * it will prompt the user for a name and register the device.
     * 
     * @return {Promise<void>} A promise that resolves when the device is registered.
     */
    public async registerDevice() {
        // Disable registration if we are in a webapp or the user has a role (support/reseller/root...)
        if (
            this.environmentInfo.isWebApp() ||
            this.userActiveSession.getSession()?.role
        ) {
            return;
        }

        // Check if device registration is disabled in the configuration settings
        if (this.configurationManager.getSetting('startup.disable_device_registration')) {
            return;
        }

        try {
            // Retrieve the device UUID
            const deviceUuid = await this.environmentInfo.getDeviceMeta().then(meta => meta.client_uuid);

            // Exit if no device UUID is found
            if (!deviceUuid) {
                return;
            }

            // Check if the current device is already registered
            const currentDevice = await this.restManager.getOne('serial_devices', deviceUuid);

            // Exit if the device is already registered
            if (currentDevice) {
                return;
            }

            this.$rootScope.hideAppLoader();

            // Prompt the user to enter a device name for registration
            const deviceName = await this.promptDialog.openDialog({
                data: {
                    disableCancel: true,
                    title: "DEVICE_REGISTRATION.TITLE",
                    label: "DEVICE_REGISTRATION.LABEL",
                    message: this.$translate.instant('DEVICE_REGISTRATION.MESSAGE'),
                    type: "text",
                }
            });

            // Register the device
            if (deviceName) {
                await this.restManager.post('serial_devices', {
                    name: deviceName,
                    serial: deviceUuid,
                });
            }
        } catch (err: any) {
            // Do nothing
        } finally {
            // Show the application loader again once the process is complete
            this.$rootScope.showAppLoader();
        }
    }

    /**
     * Loads the shop collections.
     *
     * @param {string} lastOnline - The last online date to filter the collections.
     * @param {boolean} shopExpired - Indicates whether the shop session has expired.
     */
    public async loadShopCollections(lastOnline: string, shopExpired: boolean) {
        const updateLoader = (status: { mainMessage?: string, otherMessage?: string, value?: number, showLogo?: boolean }) => {
            this.$rootScope.$broadcast('loader:changeStatus', 'backLoader', { showLogo: true, mode: 'buffer', ...status });
        }

        let updateQueries: Partial<Record<keyof EntityManagerService, any>> = {};

        if (lastOnline) {
            updateQueries = {
                customers: { updated_at_since: lastOnline },
                items: { updated_at_since: lastOnline },
                suppliers: { updated_at_since: lastOnline }
            };
        }

        const entitiesToLoad = this.getShopCollectionsToLoad();
        let entitiesLoaded = 0;

        await asyncParallelLimit(entitiesToLoad, async (eName: keyof EntityManagerService) => {
            const entityManager = this.entityManagerService[eName];
            const paginationData = this.startupQueries.getPaginationInfo(eName);
            const startupQuery = this.startupQueries.getStartupQuery(eName);
            const usePagination = paginationData != null;

            let onPageFetchSuccess, onPageFetchError;

            updateLoader({ mainMessage: this.$translate.instant(`APPLICATION.LOADER.${eName.toUpperCase()}`) });

            if (usePagination) {
                onPageFetchError = (err: any) => updateLoader({ otherMessage: this.$translate.instant('APPLICATION.LOADER.NETWORK_ISSUES') });
                onPageFetchSuccess = (status: any) => updateLoader({ mainMessage: this.$translate.instant(`APPLICATION.LOADER.${eName.toUpperCase()}`) + ' ' + MathUtils.round(status.currentPage * 100 / status.pages, 0) + '%' });
            }

            const entityStatus = this.getEntityStatus(eName);

            try {
                if (['Pending', 'Fetching'].includes(entityStatus)) { //INIT
                    if ('fetchCollectionOnline' in entityManager) {
                        this.setEntityStatus(eName, "Fetching");
                        await entityManager.fetchCollectionOnline(startupQuery, true, { paginatedFetch: usePagination, perPage: paginationData, onPageFetchError, onPageFetchSuccess });
                    }
                } else if (['PendingReload', 'Reloading'].includes(entityStatus) || (StartupService.entitiesToReload.includes(eName) || shopExpired)) {
                    if ('reloadEntityCollection' in entityManager) {
                        this.setEntityStatus(eName, "Reloading");

                        try {
                            await entityManager.reloadEntityCollection();
                        } catch (err) {
                            // Do nothing
                        }
                    }
                } else if (!shopExpired && lastOnline && updateQueries[eName]) {
                    if ('fetchCollectionOnline' in entityManager) {
                        await entityManager.fetchCollectionOnline({ ...startupQuery, ...updateQueries[eName] }, true);
                    }
                }

                this.setEntityStatus(eName, "OK");
            } catch (err) {
                console.log(err)
                // Do nothing
            } finally {
                updateLoader({ value: (++entitiesLoaded / entitiesToLoad.length) * 100 });
                this.$rootScope.resetWatchdog();
            }
        }, { parallelLimit: 5 });

        updateLoader({ mainMessage: '', otherMessage: '', showLogo: false });
    }

    /**
     * Retrieves the startup state of the application.
     *
     * @return {string} The target state for the application startup.
     */
    public getStartupState() {
        const modulesToState: Record<StartupModule, string> = {
            cashregister: 'new.cashregister.content.showcase',
            customers: 'new.customers.showcase',
            dashboard: 'new.dashboard',
            orders: 'new.delivery-take-away',
            tables: 'new.tables',
            kiosk: 'kiosk',
            history: 'new.history.sales',
            items: 'items.showcase',
            fidelity: 'fidelity.search',
            printers: 'printers.general',
            stock: 'stock.management',
            suppliers: 'new.suppliers',
        };

        let modulesOrder: StartupModule[] = ['dashboard', 'tables', 'orders', 'cashregister', 'history', 'stock', 'items', 'customers', 'fidelity', 'suppliers', 'printers', 'kiosk'];
        const offlineCompliantModules: StartupModule[] = ['tables', 'orders', 'cashregister'];

        const modulesStatus = this.configurationManager.getModulesStatus();

        if (!Object.keys(modulesStatus.enabledModules).length) {
            this.alertDialog.show(this.$translate.instant('APPLICATION.STARTUP.NO_MODULE_ENABLED')).finally(() => {
                if (!Object.keys(modulesStatus.shopModules).length) { //Delete shop data if the "no enabled module" status is caused by the shop session
                    this.sessionManager.logoutCloseSessionsDeep();
                } else { //Simply logout the current user, because this condition is likely caused by him
                    this.sessionManager.logoutActiveUserSession();
                }
            });

            return null;
        }

        const startupModule = this.configurationManager.getStartupLoadModule();

        //Make the startup module first in the attempts order
        modulesOrder = [startupModule, ...modulesOrder.filter(m => m !== startupModule)];

        //Find a bootable state
        let targetState;
        let targetModule = modulesOrder.find(m => modulesStatus.enabledModules[m] && modulesToState[m] && (this.connection.isOnline() || offlineCompliantModules.includes(m)));

        if (targetModule) {
            targetState = modulesToState[targetModule];
        }

        if (!targetState) {
            this.alertDialog.show(this.$translate.instant('APPLICATION.STARTUP.NO_BOOTABLE_MODULE')).finally(() => {
                this.sessionManager.logoutActiveUserSession();
            });

            return null;
        }

        return targetState;
    }
}

angular.module('core').service('startup', StartupService);
