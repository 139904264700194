<mat-grid-list [ngClass]="customClass||''" [cols]="gridCols" [gutterSize]="'8px'" [rowHeight]="rowHeight">
    @for (item of itemsSet; track trackByFunction) {
        <mat-grid-tile class="tw-rounded-md" tilbyGestures (click)="itemTap(item)" (longPress)="itemPress(item)" [ngStyle]="{ 'background-color': '#' + (item.color || 'BBDEFB') }">
            <div class="tw-cursor-pointer tw-h-full tw-w-full tw-max-h-full tw-max-w-full tw-flex tw-flex-col">
                <!-- Thumbnail -->
                @if (showThumbnail) {
                    <div class="tw-p-1 tw-flex" [ngClass]="{'tw-h-2/3': displayMode === 'compact', 'tw-h-3/5': displayMode === 'normal'}">
                        @if(item.thumbnail) {
                            <img class="tw-rounded-md tw-w-full tw-object-cover tw-object-top tw-pointer-events-none" [src]="item.thumbnail"/>
                        } @else {
                            <div class="tw-rounded-md tw-w-full tw-bg-white tw-opacity-25"></div>
                        }
                    </div>
                }
                <!-- Name and price -->
                <div class="tw-px-2 tw-py-1 tw-flex-auto tw-flex tw-flex-col tw-font-medium">
                    <div [ngClass]="{'tw-truncate': displayMode === 'compact', 'tw-line-clamp-2': displayMode === 'normal' && showThumbnail, 'tw-line-clamp-3': displayMode === 'normal' && !showThumbnail}" class="tw-flex-auto tw-text-sm">{{item.name}}</div>
                    <div class="tw-flex tw-flex-row">
                        @if(showPrices){
                            <div class="tw-flex-auto tw-text-sm">{{ $any(item[priceIdentifier]) | tilbyCurrency }}</div>
                        } @else {
                            <div class="tw-flex-auto tw-text-sm">***</div>
                        }
                        @if(getStockStatus(item).stock_quantity === undefined || getStockStatus(item).stock_quantity === null)  {
                            <div class="item-stock-status" [ngClass]="getStockStatus(item).status"></div>
                        } @else {
                            <div class="item-stock-status-with-quantity" [ngClass]="getStockStatus(item).status">{{getStockStatus(item).stock_quantity}}</div>
                        }
                    </div>
                </div>
            </div>
        </mat-grid-tile>
    }
</mat-grid-list>
