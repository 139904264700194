import angular from 'angular';
import _ from 'lodash';

import {
    ConfigurationManagerService,
    EntityManagerService
} from 'src/app/core';

import {
    SaleUtilsService
} from 'src/app/features';

import {
    padCenter,
    stringToLines
} from 'src/app/shared/string-utils';

import {
    groupBy,
    keyBy,
    MathUtils
} from 'src/app/shared/utils';

import {
    Departments,
    Sales,
    SalesCustomer,
    SalesItems,
    SalesItemsPriceChanges,
    SalesPayments,
    SalesPriceChanges,
} from 'tilby-models';

interface QueueRow {
    text: string;
    doubleHeight: boolean;
}

interface FiscalPayment {
    date: string;
    method_type_id: number;
    method_id: number;
    method_name: string;
    amount: number;
    unclaimed: boolean;
    code?: string;
    uuid?: string;
}

export class FiscalUtilsService {
    private cashPaymentTypes = new Set([1, 19, 21, 32, 38, 40]);
    private unclaimedPaymentTypes = new Set([2, 6, 10, 20, 22, 23, 24, 25, 26, 28, 29, 33, 34, 36]);

    private taxCodeItMap = [1, 0, 5, 7, 9, 13, 15, 17, 19, 21, 1, 0, 5, 7, 9, 13, 15, 17, 19, 21, 2, 4, 18, 20, 11, 3, 6, 8, 12, 14, 16, 10, 22, 25, 24, 23];

    private taxCodeRegexps = {
        IT: /^[0-9A-Z]{16}$/,
        ES: /(X(-|\.)?0?\d{7}(-|\.)?[A-Z]|[A-Z](-|\.)?\d{7}(-|\.)?[0-9A-Z]|\d{8}(-|\.)?[A-Z])$/gmi
    };

    static $inject = [
        "$http",
        "$translate",
        "$injector",
        "checkManager",
        "errorsLogger",
        "entityManager",
        "util"
    ];

    constructor(
        private $http: angular.IHttpService,
        private $translate: any,
        private $injector: any,
        private checkManager: ConfigurationManagerService,
        private errorsLogger: any,
        private entityManager: EntityManagerService,
        private util: any
    ) {
    }

    /**
     * Reduces the total amount of payments.
     */
    private amountReducer = (amount: number, payment: SalesPayments) => this.roundDecimals(amount + (this.roundDecimals(payment.amount) || 0));

    /**
     * Round a number to a certain number of decimals.
     * @param num The number to round.
     * @param decimals The number of decimals to round to. Default is 2.
     * @returns The rounded number.
     */
    roundDecimals(num: number, decimals: number = 2): number {
        return MathUtils.round(num, decimals);
    }

    /**
     * Round a number to a certain number of decimals and return it as a string.
     * @param num The number to round.
     * @param decimals The number of decimals to round to. Default is 2.
     * @returns The rounded number as a string, with the decimal separator replaced by a comma.
     */
    roundDecimalsToString(num: number, decimals: number = 2): string {
        return this.roundDecimals(num, decimals).toFixed(decimals).replace('.', ',');
    }

    /**
     * Converts a number to a string with a specified number of decimal places, using a comma as the decimal separator.
     * @param number - The number to convert.
     * @param decimals - The number of decimal places to include in the string. Defaults to 2.
     * @returns The number formatted as a string with the specified decimal places and a comma as the decimal separator.
     */
    decimalToString(number: number, decimals: number = 2): string {
        return number.toFixed(decimals).replace('.', ',');
    }

    /**
     * Checks if a payment method type is considered a cash payment.
     * @param {number} methodTypeId - The ID of the payment method type to check.
     * @returns {boolean} - True if the payment method is considered a cash payment, false otherwise.
     */
    isCashPayment(methodTypeId: number): boolean {
        return this.cashPaymentTypes.has(methodTypeId);
    }

    /**
     * Checks if a given payment method type is considered unclaimed.
     * @param {number} methodTypeId - The ID of the payment method type to check.
     * @param {boolean} unclaimed - A boolean indicating whether the payment is unclaimed.
     * @returns {boolean} - True if the payment method is unclaimed, false otherwise.
     */
    isMethodUnclaimed(methodTypeId: number, unclaimed: boolean): boolean {
        if ([16, 42].includes(methodTypeId)) {
            return unclaimed;
        } else {
            return this.unclaimedPaymentTypes.has(methodTypeId);
        }
    }

    /**
     * Determines whether a sale is a refund void sale based on its final amount and items.
     * @param {Sales} sale - The sale object to check.
     * @returns {boolean} - True if the sale is a refund void sale, false otherwise.
     */
    isRefundVoidSale(sale: Sales): boolean {
        //Legacy check, (check only if final_amount is negative), left in case of regressions
        if (this.checkManager.getSetting('fiscal.use_legacy_refund_check')) {
            return sale.final_amount! < 0;
        }

        //New check, if the amount is exactly 0 check if all items are refunds
        if (sale.final_amount !== 0) {
            return sale.final_amount! < 0;
        }

        return (sale.sale_items || []).every((item) => item.type === 'refund');
    }

    /**
     * Checks if payment rounding is enabled based on the configuration settings.
     * @returns {boolean} - True if payment rounding is enabled, false otherwise.
     */
    isPaymentRoundingEnabled(): boolean {
        return !!this.checkManager.getPreference('cashregister.enable_payment_rounding');
    }

    /**
     * Validates a tax code based on the shop's country.
     * @param {string} taxCode - The tax code to validate.
     * @returns {boolean} - True if the tax code is valid, false otherwise.
     */
    checkTaxCode(taxCode: string): boolean {
        let valid = true;

        taxCode = String(taxCode).toUpperCase();

        switch (this.checkManager.getShopCountry()) {
            case 'ES':
                let spainValidationRegex = new RegExp(this.taxCodeRegexps['ES']);
                valid = spainValidationRegex.test(taxCode);
                break;
            case 'IT':
                let s = 0;

                let italyValidationRegex = new RegExp(this.taxCodeRegexps['IT']);

                if (!italyValidationRegex.test(taxCode)) {
                    valid = false;
                } else {
                    //Checksum validation
                    for (let i = 0; i < 15; i++) {
                        let c = taxCode.charCodeAt(i);

                        if (c < 65) {
                            c = c - 48;
                        } else {
                            c = c - 55;
                        }
                        if (i % 2 === 0) {
                            s += this.taxCodeItMap[c];
                        } else {
                            s += c < 10 ? c : c - 10;
                        }
                    }

                    valid = String.fromCharCode(65 + s % 26) === taxCode.charAt(15);
                }
                break;
            default:
                break;
        }

        return valid;
    }

    sendRequest<T = any>(url: any, options: any) {
        if (!_.isObject(options)) {
            options = {};
        }

        const logRequests = _.isNil(options.log) ? this.checkManager.getPreference('fiscalprinter.log_data') : (options.log ? true : false);

        if (logRequests) {
            this.errorsLogger.sendReport({
                type: 'fiscalPrinter',
                ip_address: url,
                content: {
                    action: 'send',
                    data: options.data
                }
            });
        }

        let httpOptions = _.chain(options).pick(['method', 'timeout', 'headers', 'data', 'params', 'responseType']).defaults({ url: url, method: 'GET' }).value();
        let responseData: any;

        return new Promise<T>((resolve, reject) => {
            this.$http(httpOptions).then((responseSuccess: any) => {
                responseData = angular.copy(responseSuccess);

                resolve(responseSuccess);
            }, (responseError) => {
                responseData = angular.copy(responseError);

                reject(responseError);
            }).finally(() => {
                if (logRequests && httpOptions.responseType !== 'blob') {
                    this.errorsLogger.sendReport({
                        type: 'fiscalPrinter',
                        ip_address: url,
                        content: {
                            action: 'receive',
                            data: responseData.data,
                            status: responseData.status
                        }
                    });
                }
            });
        });
    }

    /**
     * Calculates the amount of a price change based on its type and value.
     * @param {SalesPriceChanges | SalesItemsPriceChanges} priceChange - The price change object.
     * @param {number} partialPrice - The price to apply the change to.
     * @param {number} decimals - The number of decimal places to round to.
     * @returns {number | null} - The calculated price change amount, or null if the price change type is not supported.
     */
    getPriceChangeAmount(priceChange: SalesPriceChanges | SalesItemsPriceChanges, partialPrice: number, decimals: number): number | null {
        switch (priceChange.type) {
            case 'discount_fix':
                return MathUtils.round(-priceChange.value, decimals);
            case 'discount_perc':
                return MathUtils.round(-(partialPrice * priceChange.value / 100), decimals);
            case 'surcharge_fix':
                return MathUtils.round(priceChange.value, decimals);
            case 'surcharge_perc':
                return MathUtils.round(partialPrice * priceChange.value / 100, decimals);
            case 'gift':
                return MathUtils.round(-partialPrice, decimals);
            default:
                return null;
        }
    }

    /**
     * Extracts and processes sale items from a sale, considering ingredients, variations, and other factors.
     * @param {Sales} sale - The sale object from which to extract the items.
     * @returns {SalesItems[]} - An array of processed sale items.
     */
    extractSaleItems(sale: Sales): SalesItems[] {
        const considerIngredientsRemoval = this.checkManager.getPreference("orders.ingredients_removal_affects_price");
        const saleUtils = this.$injector.get('newSaleUtils') as SaleUtilsService;

        const halfPortionValue = saleUtils.getHalfPortionValue();

        const saleItemsResult = structuredClone(sale.sale_items || []);

        //Get ingredients and variations amount
        for (const saleItem of saleItemsResult) {
            const ingredients = Array.isArray(saleItem.ingredients) ? saleItem.ingredients : [];
            const variations = Array.isArray(saleItem.variations) ? saleItem.variations : [];

            let ingredientsSum = 0;
            let variationsSum = 0;

            const notesArray: string[] = [];

            for (const variation of variations) {
                if (variation.price_difference) {
                    variationsSum += variation.price_difference;
                }

                notesArray.push(`${variation.name} - ${variation.value}`);
            }

            for (const ingredient of ingredients) {
                if (ingredient.price_difference) {
                    switch (ingredient.type) {
                        case 'removed':
                            if (considerIngredientsRemoval) {
                                ingredientsSum -= ingredient.price_difference;
                            }
                            break;
                        case 'added':
                        default:
                            ingredientsSum += ingredient.price_difference * (ingredient.quantity || 1);
                            break;
                    }
                }

                notesArray.push(`${ingredient.type == 'removed' ? '-' : '+'}${ingredient.quantity! > 1 ? ingredient.quantity + 'x' : ''} ${ingredient.name}`);
            }

            const price = (saleItem.half_portion) ? MathUtils.round(saleItem.price! * (1 - halfPortionValue)) : saleItem.price!;

            //Merge notes
            let notes = notesArray.join('\n');

            if (saleItem.notes) {
                notes += '\n' + saleItem.notes;
            }

            Object.assign(saleItem, {
                price: MathUtils.round((price + (variationsSum || 0) + (ingredientsSum || 0))),
                ingredients: [],
                notes: notes,
                price_changes: (saleItem.price_changes || []).sort((a, b) => a.index - b.index),
                variations: [],
            });
        }

        return saleItemsResult;
    }

    /**
     * Extracts tax information from a sale, grouped by VAT ID or VAT percentage.
     * @param {Sales} sale - The sale object to extract tax information from.
     * @param {Record<number, Departments>} [departments] - An optional object mapping department IDs to their corresponding Departments object.
     * @returns {Record<string, { tax: number; taxable: number; total: number }>} - An object containing the aggregated tax information, grouped by VAT ID or VAT percentage.
     */
    extractTax(sale: Sales, departments?: Record<number, Departments>) {
        let groupedItems: Record<string, SalesItems[]>;

        //If the departments list is provided, group the taxes by vat id, else use group by vat value
        if (departments) {
            groupedItems = groupBy(sale.sale_items || [], (saleItem) => departments[saleItem.department_id]?.vat?.id);
        } else {
            groupedItems = groupBy(sale.sale_items || [], (saleItem) => saleItem.vat_perc);
        }

        // Compute the aggregate VAT info for each items group
        return Object.entries(groupedItems).reduce((result: { [key: string]: { tax: number; taxable: number; total: number } }, [ivaValue, items]) => {
            const i = items.reduce((aggregateIva, item) => {
                const tax = (item.final_price - item.final_net_price) * item.quantity;
                const taxable = item.final_net_price * item.quantity;
                const total = item.final_price * item.quantity;

                return {
                    tax: aggregateIva.tax + (tax || 0),
                    taxable: aggregateIva.taxable + (taxable || 0),
                    total: aggregateIva.total + (total || 0)
                };
            }, { tax: 0, taxable: 0, total: 0 });

            result[ivaValue] = {
                tax: MathUtils.round(i.tax),
                taxable: MathUtils.round(i.taxable),
                total: MathUtils.round(i.total)
            };
            return result;
        }, {});
    }

    /**
     * Extracts department information from a sale.
     *
     * @param {Sales} sale - The sale object from which to extract department information.
     * @returns {Array<{ id: number; amount: number; name: string }>} An array of objects, each containing the
     * department ID, the total amount for that department in the sale, and the department name.
     */
    extractDepartments(sale: Sales) {
        // Group items by department
        const groupedItems = groupBy(sale.sale_items || [], (si) => si.department_id);

        const departmentNames = Object.entries(groupedItems).map(([key, value]) => {
            return {
                id: Number(key),
                name: value[0]?.department_name || ''
            };
        });

        const departmentNamesById = keyBy(departmentNames, d => d.id);

        const aggregateDepartmentsAmount = Object.entries(groupedItems).reduce((result, [departmentId, items]) => {
            // Per ogni array di items restituisce l'aggregato IVA
            result[Number(departmentId)] = items.reduce((aggregateDepartment, item) => MathUtils.round(aggregateDepartment + (MathUtils.round(item.final_price * item.quantity) || 0)), 0);

            return result;
        }, {} as Record<number, number>);

        const aggregateDepartments: { id: number; amount: number; name: string }[] = [];

        for (const did in aggregateDepartmentsAmount) {
            const departmentId = Number(did);
            const foundDepartment = departmentNamesById[departmentId];

            aggregateDepartments.push({
                id: departmentId,
                amount: aggregateDepartmentsAmount[departmentId] || 0,
                name: foundDepartment?.name || ''
            });
        }

        return aggregateDepartments;
    }

    /**
     * Extracts and maps payment information from a sale, grouping payments by payment method.
     * @param {Sales} sale - The sale object from which to extract payment information.
     * @returns {FiscalPayment[]} - An array of mapped payment objects, sorted by cash payments first and then by amount.
     */
    extractPayments(sale: Sales) {
        const groupedPayments = groupBy(sale.payments || [], (payment) => payment.payment_method_id);

        const giftcardsAsUnclaimed = this.checkManager.getPreference('cashregister.send_giftcards_as_unclaimed_payments');

        const paymentsMapped = Object.entries(groupedPayments).map(([pmId, payments]) => {
            let methodTypeId = payments[0].payment_method_type_id;
            let result: FiscalPayment[] = [];

            //If enabled, treat giftcards as unclaimed
            if (methodTypeId === 33 && giftcardsAsUnclaimed) {
                methodTypeId = 2;
            }

            const resultTemplate = {
                date: payments.map((payment) => payment.date!).sort()[0] as any as string,
                method_type_id: methodTypeId,
                method_id: Number(pmId),
                method_name: payments[0].payment_method_name,
            };

            switch (methodTypeId) {
                case 6: case 34: //Tickets
                    result = payments.map((ticket) => ({
                        ...resultTemplate,
                        amount: MathUtils.round(ticket.amount),
                        code: ticket.code,
                        unclaimed: true
                    })).sort((a, b) => b.amount - a.amount);
                    break;
                case 16: //For prepaid payments, split between credit and ticket type payments
                    result = [{
                        ...resultTemplate,
                        method_type_id: 26,
                        amount: payments.filter((payment) => payment.unclaimed).reduce(this.amountReducer, 0),
                        unclaimed: true
                    }, {
                        ...resultTemplate,
                        method_type_id: 26,
                        amount: payments.filter((payment) => !payment.unclaimed).reduce(this.amountReducer, 0),
                        unclaimed: false
                    }].filter((payment) => payment.amount);
                    break;
                default:
                    let paymentsToMerge = payments;

                    if ([1, 26].includes(methodTypeId)) {
                        paymentsToMerge = payments.filter(payment => payment.payment_data == null);

                        for (const payment of payments.filter(payment => payment.payment_data != null)) {
                            let paymentData;

                            try {
                                paymentData = JSON.parse(payment.payment_data!);
                            } catch (e) {
                                paymentData = payment.payment_data;
                            }

                            result.push(Object.assign({}, resultTemplate, {
                                amount: payment.amount,
                                unclaimed: this.isMethodUnclaimed(methodTypeId, payment.unclaimed!),
                                payment_data: paymentData
                            }));
                        }
                    } else if([33].includes(methodTypeId)) {
                        paymentsToMerge = payments.filter(payment => payment.payment_data == null);

                        for (const payment of payments.filter(payment => payment.payment_data != null)) {
                            let paymentData;

                            try {
                                paymentData = JSON.parse(payment.payment_data!);
                            } catch (e) {
                            }

                            result.push(Object.assign({}, resultTemplate, {
                                amount: payment.amount,
                                unclaimed: this.isMethodUnclaimed(methodTypeId, payment.unclaimed!),
                                uuid: paymentData?.giftcard_uuid
                            }));
                        }
                    } else if ([42].includes(methodTypeId)) { // ZPay Bridge
                        paymentsToMerge = [];

                        result = payments.map((payment) => {
                            return {
                                ...resultTemplate,
                                amount: payment.amount,
                                unclaimed: this.isMethodUnclaimed(methodTypeId, payment.unclaimed!),
                                payment_data: payment.payment_data
                            }
                        });
                    }

                    if (paymentsToMerge.length > 0) {
                        result.push({
                            ...resultTemplate,
                            amount: paymentsToMerge.reduce(this.amountReducer, 0),
                            unclaimed: this.isMethodUnclaimed(methodTypeId, paymentsToMerge[0]?.unclaimed!)
                        });
                    }
                    break;
            }

            return result;
        }).flat().sort((a, b) => {
            // Cash payments last
            const isACashPayment = this.isCashPayment(a.method_type_id) ? 1 : 0;
            const isBCashPayment = this.isCashPayment(b.method_type_id) ? 1 : 0;

            if (isACashPayment < isBCashPayment) {
                return -1;
            }

            if (isACashPayment > isBCashPayment) {
                return 1
            }

            // Order by amount as secondary sort
            return b.amount - a.amount;
        });

        return paymentsMapped;
    }

    /**
     * Parses a sequential number from an RT document into its components (daily closing number and document sequential number).
     *
     * The input number is expected to be in the format `DDDDNNNN`, where `DDDD` is the daily closing number and `NNNN` is the
     * document sequential number. This function splits the number into these two parts and returns them as separate numerical values.
     *
     * @param {number} [seq_number] - The sequential number to parse. If not provided or falsy, defaults to `0`.
     * @returns {{ daily_closing_num: number; document_sequential_number: number; sequential_number_string: string }}
     * An object containing:
     *   - `daily_closing_num`: The daily closing number, parsed from the first four digits of the input.
     *   - `document_sequential_number`: The document sequential number, parsed from the last four digits of the input.
     *   - `sequential_number_string`: A formatted string combining the daily closing number and document sequential number in the format `DDDD-NNNN`.
     */
    parseRTDocumentSequentialNumber(seq_number?: number) {
        const seqNumStr = String(seq_number || 0).padStart(8, '0');
        const dailyClosingNum = seqNumStr.substring(0, 4);
        const docSeqNum = seqNumStr.substring(4, 8);

        return {
            daily_closing_num: Number(dailyClosingNum) || 0,
            document_sequential_number: Number(docSeqNum) || 0,
            sequential_number_string: `${dailyClosingNum}-${docSeqNum}`
        };
    }

    /**
     * Retrieves customer information formatted as an array of strings for display.
     *
     * @param {SalesCustomer} saleCustomer - The customer object containing customer details.
     * @returns {string[]} An array of strings containing formatted customer information. Returns an empty array if customer information is missing.
     */
    getCustomerInfo(saleCustomer: SalesCustomer): string[] {
        const result: string[] = [];

        // Customer info in fiscal receipt
        if (!saleCustomer || ((!saleCustomer.first_name?.trim() || !saleCustomer.last_name?.trim()) && !saleCustomer.company_name?.trim())) {
            return result;
        }

        result.push("Informazioni cliente:");
        result.push(this.util.getCustomerCaption(saleCustomer));

        if (saleCustomer.fidelity) {
            result.push(String(saleCustomer.fidelity));
        }

        if (saleCustomer.tax_code) {
            result.push(`CF: ${saleCustomer.tax_code.toUpperCase()}`);
        }

        if (saleCustomer.vat_code) {
            result.push(`P.IVA: ${saleCustomer.vat_code}`);
        }

        if (saleCustomer.phone) {
            result.push(`Tel: ${saleCustomer.phone}`);
        }

        if (saleCustomer.mobile) {
            result.push(`Mobile: ${saleCustomer.mobile}`);
        }

        if (saleCustomer.billing_street && saleCustomer.billing_zip && saleCustomer.billing_city) {
            result.push(
                " ",
                "Informazioni di fatturazione:",
                saleCustomer.billing_street + (saleCustomer.billing_number ? `, ${saleCustomer.billing_number}` : ''),
                `${saleCustomer.billing_zip} ${saleCustomer.billing_city}`
            );
        }

        if (saleCustomer.shipping_street && saleCustomer.shipping_zip && saleCustomer.shipping_city) {
            result.push(
                " ",
                "Informazioni per la consegna:",
                saleCustomer.shipping_street + (saleCustomer.shipping_number ? `, ${saleCustomer.shipping_number}` : ''),
                `${saleCustomer.shipping_zip} ${saleCustomer.shipping_city}`
            );
        }

        return result;
    }

    /**
     * Generates an array of queue coupon rows based on the sale and configuration settings.
     * @param {Sales} sale - The sale object containing the sale number.
     * @param {number} [columns=46] - The number of columns for the queue coupon.
     * @param {object} [options] - Additional options for generating the queue coupon.
     * @param {boolean} [options.doubleWidth=false] - Whether to use double width for the main row.
     * @returns {QueueRow[]} - An array of queue coupon rows.
     */
    getQueueCouponRows(sale: Sales, columns: number = 46, options?: { doubleWidth?: boolean }): QueueRow[] {
        const queueMainRow = stringToLines([this.checkManager.getPreference('cashregister.queue.main_row') || this.$translate.instant('CASHREGISTER.QUEUE.PRINT_MAIN_ROW'), String(sale.sale_number || '')].join(' '), columns);
        const queueSecondRow = stringToLines(this.checkManager.getPreference('cashregister.queue.second_row') || this.$translate.instant('CASHREGISTER.QUEUE.PRINT_SECOND_ROW'), columns);

        const rows: QueueRow[] = [{
            text: ' ',
            doubleHeight: true
        }];

        for (const row of queueMainRow) {
            rows.push({
                text: padCenter(row, options?.doubleWidth ? Math.floor(columns / 2) : columns),
                doubleHeight: true
            });
        }

        for (const row of queueSecondRow) {
            rows.push({
                text: padCenter(row, columns),
                doubleHeight: false
            });
        }

        rows.push({
            text: ' ',
            doubleHeight: true
        });

        return rows;
    }

    /**
     * Retrieves configuration resources required for printer configuration.
     * @async
     * @returns {Promise<object>} - A promise that resolves to an object containing activity codes, departments, payment methods, and VATs.
     */
    async getPrinterConfigurationResources() {
        return {
            activityCodes: await this.entityManager.activityCodes.fetchCollectionOffline(),
            departments: await this.entityManager.departments.fetchCollectionOffline(),
            paymentMethods: await this.entityManager.paymentMethods.fetchCollectionOffline(),
            vats: await this.entityManager.vat.fetchCollectionOffline()
        };
    }

    /**
     * Retrieves the header lines for a fiscal receipt based on the sale information.
     * @param {Sales} sale - The sale object from which to extract the header lines.
     * @returns {string[]} - An array of header lines for the fiscal receipt.
     */
    getFiscalReceiptHeaderLines(sale: Sales): string[] {
        const headerLines: string[] = [];

        if (sale.name) {
            headerLines.push(sale.name);
        }

        if (sale.room_name && sale.table_name) {
            headerLines.push(`${sale.room_name} - ${sale.table_name}`);
        }

        if (sale.notes) {
            headerLines.push(sale.notes);
        }

        if (headerLines.length) {
            headerLines.push("");
        }

        return headerLines;
    }
}

angular.module('core').service('fiscalUtils', FiscalUtilsService);