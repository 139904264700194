import {
    computed,
    Injectable,
    signal
} from '@angular/core';

import { validate as validateUuid, v4 as generateUuid } from 'uuid';
import { EnvironmentConfig } from 'src/environments/environment-config';

type UserAgentOs = 'Android' | 'iOS' | 'Windows' | 'macOS' | 'Linux' | 'Unknown';

type DeviceMeta = {
    client_serial: string;
    client_uuid: string;
    client_os: string;
}

type NetworkInformation = {
    client_lan_ip: string;
}

@Injectable({
    providedIn: 'root'
})
export class EnvironmentInfoService {
    private _isAndroid = signal(false);
    private _isAppleMobile = signal(false);
    private _isElectronApp = signal(false);
    private _isMobileApp = signal(false);

    private _baseUrl: string;
    private _deviceMeta?: DeviceMeta;

    private _sessionId = this.initSessionId();
    private _userAgentOS = signal(this.setUserAgentFromNavigator());

    private initSessionId(): string {
        const localStorage = window.localStorage;
        let deviceId;

        if (localStorage) {
            deviceId = window.localStorage.getItem("scl-device-id");
        }

        if (!deviceId || !validateUuid(deviceId)) {
            deviceId = generateUuid();

            if (localStorage) {
                window.localStorage.setItem("scl-device-id", deviceId);
            }
        }

        return deviceId;
    }

    private setUserAgentFromNavigator(): UserAgentOs {
        const userAgent = window.navigator.userAgent;

        //Obtain os info from the user agent
        if (userAgent.match(/(Android)/)) {
            return 'Android';
        } else if (userAgent.match(/(iPhone|iPod|iPad)/)) {
            return 'iOS';
        } else if (userAgent.match(/(Windows)/)) {
            return 'Windows';
        } else if (userAgent.match(/(Macintosh)/)) {
            return 'macOS';
        } else if (userAgent.match(/(X11)/)) {
            return 'Linux';
        }

        return 'Unknown';
    }

    constructor() {
        switch (EnvironmentConfig.clientId) {
            case 'Scloby3PG':
                this._isMobileApp.set(true);

                switch (window.device.manufacturer) {
                    case 'Apple':
                        this._isAppleMobile.set(true);
                        break;
                    default:
                        this._isAndroid.set(true);
                        break;
                }
                break;
            case 'SclobyElectron3':
                this._isElectronApp.set(true);
                break;
            default:
                if (window.require) {
                    this._isElectronApp.set(true);

                    Object.assign(EnvironmentConfig, {
                        redirectUri: "http://ok.login.tilby.com/"
                    });
                }
                break;
        }

        if (this._isElectronApp()) {
            this._baseUrl = "./";
        } else if (this._isMobileApp()) {
            this._baseUrl = document.URL.substring(0, document.URL.lastIndexOf("/")).replace(/.*?:\/\//g, "") + "/";
        } else {
            this._baseUrl = "/";
        }
    }

    /**
     * Returns the base URL of the application based on the current environment.
     *
     * @return {string} The base URL of the application.
     */
    public getBaseUrl(): string {
        return this._baseUrl;
    }

    /**
     * Retrieves the local IP address of the device.
     * 
     * @return {Promise<NetworkInformation>} A promise that resolves with an object containing the local IP address of the device.
     * 
     * @throws {string} If the interface is not found or if the platform is invalid.
     */
    public async getNetworkInfo(): Promise<NetworkInformation> {
        if (this._isElectronApp()) {
            const sysinfo = window.require('systeminformation');

            let defInterface = await sysinfo.networkInterfaceDefault();
            let interfaces: any[] = await sysinfo.networkInterfaces();
            let targetIface = interfaces.find((i) => (i.iface === defInterface));

            if (targetIface) {
                return { client_lan_ip: targetIface.ip4 };
            } else {
                throw 'INTERFACE_NOT_FOUND';
            }
        } else if (this._isMobileApp()) {
            let clientIp = '';
            
            if(window.networkinterface) {
                clientIp = await new Promise((resolve) => window.networkinterface!.getWiFiIPAddress((result) => resolve(result.ip), () => resolve('')));

                if(!clientIp) {
                    clientIp = await new Promise((resolve) => window.networkinterface!.getCarrierIPAddress((result) => resolve(result.ip), () => resolve('')));
                }
            }

            if(!clientIp) {
                throw 'INTERFACE_NOT_FOUND';
            }

            return { client_lan_ip: clientIp };
        } else {
            throw 'INVALID_PLATFORM';
        }
    }

    /**
     * Checks if the app is running on an Android device
     */
    public isAndroid = computed(() => this._isAndroid());

    /**
     * Checks if the app is running on an Apple mobile device (iPhone or iPad)
     */
    public isAppleMobile = computed(() => this._isAppleMobile());

    /**
     * Checks if the app is running on a mobile app (Android or iOS)
     */
    public isMobileApp = computed(() => this._isMobileApp());

    /**
     * Checks if the app is running on an Electron app (desktop)
     */
    public isElectronApp = computed(() => this._isElectronApp());

    /**
     * Checks if the app is running on a web app (browser)
     */
    public isWebApp = computed(() => !this._isMobileApp() && !this._isElectronApp());

    /**
     * Checks if TCP sockets can be used
     */
    public canUseTcpSockets = computed(() => this._isMobileApp() || this._isElectronApp());

    /**
     * Checks if serial ports can be used
     */
    public canUseSerialPorts = computed(() => this._isElectronApp());

    /**
     * Checks if the camera barcode reader is supported
     */
    public isCameraBarcodeSupported = computed(() => this._isMobileApp());

    /**
     * Checks if external links can be opened
     */
    public canOpenExternalLinks = computed(() => true);

    /**
     * Checks if files can be downloaded
     */
    public canDownloadFiles = computed(() => !this._isMobileApp());

    /**
     * Checks if the network can be scanned
     */
    public canScanNetwork = computed(() => this._isMobileApp() || this._isElectronApp());

    /**
     * Checks if files can be shared
     */
    public canShare = computed(() => this._isMobileApp());

    /**
     * Checks if printing is supported
     */
    public canPrint = computed(() => this._isElectronApp() || !this._isMobileApp());

    /**
     * Checks if the login view should be used
     */
    public usesLoginView = computed(() => this._isMobileApp() || this._isElectronApp());

    /**
     * Gets the OS of the user agent
     */
    public getUserAgentOS = computed(() => this._userAgentOS());

    /**
     * Checks if the app is running on Windows
     */
    public isWindows = computed(() => this._userAgentOS() === 'Windows');

    /**
     * Checks if the app is running on macOS
     */
    public isMacOS = computed(() => this._userAgentOS() === 'macOS');

    /**
     * Returns the device meta, which contains the client serial, client UUID, and client OS.
     * @returns {DeviceMeta} the device meta
     */
    public async getDeviceMeta(): Promise<DeviceMeta> {
        if(!this._deviceMeta) {
            const deviceMeta = {
                client_serial: '',
                client_uuid: '',
                client_os: ''
            };

            if(this.isElectronApp()) {
                const sysinfo = window.require('systeminformation');
    
                try {
                    const systemInfo = await sysinfo.system();

                    deviceMeta.client_serial = systemInfo.serial;
                    deviceMeta.client_uuid = systemInfo.uuid;
                } catch (err) {
                    //Nothing to do
                }
    
                try {
                    const osInfo = await sysinfo.osInfo();

                    deviceMeta.client_os = `${osInfo.distro} ${osInfo.release}`;
                } catch (err) {
                    //Nothing to do
                }
            } else if(this.isMobileApp()) {
                deviceMeta.client_serial = window.device.serial,
                deviceMeta.client_uuid = window.device.uuid,
                deviceMeta.client_os = `${window.device.platform} ${window.device.version}`
            }

            this._deviceMeta = deviceMeta;
        }

        return { ...this._deviceMeta };
    }

    /**
     * Returns the session ID.
     * @returns {string} the session ID
     */
    public getSessionId(): string {
        return this._sessionId;
    }
}