import { ChangeDetectorRef, Component, Injectable, Injector, SimpleChanges, inject, runInInjectionContext } from "@angular/core";
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogModule,
    MatDialogRef,
} from "@angular/material/dialog";
import { Subscription, distinctUntilChanged, lastValueFrom } from "rxjs";
import {
    BaseDialogService,
    NonNullableConfigData,
    TilbyDialogContentComponent,
    TilbyDialogToolbarComponent,
} from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { MatInputModule } from "@angular/material/input";
import { ConfirmDialogService } from "../confirm-dialog";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { countryCodes } from 'src/app/core/constants/country-codes';
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { RequireMatch, countryToCode, keyBy, mobileCheck, subscribeInComponent } from '@tilby/tilby-ui-lib/utilities';
import { ChainCampaigns, ChainPrizes, Customers, FidelitiesPoints, Items } from "tilby-models";
import { AddressesFormGroups, CustomerUtilsService, CustomerValidators, CustomersFields, CustomersForm, PrepaidMovementsFields } from "src/app/features";
import { SalesCustomerCashregister } from "src/app/shared/model/cashregister.model";
import { $state, RootScope, fiscalUtils,restManager, util } from "app/ajs-upgraded-providers";
import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { ConfigurationManagerService, ConnectionService, EntityManagerService, PaginatedResponse } from "src/app/core";
import { CustomForm, CustomFormArray, CustomFormControl, CustomFormControlProps, CustomFormGroup, CustomFormGroupProps, TilbyMagicFormComponent } from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { MatIconModule } from "@angular/material/icon";
import { CommonModule, KeyValue } from "@angular/common";
import { v4 as generateUuid } from 'uuid';
import angular from "angular";
import _ from 'lodash';
import { MatButtonModule } from "@angular/material/button";
import { AlertDialogService } from "../alert-dialog";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatOptionModule } from "@angular/material/core";
import { TilbyDatePipe } from "@tilby/tilby-ui-lib/pipes/tilby-date";
import { TilbyCurrencyPipe } from "@tilby/tilby-ui-lib/pipes/tilby-currency";
import { ContactsType, FidelizationType, GenderType, GeneralFormFields, InvoiceAddressType, RadioListPrizes, ClientDataType, ShippingAddresses, Address, CustomTypesType, CurrentShippingAddress } from "./interfaces";
import { countryCodesShort} from "src/app/core/constants";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { PipesModule } from "src/app/pipes/pipes.module";
import { CountryCode } from "src/app/models";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDividerModule } from "@angular/material/divider";

export type AddSelectCustomerForm = CustomFormGroup<CustomForm<Customers>>;
export type AddSelectCustomerDialogData = { currentCustomer?: SalesCustomerCashregister, options?: { editMode?: boolean, newCustomerData?: any, showCustomerDetails?: boolean }};
type OutputCustomerDialog = {sale_customer: Customers | null, default_pricelist?: number, prize?: ChainPrizes};
type PriceList = { name: string; id: number | null };

@Component({
    selector: 'add-select-customer-dialog',
    templateUrl: './add-select-customer-dialog.component.html',
    styleUrls: ['./add-select-customer-dialog.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        CdkFixedSizeVirtualScroll,
        CdkVirtualScrollViewport,
        CdkVirtualForOf,
        FormsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDividerModule,
        MatAutocompleteModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatRadioModule,
        MatSelectModule,
        MatOptionModule,
        PipesModule,
        ReactiveFormsModule,
        TilbyDialogContentComponent,
        TilbyDialogToolbarComponent,
        TilbyMagicFormComponent,
        TranslateModule
    ]
})
export class AddSelectCustomerDialogComponent {
    private readonly utils = inject(util);    
    private readonly $state = inject($state);
    private readonly fb = inject(FormBuilder);
    private readonly injector = inject(Injector);
    private readonly $rootScope = inject(RootScope);
    private readonly cdr = inject(ChangeDetectorRef);
    private readonly restManager = inject(restManager);
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly connection = inject(ConnectionService);
    private readonly fiscalUtilsService = inject(fiscalUtils);
    private readonly translateService = inject(TranslateService);
    private readonly alertDialogService = inject(AlertDialogService);
    private readonly checkManager = inject(ConfigurationManagerService);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly customerUtilsService = inject(CustomerUtilsService);
    private readonly confirmDialogService = inject(ConfirmDialogService);

    protected readonly data: AddSelectCustomerDialogData = inject(MAT_DIALOG_DATA);
    private subscription!: Subscription;
    
    isMobile = mobileCheck();
    dataCustomerTemplate: Customers & {get_source?: any} = this.getCustomerTemplate();
    currentCustomer: any | null = null;
    openCustomerForm: boolean = false;
    context = 'open';
    previousContext: string = "";
    searchEnabled = true;
    searchText = "";
    searchForCustomer = this.fb.group({
        customerName: [this.data.currentCustomer?.first_name && this.data.currentCustomer.last_name ? (this.data.currentCustomer.first_name + ' ' + this.data.currentCustomer.last_name) : this.data.currentCustomer?.company_name ?? ''],
    })
    customers: Customers[] = [];
    customer: Customers & { type?: 'private' | 'company'; $prepaidCredit?: number } | null = null;
    foundCustomers: Customers[] = [];
    customerPoints!: FidelitiesPoints | null;
    vatValidateStatus = 'default';
    vatRegExp = this.utils.getVatRegexp();
    country = this.checkManager.getShopCountry();
    provMaxLength = ['IT', 'MT'].includes(this.country) ? 2 : 255;
    countryCodes = Object.entries(countryCodes).map(([key, val]) => {return { code: key, name: val.name }});
    countryCodesDict = keyBy(this.countryCodes, (country) => country.code);
    shippingAddressRange = _.range(1, 11);
    shippingAddressTmp!: Customers;
    currentShippingAddress: number = 1;
    shippingCountrySearch = '';
    fidelityLabel = this.checkManager.getPreference('fidelity.fidelity_label') || this.translateService.instant("APPLICATION.ADD_SELECT_CUSTOMER.FIDELITY");
    currentState = this.$state.current.name.split('.')[1];
    customTypes: KeyValue<string, string>[] | undefined = this.checkManager.getPreference("customers.custom_types") ? this.checkManager.getPreference("customers.custom_types")?.split('\n').map((code, index) => ({ key: code, value: `Option ${index + 1}` })) : undefined;

    activeCampaign: ChainCampaigns | undefined;
    isQuickAdding = false;
    hasCustomTypes = false;
    customerTypes: string[] = ['CUSTOMERS.SEARCH_DIALOG.FORM.CUSTOM_TYPE_PRIVATE', 'CUSTOMERS.SEARCH_DIALOG.FORM.CUSTOM_TYPE_COMPANY'];
    customer_type: KeyValue<string, string>[] = this.customerTypes.map((type, i) => ({ key: type, value: type === 'CUSTOMERS.SEARCH_DIALOG.FORM.CUSTOM_TYPE_PRIVATE' ? 'private' : 'company' }));
    genders: KeyValue<string, string>[] = [{value: "U", key: 'CUSTOMERS.DETAILS.FORM.UNDEFINED'}, { value: "F", key: 'CUSTOMERS.DETAILS.FEMALE'}, { value: "M", key: 'CUSTOMERS.DETAILS.MALE'}];
    customFields: { idx: number, name?: string }[] = [];

    addEditCustomerForm: CustomFormGroup<CustomForm<GeneralFormFields>> = this.declareForm();
    askShippingAddress = this.checkManager.getPreference('cashregister.ask_shipping_address');

    shippingAddresses: ShippingAddresses[] = [];
    radioListPrizes: RadioListPrizes[] = [];

    applyNewPricelist: boolean = false;
    shippingAddressesChoosed!: ShippingAddresses;
    radioListPrizesChoosed!: RadioListPrizes;

    addressesForm = new CustomFormGroup<{currentShippingAddress: any}>({
        currentShippingAddress: new CustomFormControl('1', {}, {...new CustomFormControlProps(),inputType: 'select',inputChoices: this.shippingAddressRange.map(i => ({ key: `${i}`, value: `${i}` }))})
    });
    optionsForm = new CustomFormGroup<CustomersForm & { get_source: any }>({
        get_source: new CustomFormControl('1', {}, {...new CustomFormControlProps(),inputType: 'select',inputChoices: this.shippingAddressRange.map(i => ({ key: `${i}`, value: `${i}` }))})
    });
    priceLists?: PriceList[];
    paymentMethods?: any[];
    isCustomerTypeIncludes: boolean = true;
    validators: CustomerValidators = {
        companyNameMaxLength: 255,
        firstNameMaxLength: 255,
        lastNameMaxLength: 255,
        taxCodeMaxLength: 16,
        sdiCodeMinLength: 6,
        sdiCodeMaxLength: 7,
        lotteryCodeMinLength: 8,
        lotteryCodeMaxLength: 8,
        phoneMaxLength: 15,
        mobileMaxLength: 255,
        emailMaxLength: 255,
        emailPecMaxLength: 255,
        socialMaxLength: 255,
        fidelityMaxLength: 255,
        discountPercMin: 0,
        discountPercMax: 100,
        streetMaxLength: 255,
        numberMaxLength: 25,
        zipMaxLength: 10,
        cityMaxLength: 255,
        provMinLength: 0,
        provMaxLength: 255,
        provMaxLengthArray: new Array(10).fill(2)
    };

    currentAddress = (val = 1): CurrentShippingAddress => ({
        shippingForm: `shippingForm${(val - 1 ? '_' + (val - 1) : '')}`,
        shipping_street: `shipping_street${(val - 1 ? '_' + (val - 1) : '')}`,
        shipping_number: `shipping_number${(val - 1 ? '_' + (val - 1) : '')}`,
        shipping_zip: `shipping_zip${(val - 1 ? '_' + (val - 1) : '')}`,
        shipping_city: `shipping_city${(val - 1 ? '_' + (val - 1) : '')}`,
        shipping_prov: `shipping_prov${(val - 1 ? '_' + (val - 1) : '')}`,
        shipping_country: `shipping_country${(val - 1 ? '_' + (val - 1) : '')}`
    });
    currentSlot = 1;

    customActions = [
        {
            isIt: ()=> !this.isCustomerSelected() && !this.isCreatingCustomer(),
            name:'',
            icon:()=>'add',
            click:()=> this.addCustomerMode()
        },
        {
            isIt: ()=> this.isCustomerSelected() && !this.isEditingCustomer(),
            name:'',
            icon:()=>'edit',
            click:() => this.editCustomer()
        },
        {
            isIt: ()=> true,
            name:'',
            icon:()=>'check',
            click:()=> {
                if(this.isEditingCustomer() || this.isCreatingCustomer()) {
                    if(this.addEditCustomerForm.valid){
                        this.confirm();
                    } else {
                        this.addEditCustomerForm.markAllAsTouched();
                    }
                } else {
                    this.confirm();
                }
            }
        }
    ]

    showCustomerDetails: boolean = true;
    deselectButtonDistance: number = 0;

    constructor(){
        if(this.data.options && this.data.options.showCustomerDetails === false){
            this.showCustomerDetails = this.data.options.showCustomerDetails;
        }

        runInInjectionContext(
            this.injector,
            ()=>{
                subscribeInComponent(
                    this.addEditCustomerForm.controls.client_data.controls.company_name!.valueChanges.pipe(distinctUntilChanged()),
                    ()=>{
                        const { company_name, first_name, last_name } = this.addEditCustomerForm.controls.client_data.controls;

                        if(company_name?.value) {
                            first_name?.removeValidators(Validators.required);
                            last_name?.removeValidators(Validators.required);
                            company_name?.addValidators(Validators.required);
                        } else {
                            company_name?.removeValidators(Validators.required);
                        }
                        company_name?.updateValueAndValidity();
                    }
                ),
                subscribeInComponent(
                    this.addEditCustomerForm.controls.client_data.controls.first_name!.valueChanges.pipe(distinctUntilChanged()) || this.addEditCustomerForm.controls.client_data.controls.last_name!.valueChanges.pipe(distinctUntilChanged()),
                    () => {
                        if(this.addEditCustomerForm.controls.client_data.controls.first_name!.value || this.addEditCustomerForm.controls.client_data.controls.last_name!.value) {
                            this.addEditCustomerForm.controls.client_data.controls.first_name!.addValidators(Validators.required);
                            this.addEditCustomerForm.controls.client_data.controls.last_name!.addValidators(Validators.required);
                            this.addEditCustomerForm.controls.client_data.controls.company_name!.removeValidators(Validators.required);
                        } else {
                            this.addEditCustomerForm.controls.client_data.controls.first_name!.removeValidators(Validators.required);
                            this.addEditCustomerForm.controls.client_data.controls.last_name!.removeValidators(Validators.required);
                        }
                        this.addEditCustomerForm.controls.client_data.controls.first_name!.updateValueAndValidity();
                        this.addEditCustomerForm.controls.client_data.controls.last_name!.updateValueAndValidity();
                    }
                )
            }
        )

        this.getCustomers();
        this.setupCustomFields();
        this.setupPaymentMethods();
        this.setupPricelists();

        const c: CustomersFields = <CustomersFields>{};

        const createShippingAddress = (val: number): FormGroup<CustomersForm> => {
            const ss = `shipping_street${(val - 1 ? '_' + (val - 1) : '')}` as keyof CustomersFields;
            const sn = `shipping_number${(val - 1 ? '_' + (val - 1) : '')}` as keyof CustomersFields;
            const sz = `shipping_zip${(val - 1 ? '_' + (val - 1) : '')}` as keyof CustomersFields;
            const sct = `shipping_city${(val - 1 ? '_' + (val - 1) : '')}` as keyof CustomersFields;
            const sp = `shipping_prov${(val - 1 ? '_' + (val - 1) : '')}` as keyof CustomersFields;
            const sc = `shipping_country${(val - 1 ? '_' + (val - 1) : '')}` as keyof CustomersFields;
            
            const x = new FormGroup<CustomersForm>({});

            
            x.addControl(ss, new CustomFormControl(c[ss] || null, { validators: Validators.maxLength(this.validators.streetMaxLength) }, {...new CustomFormControlProps(), hint: () => `${this.shipping_street(val)?.value?.length || 0}/ ${this.validators.streetMaxLength}`}));
            x.addControl(sn, new CustomFormControl(c[sn] || null, { validators: Validators.maxLength(this.validators.numberMaxLength) }, {...new CustomFormControlProps(), hint: () => `${this.shipping_number(val)?.value?.length || 0}/ ${this.validators.numberMaxLength}`}));
            x.addControl(sz, new CustomFormControl(c[sz] || null, { validators: Validators.maxLength(this.validators.zipMaxLength) }, {...new CustomFormControlProps(), hint: () => `${this.shipping_zip(val)?.value?.length || 0}/ ${this.validators.zipMaxLength}`}));
            x.addControl(sct, new CustomFormControl(c[sct] || null, { validators: Validators.maxLength(this.validators.cityMaxLength) }, {...new CustomFormControlProps(), hint: () => `${this.shipping_city(val)?.value?.length || 0}/ ${this.validators.cityMaxLength}`}));
            x.addControl(sp, new CustomFormControl(c[sp] || null, { validators: [ Validators.minLength(this.validators.provMinLength), Validators.maxLength(this.validators.provMaxLength)]}, {...new CustomFormControlProps(),hint: () => `${this.shipping_prov(val)?.value?.length || 0}/ ${this.validators.provMaxLength}`}));
            x.addControl(sc, new CustomFormControl(c[sc] || null, { validators: RequireMatch<CountryCode>(countryCodesShort) }, {...new CustomFormControlProps(), inputType: 'autocomplete', inputChoices: countryCodesShort.map(({ code, name }) => ({ key: code, value: name }))}));
            
            return x;
        };

        for (let n = 1; n <= 10; n++) {
            this.addressesForm.addControl(`shippingForm${n - 1 ? '_' + (n - 1) : ''}`, createShippingAddress(n));
        }

        this.dataCustomerTemplate = this.getCustomerTemplate();
        this.currentCustomer = (typeof this.data.currentCustomer === 'object') ? structuredClone(this.data.currentCustomer) : {};
        let customerId = this.currentCustomer.customer_id || this.currentCustomer.uuid || (Number.isInteger(this.data.currentCustomer) && this.data.currentCustomer);

        if (customerId) {
            this.getShippingAddresses(this.currentCustomer);

            this.entityManagerService.customers.fetchOneOffline(customerId).then((result) => {
                if(result) {
                    angular.copy(result, this.currentCustomer);
                } else {
                    if(this.currentCustomer){
                        this.currentCustomer.id = customerId;
                        this.currentCustomer.customer_id = null;
                    }
                }

                this.context = "select";

                if(this.data.options && this.data.options.editMode) {
                    this.editCustomer();
                }

                this.getCustomerCredit(this.currentCustomer);
            });
        } else if (this.data.options && _.isObject(this.data.options.newCustomerData)) {
            _.assign(this.dataCustomerTemplate, this.data.options.newCustomerData);
            this.context = 'add';
            this.addEditCustomerForm.markAsDirty();
        } else {
            this.loadShippingAddress(1);
            this.parseCustomerData(this.dataCustomerTemplate);
            this.addEditCustomerForm.markAsDirty();
        }

        if (this.checkManager.getPreference("cashregister.show_fidelity_points")) {

            this.entityManagerService.campaigns.fetchCollectionOnline({}).then((campaigns: ChainCampaigns[] | PaginatedResponse<ChainCampaigns>) => {

                if (Array.isArray(campaigns)) {
                    this.activeCampaign = campaigns.find((campaign: ChainCampaigns) => campaign.isValid);
                }

                this.getPointsForCustomer(this.currentCustomer);
                if (this.customer !== null) {
                    this.getCustomerCredit(this.customer);
                }

                this.getPrizes();
            });

            this.$rootScope.$on("fidelity-updated", (event: any, fidelityPoints: FidelitiesPoints) => {
                if (this.customerPoints && fidelityPoints.id === this.customerPoints.id) {
                    this.customerPoints = fidelityPoints;
                }
            });
        }

        if(this.checkManager.isFunctionEnabledOptin("prepaid.enabled" as any)) {
            this.$rootScope.$on('prepaid-updated', (event: any, prepaidInfo: PrepaidMovementsFields) => {
                if(prepaidInfo.customer_uuid === this.currentCustomer.uuid) {
                    this.currentCustomer.$prepaidCredit = (prepaidInfo.credit + prepaidInfo.ticket_credit);
                }
            });
        }

        this.$rootScope.$on("OfflineFirst-customers:completed", (event: any, data: any) => {
            this.replaceCustomerIds(this.currentCustomer, data);
            const found = this.customers.find(customer => customer.id === data.uuid);
            if (found) {
                this.replaceCustomerIds(found, data);
            }
        });

        this.$rootScope.$on("connection:changed", (event: any, data: any) => {
            if(event.status === 'offline') {
                if (this.isCreatingCustomer()) {
                    data.fidelity = "";
                }
                if (this.isEditingCustomer()) {
                    data.fidelity = this.currentCustomer.fidelity;
                }
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.currentShippingAddress.currentValue !== changes.currentShippingAddress.previousValue) {
            this.saveShippingAddress(changes.currentShippingAddress.previousValue);
            this.shippingCountrySearch = "";
            this.loadShippingAddress(changes.currentShippingAddress.currentValue);
        }
    }

    ngOnInit() {
        this.getCustomers();
    }

    ngAfterViewInit() {
        this.searchForCustomer.controls.customerName.valueChanges.pipe(distinctUntilChanged())
        .subscribe((inputSearch) => {
            if (inputSearch) {
                this.searchText = inputSearch;
                this.searchCustomers();
            } else {
                this.clearSearchText();
            }
        });

        if(this.currentCustomer){
            this.getCustomerCredit(this.currentCustomer);
            this.getShippingAddresses(this.currentCustomer);
            this.getPointsForCustomer(this.currentCustomer);
            this.getPrizes();
        }
    }
    
    async getCustomers(){
        const customers = await this.entityManagerService.customers.fetchCollectionOffline();
        this.customers = customers || [];
    }

    setupPricelists() {
        const priceListStart = this.translateService.instant('ITEMS.DETAILS.PRICE_LIST_START');
        this.priceLists = [...Array(11).keys()]
            .map((id) => ({
                id,
                name: this.checkManager.getPreference(`price_list_${id}_name`) || `${priceListStart} ${id}`
            }));

        this.priceLists[0] = {
            id: null,
            name: this.translateService.instant("CUSTOMERS.DETAILS.NONE")
        };
    }

    async setupPaymentMethods() {
        this.paymentMethods = await this.entityManagerService.paymentMethods.fetchCollectionOffline();
    }

    replaceCustomerIds(customer: Customers, data: any) {
        if (data.uuid === customer.id) {
            customer = { ...customer, id: data.entity.id };
        }
    };
    
    getCustomerTemplate(): Customers & { type: 'private' | 'company'; get_source?: any } {
        const shopCountry = this.checkManager.getShopCountry(); 

        return {
            type: 'private',
            gender: 'M',
            country: shopCountry,
            shipping_country: shopCountry,
            billing_country: shopCountry,
            first_name: this.checkManager.getPreference("customers.default_first_name" as any) || '',
            last_name: this.checkManager.getPreference("customers.default_last_name" as any) || '',
            send_commercials: true,
            disable_mail_receipts: false,
            uuid: generateUuid()
        };
    };

    parseCustomerData(customer: Customers & { type?: string; billingCountry?: { code: string; name: string; } }) {
        if (customer.first_name) {
            if(customer.birthdate){
                if (!(customer.birthdate instanceof Date)) {
                    let bDate = Date.parse(customer.birthdate);
                    customer.birthdate = !_.isNaN(bDate) ? new Date(bDate) : undefined;
                }

                customer.type = 'private';
            }
        } else if (customer.company_name) {
            customer.type = 'company';
        } else if (customer.tax_code) {
            customer.type = 'private';
        }

        if(customer.billing_country) {
            customer.billingCountry =  this.countryCodesDict[customer.billing_country];
        }

        if (this.addEditCustomerForm && this.addEditCustomerForm.controls.client_data.controls.tax_code) {
            this.validateTaxCode(this.addEditCustomerForm.controls.client_data.controls.tax_code as CustomFormControl<any>);
        }
    };

    resetForm() {
        this.addEditCustomerForm.markAsPristine();
        this.addEditCustomerForm.markAsUntouched();

        Object.entries(this.addEditCustomerForm.controls).forEach(([key, control]) => {
            control.reset();
        });
    };

    saveShippingAddress(slot: number) {
        let targetCustomer = this.dataCustomerTemplate;
        let sourceCustomer = this.shippingAddressTmp;
        let slotSuffix = slot === 1 ? "" : "_" + (slot - 1);

        (targetCustomer as any)['shipping_street' + slotSuffix] = sourceCustomer.shipping_street;
        (targetCustomer as any)['shipping_number' + slotSuffix] = sourceCustomer.shipping_number;
        (targetCustomer as any)['shipping_zip' + slotSuffix] = sourceCustomer.shipping_zip;
        (targetCustomer as any)['shipping_city' + slotSuffix] = sourceCustomer.shipping_city;
        (targetCustomer as any)['shipping_prov' + slotSuffix] = sourceCustomer.shipping_prov;
        (targetCustomer as any)['shipping_country' + slotSuffix] = _.get(sourceCustomer, '_shippingCountry.code');
    };

    loadShippingAddress(slot: number) {
        let sourceCustomer = this.dataCustomerTemplate;
        let slotSuffix = slot === 1 ? "" : "_" + (slot - 1);
        angular.copy({}, this.shippingAddressTmp);

        _.assign(this.shippingAddressTmp, {
            shipping_prov: (sourceCustomer as any)['shipping_prov' + slotSuffix],
            shipping_street: (sourceCustomer as any)['shipping_street' + slotSuffix],
            shipping_number: (sourceCustomer as any)['shipping_number' + slotSuffix],
            shipping_zip: (sourceCustomer as any)['shipping_zip' + slotSuffix],
            shipping_city: (sourceCustomer as any)['shipping_city' + slotSuffix],
            _shippingCountry: this.countryCodesDict[(sourceCustomer as any)['shipping_country' + slotSuffix]]
        });
    };

    getShippingAddress(slot?: number, currentCustomer?: CustomersFields) {
        let slotSuffix;
        let source: CustomersFields;
        let result = '';
        let customer!: CustomersFields;

        if(currentCustomer){
            customer = this.customers.find(c => c.id === currentCustomer.id) as CustomersFields;
        }

        if (!customer) {
            return null;
        }

        if (slot === this.currentShippingAddress) {
            source = <CustomersFields>this.addressesShippingForm(slot)?.value;
            slotSuffix = '';
        } else {
            source = customer;
            slotSuffix = slot === 1 ? '' : '_' + 1;
        }

        if ((source as any)['shipping_street' + slotSuffix] !== undefined && (source as any)['shipping_street' + slotSuffix] !== "") {
            result += ' - ' + source[('shipping_street' + slotSuffix) as keyof CustomersFields] + ' ';
            result += (source[('shipping_number' + slotSuffix) as keyof CustomersFields] || '') + ' ';
            result += (source[('shipping_zip' + slotSuffix) as keyof CustomersFields] || '') + ' ';
            result += (source[('shipping_city' + slotSuffix) as keyof CustomersFields] || '') + ' ';
            result += (source[('shipping_prov' + slotSuffix) as keyof CustomersFields] ? '(' + source[('shipping_prov' + slotSuffix) as keyof CustomersFields] + ')' : '') + ' ';
        }

        return result;
    }

    getCustomerCaption(customer: Customers) {
        let customerCaption = '';

        if (customer.company_name) {
            customerCaption += customer.company_name + ' ';
        }

        if(customer.first_name){
            customerCaption += customer.first_name;
        }

        if(customer.last_name){
            customerCaption += ' ' + customer.last_name;
        }

        return customerCaption;
    };

    getCustomerAddress(customer: Customers) {
        if(this.currentState === 'orders') {
            for(let i = 0; i <= 9; i++) {
                let suffix = (i === 0 ? '' : '_' + i);

                if((customer as any)['shipping_street' + suffix]) {
                    return (customer as any)['shipping_street' + suffix] + " " +
                        ((customer as any)['shipping_number' + suffix] ? ((customer as any)['shipping_number' + suffix] + " ") : "") +
                        ((customer as any)['shipping_zip' + suffix] ? ((customer as any)['shipping_zip' + suffix] + " ") : "") +
                        ((customer as any)['shipping_city' + suffix] ? (customer as any)['shipping_city' + suffix] + " " : "") +
                        ((customer as any)['shipping_prov' + suffix] ? "(" + (customer as any)['shipping_prov' + suffix] + ")" : "");
                }
            }
        } else {
            if(customer.billing_street) {
                return customer.billing_street + " " + (customer.billing_number ? (customer.billing_number + " ") : "") + (customer.billing_zip ? (customer.billing_zip + " ") : "") + (customer.billing_city ? customer.billing_city + " " : "") + (customer.billing_prov ? "(" + customer.billing_prov + ")" : "");
            }
        }
    };

    getPointsForCustomer(customer: Customers) {
        if (this.activeCampaign && customer.fidelity && !!this.checkManager.getPreference("cashregister.show_fidelity_points")) {
            this.entityManagerService.fidelitiesPoints.fetchCollectionOffline({ fidelity: customer.fidelity })
            .then((fidelitiesPoints: FidelitiesPoints[]) => {
                this.customerPoints = fidelitiesPoints.find((fidelityPoint: FidelitiesPoints) => {
                    if(this.activeCampaign){
                        return fidelityPoint.campaign_id === this.activeCampaign.id
                    }
                }) || null;
            });
        }
    };

    getCustomerCredit(customer: Customers & { $prepaidCredit?: number }) {
        if(customer && customer.uuid && customer.fidelity) {
            let currentUuid = customer.uuid; //Keep track of the current uuid to avoid showing someone else's credit
            this.restManager.getList('prepaid_movements', {
                customer_uuid: currentUuid,
                valid_to: 'null',
                pagination: 'false'
            }).then((movements: any) => {
                if(currentUuid === customer.uuid) {
                    if(!_.isEmpty(movements)) {
                        let lastMovement = movements[0];
                        customer.$prepaidCredit = (lastMovement.credit + lastMovement.ticket_credit);
                    }
                } else {
                    customer.$prepaidCredit = 0;
                }
            });
        }
    };

    isOffline() {
        return this.connection.isOffline();
    };

    isOnline() {
        return this.connection.isOnline();
    };

    filterCountry(searchText: string) {
        return this.countryCodes.filter(country => country.name.toLowerCase().includes(searchText.toLowerCase()));
    };

    searchVat() {
        if (this.vatValidateStatus === 'default') {
            if(!this.dataCustomerTemplate.vat_code){
                return;
            }

            this.customerUtilsService.getVatInfo(this.dataCustomerTemplate.vat_code)
            .then((result) => {
                if (result) {
                    this.dataCustomerTemplate = { ...this.dataCustomerTemplate, ...result };
                    this.vatValidateStatus = 'found';
                    // $("md-dialog-content:visible").scrollTop(0);

                    if (result.billing_country) {
                        this.dataCustomerTemplate = { ...this.dataCustomerTemplate, ...result } as Customers & { type?: string | undefined; _billingCountry: any };
                    }
                } else {
                    setTimeout(() => {
                        this.vatValidateStatus = 'invalid';
                        // this.vatValidateError = this.translateService.instant('APPLICATION.VAT_VALIDATOR.NOT_FOUND');
                    });
                }
            }).catch((error) => {
                const errorCode = error.data.error.message.code;

                if (errorCode) {
                    setTimeout(() => {
                        // this.vatValidateError = this.translateService.instant('APPLICATION.VAT_VALIDATOR.' + errorCode);
                    });
                }
            });
        }
    };

    onFidelityScanned(result: string) {
        this.dataCustomerTemplate['fidelity'] = result;
    };

    searchCustomers() {
        if (this.searchText.length < 3) {
            this.foundCustomers = [];
        } else if(this.previousContext === "edit" && this.isCustomerSelected()) {
            this.context = "select";
        } else {
            this.context = "search";
            this.getCustomers();
            this.performSearch();
        }
    };

    performSearch() {
        let searchWords = this.searchText.split(' ');

        searchWords.forEach((word) => {
            word = word.toLowerCase();
            this.foundCustomers = this.customers.filter((customer) => {
            return (customer.first_name || '').toLowerCase().includes(word) ||
                (customer.company_name || '').toLowerCase().includes(word) ||
                (customer.last_name || '').toLowerCase().includes(word) ||
                (customer.vat_code || '').toLowerCase().includes(word) ||
                (customer.tax_code || '').toLowerCase().includes(word) ||
                (customer.fidelity || '').toLowerCase().includes(word) ||
                (customer.email || '').toLowerCase().includes(word) ||
                (customer.phone || '').toLowerCase().includes(word) ||
                (customer.billing_street || '').toLowerCase().includes(word);
            });
        });

        let searchVatRegExp = this.utils.getVatRegexp(true);

        if(searchVatRegExp.test(this.searchText)) {
            this.customerUtilsService.getVatInfo(this.searchText)
            .then((result) => {
                if(result) {
                    this.foundCustomers.push(result);
                    this.foundCustomers = _.sortBy(this.foundCustomers, ['last_name', 'company_name']);
                }
            })
            .finally(() => {
                this.cdr.detectChanges();
            })
        }
    };

    clearSearchText() {
        this.searchForCustomer.reset();
        this.searchText = "";
        this.currentCustomer = null;
        this.context = "open";
        this.foundCustomers = [];
    };

    onKeyDown(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    cancel() {
        this.matDialogRef.beforeClosed();
    };

    addCustomerMode() {
        this.cancelSearch();
        this.previousContext = this.context;
        this.context = "add";
        this.addEditCustomerForm.reset();
        this.addressesForm.reset();
        this.optionsForm.reset();
        this.addEditCustomerForm = this.declareForm();
        this.createOptionsForm();
    };

    cancelAddCustomer() {
        this.resetForm();
        angular.copy(this.getCustomerTemplate(), this.dataCustomerTemplate);
        this.parseCustomerData(this.dataCustomerTemplate);
        this.loadShippingAddress(1);
    };

    isPrivateCustomer() {
        return !this.dataCustomerTemplate.company_name;
    };
    
    protected confirm() {        
        if (this.isSelectingCustomer()) {
            if (this.currentCustomer && this.currentCustomer.uuid || this.currentCustomer && this.currentCustomer.id) {
                if(this.shippingAddressesChoosed){
                    Object.assign(this.currentCustomer, this.shippingAddressesChoosed);
                }
                this.matDialogRef.close({sale_customer: this.currentCustomer, default_pricelist: this.applyNewPricelist && this.currentCustomer.default_pricelist, prize: this.radioListPrizesChoosed ? this.radioListPrizesChoosed.prize : null});
            } else {
                this.matDialogRef.close({sale_customer: null});
            }
        } else if (this.isCreatingCustomer() || this.isEditingCustomer()) {
            if(this.addEditCustomerForm.valid && this.addressesForm.valid && this.optionsForm.valid) {
                this.confirmCustomer();
            }
        } else {
            this.matDialogRef.close({sale_customer: this.currentCustomer && this.data.currentCustomer ? this.data.currentCustomer : null});
        }
    }

    isCompany() {
        return !!this.dataCustomerTemplate.company_name;
    };

    async confirmCustomer() {
        let tmpCustomer = structuredClone(this.dataCustomerTemplate);

        const { first_name, last_name, company_name, birthdate, vat_code, tax_code, sdi_code, country, notes, custom_type, lottery_code } = this.addEditCustomerForm.controls.client_data.controls;
        const { genders } = this.addEditCustomerForm.controls.gender.controls;
        const { phone, mobile, email, email_pec, facebook, twitter, linkedin, instagram } = this.addEditCustomerForm.controls.contacts.controls;
        const { fidelity, discount_perc } = this.addEditCustomerForm.controls.fidelization.controls;
        const { billing_street, billing_number, billing_zip, billing_city, billing_prov, billing_country } = this.addEditCustomerForm.controls.invoice_address.controls;

        tmpCustomer.first_name = first_name.value ?? null;
        tmpCustomer.last_name = last_name.value ?? null;
        tmpCustomer.company_name = company_name.value ?? null;
        tmpCustomer.birthdate = birthdate?.value ?? null;
        tmpCustomer.vat_code = vat_code?.value ?? null;
        tmpCustomer.tax_code = tax_code?.value ?? null;
        tmpCustomer.sdi_code = sdi_code?.value ?? null;
        tmpCustomer.country = country?.value ? countryToCode('country', country?.value) : '';
        tmpCustomer.notes = notes?.value ?? null;
        tmpCustomer.custom_type = custom_type?.value ?? null;
        tmpCustomer.lottery_code = lottery_code?.value ?? null;
        tmpCustomer.gender = genders?.value ?? null;
        tmpCustomer.phone = phone?.value ?? null;
        tmpCustomer.mobile = mobile?.value ?? null;
        tmpCustomer.email = email?.value ?? null;
        tmpCustomer.email_pec = email_pec?.value ?? null;
        tmpCustomer.facebook = facebook?.value ?? null;
        tmpCustomer.twitter = twitter?.value ?? null;
        tmpCustomer.linkedin = linkedin?.value ?? null;
        tmpCustomer.instagram = instagram?.value ?? null;
        tmpCustomer.fidelity = fidelity.value ?? null;
        tmpCustomer.discount_perc = discount_perc.value ?? null;

        tmpCustomer.billing_street = billing_street?.value ?? null;
        tmpCustomer.billing_number = billing_number?.value ?? null;
        tmpCustomer.billing_zip = billing_zip?.value ?? null;
        tmpCustomer.billing_city = billing_city?.value ?? null;
        tmpCustomer.billing_prov = billing_prov?.value ?? null;
        tmpCustomer.billing_country = countryToCode('country', billing_country?.value) || '';

        // AddressesForm
        for(let index = 1; index < this.shippingAddressRange.length; index++) {
            const label = `shippingForm${index - 1 ? '_' + (index - 1) : ''}`;
            const labelShippingStreet = `shipping_street${(index - 1 ? '_' + (index - 1) : '')}`;
            const labelShippingNumber = `shipping_number${(index - 1 ? '_' + (index - 1) : '')}`;
            const labelShippingZip = `shipping_zip${(index - 1 ? '_' + (index - 1) : '')}`;
            const labelShippingCity = `shipping_city${(index - 1 ? '_' + (index - 1) : '')}`;
            const labelShippingProv = `shipping_prov${(index - 1 ? '_' + (index - 1) : '')}`;
            const labelShippingCountry = `shipping_country${(index - 1 ? '_' + (index - 1) : '')}`;

            if(
                !(this.addressesForm.controls as { [key: string]: any })[label]?.value[labelShippingStreet]
                && !(this.addressesForm.controls as { [key: string]: any })[label]?.value[labelShippingNumber]
                && !(this.addressesForm.controls as { [key: string]: any })[label]?.value[labelShippingZip]
                && !(this.addressesForm.controls as { [key: string]: any })[label]?.value[labelShippingCity]
                && !(this.addressesForm.controls as { [key: string]: any })[label]?.value[labelShippingProv]
                && !(this.addressesForm.controls as { [key: string]: any })[label]?.value[labelShippingCountry])
            {
                continue;
            }
            
            const countryCode = countryCodesShort.find(country => country.name === (this.addressesForm.controls as { [key: string]: any })[label]?.value[labelShippingCountry])?.code;

            (tmpCustomer as {[key: string]: any})[labelShippingStreet] = (this.addressesForm.controls as { [key: string]: any })[label]?.value[labelShippingStreet] || '';
            (tmpCustomer as {[key: string]: any})[labelShippingNumber] = (this.addressesForm.controls as { [key: string]: any })[label]?.value[labelShippingNumber] || '';
            (tmpCustomer as {[key: string]: any})[labelShippingZip] = (this.addressesForm.controls as { [key: string]: any })[label]?.value[labelShippingZip] || '';
            (tmpCustomer as {[key: string]: any})[labelShippingCity] = (this.addressesForm.controls as { [key: string]: any })[label]?.value[labelShippingCity] || '';
            (tmpCustomer as {[key: string]: any})[labelShippingProv] = (this.addressesForm.controls as { [key: string]: any })[label]?.value[labelShippingProv] || '';
            (tmpCustomer as {[key: string]: any})[labelShippingCountry] = countryCode || '';   
        }

        // OptionsForm
        const { payments_allowed, default_pricelist, get_source, external_id, payment_token, send_commercials, disable_mail_receipts } = this.optionsForm.controls;

        tmpCustomer.payments_allowed = JSON.stringify(payments_allowed?.value) ?? null;
        tmpCustomer.default_pricelist = default_pricelist?.value ?? [];
        tmpCustomer.get_source = get_source?.value ?? null;
        tmpCustomer.external_id = external_id?.value ?? null;
        tmpCustomer.payment_token = payment_token?.value ?? "Presente";
        tmpCustomer.send_commercials = send_commercials?.value ?? null;
        tmpCustomer.disable_mail_receipts = disable_mail_receipts?.value ?? null;

        if (tmpCustomer.company_name && !tmpCustomer.first_name && !tmpCustomer.last_name) {
            delete tmpCustomer.first_name;
            delete tmpCustomer.last_name;
            delete tmpCustomer.gender;
            delete tmpCustomer.birthdate;
            delete tmpCustomer.mobile;
            delete tmpCustomer.phone;
        } else if (!tmpCustomer.company_name && tmpCustomer.first_name && tmpCustomer.last_name) {
            delete tmpCustomer.company_name;
        }

        if (tmpCustomer.birthdate) {
            try {
                tmpCustomer.birthdate = tmpCustomer.birthdate;
            } catch (err) {
                delete tmpCustomer.birthdate;
            }
        }

        if (!tmpCustomer.sdi_code || tmpCustomer.sdi_code.trim() === '') {
            delete tmpCustomer.sdi_code;
        }

        const lotteryCheckDone = await this.lotteryCheck(tmpCustomer);
        const fidelityCheckDone = await this.fidelityCheck(tmpCustomer);

        if(!lotteryCheckDone || !fidelityCheckDone) {
            return;
        }

        const functionToCall = this.isEditingCustomer() ? 'putOneOfflineFirst' : 'postOneOfflineFirst';

        try {
            const result = await this.entityManagerService.customers[functionToCall](tmpCustomer);

            if(this.isCreatingCustomer()) {
                this.customers.push(result);
                this.selectCustomer(result);
            } else if (this.isEditingCustomer()) {
                this.getCustomers();
                this.getShippingAddresses(result);
                this.currentCustomer = result;
                this.backToSearch();
                this.searchCustomers();
            }

            this.cancelAddCustomer();
        } catch(err: any) {
            this.alertDialogService.openDialog({ data: { messageLabel: err } })
        }
    };

    async selectCustomer(customer: Customers) {
        if (!customer.id) {
            if (!this.isQuickAdding) {
                this.isQuickAdding = true;
                try {
                    const result = await this.entityManagerService.customers.postOneOnline(customer);
                    Object.assign(customer, result);
                } finally {
                    this.isQuickAdding = false;
                }
            } else {
                return;
            }
        }

        this.customerPoints = null;
        this.radioListPrizes = [];
        this.getShippingAddresses(customer);
        this.getCustomerCredit(customer);
        this.getPointsForCustomer(customer);
        this.getPrizes();
        this.createOptionsForm();
        this.searchEnabled = false;
        this.context = "select";
        this.currentCustomer = customer;
        this.positionDeselectButton();
    }

    positionDeselectButton() {
        const title = document.querySelector('[title]') as HTMLElement;
        if (title) {
            title.style.position = "relative";
        }
    }

    createOptionsForm(){
        this.optionsForm = new CustomFormGroup<CustomersForm & { get_source: any }>({
            payments_allowed: new CustomFormControl(
                    this.currentCustomer && this.currentCustomer.payments_allowed && this.currentCustomer.payments_allowed.length > 0 ? JSON.parse(this.currentCustomer.payments_allowed) : null, {}, {...new CustomFormControlProps(), inputType: 'selectMultiple', inputChoices: this.paymentMethods?.map(({ id, name }) => ({ value: `${id}`, key: name })) || []}),
                    default_pricelist: new CustomFormControl(this.currentCustomer?.default_pricelist || null, {}, {...new CustomFormControlProps(), inputType: 'select', inputChoices: this.priceLists?.map(({ id, name }) => ({ value: `${id}`, key: name })) || []}),
                    get_source: new CustomFormControl({value: ((this.currentCustomer?.source_store || "") + (this.currentCustomer?.source_channel ? " (" + this.currentCustomer?.source_channel + ")" : "")) || null,disabled: true}),
                    external_id: new CustomFormControl({ value: this.currentCustomer?.external_id || null, disabled: true }),
                    payment_token: new CustomFormControl({ value: this.currentCustomer?.payment_token || "Presente", disabled: true }),
                    send_commercials: new CustomFormControl(this.currentCustomer?.send_commercials || null,{}, {...new CustomFormControlProps(),inputType: 'checkbox'}
                ),
            disable_mail_receipts: new CustomFormControl(
                this.currentCustomer?.disable_mail_receipts || null, {}, {...new CustomFormControlProps(), inputType: 'checkbox'}
            ),
        });
    }

    patchValueAddressForm(shippingAddresses: ShippingAddresses[]) {
        if(!shippingAddresses) return;

        shippingAddresses.forEach((shippingAddress, index) => {
            if(shippingAddress.idx === -1) return;

            const label = `shippingForm${index - 1 ? '_' + (index - 1) : ''}`
            const labelShippingStreet = `shipping_street${(index - 1 ? '_' + (index - 1) : '')}`
            const labelShippingNumber = `shipping_number${(index - 1 ? '_' + (index - 1) : '')}`
            const labelShippingZip = `shipping_zip${(index - 1 ? '_' + (index - 1) : '')}`
            const labelShippingCity = `shipping_city${(index - 1 ? '_' + (index - 1) : '')}`
            const labelShippingProv = `shipping_prov${(index - 1 ? '_' + (index - 1) : '')}`
            const labelShippingCountry = `shipping_country${(index - 1 ? '_' + (index - 1) : '')}`

            if (shippingAddress && shippingAddress.address) {
                (this.addressesForm.controls as { [key: string]: any })[label]?.controls[labelShippingStreet]?.patchValue(shippingAddress.address.shipping_street || '');
                (this.addressesForm.controls as { [key: string]: any })[label]?.controls[labelShippingNumber]?.patchValue(shippingAddress.address.shipping_number || '');
                (this.addressesForm.controls as { [key: string]: any })[label]?.controls[labelShippingZip]?.patchValue(shippingAddress.address.shipping_zip || '');
                (this.addressesForm.controls as { [key: string]: any })[label]?.controls[labelShippingCity]?.patchValue(shippingAddress.address.shipping_city || '');
                (this.addressesForm.controls as { [key: string]: any })[label]?.controls[labelShippingProv]?.patchValue(shippingAddress.address.shipping_prov || '');
                (this.addressesForm.controls as { [key: string]: any })[label]?.controls[labelShippingCountry]?.patchValue(countryCodesShort.find(country => country.code === shippingAddress.address.shipping_country)?.name || '');
            }    
        })
    }

    editCustomer() {
        this.context = "edit";
        let customerToEdit = JSON.parse(JSON.stringify(this.currentCustomer));
        this.parseCustomerData(customerToEdit);
        this.dataCustomerTemplate = customerToEdit;
        this.currentShippingAddress = 1;
        this.loadShippingAddress(this.currentShippingAddress);
        this.previousContext = this.context;
        this.addEditCustomerForm.reset();
        this.addEditCustomerForm = this.declareForm(this.dataCustomerTemplate);
        this.getShippingAddresses(this.currentCustomer);
        this.createOptionsForm();
    };

    deselectCurrentCustomer() {
        this.context = "search";
        this.currentCustomer = null;
        this.searchEnabled = true;
        this.searchForCustomer.reset();
        this.getCustomers();
        this.performSearch();
        this.addressesForm.reset();
        this.optionsForm.reset();
    }

    backToSearch() {
        if(this.isEditingCustomer() && this.previousContext === "open") {
            this.context = 'open';
        } else if (this.isEditingCustomer()) {
            this.context = "select";
            this.positionDeselectButton(); 
        } else {
            this.context = 'open';
        }
    }

    isCustomerSelected() {
        return (this.currentCustomer && (this.currentCustomer.id || this.currentCustomer.customer_id));
    };

    async lotteryCheck(tmpCustomer?: Customers) {
        if(!tmpCustomer?.lottery_code || tmpCustomer.lottery_code === this.currentCustomer.lottery_code) {
            return true;
        }

        const answer = await this.confirmDialogService.openDialog({
            data: {
                messageLabel: this.translateService.instant('APPLICATION.ADD_SELECT_CUSTOMER.CONFIRM_LOTTERY_CODE_CHANGE')
            }
        });

        return !!answer;
    }

    async fidelityCheck(tmpCustomer?: Customers) {
        if(!tmpCustomer?.fidelity) {
            return true;
        }

        if(this.connection.isOffline()) {
            tmpCustomer.fidelity = this.currentCustomer.fidelity;
            return true;
        }

        try {
            const results = await this.entityManagerService.customers.fetchCollectionOnline({ fidelity: tmpCustomer.fidelity, pagination: false }) as Customers[];

            // Check is valid if there are no customers with this fidelity or the customer we are editing is present in the results
            if(!results.length || (!this.isCreatingCustomer() && results.some((customer) => customer.id === tmpCustomer.id))) {
                return true;
            }
        } catch (e) {
            //Do nothing
        }

        return false;
    }
    
    declareForm(customer?: Customers & { type?: string }): CustomFormGroup<CustomForm<GeneralFormFields>> {
        const countryCodes = countryCodesShort.map(({code,name})=>({key:code,value: name}));

        let customFields: CustomFormGroup<CustomForm<CustomTypesType>>[] = [];

        this.customFields?.map((field) => {
            const customTypeKey = `custom_${field.idx}`;

            customFields.push(
                new CustomFormGroup<CustomForm<CustomTypesType>>({
                    [customTypeKey]: new CustomFormControl('', {}, {...new CustomFormControlProps(), inputType:'text', label: field.name || '', id:'input-custom_field_'+field.idx, class:'tw-w-1/3'})}, {}, {...new CustomFormGroupProps(), label:'', classLabel:'tw-text-xl tw-py-2 tw-ml-2', class:"tw-w-1/3"})
            )
        });

        const form = new CustomFormGroup<CustomForm<GeneralFormFields>>({
            client_data: new CustomFormGroup<CustomForm<ClientDataType>>({
                company_name: new CustomFormControl(customer?.company_name || '', {}, {...new CustomFormControlProps(), inputType:'text', label:'CUSTOMERS.SEARCH_DIALOG.FORM.COMPANY_NAME', id:'input-company_name', class:'tw-w-1/3'}),
                first_name: new CustomFormControl(customer?.first_name || '', {}, {...new CustomFormControlProps(), inputType:'text', label:'CUSTOMERS.SEARCH_DIALOG.FORM.FIRST_NAME', id:'input-first_name', class:'tw-w-1/3'}),
                last_name: new CustomFormControl(customer?.last_name || '', {}, {...new CustomFormControlProps(), inputType:'text', label:'CUSTOMERS.SEARCH_DIALOG.FORM.LAST_NAME', id:'input-last_name', class:'tw-w-1/3'}),
                birthdate: new CustomFormControl(customer?.birthdate || '', {}, {...new CustomFormControlProps(), inputType:'date', label:'CUSTOMERS.SEARCH_DIALOG.FORM.BIRTHDATE', id:'input-birthdate', class:'tw-w-1/3'}),
                tax_code: new CustomFormControl(customer?.tax_code || '', {}, {...new CustomFormControlProps(), inputType:'text', label:'CUSTOMERS.SEARCH_DIALOG.FORM.TAX_CODE', id:'input-tax_code', class:'tw-w-1/3'}),
                vat_code: new CustomFormControl(customer?.vat_code || '', {}, {...new CustomFormControlProps(), inputType:'text', label:'CUSTOMERS.SEARCH_DIALOG.FORM.VAT_CODE', id:'input-vat_code', class:'tw-w-1/3'}),
                sdi_code: new CustomFormControl(customer?.sdi_code || '', {}, {...new CustomFormControlProps(), inputType:'text', label:'CUSTOMERS.SEARCH_DIALOG.FORM.SDI_CODE', id:'input-sdi_code', class:'tw-w-1/3'}),
                lottery_code: new CustomFormControl(customer?.lottery_code || '', {validators:[Validators.minLength(8), Validators.maxLength(8), Validators.pattern(/^[a-zA-Z0-9_]*$/)]}, {...new CustomFormControlProps(), inputType:'text', label:'CUSTOMERS.SEARCH_DIALOG.FORM.LOTTERY_CODE', id:'input-lottery_code', class:'tw-w-1/3' }),
                country: new CustomFormControl(countryCodesShort.find(country => country.code === customer?.country)?.name || '', {}, {...new CustomFormControlProps(), inputType:'autocomplete', inputChoices: countryCodes, label:'CUSTOMERS.SEARCH_DIALOG.FORM.COUNTRY', id:'input-country', class:'tw-w-1/3'}),
                notes: new CustomFormControl(customer?.notes || '', {}, {...new CustomFormControlProps(), inputType:'textarea', label:'CUSTOMERS.SEARCH_DIALOG.FORM.NOTE', id:'input-notes', class:'tw-w-1/3 tw-pb-4', matElementClass:'tw-min-h-[19px] tw-h-[25px]'}),
                custom_type: new CustomFormControl(customer?.custom_type || '', {}, {...new CustomFormControlProps(), inputType:'select', inputChoices: this.customTypes ?? [], label:'CUSTOMERS.SEARCH_DIALOG.FORM.CUSTOM_TYPE', id:'input-custom_type', class:'tw-w-1/3'})
            }, {}, {...new CustomFormGroupProps(), label:'CUSTOMERS.DETAILS.CLIENT_DATA', classLabel:'tw-text-xl tw-py-2 tw-ml-2', class:"tw-w-full"}),

            gender: new CustomFormGroup<CustomForm<GenderType>>({
                genders: new CustomFormControl(customer?.gender || '', {}, {... new CustomFormControlProps(), inputType: 'radio', inputChoices: this.genders, label:'', id:'input-genders', class:'tw-w-1/3 tw-max-w-[280px]'}),
            }, {}, {...new CustomFormGroupProps(), label:'CUSTOMERS.DETAILS.GENDER', classLabel:'tw-text-xl tw-py-2 tw-ml-2', class:"tw-w-full"}),

            contacts: new CustomFormGroup<CustomForm<ContactsType>>({
                phone: new CustomFormControl(customer?.phone || '', { validators: [Validators.pattern("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$")]}, {...new CustomFormControlProps(), inputType:'text', label:'CUSTOMERS.DETAILS.HOME_PHONE', id:'input-phone', class:'tw-w-1/3'}),
                mobile: new CustomFormControl(customer?.mobile || '', { validators: [Validators.pattern("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$")]}, {...new CustomFormControlProps(), inputType:'text', label:'CUSTOMERS.DETAILS.CELLULAR', id:'input-mobile', class:'tw-w-1/3'}),
                email: new CustomFormControl(customer?.email || '', { validators: [Validators.email] }, {...new CustomFormControlProps(), inputType:'email', label:'CUSTOMERS.DETAILS.EMAIL', id:'input-email', class:'tw-w-1/3'}),
                email_pec: new CustomFormControl(customer?.email_pec || '', { validators: [Validators.email]}, {...new CustomFormControlProps(), inputType:'email', label:'CUSTOMERS.DETAILS.PEC', id:'input-email_pec', class:'tw-w-1/3'}),
                facebook: new CustomFormControl(customer?.facebook || '', {validators: [Validators.pattern("(?:https?:\/\/)?(?:www.)?facebook.com\/?([a-zA-Z0-9\.\_\-]+)?\/")]}, {...new CustomFormControlProps(), inputType:'text', label:'CUSTOMERS.DETAILS.FACEBOOK', id:'input-facebook', class:'tw-w-1/3'}),
                twitter: new CustomFormControl('', {validators: [Validators.pattern("^(http\:\/\/|https\:\/\/)?(?:www\.)?twitter\.com\/(?:#!\/)?@?([^\?#]*)(?:[?#].*)?$")]}, {...new CustomFormControlProps(), inputType:'text', label:'CUSTOMERS.DETAILS.TWITTER', id:'input-twitter', class:'tw-w-1/3'}),
                linkedin: new CustomFormControl('', {validators: [Validators.pattern("^(http\:\/\/|https\:\/\/)?(?:www\.)?linkedin\.com\/(?:#!\/)?@?([^\?#]*)(?:[?#].*)?$")]}, {...new CustomFormControlProps(), inputType:'text', label:'CUSTOMERS.DETAILS.LINKEDIN', id:'input-linkedin', class:'tw-w-1/3'}),
                instagram: new CustomFormControl('', {validators: [Validators.pattern("(?:https?:\/\/)?(?:www.)?instagram.com\/?([a-zA-Z0-9\.\_\-]+)?\/")]}, {...new CustomFormControlProps(), inputType:'text', label:'CUSTOMERS.DETAILS.INSTAGRAM', id:'input-instagram', class:'tw-w-1/3'}),            
            }, {}, {...new CustomFormGroupProps(), label:'CUSTOMERS.DETAILS.CONTACTS', classLabel:'tw-text-xl tw-py-2 tw-ml-2', class:"tw-w-full"}),

            fidelization: new CustomFormGroup<CustomForm<FidelizationType>>({
                fidelity: new CustomFormControl(customer?.fidelity || '', { validators: [Validators.maxLength(255)]}, {...new CustomFormControlProps(), inputType:'text', label:'CUSTOMERS.DETAILS.FIDELITY', id:'input-fidelity', class:'tw-w-1/3'}),
                discount_perc: new CustomFormControl(customer?.discount_perc || '', { validators: [Validators.min(0), Validators.max(100)]}, {...new CustomFormControlProps(), inputType:'number', label:'CUSTOMERS.DETAILS.DISCOUNT_PERCENTAGE', id:'input-discount-percentage', class:'tw-w-1/3'}),
            }, {}, {...new CustomFormGroupProps(), label:'CUSTOMERS.DETAILS.FIDELITY', classLabel:'tw-text-xl tw-py-2 tw-ml-2', class:"tw-w-full"}),

            customTypes: new CustomFormArray<CustomFormGroup<CustomForm<CustomTypesType>>>(customFields,
                {}, {...new CustomFormGroupProps(), label:'CUSTOMERS.DETAILS.CUSTOM_FIELDS', classLabel:'tw-text-xl tw-py-2 tw-ml-2', class:"tw-w-1/3"}),

            invoice_address: new CustomFormGroup<CustomForm<InvoiceAddressType>>({
                billing_street: new CustomFormControl(customer?.billing_street || '', { validators: [Validators.maxLength(255)]}, {...new CustomFormControlProps(), inputType:'text', label:'CUSTOMERS.DETAILS.STREET_NAME', id:'input-billing_street', class:'tw-w-1/3'}),
                billing_number: new CustomFormControl(customer?.billing_number || '', { validators: [Validators.maxLength(25)]}, {...new CustomFormControlProps(), inputType:'text', label:'CUSTOMERS.DETAILS.STREET_NUMBER', id:'input-billing_number', class:'tw-w-1/3'}),
                billing_zip: new CustomFormControl(customer?.billing_zip || '', { validators: [Validators.maxLength(10)]}, {...new CustomFormControlProps(), inputType:'text', label:'CUSTOMERS.DETAILS.ZIP_CODE', id:'input-billing_zip', class:'tw-w-1/3'}),
                billing_city: new CustomFormControl(customer?.billing_city || '', { validators: [Validators.maxLength(255)]}, {...new CustomFormControlProps(), inputType:'text', label:'CUSTOMERS.DETAILS.CITY', id:'input-billing_city', class:'tw-w-1/3'}),
                billing_prov: new CustomFormControl(customer?.billing_prov || '', { validators: [Validators.min(0), Validators.max(255)]}, {...new CustomFormControlProps(), inputType:'text', label:'CUSTOMERS.DETAILS.COUNTY', id:'input-billing_prov', class:'tw-w-1/3'}),
                billing_country: new CustomFormControl(countryCodesShort.find(country => country.code === customer?.billing_country)?.name || '', {}, {...new CustomFormControlProps(), inputType:'autocomplete', inputChoices: countryCodes, label:'CUSTOMERS.DETAILS.COUNTRY', id:'input-billing_country', class:'tw-w-1/3'}),
            }, {}, {...new CustomFormGroupProps(), label:'CUSTOMERS.DETAILS.INVOICE_ADDRESS', classLabel:'tw-text-xl tw-py-2 tw-ml-2', class:"tw-w-full"}),
        });

        return form;
    }

    get billing_street() {
        return this.addressesForm?.controls.currentShippingAddress.billing_street;
    }

    get billing_number() {
        return this.addressesForm?.controls.currentShippingAddress.billing_number;
    }

    get billing_zip() {
        return this.addressesForm?.controls.currentShippingAddress.billing_zip;
    }

    get billing_city() {
        return this.addressesForm?.controls.currentShippingAddress.billing_city;
    }

    get billing_prov() {
        return this.addressesForm?.controls.currentShippingAddress.billing_prov;
    }

    get billing_country() {
        return this.addressesForm?.controls.currentShippingAddress.billing_country;
    }

    shipping_street(n = 1) {
        return this.addressesShippingForm(n)?.get('shipping_street' + (n - 1 ? '_' + (n - 1) : '')) as FormControl<string | null | undefined> | undefined;
    }

    shipping_number(n = 1) {
        return this.addressesShippingForm(n)?.get('shipping_number' + (n - 1 ? '_' + (n - 1) : '')) as FormControl<string | null | undefined> | undefined;
    }

    shipping_zip(n = 1) {
        return this.addressesShippingForm(n)?.get('shipping_zip' + (n - 1 ? '_' + (n - 1) : '')) as FormControl<string | null | undefined> | undefined;
    }

    shipping_city(n = 1) {
        return this.addressesShippingForm(n)?.get('shipping_city' + (n - 1 ? '_' + (n - 1) : '')) as FormControl<string | null | undefined> | undefined;
    }

    shipping_prov(n = 1) {
        return this.addressesShippingForm(n)?.get('shipping_prov' + (n - 1 ? '_' + (n - 1) : '')) as FormControl<string | null | undefined> | undefined;
    }

    shipping_country(n = 1) {
        return this.addressesShippingForm(n)?.get('shipping_country' + (n - 1 ? '_' + (n - 1) : '')) as FormControl<string | null | undefined> | undefined;
    }

    addressesShippingForm(n = 1) {
        return this.addressesForm?.get('shippingForm' + (n - 1 ? '_' + (n - 1) : '')) as AddressesFormGroups['shippingForm'] | undefined;
    }

    cancelSearch() {
        this.searchEnabled = false;
        this.searchText = "";
    };

    validateTaxCode(taxCodeField: CustomFormControl) {
        taxCodeField.setErrors({'taxCode': !this.dataCustomerTemplate.tax_code || !this.isPrivateCustomer() || this.fiscalUtilsService.checkTaxCode(this.dataCustomerTemplate.tax_code) ? {'taxCode': true} : {'taxCode': false}});
    };

    onCustomerTypeChange() {
        // this.validateTaxCode(form.tax_code);
    };

    setupCustomFields() {
        for(let i = 1; i <= 9; i++) {
            const fieldName = this.checkManager.getSetting(`customers.custom_field_${i}`);

            if(fieldName) {
                this.customFields.push({
                    idx: i,
                    name: fieldName
                });
            }
        }
    }

    isOpenDialog(){
        return this.context === "open"
    }

    isSearchingCustomer() {
        return this.context === "search";
    }

    isSelectingCustomer() {
        return this.context === "select";
    };

    isCreatingCustomer() {
        return this.context === "add";
    };

    isEditingCustomer() {
        return this.context === "edit";
    };

    async getShippingAddresses(currentCustomer: any) {
        let foundCustomer: any = null;
        if(currentCustomer.id) {
            foundCustomer = await this.entityManagerService.customers.fetchOneOffline(+currentCustomer.id);
        }

        if(!foundCustomer) {
            return;
        }

        //Check if the customer has multiple shipping addresses and, if that's the case, ask which one is to use
        if (this.askShippingAddress) {
            let shippingAddressesCount = 0;
            this.shippingAddresses = [{
                address: {
                    shipping_prov: null,
                    shipping_street: null,
                    shipping_number: null,
                    shipping_zip: null,
                    shipping_city: null,
                    shipping_country: null,
                    shipping_address_id: null
                },
                name: this.translateService.instant('CASHREGISTER.ACTIVE_SALE_MODEL.NO_DELIVERY'),
                idx: -1
            }];

            
            for (let idx = 0; idx < 10; idx++) {
                const slot = idx === 0 ? "" : `_${idx}` as ("" | "_2" | "_3" | "_4" | "_5" | "_6" | "_7" | "_8" | "_9");

                if (!foundCustomer[`shipping_street${slot}`]) {
                    continue;
                }

                shippingAddressesCount++;

                if (idx === 0) { //Set shipping_address_id to 0 in case the user cancels the selection afterwards
                    foundCustomer.shipping_address_id = 0;
                }

                const addressObj: Address = {
                    shipping_prov: foundCustomer[`shipping_prov${slot}`],
                    shipping_street: foundCustomer[`shipping_street${slot}`],
                    shipping_number: foundCustomer[`shipping_number${slot}`],
                    shipping_zip: foundCustomer[`shipping_zip${slot}`],
                    shipping_city: foundCustomer[`shipping_city${slot}`],
                    shipping_country: foundCustomer[`shipping_country${slot}`],
                    shipping_address_id: idx
                };

                if (idx) {
                    delete foundCustomer[`shipping_prov${slot}`];
                    delete foundCustomer[`shipping_street${slot}`];
                    delete foundCustomer[`shipping_number${slot}`];
                    delete foundCustomer[`shipping_zip${slot}`];
                    delete foundCustomer[`shipping_city${slot}`];
                    delete foundCustomer[`shipping_country${slot}`];
                }

                let addressStr = `${addressObj.shipping_street} ${addressObj.shipping_number || ''} ${addressObj.shipping_zip || ''} ${addressObj.shipping_city || ''} ${addressObj.shipping_prov ? `(${addressObj.shipping_prov})` : ''} `;
                const isDisabled = this.checkManager.getPreference('orders.allow_street_only_shipping_addresses') ? !addressObj.shipping_street : !addressObj.shipping_street || !addressObj.shipping_city;

                if (isDisabled) {
                    addressStr += " (Incompleto)";
                }

                this.shippingAddresses.push({
                    address: addressObj,
                    name: addressStr,
                    $disabled: isDisabled
                });
            }

            setTimeout(() => {
                this.patchValueAddressForm(this.shippingAddresses);
            },500)
        }
    }

    getInfoCustomer(){
        let customerCaption = '';

        if (this.currentCustomer.company_name) {
            customerCaption += this.currentCustomer.company_name + ' ';
        }

        if(this.currentCustomer.first_name){
            customerCaption += this.currentCustomer.first_name    
        }

        if(this.currentCustomer.last_name){
            customerCaption += ' ' + this.currentCustomer.last_name;
        }

        this.deselectButtonDistance = customerCaption.length * 10;
        return customerCaption;
    }

    async getPrizes(){
        if(!this.activeCampaign) {
            return
        }

        const prizes = await this.entityManagerService.prizes.fetchCollectionOffline({ campaign_id: this.activeCampaign.id });
        const now = TilbyDatePipe.date();

        const giftPrizes = prizes.filter((prize) => prize.type === 'gift');
        const giftsMap: Partial<Record<string, Items>> = {};

        for (let giftPrize of giftPrizes) {
            const items = await this.entityManagerService.items.fetchCollectionOffline({ sku: giftPrize.item_sku });

            if (items.length) {
                giftsMap[giftPrize.item_sku || ''] = items[0];
            }
        }

        const eligiblePrizes = prizes.filter((prize) => (((this.customerPoints && this.customerPoints.points || 0) >= prize.points) && (!prize.valid_from || TilbyDatePipe.compareDates(prize.valid_from.toString(), now) !== '>' && (!prize.valid_to || TilbyDatePipe.compareDates(prize.valid_to.toString(), now) !== '<'))));

        if (eligiblePrizes?.length) {
            this.radioListPrizes = eligiblePrizes.map((prize) => {
                const prizeName = [
                    this.translateService.instant('FIDELITY.PRIZES.PRIZE_DIALOG.PRIZE_POINTS', { points: prize.points }),
                    prize.name
                ];

                switch (prize.type) {
                    case 'discount_fix':
                        prizeName.push(this.translateService.instant('FIDELITY.PRIZES.PRIZE_DIALOG.DISCOUNT', { value: this.injector.get(TilbyCurrencyPipe).transform(prize.discount_amount || 0) }));
                        break;
                    case 'discount_perc':
                        prizeName.push(this.translateService.instant('FIDELITY.PRIZES.PRIZE_DIALOG.DISCOUNT', { value: this.injector.get(util).round(prize.discount_amount) + '%' }));
                        break;
                    case 'gift':
                        prizeName.push(this.translateService.instant('FIDELITY.PRIZES.PRIZE_DIALOG.GIFT', { value: giftsMap?.[prize.item_sku || '']?.name ?? '' }));
                        break;
                    default: break;
                }

                return {
                    name: prizeName.join(' - '),
                    prize: prize
                };
            });
        }
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}

@Injectable({
    providedIn: "root",
})
export class AddSelectCustomerDialogService extends BaseDialogService {
    private isMobile = mobileCheck();
    private readonly dialogRef = inject(MatDialog);

    public openDialog(config?: NonNullableConfigData<AddSelectCustomerDialogData>): Promise<OutputCustomerDialog> {
        const data: AddSelectCustomerDialogData = config?.data||{ currentCustomer: undefined };
        const configEdited: NonNullableConfigData<AddSelectCustomerDialogData> = {
            ...config,
            ...this.switchMobileDesktopDimensions({width: '830px', height: this.isMobile ? '100vh' : ''}, { fullScreenForMobile: true }),
            disableClose: true,
            data,
        };
        return lastValueFrom(
            this.dialogRef.open(AddSelectCustomerDialogComponent, configEdited).afterClosed()
        );
    }
}