import angular from 'angular';
import _ from 'lodash';
import { SclDictionary } from 'app/libs/SclDictionary';

angular.module('items').controller('ItemsDetailsCtrl', ItemsDetailsCtrl).directive("ngUploadChange", function () {
    return {
        scope: {
            ngUploadChange: "&"
        },
        link: function ($scope, $element, $attrs) {
            $element.on("change", function (event) {
                $scope.$apply(function () {
                    $scope.ngUploadChange({ $event: event });
                });
            });

            $scope.$on("$destroy", function () {
                $element.off();
            });
        }
    };
});

ItemsDetailsCtrl.$inject = ["$scope", "$rootScope", "$state", "$stateParams", "$filter", "$timeout", "$translate", "categories", "departments", "vat", "components", "allergens", "suppliers", "channels", "entityManager", "checkManager", "item", "itemLastMovements", "itemStock", "orderPrinters", "confirmDialog", "alertDialog", "promptDialog", "itemSelector", "util", "leanPMS", "leanPMSApi", "errorsLogger", "restManager", "automateItemsDialogService"];

function ItemsDetailsCtrl($scope, $rootScope, $state, $stateParams, $filter, $timeout, $translate, categories, departments, vat, components, allergens, suppliers, channels, entityManager, checkManager, item, itemLastMovements, itemStock, orderPrinters, confirmDialog, alertDialog, promptDialog, itemSelector, util, leanPMS, leanPMSApi, errorsLogger, restManager, automateItemsDialogService) {
    $scope.itemColors = [{
        value: null,
        name: $translate.instant('ITEMS.DETAILS.COLOR_NONE')
    }, {
        value: 'D1C4E9',
        name: $translate.instant('ITEMS.DETAILS.COLOR_PURPLE')
    }, {
        value: 'C5CAE9',
        name: $translate.instant('ITEMS.DETAILS.COLOR_INDIGO')
    }, {
        value: 'B3E5FC',
        name: $translate.instant('ITEMS.DETAILS.COLOR_LIGHT_BLUE')
    }, {
        value: 'B2DFDB',
        name: $translate.instant('ITEMS.DETAILS.COLOR_WATER_GREEN')
    }, {
        value: 'C8E6C9',
        name: $translate.instant('ITEMS.DETAILS.COLOR_GREEN')
    }, {
        value: 'FFECB3',
        name: $translate.instant('ITEMS.DETAILS.COLOR_AMBER')
    }, {
        value: 'FFE0B2',
        name: $translate.instant('ITEMS.DETAILS.COLOR_ORANGE')
    }, {
        value: 'FFCDD2',
        name: $translate.instant('ITEMS.DETAILS.COLOR_RED')
    }];

    const exits = _.toInteger(checkManager.getPreference('orders.exits')) || 5;

    Object.assign($scope, {
        allergens: allergens,
        categories: categories,
        currentSection: 'general',
        departments: departments,
        imagesDirty: false,
        itemLastMovements: itemLastMovements,
        isStockAvailable: checkManager.isModuleEnabled('stock'),
        maximumIndexes: _.range(1, 21),
        exits: [],
        priceLists: [],
        optionNames: [],
        orderPrinters: orderPrinters,
        showPrintersTab: checkManager.isModuleEnabled('orders') || !!checkManager.getPreference('cashregister.enable_print_order'),
        vat: vat,
        topbar_context: {
            showcaseView: 'itemseditmode',
            itemName: function() { return $scope.item.name; }
        },
        isAutomateItem: checkManager.isFunctionEnabledOptin('items.automate_item')
    });

    for(let i = 1; i <= exits; i++) {
        $scope.exits.push(i);
    }

    var itemsByUuid = {};
    var variationLinkableItems = [];
    var bomItems = [];
    var bomItemsMap = {};
    var bomRawMaterials = [];
    var bomRawMaterialsMap = {};

    var printersById = _.keyBy(orderPrinters, 'id');
    var printersByCategory = {};

    _.forEach(orderPrinters, function(printer) {
        _.forEach(printer.categories, function(category) {
            if(_.isNil(printersByCategory[category.id])) {
                printersByCategory[category.id] = [];
            }

            printersByCategory[category.id].push(printer);
        });
    });

    for(let idx = 1; idx <= 10; idx++) {
        if(idx === 1 || !checkManager.getPreference(`price_list_${idx}_hide`)) {
            let priceListName = checkManager.getPreference(`price_list_${idx}_name`) || `${$translate.instant('ITEMS.DETAILS.PRICE_LIST_START')} ${idx}`;

            $scope.priceLists.push({
                index: idx,
                departmentLabel: `${$translate.instant('ITEMS.DETAILS.DEPARTMENT')} ${priceListName}`,
                departmentFieldName: idx === 1 ? 'department_id' : `department${idx}_id`,
                priceFieldName: `price${idx}`,
                value: priceListName,
                required: idx === 1
            });
        }
    }

    for(let idx = 1; idx <= 4; idx++) {
        let optionName = checkManager.getPreference(`items.option${idx}_name`);

        if(optionName) {
            $scope.optionNames.push({
                index: idx,
                targetField: `option${idx}_value`,
                fieldName: optionName
            });
        }
    }

    $scope.showOptions = !_.isEmpty($scope.optionNames);

    Object.defineProperty($scope, 'itemForms', { get: function() { return [$scope.generalForm, $scope.channelsForm, $scope.printersForm, $scope.inventoryForm, $scope.detailsForm, $scope.variationsForm, $scope.stockForm, $scope.integrationsForm]; } });

    var computeAvailableIndexes = function(v) {
        v.availableIndexes = _(_.range(1,21)).difference(_.map(v.variation_values, 'index')).sortBy(function(n) { return n; } ).value();
    };

    var suppliersDict = new SclDictionary();

    _.forEach(suppliers, function(supplier) {
        suppliersDict.set(supplier.id, supplier);
    });

    $scope.goToSection = function(section) {
        $scope.currentSection = section;
        //Workaround textarea resizing issues
        $timeout(function() { $scope.$broadcast('md-resize-textarea');});
    };

    var parseBomComponentsData = function(bomComponent) {
        if (bomComponent.component_raw_material_id) {
            bomComponent.$type = 'raw_material';
        } else if (bomComponent.component_item_id) {
            bomComponent.$type = 'item';
        } else {
            bomComponent.$type = 'bom_component';
            _.forEach(bomComponent.bom_components, parseBomComponentsData);
        }
    };

    var returnToShowcase = function() {
        $state.go('app.items.showcase', $stateParams.showcaseState);
    };

    if(item) {
        // If the item is being edited, add it to the scope and compute available indexes
        if (item.combinations.length) {
            $scope.combinationsActive = true;

            //Align the combinations to the variations position
            for (const combination of item.combinations) {
                const combinationValues = [];

                for (const variation of item.variations) {
                    const combinationValue = { variation_value: null };

                    //Find the combination_value corresponding to the variation
                    for (const combValue of combination.combination_values) {
                        if (combValue.variation_name === variation.name) {
                            const variationValueIndex = variation.variation_values.findIndex((v) => v.value === combValue.variation_value);

                            if(variationValueIndex !== -1) {
                                combinationValue.variation_value = variationValueIndex;
                            }
                            break;
                        }
                    }

                    combinationValues.push(combinationValue);
                }

                //Assign aligned combination_values
                combination.combination_values = combinationValues;
            }
        } else {
            $scope.combinationsActive = false;
        }

        if (item.stock_type) {
            $scope.isStockActive = true;
        }

        for (const variation of item.variations) {
            computeAvailableIndexes(variation);
        }

        item.$supplier = suppliersDict.get(item.default_supplier_id);

        for (const bomComponent of item.bom_components) {
            parseBomComponentsData(bomComponent);
        }

        for (const printer of item.printers) {
            if(printersById[printer.id]) {
                printersById[printer.id]._enabled = true;
            }
        }

        $scope.item = item;
    } else if (_.isUndefined(item)) {
        // If the item is being created, I need to put some empty arrays for complex entities
        var showcaseCategory = _.get($stateParams, ['showcaseState','itemsQuery','category_id']);

        if(showcaseCategory === 'nocategory') {
            showcaseCategory = null;
        }

        $scope.item = {
            barcodes: [],
            components: [],
            channels: [],
            allergens: [],
            printers: [],
            variations: [],
            combinations: [],
            bom_components: [],
            images: [],
            on_sale: true,
            category_id: showcaseCategory || null
        };
    } else {
        //null case: item is not existing, return to showcase
        alertDialog.show($translate.instant('ITEMS.DETAILS.ITEM_NOT_EXIST'));
        return returnToShowcase();
    }

    $scope.generalShowMore = !(_.isEmpty($scope.item.brand) && _.isEmpty($scope.item.season) && _.isEmpty($scope.item.default_supplier_id));

    $scope.itemImages = [];

    _.forEach($scope.item.images, function(image) {
        $scope.itemImages.push(image);
    });

    if($scope.item.thumbnail) {
        $scope.itemThumbnail = _.find($scope.itemImages, { image_url: $scope.item.thumbnail });

        if(!$scope.itemThumbnail) {
            var image = {
                image_url: $scope.item.thumbnail
            };

            $scope.itemImages.push(image);
            $scope.itemThumbnail = image;
        }
    }

    $scope.itemChannels = _.cloneDeep(channels);
    var itemChannels = _.keyBy($scope.item.channels, 'channel_id');

    _.forEach($scope.itemChannels, function(channel) {
        _.assign(channel, {
            _imgUrl: channel.image_url || "assets/images/channels/" + channel.id + ".png",
            _enabled: itemChannels[channel.id] ? true : false,
            _entId: _.get(itemChannels, [channel.id, 'id']),
            category_id: _.get(itemChannels, [channel.id, 'category_id'], null),
            default_pricelist: _.get(itemChannels, [channel.id, 'default_pricelist'], 1)
        });
    });

    $scope.itemChannels.unshift({
        name: $translate.instant('ITEMS.DETAILS.CASHREGISTER'),
        category_id: $scope.item.category_id,
        _enabled: true,
        _imgUrl: "assets/images/channels/pos.png",
        _readOnly: true
    });

    //Load Stock-management related data
    const reassignComponentUnit = (bomRawMaterialsMap, bomItemsMap, bomComponent) => {
        if (bomComponent.component_raw_material_id) {
            bomComponent.$unit = bomRawMaterialsMap[bomComponent.component_raw_material_id]?.unit;
        } else if (bomComponent.component_item_id) {
            bomComponent.$unit = bomItemsMap[bomComponent.component_item_id]?.unit;
        } else if (Array.isArray(bomComponent.bom_components)) {
            for (const bc of bomComponent.bom_components) {
                reassignComponentUnit(bomRawMaterialsMap, bomItemsMap, bc);
            }
        }
    };

    const loadStockManagementData = async () => {
        //Fetch Stock-management related entities
        const items = await entityManager.items.fetchCollectionOffline();
        const rawMaterials = await entityManager.rawMaterials.fetchCollectionOffline({ stock_type: 'simple' });

        //Prepare items data
        bomItems = _.chain(items)
            .filter((item) => (!item.combinations.length && (item.stock_type === 'simple' || (item.stock_type === 'bom' && item.manual_production)) && item.id !== $scope.item.id))
            .sortBy('name')
            .value();

        bomItemsMap = _.keyBy(bomItems, 'id');

        variationLinkableItems = items.filter((item) => (item.uuid && !item.combinations.length));

        itemsByUuid = _.keyBy(items, 'uuid');

        //Prepare raw materials data
        bomRawMaterials = _.chain(rawMaterials)
            .sortBy('name')
            .value();

        bomRawMaterialsMap = _.keyBy(bomRawMaterials, 'id');

        for (const bomComponent of $scope.item.bom_components) {
            reassignComponentUnit(bomRawMaterialsMap, bomItemsMap, bomComponent);
        }

        for (const variation of $scope.item.variations) {
            for (const variationValue of variation.variation_values) {
                if(variationValue.linked_item_uuid) {
                    variationValue._linkedItemName = itemsByUuid[variationValue.linked_item_uuid]?.name;
                }

                variationValue._images = [];

                if(variationValue.image_url) {
                    variationValue._images.push({ image_url: variationValue.image_url });
                }
            }
        }
    };

    loadStockManagementData();

    $scope.units = [{
        name: $translate.instant('ITEMS.DETAILS.PIECES_FULL'),
        value: $translate.instant('ITEMS.DETAILS.PIECES_SHORT')
    }, {
        name: $translate.instant('ITEMS.DETAILS.METERS_FULL'),
        value: $translate.instant('ITEMS.DETAILS.KILO_METERS_SHORT')
    }, {
        name: $translate.instant('ITEMS.DETAILS.KILO_FULL'),
        value: $translate.instant('ITEMS.DETAILS.KILO_SHORT')
    }, {
        name: $translate.instant('ITEMS.DETAILS.LITRE_FULL'),
        value: $translate.instant('ITEMS.DETAILS.LITRE_SHORT')
    }];

    $scope.getItemPriceString = function(priceList) {
        return $scope.item[priceList] ? '(' + $filter('sclCurrency')($scope.item[priceList]) + ')': '';
    };

    $scope.getDepartmentVat = function(vat) {
        if(vat) {
            if(!vat.value && vat.code) {
                return '(' + $translate.instant('ITEMS.DEPARTMENTS.VAT_EXEMPTION_' + vat.code) + ')';
            } else {
                return '(' + $translate.instant('ITEMS.DETAILS.VAT', {value: vat.value}) + ')';
            }
        } else {
            return '';
        }
    };

    $scope.generalExpand = function() {
        $scope.generalShowMore = true;
    };

    $scope.filterSuppliers = function(searchText) {
        return $filter('filter')(suppliers, searchText);
    };

    $scope.onEnableStock = function() {
        $scope.item.auto_unload = $scope.isStockActive;
        if ($scope.isStockActive && !$scope.item.stock_type) {
            $scope.item.stock_type = 'simple';
        }
    };

    $scope.getItemStock = function() {
        return $scope.combinationsActive || _.isNil(itemStock.stock_quantity) ? '' : '(' + itemStock.stock_quantity + (item.unit ? ' ' + item.unit : '') + ')';
    };

    $scope.updateIndexes = function(variation, index, oldValue, variationValueIndex) {
        // This function is triggered _after_ changing/setting an index,
        // Thus I compute the available indexes and check if the current index can be used. Otherwise I raise an error
        if (_.isNil(index) || _.includes(variation.availableIndexes, index)) {
            computeAvailableIndexes(variation);
        } else {
            alertDialog.show($translate.instant('ITEMS.DETAILS.INDEX_ALREADY_USED'));

            variation.variation_values[variationValueIndex].index = _.toInteger(oldValue) || undefined;
            // If I delete the index from the object, it is displayed/selected anyway in the dropdown menu...
        }
    };

    // Search for ingredients and display chips
    $scope.selectedComponent = null;
    $scope.searchComponent = null;
    $scope.componentSearch = function(query) {
        var createComponentFilter = function createComponentFilter(query) {
            return function filterFn(component) {
                return _.startsWith(_.toLower(component.name), _.toLower(query)) && (_.isEmpty(component.categories) || _.find(component.categories, { id: $scope.item.category_id }));
            };
        };

        return query ? components.filter(createComponentFilter(query)) : [];
    };

    // prepare allergens for checkbox
    $scope.allergens = structuredClone(allergens);
    for(let allergen of $scope.allergens) {
        const found = $scope.item.allergens.find(itemAllergen => itemAllergen.id === allergen.id);
        if (found) {
            allergen.checked = true;
        } else {
            allergen.checked = false;
        }
    }

    $scope.isFormDirty = function() {
        return _.some($scope.itemForms, { $dirty: true });
    };

    $scope.isFormValid = function() {
        return _.every($scope.itemForms, { $valid: true });
    };

    // shows restore button
    $scope.topbar_context.isDirty = function() {
        return ($scope.isFormDirty() || $scope.imagesDirty) && item;
    };

    // enables main save button
    $scope.topbar_context.mainSaveEnabled = function() {
        return ($scope.isFormDirty() || $scope.imagesDirty) && $scope.checkCombinations();
    };

    $scope.topbar_context.restore = function() {
        var message = $translate.instant('ITEMS.DETAILS.UNDO_CHANGES');

        confirmDialog.show(message).then(function(result) {
            if (result) {
                $rootScope.showAppLoader();
                if ($stateParams.id === "new" && $scope.item.id !== undefined) {
                    $timeout(function() {
                        $state.go('app.items.details', { id: $scope.item.id });
                    });
                } else {
                    $timeout(function() {
                        $state.reload("app.items.details");
                    });
                }
            }
        });
    };

    var calculateErrors = function() {
        return _.some($scope.itemForms, function(form) { return form.$error.required; }) ? $translate.instant('FORM_ERRORS.INSERT_REQUIRED_DATA') : $translate.instant('FORM_ERRORS.THERE_ARE_ERRORS');
    };

    const cleanUpStockData = () => {
        const cleanUpBomComponent = (bomComponent) => {
            delete bomComponent.$type;

            if(!Array.isArray(bomComponent.bom_components)) {
                bomComponent.bom_components = [];
            }

            for (const component of bomComponent.bom_components) {
                cleanUpBomComponent(component);
            }
        };

        if ($scope.item.stock_type !== 'bom') {
            $scope.item.bom_components = [];
        } else {
            for (const component of $scope.item.bom_components) {
                cleanUpBomComponent(component);
            }
        }
    };

    const areBomComponentsDifferent = (bomComponents) => {
        const componentsByType = _.groupBy(bomComponents, '$type');

        if (componentsByType['raw_material']) {
            const rawMaterialsById = _.groupBy(componentsByType['raw_material'], 'component_raw_material_id');

            if (_.some(rawMaterialsById, (idGroup) => idGroup.length > 1)) {
                return false;
            }
        }

        if (componentsByType['item']) {
            const itemsById = _.groupBy(componentsByType['item'], 'component_item_id');

            if (_.some(itemsById, (idGroup) => idGroup.length > 1)) {
                return false;
            }
        }

        if (componentsByType['bom_component']) {
            if (!_.every(componentsByType['bom_component'], (bomComponent) => areBomComponentsDifferent(bomComponent.bom_components))) {
                return false;
            }
        }

        return true;
    };

    const isMagoEnabled = () => {
        const integrations_mago_publish_sales = checkManager.getPreference('integrations.mago.publish_sales');
        const integrations_mago_publish_customers = checkManager.getPreference('integrations.mago.publish_customers');
        return integrations_mago_publish_sales || integrations_mago_publish_customers;
    };

    $scope.topbar_context.confirm = async () => {
        if(isMagoEnabled()) {
            alertDialog.show($translate.instant('ITEMS.NO_EDIT_WITH_MAGO_ENABLED'));
            return;
        }

        if (!$scope.isFormValid() || !$scope.checkCombinations()) {
            alertDialog.show(calculateErrors());
            return;
        }

        // errors of AvailableIndexes
        const errors = [];

        // Check if there is any duplicate index
        for (const variation of $scope.item.variations) {
            const indexValues = variation.variation_values.map(v => v.index).filter(index => index !== undefined);

            if (_.uniq(indexValues).length !== indexValues.length) {
                errors.push(variation.name);
            }
        }

        if (errors.length) {
            alertDialog.show($translate.instant('ITEMS.DETAILS.VARIATIONS_WITH_SAME_INDEX', { value: errors.join(',') }));
            return;
        }

        const hasEmptyBomComponents = $scope.item.bom_components.some((bomComponent) => (bomComponent.$type === 'bom_component' && !bomComponent.bom_components.length));

        if (hasEmptyBomComponents) {
            alertDialog.show($translate.instant('ITEMS.DETAILS.BOM_COMPONENT_WITHOUT_SUB_BOM_COMPONENTS'));
            return;
        }

        if (!areBomComponentsDifferent($scope.item.bom_components)) {
            alertDialog.show($translate.instant('ITEMS.DETAILS.BOM_COMPONENT_WITH_REPEATED_VALUES'));
            return;
        }

        const confirm = await confirmDialog.show($translate.instant('ITEMS.DETAILS.WANT_TO_SAVE'));

        if (!confirm) {
            return;
        }

        $rootScope.showAppLoader();

        cleanUpStockData();

        //remove stock_type if stock management is disabled
        if (!$scope.isStockActive) {
            delete $scope.item.stock_type;
        }

        if ($scope.item.$supplier) {
            $scope.item.default_supplier_id = $scope.item.$supplier.id;
        } else {
            delete $scope.item.default_supplier_id;
        }

        delete $scope.item.$supplier;

        //Remove linked_item_uuid and price_difference from variation values if combinations are enabled
        if ($scope.combinationsActive) {
            for (const variation of $scope.item.variations) {
                for (const value of variation.variation_values) {
                    delete value.linked_item_uuid;
                    delete value.price_difference;
                }
            }
        }

        //Rebuild combination values
        for (const combination of $scope.item.combinations) {
            const combinationValues = [];

            for (let i = 0; i < combination.combination_values.length; i++) {
                const combinationValue = combination.combination_values[i];

                if (combinationValue.variation_value != null) {
                    combinationValues.push({
                        variation_name: $scope.item.variations[i].name,
                        variation_value: $scope.item.variations[i].variation_values[combinationValue.variation_value].value
                    });
                }
            }

            combination.combination_values = combinationValues;
        }

        //Rebuild channels
        $scope.item.channels = [];

        for (const channel of $scope.itemChannels) {
            if (channel._enabled && channel.id) {
                $scope.item.channels.push({
                    id: channel._entId,
                    channel_id: channel.id,
                    default_pricelist: channel.default_pricelist,
                    category_id: channel.category_id
                });
            }
        }

        //Rebuild Printers
        $scope.item.printers = [];

        for (const printer of $scope.orderPrinters) {
            if (printer._enabled) {
                $scope.item.printers.push({ id: printer.id });
            }
        }

        const imgPromises = $scope.imagesCallbacks.map((callback) => callback());

        try {
            await Promise.all(imgPromises);
        } catch (e) {
            try {
                await alertDialog.show($translate.instant('ITEMS.DETAILS.IMAGES_SAVE_HAS_ERRORS'));
            } catch (err) {
            }
        }

        const thumbnail = $scope.itemImages.find((image) => (image === $scope.itemThumbnail));

        $scope.item.thumbnail = thumbnail ? thumbnail.image_url : null;
        $scope.item.images = $scope.itemImages;

        for (const variation of $scope.item.variations) {
            for (const variationValue of variation.variation_values) {
                variationValue.image_url = variationValue._images?.[0]?.image_url || null;
            }
        }

        //save allergens
        $scope.item.allergens = [];
        $scope.allergens.forEach((allergen) => {
            if(allergen.checked) {
                delete allergen.checked;
                $scope.item.allergens.push(allergen);
            }
        });

        const eManFunction = (item === undefined) ? 'postOneOnline' : 'putOneOnline';

        try {
            await entityManager.items[eManFunction]($scope.item);
            returnToShowcase();
        } catch {
            $rootScope.hideAppLoader();
            alertDialog.show($translate.instant('ITEMS.DETAILS.ITEM_SAVE_HAS_ERROR'));
        }
    };

    $scope.topbar_context.onSubmitBarcode = function(barcodeInput) {
        if(!_.isEmpty(barcodeInput)) {
            var barcode = _.find($scope.item.barcodes, { barcode: barcodeInput });

            if (barcode) {
                alertDialog.show($translate.instant('ITEMS.DETAILS.BARCODE_ALREADY_PRESENT'));
            } else {
                $scope.item.barcodes.push({ barcode: barcodeInput });
                $scope.generalForm.$setDirty();
                $scope.$digest();
            }
        }
    };

    $scope.changePriceByMargin = function() {
        promptDialog.show({title: $translate.instant('ITEMS.DETAILS.CALC_COST_TITLE'), label: $translate.instant('ITEMS.DETAILS.CALC_COST_LABEL'), type: "number"}).then(function(marginPerc) {
            var itemDep = _.find(departments, {id: $scope.item.department_id });
            var vatPerc = _.get(itemDep, ["vat", "value"]) || 0;
            var netPrice = util.round($scope.item.price1 / (1 + vatPerc / 100));
            $scope.item.cost = util.round((netPrice * (100 - marginPerc)) / 100);
        });
    };

    Object.defineProperty($scope, "itemMargin", {
        get: function() {
            if ($scope.item.price1 && $scope.item.cost) {
                var itemDep = _.find(departments, {id: $scope.item.department_id });
                var vatPerc = _.get(itemDep, ["vat", "value"]) || 0;
                var netPrice = util.round($scope.item.price1 / (1 + vatPerc / 100));
                var margin = netPrice - $scope.item.cost;
                return $filter('sclCurrency')(margin) + " (" + $filter('number')((margin / netPrice) * 100, 2) + "%)";
            } else {
                return null;
            }
        }
    });

    Object.defineProperty($scope, "itemMarkup", {
        get: function() {
            if ($scope.item.price1 && $scope.item.cost) {
                var margin = $scope.item.price1 - $scope.item.cost;
                return $filter('number')((margin / $scope.item.cost) * 100, 2) + "%";
            } else {
                return null;
            }
        }
    });

    $scope.onBarcodeAdd = function(barcodeInput) {
        var hasBarcode = _.some($scope.item.barcodes, { barcode: barcodeInput });

        if (hasBarcode) {
            alertDialog.show($translate.instant('ITEMS.DETAILS.BARCODE_ALREADY_PRESENT'));
            return null;
        }

        return { barcode: barcodeInput };
    };

    $scope.onBarcodeRemove = function(chip) {
        $scope.generalForm.$setDirty();
    };

    $scope.onNewImage = function(image) {
        if(!$scope.itemThumbnail) {
            $scope.itemThumbnail = image;
        }
    };

    $scope.onCategoryChange = function() {
        if($scope.item.category_id) {
            _.forEach($scope.orderPrinters, function(printer) {
                if(_.find(printersByCategory[$scope.item.category_id], function(p) { return p === printer; })) {
                    printer._categoryEnabled = true;
                } else {
                    printer._categoryEnabled = false;
                }
            });
        }
    };

    $scope.onCategoryChange();

    $scope.onPrinterCheck = function() {
        $scope.printersForm.$setDirty();
    };

    $scope.checkDoubleIngredients = function(chip) {
        var hasComponent = _.some($scope.item.components, { id: chip.id });

        if (hasComponent) {
            alertDialog.show($translate.instant('ITEMS.DETAILS.COMPONENT_ALREADY_PRESENT'));
            return null;
        }

        return chip;
    };

    $scope.addNewVariation = async () => {
        await checkInvalidCombinations();

        $scope.item.variations.push({
            name: "",
            required: false,
            variation_values: [],
            availableIndexes: _.range(1, 21)
        });

        //Add corresponding combination_values
        for (const combination of $scope.item.combinations) {
            combination.combination_values.push({ variation_value: null });
        }

        $scope.variationsForm.$setDirty();
    };

    $scope.copyVariation = async () => {
        const itemsWithVariations = await entityManager.items.fetchCollectionOffline()
            .then(items => items.filter(item => item.variations.length > 0));

        const itemToCopy = await itemSelector.show(itemsWithVariations);
        const variations = itemToCopy?.variations;

        if (!variations?.length) {
            return;
        }

        const variationToCopy = await itemSelector.show(variations);

        if(!variationToCopy) {
            return;
        }

        const { id, deleted_at, created_at, updated_at, ...copiedVariation } = variationToCopy;

        copiedVariation.variation_values = copiedVariation.variation_values.map(({ id, deleted_at, created_at, updated_at, ...variationValue }) => variationValue);

        for (const variationValue of copiedVariation.variation_values) {
            // set _linkedItemName
            if (variationValue.linked_item_uuid) {
                const result = variationLinkableItems.filter(item => item.uuid === variationValue.linked_item_uuid);

                if (result && result.length) {
                    variationValue._linkedItemName = result[0].name;
                }
            }
            // set _images
            if (variationValue.image_url) {
                variationValue._images = [{ image_url: variationValue.image_url }];
            }
        }

        $scope.item.variations.push({
            ...copiedVariation,
            availableIndexes: Array.from({ length: 20 }, (_, i) => i + 1)
        });

        $scope.variationsForm.$setDirty();
    };

    $scope.removeVariation = async (variationIndex) => {
        if ($scope.item.combinations.length) {
            if ($scope.item.variations.length <= 2) {
                const confirm = await confirmDialog.show($translate.instant('ITEMS.DETAILS.COMBINATIONS_REQUIRED_TWO_VARIATIONS'));

                if (!confirm) {
                    return;
                }

                $scope.item.combinations = [];
            } else {
                const variationIsUsedInCombination = $scope.item.combinations.some((combination) => (combination.combination_values[variationIndex].variation_value != null));

                if (variationIsUsedInCombination) {
                    const confirm = await confirmDialog.show($translate.instant('ITEMS.DETAILS.VARIATION_DELETE_CONFIRMATIONS'));

                    if (!confirm) {
                        return;
                    }
                }

                //remove all the combinations corresponding to this variation
                $scope.item.combinations = $scope.item.combinations.filter((combination) => (combination.combination_values[variationIndex].variation_value == null));

                // remove the combination_value corresponding to the variation (using the index)
                for (const combination of $scope.item.combinations) {
                    combination.combination_values.splice(variationIndex, 1);
                }
            }
        }

        $scope.item.variations.splice(variationIndex, 1);

        $scope.variationsForm.$setDirty();
    };

    $scope.addVariationValue = async (variation) => {
        await checkInvalidCombinations();

        // Add the new variation option to the scope
        variation.variation_values.push({
            value: "",
            price_difference: 0,
            _images: []
        });

        $scope.variationsForm.$setDirty();
    };

    $scope.variationValueHasImage = (variationValue) => {
        return !!(variationValue._images?.some(image => !image.deleted));
    };

    $scope.removeVariationValue = async (variationIndex, valueIndex) => {
        const variation = $scope.item.variations[variationIndex];
        const variationValue = variation.variation_values[valueIndex];

        if ($scope.item.combinations.length) {
            const variationIsUsedInCombination = $scope.item.combinations.some((combination) => (combination.combination_values[variationIndex].variation_value != valueIndex));

            if (variationIsUsedInCombination) {
                const confirm = await confirmDialog.show($translate.instant('ITEMS.DETAILS.VARIATION_DELETE_CONFIRMATIONS'));

                if (!confirm) {
                    return;
                }

                //Remove all the combinations that use this variation value
                $scope.item.combinations = $scope.item.combinations.filter((combination) => (combination.combination_values[variationIndex].variation_value != valueIndex));

                //Shift the variation values above the removed index
                for (const combination of $scope.item.combinations) {
                    if(combination.combination_values[variationIndex].variation_value > valueIndex) {
                        combination.combination_values[variationIndex].variation_value--;
                    }
                }
            }
        }

        // Put the index back into availableIndexes
        if (variationValue.index != null) {
            variation.availableIndexes.push(variationValue.index);
        }

        variation.variation_values.splice(valueIndex, 1);

        $scope.variationsForm.$setDirty();
    };

    $scope.onVariationValueDefaultChange = function(variation, variationValue) {
        if(variationValue.default_value) {
            _.forEach(variation.variation_values, function(vVal) {
                if(vVal !== variationValue) {
                    vVal.default_value = false;
                }
            });
        }
    };

    // blocks combinations section if there aren't two variations with one or more values
    $scope.checkCombinations = function () {
        if (!$scope.combinationsActive) {
            return true;
        }

        const nonEmptyVariations = $scope.item.variations.filter((variation) => variation.variation_values.length);

        if (nonEmptyVariations.length >= 2) {
            return true;
        }
    };

    $scope.generateCombinations = async () => {
        let message = "";

        const valuesPerCombination = $scope.item.variations.map(variation => variation.variation_values.length);
        const numberOfCombinations = valuesPerCombination.reduce((a, b) => a * b, 1);

        if (numberOfCombinations > 64) {
            alertDialog.show($translate.instant('ITEMS.DETAILS.TOO_MUCH_COMBINATIONS'));
            return;
        }

        if (numberOfCombinations === 0) {
            alertDialog.show($translate.instant('ITEMS.DETAILS.VARIATION_HAS_NO_VALUES'));
            return;
        }

        if ($scope.item.combinations.length) {
            message += $translate.instant('ITEMS.DETAILS.AUTO_GENERATION_DELETES_ALL_COMBINATIONS');
        }

        if (message) {
            const result = await confirmDialog.show(message + $translate.instant('ITEMS.DETAILS.WANT_TO_PROCEED'));

            if (!result) {
                return;
            }
        }

        $scope.item.combinations = [];

        generateCombinations($scope.item.combinations, $scope.item.variations);

        $scope.variationsForm.$setDirty();
    };

    const generateCombinations = (combinations, variations, currentCombination = []) => {
        // Base case (no more variations)
        if (!variations.length) {
            combinations.push({
                combination_values: currentCombination,
                price1: $scope.item.price1,
                price2: $scope.item.price2,
                price3: $scope.item.price3,
                price4: $scope.item.price4,
                price5: $scope.item.price5,
                barcodes: []
            });
            return;
        }

        // Recursive case
        // Determine the current variation to iterate and the remaining variations
        const currentVariation = variations[0];
        const remainingVariations = variations.slice(1);

        for (let i = 0; i < currentVariation.variation_values.length; i++) {
            const newCombination = [...currentCombination, { variation_value: i }];

            generateCombinations(combinations, remainingVariations, newCombination);
        }
    };

    $scope.addCombination = function() {
        $scope.item.combinations.push({
            price1: $scope.item.price1,
            price2: $scope.item.price2,
            price3: $scope.item.price3,
            price4: $scope.item.price4,
            price5: $scope.item.price5,
            combination_values: $scope.item.variations.map(() => ({ variation_value: null })),
            compiledValues: 0,
            barcodes: []
        });

        $scope.variationsForm.$setDirty();
    };

    $scope.removeCombination = function(index) {
        $scope.item.combinations.splice(index, 1);
    };

    $scope.addBarcodeToCombination = function(combination, barcodeInput) {
        const hasBarcode = combination.barcodes.some((bc) => bc.barcode == barcodeInput);

        if (hasBarcode) {
            alertDialog.show($translate.instant('ITEMS.DETAILS.BARCODE_ALREADY_PRESENT'));
            return null;
        }

        return { barcode: barcodeInput };
    };

    // oldValue is a backup of combination_values
    $scope.onCombinationValueChange = function (combination, combinationIndex, oldValue) {
        // Check if we have a combination with the same combination_values
        const hasDuplicateCombination = $scope.item.combinations.some((c) => {
            if (c === combination) {
                return false;
            }

            //Check if this combination has the same combination_values
            for (let i = 0; i < combination.combination_values.length; i++) {
                if (combination.combination_values[i].variation_value != c.combination_values[i].variation_value) {
                    return false;
                }
            }

            return true;
        });

        if (hasDuplicateCombination) {
            alertDialog.show($translate.instant('ITEMS.DETAILS.COMBINATION_HAS_BEEN_DUPLICATED'));
            combination.combination_values[combinationIndex].variation_value = oldValue.variation_value;
            return;
        }

        calculateCompiledValues(combination);
    };

    const calculateCompiledValues = (combination) => {
        combination.compiledValues = combination.combination_values.filter((c) => c.variation_value != null).length;
    };

    // makes combination data valid by checking price1 and number of combination_values
    const checkInvalidCombinations = async () => {
        const invalidCombinationIndexes = [];

        for (let i = 0; i < $scope.item.combinations.length; i++) {
            const combination = $scope.item.combinations[i];
            calculateCompiledValues(combination);

            if (combination.price1 == null || combination.compiledValues < 2) {
                invalidCombinationIndexes.push(i);
            }
        }

        if (invalidCombinationIndexes.length) {
            const confirm = await confirmDialog.show($translate.instant('ITEMS.DETAILS.NOT_VALID_COMBINATIONS_FOUND'));

            if (confirm) {
                $scope.item.combinations = $scope.item.combinations.filter((_, index) => !invalidCombinationIndexes.includes(index));
            }
        }
    };

    $scope.editVariationLinkedItem = async (variationValue) => {
        const selectedItem = await itemSelector.show(variationLinkableItems);

        Object.assign(variationValue, {
            linked_item_uuid: selectedItem.uuid,
            _linkedItemName: selectedItem.name
        });

        if(!variationValue.name) {
            variationValue.value = selectedItem.name;
        }
    };

    let chainProductsLeanPMS = [];

    const fetchChainProducts = async() => {
        const isLeanPmsEnabled = await leanPMS.isSetup();

        if(!isLeanPmsEnabled) {
            return;
        }

        try {
            const data = await leanPMSApi.getChainProducts();
            chainProductsLeanPMS = data.results;
        } catch(error) {
            errorsLogger.err(error);
        }
    };

    fetchChainProducts();

    $scope.selectChainProductsLeanPMS = (option) => {
        itemSelector.show(chainProductsLeanPMS).then(function(selectedItem) {
            $scope.item[option.targetField] = selectedItem.id;
            $scope.detailsForm.$setDirty();
        });
    };

    /*BEGIN STOCK MANAGEMENT FUNCTIONS*/
    var updateCost = function() {
        var getBomComponentCost = function(bomComponents) {
            return _.reduce(bomComponents, function(amount, bomComponent) {
                var costToUse, componentCost;

                if (bomComponent.component_item_id) {
                    costToUse = bomItemsMap[bomComponent.component_item_id].cost;
                } else if (bomComponent.component_raw_material_id) {
                    costToUse = bomRawMaterialsMap[bomComponent.component_raw_material_id].cost;
                } else if (bomComponent.bom_components) {
                    costToUse = getBomComponentCost(bomComponent.bom_components);
                } else {
                    return amount;
                }
                componentCost = util.round(costToUse * bomComponent.quantity);

                return _.isFinite(costToUse) && _.isFinite(componentCost) ? util.round(amount + componentCost) : amount;
            }, null);
        };
        $scope.item.cost = getBomComponentCost($scope.item.bom_components);
    };

    $scope.bomComponentHasCost = function(bomComponent) {
        if (bomComponent.component_item_id) {
            return bomItemsMap[bomComponent.component_item_id] && _.isFinite(bomItemsMap[bomComponent.component_item_id].cost);
        } else if (bomComponent.component_raw_material_id) {
            return bomRawMaterialsMap[bomComponent.component_raw_material_id] && _.isFinite(bomRawMaterialsMap[bomComponent.component_raw_material_id].cost);
        }
    };

    $scope.getBomComponentCost = function(bomComponent) {
        if (bomComponent.component_item_id) {
            return $filter('sclCurrency')(bomItemsMap[bomComponent.component_item_id].cost);
        } else if (bomComponent.component_raw_material_id) {
            return $filter('sclCurrency')(bomRawMaterialsMap[bomComponent.component_raw_material_id].cost);
        }
    };

    $scope.updateOnBomChange = function() {
        updateCost();
        $scope.stockForm.$setDirty();
    };

    $scope.addNewBomComponent = function(target) {
        if (!target) {
            target = $scope.item;
        }
        target.bom_components.push({
            $type: 'item'
        });
        $scope.updateOnBomChange();
    };

    $scope.changeStockType = function() {
        if($scope.item.stock_type === 'bom') {
            var x_query = 'OR(bom_components.component_item_id=' + $scope.item.id + ')';
            var query = {
                x_query: x_query
            };

            entityManager.items.fetchCollectionOnline(query).then(function(results) {
                var isInOtherBoms;

                if (results) {
                    if (!_.isArray(results) && results.results) {
                        isInOtherBoms = (results.total > 0);
                    }
                    isInOtherBoms = (results.length > 0);
                }

                if(isInOtherBoms) {
                    confirmDialog.show($translate.instant('ITEMS.DETAILS.WARNING_STOCK_TYPE_IS_A_SUB_BOM_COMPONENT'))
                    .then(function(answer) {
                        if(!answer) {
                            $scope.item.stock_type = "simple";
                        }
                    });
                }
            });
        } else {
            if (!_.isEmpty($scope.item.bom_components)) {
                confirmDialog.show($translate.instant('ITEMS.DETAILS.WARNING_STOCK_TYPE_DELETE_BOM_COMPONENT'))
                .then(function(answer) {
                    if (answer) {
                        $scope.item.bom_components = [];
                    } else {
                        $scope.item.stock_type = "bom";
                    }
                });
            }
        }
    };

    $scope.removeBomComponent = function(bomComponent, target) {
        if (!target) {
            target = $scope.item;
        }
        _.pull(target.bom_components, bomComponent);
        $scope.updateOnBomChange();
    };

    $scope.editBomComponent = function(bomComponent) {
        var dataSet;

        if (bomComponent.$type === 'item') {
            dataSet = bomItems;
        } else if (bomComponent.$type === 'raw_material') {
            dataSet = bomRawMaterials;
        } else {
            return;
        }
        itemSelector.show(dataSet).then(function(selectedComponent) {
            if (bomComponent.$type === 'item') {
                bomComponent.component_item_id = selectedComponent.id;
            } else if (bomComponent.$type === 'raw_material') {
                bomComponent.component_raw_material_id = selectedComponent.id;
            }
            bomComponent.$unit = selectedComponent.unit;
            bomComponent.name = selectedComponent.name;
            $scope.updateOnBomChange();
        });
    };

    $scope.onBomComponentTypeChange = function(bomComponent) {
        delete bomComponent.bom_components;
        if (bomComponent.$type === 'bom_component') {
            bomComponent.bom_components = [];
        }
        delete bomComponent.component_raw_material_id;
        delete bomComponent.component_item_id;
        delete bomComponent.name;
        delete bomComponent.$unit;
        $scope.updateOnBomChange();
    };

    /*END STOCK MANAGEMENT FUNCTIONS*/

    /*HANDLE SWITCHES IN VARIATIONS SECTION */
    $scope.$watch((scope) => scope.combinationsActive, async (newValue, oldValue) => {
        if(newValue === false) {
            if (!_.isEmpty($scope.item.combinations)) {
                // Ask for confirmation if there are combinations
                let confirm = await confirmDialog.show($translate.instant('ITEMS.DETAILS.WANT_TO_DISABLE_COMBINATIONS'));

                if (confirm) {
                    $scope.item.combinations = [];
                } else {
                    $scope.combinationsActive = true;
                }
            }
        } else {
            //Disable is_group_item and split_group_components
            Object.assign($scope.item, {
                is_group_item: false,
                split_group_components: false
            });
        }
    });

    $scope.$watch((scope) => scope.item.is_group_item, (newValue, oldValue) => {
        if(newValue === true) {
            //Disable combinations and split_group_components
            $scope.combinationsActive = false;
            $scope.item.split_group_components = false;
        }
    });

    $scope.$watch((scope) => scope.item.split_group_components, (newValue, oldValue) => {
        if(newValue === true) {
            //Disable combinations and is_group_item
            $scope.combinationsActive = false;
            $scope.item.is_group_item = false;
        }
    });

    $scope.imagesCallbacks = [];

    $scope.handleInputChange = function(e) {
        var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
        var pattern = /image-*/;
        var reader = new FileReader();
        if (!file.type.match(pattern)) {
            return;
        }
        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsDataURL(file);
    };
    
    $scope._handleReaderLoaded = function(e) {
        const payload = e.target.result;

        restManager.post('automate/items', payload).then((response) => {
            if (!response || typeof response !== "object" || !$scope.isResponsePopulated(response)) {
                return;
            }

            const item = {
                name: '',
                category_name: '',
                price: '',
                description: '',
                sku: '',
                barcodes: '',
            };

            if(typeof response.name === "string") {
                $scope.item.name = item.name = response.name;
            }

            //check if response.category_name exists in categories
            if(typeof response.category_name === "string") {
                item.category_name = response.category_name;
                const categoryName = response.category_name.toLowerCase();
                const category = categories.find(category => category.name.toLowerCase() === categoryName);

                if (category) {
                    $scope.item.category_id = category.id;
                }
            }

            //check if response.price not null
            if (typeof response.price === "string") {
                item.price = response.price;
                const price = +(response.price.replace(',', '.'));

                //check if price is a number
                if (price) {
                    $scope.item.price1 = price;
                }
            }

            //check if response.description.length is less than 255
            if(response.description && typeof response.description === "string") {
                $scope.item.description = item.description = response.description.slice(0, 255);
            }

            if(response.sku && typeof response.sku === "string") {
                $scope.item.sku = item.sku = response.sku;
            }

            if(response.barcode && typeof response.barcode === "string") {
                item.barcode = response.barcode;
                $scope.item.barcodes.push({ barcode: response.barcode });
            }

            //open dialog to show properties received
            automateItemsDialogService.openDialog({data: {item: item}});
        })
        .catch(err => {
            console.log("ERROR POST automate/items", err.data.error.message);
        });
    };

    $scope.isResponsePopulated = function(response) {
        return Object.values(response).some(value => value !== null && value !== "" && value !== " ");
    };
}