
<tilby-dialog-toolbar mat-dialog-title
title="SETTINGS.SETTINGS.SAVE_SALE"
[disabled]="!editSaleNameForm.valid"
[customActions]="customActions"
(confirm)="confirm()"
/>
<tilby-dialog-content>
    <tilby-magic-form [form]="editSaleNameForm"/>
</tilby-dialog-content>
