import {
    inject,
    Injectable
} from "@angular/core";

import {
    EnvironmentInfoService
} from "src/app/core";

@Injectable({
    providedIn: 'root'
})
export class SystemUtilsService {
    private readonly environmentInfo = inject(EnvironmentInfoService);

    /**
     * Opens the given url in an external browser, or in the default
     * system browser if the app is running in a web context.
     *
     * @param url the url to open
     */
    public openExternalLink(url: string) {
        if(this.environmentInfo.isElectronApp()) {
            window.require('electron').shell.openExternal(url);
        } else {
            window.open(url, '_system');
        }
    }

    /**
     * Minimizes the app.
     *
     * If the app is running in an electron context, sends an IPC
     * message to the main process to minimize the app.
     *
     * If the app is running in a web context, this function does
     * nothing.
     */
    public minimizeApp() {
        if(this.environmentInfo.isElectronApp()) {
            window.require('electron').ipcRenderer.send('electron-minimize-app');
        }
    }
}