import angular from 'angular';

import {
    OpenDialogsService,
    WaitDialogService
} from 'src/app/dialogs';

import {
    DigitalPaymentHandler,
    DigitalPaymentHandlerOptions,
    DigitalPaymentHandlerResult
} from 'src/app/shared/model/digital-payments.model';

import {
    keyBy,
    MathUtils
} from 'src/app/shared/utils';

type ZPayPaymentType =
    'Auto' |
    'Debit' |
    'Credit' |
    'Other' |
    'Alternative' |
    'Ticket' |
    'ConadCard';

type ZPayChannelType =
    "Satispay" |
    "Bitcoin" |
    "Tinaba" |
    "Alipay" |
    "Wechat" |
    "Postepay" |
    "BancomatPay" |
    "CibusPay";

type ZPayPOSType =
    "Argentea" |
    "Ingenico" |
    "Pax";

type ZPayDeviceType =
    "Pos" |
    "Printer" |
    "FiscalPrinter" |
    "Drawer" |
    "Scale";

type ZPayManufacturer =
    "Ingenico" |
    "Pax" |
    "Rch" |
    "Epson" |
    "Custom" |
    "Ditron" |
    "Cima" |
    "Glory" |
    "Cashmatic" |
    "Vne" |
    "PayPrint" |
    "Helmac" |
    "ItalRetail" |
    "Unknown";

type ZPayPOS = {
    pre_auth_port?: number;
    bpe_port?: number;
    alt_port?: number;
    conad_port?: number;
    type?: ZPayPOSType;
    manufacturer?: ZPayManufacturer;
    supported_payments?: ZPayPaymentType[];
    supported_channels?: ZPayChannelType[];
    id: string;
    name: string;
    ip?: string;
    port?: number;
    device_type?: ZPayDeviceType;
    created_at?: string;
    created_by?: string;
    last_modified_at?: string;
    last_modified_by?: string;
    deleted_at?: string;
    deleted_by?: string;
    row_version?: number;
}

type ZPayTransactionRequest = {
    terminal_id?: string;
    cashier_id?: string;
    payment_type?: ZPayPaymentType;
    value?: number;
    additional_data?: string;
    channel_type?: ZPayChannelType;
    fidelity_code?: string;
    type?: 'Payment' | 'Refund' | 'Cancel';
    pos_id?: string;
}

type ZPayVoucherDetails = {
    total_vouchers: number;
    vouchers: ZPayVoucher[];
}

type ZPayVoucher = {
    quantity: number;
    denomination: number;
}

type ZPayTransaction = {
    request: ZPayTransactionRequest;
    success: boolean;
    id_acquirer: string;
    stan: string;
    online_progressive: number;
    pan: string;
    host_auth_code: string;
    date: Date;
    error: string;
    action_code: string;
    is_currency_change: boolean;
    conversion_rate: number;
    currency_code: string;
    outcome_description: string;
    voucher_code: string;
    detail_voucher: string;
    voucher_details?: ZPayVoucherDetails;
    description_voucher: string;
    provider_code: string;
    provider_name: string;
    additional_info: string;
    id: string;
    created_at: Date;
    created_by: string;
    last_modified_at: Date;
    last_modified_by: string;
    deleted_at: Date;
    deleted_by: string;
    receipt?: string;
}

type ZPaySupportedMethods = 'Auto' | 'Debit' | 'Credit' | 'Ticket' | 'Satispay';

const availableMethods: Set<ZPaySupportedMethods> = new Set([
    'Auto',
    'Debit',
    'Credit',
    'Ticket',
    'Satispay',
]);

export class ZPayBridge implements DigitalPaymentHandler {
    constructor(
        private readonly openDialogsService: OpenDialogsService,
        private readonly waitDialogService: WaitDialogService
    ) {
    }

    private sendTransactionRequest(apiUrl: string, transactionRequest: ZPayTransactionRequest): Promise<ZPayTransaction> {
        return fetch(`http://${apiUrl}/api/transaction/start`, {
            method: 'POST',
            body: JSON.stringify(transactionRequest),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (!res.ok) {
                throw res.json();
            }

            return res.json();
        });
    }

    private getPosAllowedMethods(pos: ZPayPOS): ZPaySupportedMethods[] {
        if(!pos.supported_payments) {
            return [
                'Auto',
                'Ticket',
                'Satispay'
            ];
        }

        return [
            ...pos.supported_payments.filter((method: any) => availableMethods.has(method)) as ZPaySupportedMethods[],
            ...(pos.supported_channels?.filter((method: any) => availableMethods.has(method)) || []) as ZPaySupportedMethods[],
        ];
    }

    private getPOSList(apiUrl: string): Promise<ZPayPOS[]> {
        return fetch(`http://${apiUrl}/api/pos`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (!res.ok) {
                throw res.json();
            }

            return res.json();
        });
    }

    /**
     * Given a detail voucher, parse it and extract the actual amount from it.
     * The detail voucher is a string with the following format:
     * <number of pairs>|<quantity1>|<value1>|<quantity2>|<value2>|...
     * The function will return the actual amount, divided by 100 and rounded to the nearest integer.
     * If the detail voucher is invalid, the function will return undefined.
     * @param detailVoucher The detail voucher to parse.
     * @returns The actual amount, or undefined if the detail voucher is invalid.
     */
    private parseDetailVoucher(detailVoucher: string): number | undefined {
        try {
            let actualAmount = 0;

            const detailVoucherArr = detailVoucher.split('|');
            const numPairs = Number(detailVoucherArr.shift());

            for (let i = 0; i < numPairs; i++) {
                const quantity = Number(detailVoucherArr.shift());
                const value = Number(detailVoucherArr.shift());

                actualAmount += quantity * value;
            }

            return MathUtils.round(actualAmount / 100) || undefined;
        } catch (error) {
            //Do nothing
        }
    }

    public async payment(amount: number, options: DigitalPaymentHandlerOptions): Promise<DigitalPaymentHandlerResult> {
        const paymentMethod = options.paymentMethod;
        const apiUrl = paymentMethod.schema_name!;

        // POS Selection
        const posList = await this.getPOSList(apiUrl);
        const posById = keyBy(posList, pos => pos.id);

        let selectedPos: ZPayPOS | undefined;

        switch (posList.length) {
            case 0:
                throw 'DIGITAL_PAYMENTS.ZPAY_BRIDGE.NO_POS_AVAILABLE';
            case 1:
                selectedPos = posList[0];
                break;
            default:
                selectedPos = await this.openDialogsService.openGenericListDialog({
                    data: {
                        title: { label: 'DIGITAL_PAYMENTS.ZPAY_BRIDGE.SELECT_POS' },
                        list: posList.map((pos) => ({ name: pos.name, nameValue: pos.id, isSelected: false }))
                    }
                }).then(result => {
                    if(result) {
                        return posById[result?.nameValue!]
                    }
                });
                break;
        }

        if (!selectedPos) {
            throw 'CANCELED';
        }

        // Method Selection
        const methods = this.getPosAllowedMethods(selectedPos);

        if(!methods.length) {
            throw 'DIGITAL_PAYMENTS.ZPAY_BRIDGE.NO_METHODS_AVAILABLE';
        }

        let selectedMethod: ZPaySupportedMethods | undefined;

        if (options.sale.payments?.some(payment => payment.payment_method_type_id === 42 && payment.unclaimed)) {
            selectedMethod = 'Auto';
        } else if(methods.length === 1) {
            selectedMethod = methods[0];
        }

        if (!selectedMethod) {
            selectedMethod = await this.openDialogsService.openGenericListDialog({
                data: {
                    title: { label: 'DIGITAL_PAYMENTS.ZPAY_BRIDGE.SELECT_PAYMENT_TYPE' },
                    list: methods.map((type) => ({ name: `DIGITAL_PAYMENTS.ZPAY_BRIDGE.PAYMENT_TYPE.${type.toUpperCase()}`, nameValue: type, isSelected: false }))
                }
            }).then(answer => answer?.nameValue) as ZPaySupportedMethods | undefined;
        }

        if (!selectedMethod) {
            throw 'CANCELED';
        }

        // Payment
        let transactionRequest: ZPayTransactionRequest | undefined;

        switch (selectedMethod) {
            case 'Satispay':
                transactionRequest = {
                    payment_type: 'Alternative',
                    channel_type: selectedMethod,
                    type: 'Payment',
                    value: Math.round(amount * 100),
                    pos_id: selectedPos.id,
                };
                break;
            default:
                transactionRequest = {
                    payment_type: selectedMethod,
                    type: 'Payment',
                    value: Math.round(amount * 100),
                    pos_id: selectedPos.id
                }
                break;
        }

        const paymentPromise = this.sendTransactionRequest(apiUrl, transactionRequest);

        const response = await this.waitDialogService.openDialog({
            data: {
                message: 'DIGITAL_PAYMENTS.INGENICO_17.COMPLETE_ON_POS',
                promise: paymentPromise,
            }
        }) as ZPayTransaction;

        if (!response.success) {
            throw response.error || response.outcome_description || response.receipt || 'DIGITAL_PAYMENTS.ZPAY_BRIDGE.UNKNOWN_ERROR';
        }

        const result: DigitalPaymentHandlerResult = {
            acquirer_id: Number(response.id_acquirer) || undefined,
            payment_data: JSON.stringify(response),
            acquirer_name: response.provider_name,
            tail: this.getPaymentTail(response),
            unclaimed: false
        };

        if (['Ticket'].includes(selectedMethod)) {
            result.unclaimed = true;
        }

        if (['Ticket', 'Satispay'].includes(selectedMethod)) {
            //Parse detail voucher in order to get the amount of the used vouchers
            if (response.voucher_details?.vouchers) {
                const actualAmount = response.voucher_details.vouchers.reduce((total, voucher) => total + voucher.denomination * voucher.quantity, 0);

                if (actualAmount) {
                    result.amount = MathUtils.round(actualAmount / 100);
                }
            } else if (response.detail_voucher) {
                const actualAmount = this.parseDetailVoucher(response.detail_voucher);

                if (actualAmount) {
                    result.amount = actualAmount;
                }
            }
        }

        return result;
    }

    getPaymentTail(response: ZPayTransaction): string | undefined {
        if (response.receipt) {
            return response.receipt;
        }

        return undefined;
    }
}

ZPayBridge.$inject = [
    'openDialogsService',
    'newWaitDialog'
];

angular.module('digitalPayments').service('ZPayBridge', ZPayBridge);