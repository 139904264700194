import { Component, Injector, ViewChild, inject } from "@angular/core";
import { RowClickedEvent } from "ag-grid-community";
import { from } from "rxjs";
import { EntityManagerService, ModuleEvents, RIGHT_SIDENAV_PORTAL_DATA_TOKEN, RightSidenavService, ToolbarEventsService } from "src/app/core";
import { GridCellExportFormatterService, GridCellFormatterService, GridClickableButtonComponent, GridFixedButtons } from "src/app/shared/components";
import { GridService } from "src/app/shared/components/grid/grid.service";
import { SaleItemsDeletedFe, TableData } from "src/app/shared/model/model";
import { headersTranslate,} from 'src/app/shared/components/grid';
import { restManager } from "app/ajs-upgraded-providers";
import { OnDestroyService } from "src/app/core/services/on-destroy.service";
import { HistorySaleDetailComponent } from "../history-sales/components/history-sale-detail/history-sale-detail.component";
import { ComponentPortal } from "@angular/cdk/portal";
import { GridClientSideComponent } from "src/app/shared/components/grid-client-side/grid-client-side.component";
import { SaleItemsDeleted } from "tilby-models";
import { AgGridAngular } from "ag-grid-angular";

const productsDeleteOptions = {
    sort_type: "desc",
    sort_col: 'date'
}

@Component({
    selector: 'history-products-deleted',
    templateUrl: './history-products-deleted.component.html',
    styleUrls: ['./history-products-deleted.component.scss'],
})
export class HistoryProductsDeletedComponent {
    @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
    @ViewChild("appHistoryProductsDeletedComponent", { static: true }) gridRef!: GridClientSideComponent;

    private readonly gridService = inject(GridService);
    private readonly restManagerService = inject(restManager);
    private readonly onDestroyService = inject(OnDestroyService);
    private readonly entityManager = inject(EntityManagerService);
    private readonly toolbarEventsService = inject(ToolbarEventsService);
    private readonly gridCellFormatterService = inject(GridCellFormatterService);
    private readonly gridCellExportFormatterService = inject(GridCellExportFormatterService);
    private readonly rightSidenavService = inject(RightSidenavService<HistorySaleDetailComponent>);

    saleItemsDeleted: SaleItemsDeletedFe[] = [];
    exportFormatterOptions?: (...any: any[])=> any;
    fixedButtons: GridFixedButtons;

    async mapFields(a: any, b: any): Promise<any> {
        const response = await this.restManagerService.getList(a, b);

        for(let i = 0; i < response.length; i++) {
            response[i].id = i+1;
        }

        return new Promise((resolve) => 
            {
                resolve({
                    page: 0,
                    pages: 1,
                    per_page: response.length,
                    results: response,
                    total: response.length
                })
            }
        )
    }

    tableData: TableData[] = [
        {
            rowData$: from(this.entityManager.saleItemsDeleted.fetchCollectionOnline(productsDeleteOptions).then(res => res && Array.isArray(res) ? this.mapSaleItemsDeleted(res): [])),
            dataType: new SaleItemsDeletedFe(),
            headersTranslate: headersTranslate.history_products_deleted,
            columnsFormatter: this.gridCellFormatterService.history_products_deleted
        }
    ];

    mapSaleItemsDeleted(data: SaleItemsDeleted[]) {
        const result: any[] = [];

        data?.forEach((element, idx) => {
            result.push({
                id: idx,
                lastupdate_at: element.sale_item_details?.lastupdate_at ? new Date(element.sale_item_details?.lastupdate_at) : "",
                sale_name: element.sale_name || '',
                name: element.sale_item_details?.name || '',
                sku: element.sale_item_details?.sku || '',
                category_name: element.sale_item_details?.category_name || '',
                department_name:  element.sale_item_details?.department?.name || '',
                vat_perc: element.sale_item_details?.vat_perc || '',
                price: element.sale_item_details?.price || '',
                cost: element.sale_item_details?.cost || '',
                operator_name: element.operator_name || '',
                room_name: element.room_name || '',
                table_name: element.table_name || '',
                covers: element.covers || '',
                order_type: element.order_type || '',
                quantity_difference: element.neg_quantity_difference || 0,
                revenues: (element.sale_item_details?.price && element?.neg_quantity_difference) ? element.sale_item_details?.price * element?.neg_quantity_difference : 0,
                sale_id: element.sale_id || ''
            });
        });

        return result;
    }

    constructor() {
        this.fixedButtons = {
            checkbox: { visible: false, lockPosition: 'left' },
            customButton: { visible: false, cellRenderer: GridClickableButtonComponent, lockPosition: 'right' }
        };

        this.gridService.history_products_deleted$.data.subscribe(data => {
            if(this.gridRef.agGrid.gridOptions) {this.gridRef.agGrid.gridOptions.rowClass = 'ag-row-background__unselected';}
            this.saleItemsDeleted = data;
        });
    }

    ngOnInit(): void {
        this.createToolbarButtons();
        this.setModuleTitle();
        this.toolbarEventsService.events.pipe(this.onDestroyService.takeUntilDestroy).subscribe(e => this.callbackToToolbarClick(e));
        this.toolbarEventsService.searchBarValue$.next('');
        this.toolbarEventsService.searchBarIcon$.next('tune');
        this.toolbarEventsService.searchBarIconFilled$.next('tune');
        this.toolbarEventsService.searchBarAction$.next({ openToolPanel: 'filters' });
        this.toolbarEventsService.searchBarActionFilled$.next({ openToolPanel: 'filters' });
        this.exportFormatterOptions = this.gridCellExportFormatterService.historySaleItemsDeleteFormatter;
    }

    createToolbarButtons() {
        this.toolbarEventsService.buttons$.next({
            barButtons: [],
            panelButtons: []
        });
    }

    setModuleTitle() {
        this.toolbarEventsService.moduleTitle.next("PRODUCTS-DELETED");
    }

    async callbackToToolbarClick(event: Partial<ModuleEvents> = {}) {
        if ("search" in event) {
            this.gridRef.onFilterTextBoxChanged(event.search);
        } else if ("openToolPanel" in event && event.openToolPanel) {
            this.gridRef.openToolPanel(event.openToolPanel);
        } else if ("type" in event && event.type === 'advFiltersSwitch') {
            this.gridRef.advancedFilterSwitch(event.advFiltersSwitch || false);
        } else if ("type" in event && event.type === 'movColumnsSwitch') {
            this.gridRef.columnMovableSwitch(event.movColumnsSwitch || false);
        } else if ("save" in event) {
            await this.gridRef.openDialogSaveColumnsPreference();
        }
    }

    async rowClicked({data, node}: RowClickedEvent) {
        const sale_id = data.sale_id;
        const sale = await this.restManagerService.getOne(`sales/${sale_id}`);

        if(sale.length === 0){
            this.rightSidenavService.isOpen = false;
            return;
        }

        const portalInjector = Injector.create({
            providers: [{
                provide: RIGHT_SIDENAV_PORTAL_DATA_TOKEN,
                useValue: {sale, gridRef: this.gridRef}
            }],
        });
        this.rightSidenavService.componentPortal$.next(new ComponentPortal(HistorySaleDetailComponent, null, portalInjector));
        this.rightSidenavService.isOpen = true;
    }
}