import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { TilbyCurrencyPipe } from '@tilby/tilby-ui-lib/pipes/tilby-currency';
import { Sales } from 'tilby-models';
import { TilbyGesturesDirective } from "@tilby/tilby-ui-lib/directives/tilby-gestures";
import {MatBadgeModule} from "@angular/material/badge";
import {MatIconModule} from "@angular/material/icon";
import {BadgeInputButton, BaseInputButton, LongPressInputButton} from '@tilby/tilby-ui-lib/models';
import {MatButtonModule} from "@angular/material/button";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-active-sale-action-buttons',
  templateUrl: './active-sale-action-buttons.component.html',
  styleUrls: ['./active-sale-action-buttons.component.scss'],
  standalone: true,
  imports: [CommonModule, MatButtonToggleModule, TranslateModule, TilbyCurrencyPipe, TilbyGesturesDirective, MatBadgeModule, MatIconModule, MatButtonModule, MatProgressSpinnerModule],
    host: {
      class: 'tw-block'
    }
})
export class ActiveSaleActionButtonsComponent{

    @Input() sale: Sales | undefined;
    @Input() layoutActionButtons: string | undefined;
    @Input() actionButtons: BadgeInputButton[]=[];
    @Input() fastPaymentsButtons: LongPressInputButton[]=[];
    @Input() principalActionButton: BaseInputButton | undefined;
    @Input() isLoading: boolean = false;
    @Input() isHidePrices: boolean = false;

    //START - WORKAROUND TO TRIGGER LONGPRESS WITHOUT CLICK
    private clickEnable =true;
    protected enableClick() {
        setTimeout(()=>this.clickEnable=true);
    }
    protected longPress(time: number, btn: LongPressInputButton) {
        this.clickEnable=false;
        btn.longPress?.(time,btn.name);
    }
    protected click($event: MouseEvent, btn: LongPressInputButton) {
        if(this.clickEnable) btn?.click($event,btn.name);
    }
    //END - WORKAROUND TO TRIGGER LONGPRESS WITHOUT CLICK
}
