<tilby-dialog-toolbar 
    [title]="
        isOpenDialog() && (searchText.length > 0 && searchText.length < 3) ? '' :
        isSearchingCustomer() && !isSelectingCustomer() ? 'CUSTOMERS.SEARCH_DIALOG.CLIENT' : 
        isCreatingCustomer() ? 'CUSTOMERS.SEARCH_DIALOG.NEW_CLIENT' : 
        (isCustomerSelected() || isSelectingCustomer()) && !isEditingCustomer() && !isCreatingCustomer() ? getInfoCustomer() : ''"
    (confirm)="confirm()"
    [customActions]="customActions"
    [hideCancel]="isSelectingCustomer() || isEditingCustomer() || isCreatingCustomer() || isCustomerSelected()"
    [isBack]="isCustomerSelected() && isEditingCustomer() || isCreatingCustomer()"
    (backClick)="backToSearch()">
    
    <!-- deselect currentCustomer button -->
    @if((isCustomerSelected() || isSelectingCustomer()) && !isEditingCustomer() && !isCreatingCustomer()) {
        <button mat-button toolbar-content 
            id="deselectButton"
            class="tw-absolute tw-min-w-[5%] tw-p-0"
            style="top: 14px;"
            [ngStyle]="{'left': isMobile ? '200px' : deselectButtonDistance + 'px'}"
            (click)="deselectCurrentCustomer()">
            <mat-icon class="tw-m-0">deselect</mat-icon>
        </button>
    }
</tilby-dialog-toolbar>
<tilby-dialog-content class="tw-flex tw-flex-col tw-gap-5">
    <!-- search for customer -->
    @if(isOpenDialog() && !isCustomerSelected() || isSearchingCustomer() && !isCustomerSelected()) {
        <form [formGroup]="searchForCustomer">
            <mat-form-field class="tw-w-full" subscriptSizing="dynamic" cdkFocusInitial>
                <mat-label translate="CUSTOMERS.SEARCH_DIALOG.SEARCH"></mat-label>
                <input type="text" matInput formControlName="customerName" (keydown)="onKeyDown($event)">
                <button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearchText()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </form>

        <!-- no customers -->
        @if(foundCustomers.length === 0 && searchText.length > 3) { 
            <div class="tw-py-3">
                <span class="add-select-customer-no-customer" translate>APPLICATION.ADD_SELECT_CUSTOMER.NO_CLIENT</span>
            </div>
        }

        <!-- found customers | customers list -->
        @if(foundCustomers.length > 0){
            <section>
                <cdk-virtual-scroll-viewport appendOnly itemSize="50" class="tw-h-[56.5vh]">
                    <div *cdkVirtualFor="let customer of foundCustomers" class="tw-flex tw-items-center tw-py-2" (click)="selectCustomer(customer)">    
                        <div class="tw-w-full" [ngClass]="{'tw-flex': !customer.id}">
                            @if(!customer.id){
                                <button mat-icon-button>
                                    <mat-icon>add</mat-icon>
                                </button>
                            }
                            <div class="tw-py-2 tw-cursor-pointer">
                                @if(!customer.id){
                                    <strong>
                                        ({{'APPLICATION.ADD_SELECT_CUSTOMER.QUICK_ADD' | translate}})
                                    </strong>
                                }
                                <p class="tw-text-[1.2rem]">
                                    <strong>{{getCustomerCaption(customer)}}</strong>
                                </p>
                                <div class="tw-flex">
                                    <div class="data-list">
                                        <ul class="tw-h-[10rem] tw-list-none tw-m-0">
                                            @if(customer.mobile){
                                                <li>
                                                    <span>{{'CUSTOMERS.SEARCH_DIALOG.CLIENT_INFO.MOBILE' | translate}}: {{customer.mobile}}</span>
                                                </li>
                                            }
                                            @if(customer.vat_code){
                                                <li>
                                                    <span>{{'CUSTOMERS.SEARCH_DIALOG.CLIENT_INFO.VAT_CODE' | translate}}: {{customer.vat_code}}</span>
                                                </li>
                                            }
                                            @if(getCustomerAddress(customer)){
                                                <li>
                                                    <span>{{'CUSTOMERS.SEARCH_DIALOG.CLIENT_INFO.BILLING_ADDRESS' | translate}}: {{getCustomerAddress(customer)}}</span>
                                                </li>
                                            }
                                            @if(getShippingAddress(undefined, customer)){
                                                <li>
                                                    <span>{{'CUSTOMERS.SEARCH_DIALOG.CLIENT_INFO.SHIPPING_ADDRESS' | translate}}: {{getShippingAddress(undefined, customer)}}</span>
                                                </li>
                                            }
                                            @if(customer.fidelity){   
                                                <li>
                                                    <span>{{'CUSTOMERS.SEARCH_DIALOG.CLIENT_INFO.FIDELITY' | translate}}: {{customer.fidelity}}</span>
                                                </li>
                                            }
                                            @if(customer.email){
                                                <li>
                                                    <span>{{'CUSTOMERS.SEARCH_DIALOG.CLIENT_INFO.EMAIL' | translate}}: {{customer.email}}</span>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <mat-divider></mat-divider>
                        </div>
                    </div>
                </cdk-virtual-scroll-viewport>
            </section>
        }
    }
    <!-- show customer informations if a customer is selected -->
    @if(isCustomerSelected() && !isEditingCustomer()){
        <div class="tw-flex tw-flex-row">
            <section class="tw-w-1/2 tw-flex tw-flex-col">
                <span>
                    @switch (currentCustomer.custom_type) {
                        @case ('Option 1') {
                            {{'CUSTOMERS.SEARCH_DIALOG.CLIENT_INFO.CLIENT_TYPE' | translate}}: {{'CUSTOMERS.SEARCH_DIALOG.CLIENT_INFO.TYPE_1' | translate}}
                        }
                        @case ('Option 2') {
                            {{'CUSTOMERS.SEARCH_DIALOG.CLIENT_INFO.CLIENT_TYPE' | translate}}: {{'CUSTOMERS.SEARCH_DIALOG.CLIENT_INFO.TYPE_2' | translate}}
                        }
                        @case ('Option 3') {
                            {{'CUSTOMERS.SEARCH_DIALOG.CLIENT_INFO.CLIENT_TYPE' | translate}}: {{'CUSTOMERS.SEARCH_DIALOG.CLIENT_INFO.TYPE_3' | translate}}
                        }
                        @case ('Option 4') {
                            {{'CUSTOMERS.SEARCH_DIALOG.CLIENT_INFO.CLIENT_TYPE' | translate}}: {{'CUSTOMERS.SEARCH_DIALOG.CLIENT_INFO.TYPE_4' | translate}}
                        }
                    }
                </span>
                @if(currentCustomer.fidelity){
                    <span>
                        {{'CUSTOMERS.SEARCH_DIALOG.CLIENT_INFO.FIDELITY' | translate}}: {{currentCustomer.fidelity}}
                    </span>
                }
                @if(currentCustomer.mobile){
                    <span>
                        {{'CUSTOMERS.SEARCH_DIALOG.CLIENT_INFO.MOBILE' | translate}}: {{currentCustomer.mobile}}
                    </span>
                }
                @if(currentCustomer.email){
                    <span>
                        {{'CUSTOMERS.SEARCH_DIALOG.CLIENT_INFO.EMAIL' | translate}}: {{currentCustomer.email}}
                    </span>
                }
                @if(currentCustomer.vat_code){
                    <span>
                        {{'CUSTOMERS.SEARCH_DIALOG.CLIENT_INFO.VAT_CODE' | translate}}: {{currentCustomer.vat_code}}
                    </span>
                }
                @if(getCustomerAddress(currentCustomer)){
                    <span>
                        {{'CUSTOMERS.SEARCH_DIALOG.CLIENT_INFO.BILLING_ADDRESS' | translate}}: {{getCustomerAddress(currentCustomer)}}
                    </span>
                }
                @if(getShippingAddress(undefined, currentCustomer)){
                    <span>
                        {{'CUSTOMERS.SEARCH_DIALOG.CLIENT_INFO.SHIPPING_ADDRESS' | translate}}: {{getShippingAddress(undefined, currentCustomer)}}
                    </span>
                }
            </section>
            <section class="tw-w-1/2 tw-text-right tw-flex tw-flex-col">
                @if(currentCustomer.tot_spent){
                    <span>
                        {{'CUSTOMERS.SEARCH_DIALOG.CLIENT_INFO.TOTAL_SPENT' | translate}}: {{currentCustomer.tot_spent | currency}}
                    </span>
                }
                @if(currentCustomer.$prepaidCredit){
                    <span>
                        {{'CUSTOMERS.SEARCH_DIALOG.CLIENT_INFO.TOTAL_CREDIT' | translate}}: {{currentCustomer.$prepaidCredit | currency}}
                    </span>
                }
                @if(customerPoints && customerPoints.points){
                    <span>
                        {{'CUSTOMERS.SEARCH_DIALOG.CLIENT_INFO.TOTAL_POINTS' | translate}}: {{customerPoints.points}}
                    </span>
                }
            </section>
        </div>
        @if((currentCustomer.notes || currentCustomer.default_pricelist || askShippingAddress) && showCustomerDetails){
            <div class="tw-flex tw-flex-col tw-w-full">
                @if(currentCustomer.notes){
                    <span>
                        <strong>
                            {{'CUSTOMERS.SEARCH_DIALOG.CLIENT_INFO.NOTES' | translate}}:
                        </strong>
                        {{currentCustomer.notes}}
                    </span>
                }

                @if (currentCustomer.default_pricelist) {
                    <span class="tw-flex tw-justify-between tw-items-center">
                        <strong>
                            {{'CASHREGISTER.SHOWCASE.APPLY_NEW_PRICELIST' | translate}}
                        </strong>
                        <mat-radio-group [(ngModel)]="applyNewPricelist">
                            <mat-radio-button value="true">{{'CUSTOMERS.SEARCH_DIALOG.CLIENT_INFO.YES' | translate}}</mat-radio-button>
                            <mat-radio-button value="false">{{'CUSTOMERS.SEARCH_DIALOG.CLIENT_INFO.NO' | translate}}</mat-radio-button>
                        </mat-radio-group>
                    </span>
                }

                @if(askShippingAddress){
                    <section class="tw-flex tw-flex-col">
                        <strong>
                            {{'CUSTOMERS.SEARCH_DIALOG.CLIENT_INFO.SHIPPING_ADDRESS' | translate}}:
                        </strong>
                        <mat-form-field subscriptSizing="dynamic">
                            <mat-label>{{'CUSTOMERS.SEARCH_DIALOG.CLIENT_INFO.ADDRESS' | translate}}</mat-label>
                            <mat-select [(ngModel)]="shippingAddressesChoosed">
                                <mat-option *ngFor="let shippingAddress of shippingAddresses" [value]="shippingAddress">
                                    {{ shippingAddress.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </section>
                }
            </div>
        }
        @if(activeCampaign && radioListPrizes.length > 0 && showCustomerDetails) {
            <div>
                <span class="tw-flex tw-flex-col">
                    <strong>
                        {{'CUSTOMERS.SEARCH_DIALOG.CLIENT_INFO.AVAILABLE_PRIZES' | translate}}:
                    </strong>
                    <mat-radio-group class="tw-flex tw-flex-col" [(ngModel)]="radioListPrizesChoosed">
                        <mat-radio-button *ngFor="let prize of radioListPrizes" [value]="prize">
                            {{prize.name}}
                        </mat-radio-button>
                    </mat-radio-group>
                </span>
            </div>
        }
    }
    <!-- add | edit customer form -->
    @if(isCreatingCustomer() || isEditingCustomer()) {
        <section>
            <tilby-magic-form [form]="addEditCustomerForm"/>
        </section>
        <form [formGroup]="addressesForm">
            <section [id]="'shipping_address'">
                <mat-label class="tw-text-xl" translate>CUSTOMERS.DETAILS.SHIPPING_ADDRESS</mat-label>
                <section class="sass">
                    <mat-form-field color="accent" class="sass tw-w-1/3">
                        <mat-select formControlName="currentShippingAddress"
                                    [placeholder]="'CUSTOMERS.DETAILS.SHIPPING_ADDRESS' | translate"
                                    (selectionChange)="currentSlot=$event.value">
                            <mat-option *ngFor="let val of shippingAddressRange"
                                        [value]="val.toString()">
                                {{val}}{{getShippingAddress(val)}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <ng-container *ngFor="let n of shippingAddressRange">
                        <ng-container *ngIf="currentSlot == n &&  currentAddress(n) as val"
                                        [formGroupName]="val.shippingForm">
                            <mat-form-field color="primary" class="sass tw-w-1/3">
                                <mat-label translate>CUSTOMERS.DETAILS.STREET_NAME</mat-label>
                                <input matInput [formControlName]="val.shipping_street">
                                <mat-error
                                    *ngIf="shipping_street(n)?.hasError('maxlength')">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}}
                                    (max. {{validators.streetMaxLength}})
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field color="primary" class="sass tw-w-1/3">
                                <mat-label translate>CUSTOMERS.DETAILS.STREET_NUMBER</mat-label>
                                <input matInput [formControlName]="val.shipping_number">
                                <mat-error
                                    *ngIf="shipping_number(n)?.hasError('maxlength')">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}}
                                    (max. {{validators.numberMaxLength}})
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field color="primary" class="sass tw-w-1/3">
                                <mat-label translate>CUSTOMERS.DETAILS.ZIP_CODE</mat-label>
                                <input matInput [formControlName]="val.shipping_zip">
                                <mat-error
                                    *ngIf="shipping_zip(n)?.hasError('maxlength')">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}}
                                    (max. {{validators.zipMaxLength}})
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field color="primary" class="sass tw-w-1/3">
                                <mat-label translate>CUSTOMERS.DETAILS.CITY</mat-label>
                                <input matInput [formControlName]="val.shipping_city">
                                <mat-error
                                    *ngIf="shipping_city(n)?.hasError('maxlength')">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}}
                                    (max. {{validators.cityMaxLength}})
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field color="primary" class="sass tw-w-1/3">
                                <mat-label translate>CUSTOMERS.DETAILS.COUNTY</mat-label>
                                <input matInput [formControlName]="val.shipping_prov">
                                <mat-error
                                    *ngIf="shipping_prov(n)?.hasError('pattern')">{{'NG_MESSAGES.INVALID_VALUE' | translate}}</mat-error>
                                <mat-error
                                    *ngIf="shipping_prov(n)?.hasError('minlength')">{{'NG_MESSAGES.NAME_TOO_SHORT' | translate}}
                                    (max. {{validators.provMinLength}})
                                </mat-error>
                                <mat-error
                                    *ngIf="shipping_prov(n)?.hasError('maxlength')">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}}
                                    (max. {{validators.provMaxLength}})
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field color="primary" class="sass tw-w-1/3">
                                <mat-label>Nazione</mat-label>
                                <input matInput [formControlName]="val.shipping_country"
                                        [matAutocomplete]="$any(autoShippingNazione)"
                                        type="text">
                                <mat-error
                                    *ngIf="shipping_country(n)?.hasError('matchRequired')">{{'NG_MESSAGES.INVALID_VALUE' | translate}}</mat-error>
                                <mat-autocomplete autoActiveFirstOption #autoShippingNazione="matAutocomplete">
                                    <mat-option
                                        *ngFor="let country of (shipping_country(n)?.valueChanges|async|filteredCountry)"
                                        [value]="country.name">
                                        {{country.name}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </ng-container>
                    </ng-container>
                </section>
            </section>
        </form>
        <form [formGroup]="optionsForm">
            <section [id]="'options'">
                <mat-label class="tw-text-xl" translate>CUSTOMERS.DETAILS.OPTIONS</mat-label>
                <section class="sass">
                    <section class="sass">
                        <mat-form-field color="primary" class="sass tw-w-1/3">
                            <mat-label translate>CUSTOMERS.DETAILS.PAYMENT_LIMIT</mat-label>
                            <mat-select multiple formControlName="payments_allowed">
                                <mat-option *ngFor="let pm of paymentMethods" [value]="pm.id">{{pm.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field color="primary" class="sass tw-w-1/3">
                            <mat-label translate>CUSTOMERS.DETAILS.DEFAULT_PRICELIST</mat-label>
                            <mat-select formControlName="default_pricelist">
                                <mat-option *ngFor="let pl of priceLists" [value]="pl.id">{{pl.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field color="primary" class="sass tw-w-1/3">
                            <mat-label translate>CUSTOMERS.DETAILS.CREATED_IN</mat-label>
                            <input matInput formControlName="get_source">
                        </mat-form-field>
                        <mat-form-field color="primary" class="sass tw-w-1/3">
                            <mat-label translate>CUSTOMERS.DETAILS.EXTERNAL_ID</mat-label>
                            <input matInput formControlName="external_id">
                        </mat-form-field>
                        <mat-form-field color="primary" class="sass tw-w-1/3">
                            <mat-label translate>CUSTOMERS.DETAILS.PAYMENT_TOKEN</mat-label>
                            <input matInput formControlName="payment_token">
                        </mat-form-field>
                    </section>
    
                    <section class="sass">
                        <mat-checkbox color="primary" formControlName="send_commercials"><span
                            class="tw-whitespace-normal">{{'CUSTOMERS.DETAILS.SEND_COMMERCIALS' | translate}}</span>
                        </mat-checkbox>
                        <mat-checkbox color="primary" formControlName="disable_mail_receipts"><span
                            class="tw-whitespace-normal">{{'CUSTOMERS.DETAILS.DISABLE_MAIL' | translate}}</span>
                        </mat-checkbox>
                    </section>
                </section>
            </section>
        </form>
    }
</tilby-dialog-content>