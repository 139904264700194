import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { ItemsByCategory } from '../../cashregister.component';
import { Categories, Items } from 'tilby-models';
import { CashregisterSearchItemsComponent } from '../cashregister-search-items/cashregister-search-items.component';
import { keyBy, omitBy, pickBy } from 'src/app/shared/utils';
import { ConfigurationManagerService } from 'src/app/core';

@Component({
  selector: 'app-cashregister-search-items-wrapper',
  templateUrl: './cashregister-search-items-wrapper.component.html',
  styleUrls: ['./cashregister-search-items-wrapper.component.scss'],
  standalone: true,
  imports: [ TranslateModule, CashregisterSearchItemsComponent]
})
export class CashregisterSearchItemsWrapperComponent implements OnChanges {

    @ViewChild(CashregisterSearchItemsComponent) cashregisterSearchItemsComponent!: CashregisterSearchItemsComponent;

    private readonly configurationManagerService = inject(ConfigurationManagerService);

    private readonly translateService = inject(TranslateService);

    @Input() items: ItemsByCategory = {};
    @Input() searchValue: string = '';
    @Input() showcaseView: string = '';
    @Input() visibleCategories : Categories[] = [];
    @Input() selectedCategory : Categories | undefined;
    @Input() priceListStr : string = 'price1';
    @Input() showUnavailable : boolean = false;
    @Input() countNoStockAsUnavailable : boolean | null = false;

    @Input() getItemStockStatus : Function = () => {};
    @Output() showItem = new EventEmitter<Items>();
    @Output() addItemToSale = new EventEmitter<Items>();
    @Input() getItemPriceList : Function = () => {};

    isLoading : boolean = false;
    firstSearch = true;

    searchResults : {hasResults: boolean, allCategories: boolean | undefined, results: {id: string, name: string, items: Items[]}[]} = {
        "allCategories": false,
        "hasResults": false,
        "results": []
    };
    categoriesById : Record<string, Categories> = {};
    
    protected showPrices = this.configurationManagerService.isUserPermitted('show_prices_ui');

    ngOnChanges(simpleChanges: SimpleChanges) {
        if(simpleChanges.searchValue) {
            this.categoriesById = keyBy(this.visibleCategories, m => m.id);
            this.searchValue = simpleChanges.searchValue.currentValue;
            this.searchItems({categoryFilter: this.cashregisterSearchItemsComponent?.categoryFilter ||this.selectedCategory?.id || "ALL", typeFilter: this.cashregisterSearchItemsComponent?.typeFilter || 'ALL', stockFilter: this.cashregisterSearchItemsComponent?.stockFilter || (this.showUnavailable ? "ALL" : 'ONLY_STOCK')});
        }
    }

    searchItems = (options? : {allCategories?: boolean, forceSearch?: boolean, categoryFilter?: string | number, typeFilter?: string, stockFilter?: string }) => {
        if (typeof options !== 'object' || options === null || options === undefined) {
            options = {};
        }

        if (this.searchValue.length < 1) {
            this.searchResults = {
                "allCategories": false,
                "hasResults": false,
                "results": []
            };
        } else {
            let searchTarget : Partial<ItemsByCategory>;
            let searchAllCategories : boolean;

            if (this.configurationManagerService.getPreference("search_in_all_categories") && this.firstSearch) {
                this.selectedCategory = undefined;
                options.categoryFilter = "ALL";
            }
            this.firstSearch = false;


            // if(options.categoryFilter === "ALL" || !['categories', 'categoryTab'].includes(this.showcaseView)) {
            if(options.categoryFilter === "ALL") {
                searchTarget = pickBy(this.items, (itemsInCategory, categoryId) => { return this.categoriesById[categoryId] !== null && this.categoriesById[categoryId] !== undefined});
                searchAllCategories = true;
            } else {
                searchTarget = this.items[options.categoryFilter!] ? { [options.categoryFilter!]: this.items[options.categoryFilter!] } : this.items;
                searchAllCategories = false;
            }

            let searchableItems = _(searchTarget).values().sumBy(_.size);

            if(searchableItems <= 100000 || options.forceSearch) {
                this.isLoading = true;
                setTimeout(() => {
                    this.searchResults = this.performSearch(searchTarget, this.searchValue, {
                            allCategories: searchAllCategories,
                            priceList: this.priceListStr,
                            typeFilter: options?.typeFilter || 'ALL',
                            stockFilter: options?.stockFilter || 'ALL'
                        });
                    setTimeout(() =>
                        this.isLoading= false
                    );
                });
            }
        }
    };

    private filterItems<Items>(items: Items[], callback: (item: Items) => any): Items[] {
        const filteredItems: Items[] = [];
        for (const item of items) {
            const callbackResult = callback(item);
            if (callbackResult.isFound) {
                (item as Items & { researchPriority: number }).researchPriority = callbackResult.researchPriority;
                filteredItems.push(item);
            }
        }
        return filteredItems;
    }

    performSearch(searchTarget : Partial<ItemsByCategory>, searchText : string, options : {allCategories?: boolean, priceList?: string, typeFilter: string, stockFilter?: string}) {
        const allStockStatuses : string[] = [];
        const unavailableStockStatuses = ['stock-info-unavailable'];
        const availableStockStatuses = ['stock-info-available', 'stock-info-nostock'];

        if(this.countNoStockAsUnavailable) {
            unavailableStockStatuses.push('stock-info-nostock');
            allStockStatuses.push('stock-info-nostock');
        }

        let itemFilter = (item : any) => {
            if(!_.isFinite(item[options.priceList || 'price1'])) {
                return false;
            }

            if(!options.stockFilter || options.stockFilter === 'ALL') {
                if(allStockStatuses.includes(this.getItemStockStatus(item).status)) {
                    return false;
                }
            } else if(!options.stockFilter || options.stockFilter === 'ONLY_STOCK') {
                if(unavailableStockStatuses.includes(this.getItemStockStatus(item).status)) {
                    return false;
                }
            } else if(!options.stockFilter || options.stockFilter === 'NO_STOCK') {
                if(availableStockStatuses.includes(this.getItemStockStatus(item).status)) {
                    return false;
                }
            }

            const { researchPriority, isFound } = this.checkTypeFilter(item, options.typeFilter, searchText);
            return {researchPriority : researchPriority, isFound: isFound};
        };

        const resultsByCategory = _(searchTarget).mapValues((itemsInCategory : any) =>{
            return this.filterItems(itemsInCategory || [], itemFilter).sort((a, b) => {
                const itemA = a as Items & { researchPriority: number };
                const itemB = b as Items & { researchPriority: number };
                return itemA.researchPriority - itemB.researchPriority;
            });
        }).pickBy((itemsInCategory : any) => {
            return !_.isEmpty(itemsInCategory);
        }).value();

        const favorites = resultsByCategory.favorite;
        delete resultsByCategory.favorite;

        const results = Object.values(resultsByCategory).map((itemsInCategory, index) => {
            const categoryId = Object.keys(resultsByCategory)[index];
            return {
                id: categoryId,
                name: this.categoriesById[categoryId]?.name,
                items: itemsInCategory,
                color: this.categoriesById[categoryId]?.color
            };
        });

        if(favorites) {
            results.unshift({
                id: 'favorite',
                name: this.translateService.instant('CASHREGISTER.SHOWCASE.FAVOURITES'),
                items: favorites,
                color: undefined
            });
        }

        return {
            allCategories: options.allCategories,
            hasResults: !results || results.length === 0,
            results: this.reorderCategories(results)
        };
    };

    private reorderCategories(categories : any) {
        const sortedData = categories.sort((a: any, b : any) => {
            const aHasPriorityZero = a.items.some((item : any) => item.researchPriority === 0);
            const bHasPriorityZero = b.items.some((item : any) => item.researchPriority === 0);

            if (aHasPriorityZero && !bHasPriorityZero) {
              return -1;
            } else if (!aHasPriorityZero && bHasPriorityZero) {
              return 1;
            } else {
              return 0;
            }
        });

        return sortedData;
    }



    private checkTypeFilter(item: Items, typeFilter: string, searchText: string): {researchPriority: number, isFound: boolean} {
        if (typeFilter === 'ALL') {
            const itemWithoutSelectedKeys = omitBy(item, (value, key) => key === 'created_at' || key === 'updated_at' || key === 'thumbnail' || key === 'images', true);
            return this.globalSearchPlus([itemWithoutSelectedKeys], searchText);
        } else {
            switch (typeFilter) {
                case 'NAME': return this.searchText(item.name, searchText) || {researchPriority: -1, isFound: false};
                case 'SKU': return this.searchText(item.sku || '', searchText) || this.searchText(item.code || '', searchText) || {researchPriority: -1, isFound: false};
                case 'BARCODE': return this.searchBarcode(item, searchText);
                case 'BRAND': return this.searchText(item.brand || '', searchText) || {researchPriority: -1, isFound: false};
                case 'SEASON': return this.searchText(item.season || '', searchText) || {researchPriority: -1, isFound: false};
                default: return {researchPriority: -1, isFound: false};
            }
        }
    }

    private searchBarcode(item : Items, searchText: string) {
        let researchPriority = -1;
        if (item.barcodes) {
            for(const barcode of item.barcodes) {
                const tempResearchPriority = this.searchText(barcode.barcode || '', searchText);
                if (researchPriority === -1 || tempResearchPriority.researchPriority < researchPriority) {
                    researchPriority = tempResearchPriority.researchPriority;
                }
            }
            return {researchPriority: researchPriority, isFound: researchPriority !== -1 ? true : false};
        }
        return {researchPriority: -1, isFound: false};
    }

    private searchText(text: string, searchText: string): {researchPriority: number, isFound: boolean} {
        let search = this.searchByExactString(text, searchText);

        if (search.researchPriority !== -1) {
            return search;
        } else {
            const wordsToSearch = searchText.split(' ');
            search = this.searchBySpecificWords(text, wordsToSearch);
            if (search.researchPriority !== -1) {
                return search;
            }
        }
        return {researchPriority: -1, isFound: false};
    }

    private searchByExactString(text: string, searchText: string): {researchPriority: number, isFound: boolean} {
        const isSearchFound = text.toUpperCase().includes(searchText.toUpperCase()) as boolean || false;
        const researchPriority = isSearchFound ? 0 : -1;

        return {
            researchPriority: researchPriority,
            isFound: isSearchFound
        };
    }

    private searchBySpecificWords(text: string, wordsToSearch: string[]): {researchPriority: number, isFound: boolean} {
        const isSearchFound = wordsToSearch.every(word => text.toUpperCase().includes(word.toUpperCase()));
        const researchPriority = isSearchFound ? 1 : -1;
        return {
            researchPriority: researchPriority,
            isFound: isSearchFound
        };
    }

    private searchHelperPlus(obj: any, result: { researchPriority: number; isFound: boolean }, searchValue: string): boolean {
        if (typeof obj === 'string') {
            const research = this.searchText(obj, searchValue);
            if (research.researchPriority === 0) {
                result.researchPriority = 0;
                result.isFound = true;
                return true;
            } else if (research.researchPriority > 0) {
                result.researchPriority = research.researchPriority;
                result.isFound = true;
            }
            } else if (typeof obj === 'object' && obj !== null) {
            for (const key in obj) {
                if (this.searchHelperPlus(obj[key], result, searchValue)) {
                return true;
                }
            }
        }
        return false;
    }

    private globalSearchPlus(obj: any, searchValue: string): { researchPriority: number; isFound: boolean } {
        let result = { researchPriority: -1, isFound: false };
        this.searchHelperPlus(obj, result, searchValue);
        return result;
    }

}
