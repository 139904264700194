import { Bookings, Rooms, RoomsTables, Sales} from "tilby-models";
import { MatTabChangeEvent, MatTabGroup, MatTabsModule} from "@angular/material/tabs";
import { 
    RoomElementComponent
} from 'src/app/features/tables/components/room';
import {
    Component,
    inject,
    QueryList,
    ViewChild,
    ViewChildren,
    Input,
    effect,
} from '@angular/core';

import { DevLogger } from 'src/app/shared/dev-logger';
import {CommonModule} from "@angular/common";
import {TilbyDatePipe} from '@tilby/tilby-ui-lib/pipes/tilby-date';
import {TranslateModule} from '@ngx-translate/core';
import { OnDestroyService } from 'src/app/core/services/on-destroy.service';
import { BookingsDataInput, BookingsStateService } from 'src/app/features';
import { BookingRoomComponent } from "./booking-room/booking-room.component";
import { BookingRoomElementComponent } from "./booking-room-element/booking-room-element.component";
import { isArray } from "angular";
import { Subscription } from "rxjs";

export const ROOM_MULTIPLICATION_FACTOR=80;

type TablesBookingMap = Record<number, Bookings>;
type TablesSalesMap = Record<number, Sales[]>;

export const resizeRoom = (roomSize: number,isBigger:boolean)=> isBigger
    ? Math.ceil((roomSize||0)/ROOM_MULTIPLICATION_FACTOR)
    : Math.floor((roomSize||0)/ROOM_MULTIPLICATION_FACTOR);
@Component({
    selector: 'app-booking-map',
    templateUrl: './booking-map.component.html',
    styleUrls: ['./booking-map.component.scss'],
    standalone: true,
    imports: [
    CommonModule,
    MatTabsModule,
    TranslateModule,
    BookingRoomComponent,
    BookingRoomElementComponent
],
    providers: [OnDestroyService],
})
export class BookingMapComponent {
    private readonly tilbyDatePipe = inject(TilbyDatePipe);
    private readonly bookingsStateService = inject(BookingsStateService);
    private isSwipable = false;
    private tabIndex = this.bookingsStateService.tabIndex;

    protected bookingPerTableMap: TablesBookingMap = {};
    protected readonly roomMultiplier = ROOM_MULTIPLICATION_FACTOR;
    protected tabIndexValueMatTabsAtInit = this.tabIndex();
    protected tableSalesMap: TablesSalesMap = {};
    public lastTableSelected?: RoomsTables;

    tables: any[] = [];
    tablesById: any[] = [];
    bookingsFiltered: Bookings[] = [];
    bookingsDataInput!: BookingsDataInput;
    bookingInfo: { table_id: number, booking_id: number, people: number, booked_for: string }[] = [];

    @Input() bookings: Bookings[] = [];
    @Input() rooms: Rooms[] = [];

    @ViewChild('tabs') tabs?: MatTabGroup;
    @ViewChildren('tables') roomTablesElements?: QueryList<RoomElementComponent> | undefined;

    bookingSubscription: Subscription;

    private log(...args: any[]) {
        DevLogger.debug('[ TablesComponent ]', ...args);
    }

    constructor(){
        this.bookingSubscription = this.bookingsStateService.bookingObservable.subscribe((updatedBookings) => {
            this.bookings = updatedBookings;
            this.bookingsDataInput = this.bookingsStateService.selectBookingsData();
            this.bookingInfo = [];
            this.filterBookings();
            this.getSelectedTables();
        });

        effect(() => {
            this.bookingsDataInput = this.bookingsStateService.selectBookingsData();
            this.bookingInfo = [];
            this.filterBookings();
            this.getSelectedTables();
        });

        effect(() => {
            if(this.bookingsStateService.bookingData()) {
                this.bookingInfo = [];

                this.bookings = this.bookingsStateService.bookingData();
                this.bookingsDataInput = this.bookingsStateService.selectBookingsData();
                if(this.bookings.length === 0) return;
                this.filterBookings();
                this.getSelectedTables();
            }
        }, { allowSignalWrites: true });
    }

    filterBookings() {
        this.bookingsFiltered = [];
        this.bookingsFiltered = this.bookings.filter((booking) => {
            const transformDate = this.tilbyDatePipe.transform(booking.booked_for, 'yyyy-MM-dd HH:mm:ss');
            const bookedForDate = TilbyDatePipe.date({outputFormat: 'date', date: transformDate || ''});

            if(bookedForDate)
                return bookedForDate.getDate() === this.bookingsDataInput.date.getDate() &&
                bookedForDate.getMonth() === this.bookingsDataInput.date.getMonth() &&
                bookedForDate.getFullYear() === this.bookingsDataInput.date.getFullYear();
        });

        if(this.bookingsFiltered && this.bookingsFiltered.length > 0) {
            this.bookingsFiltered.forEach(booking => {
                if(booking.tables) {
                    booking.tables.forEach(bookingTable => {
                        //check if the booking is already in the bookingInfo
                        if(this.bookingInfo.find(b => b.table_id === (bookingTable.table_id ? +bookingTable.table_id : 0) && b.booking_id === booking.id)) return;
                        
                        //check if there is a booking info for this table
                        if(this.bookingInfo.find(b => b.table_id === (bookingTable.table_id ? +bookingTable.table_id : 0))) return;

                        this.bookingInfo.push({
                            table_id: bookingTable.table_id && +bookingTable.table_id || 0,
                            booking_id: booking.id || 0,
                            people: booking.people || 0,
                            booked_for: booking.booked_for
                        });
                    })
                }
            })
        }
    }

    getSelectedTables(){
        this.rooms.forEach((room) => {
            if(isArray(room.tables)) {
                room.tables.forEach((table) => {
                    if(!table.name.startsWith('ph_')) {
                        this.tables.push({
                            room_name: room.name,
                            room_id: room.id,
                            table_name: table.name,
                            table_id: table.id,
                            covers: table.covers,
                            type: table.order_type,
                            selected: false,
                            available: true
                        });
                    }
                });
            }
        });

        this.tablesById = Object.assign([], ...this.tables.map(table => ({ [table.table_id]: table })));

        this.bookingsFiltered.forEach((booking) => {
            booking.tables && booking.tables.forEach((table: any) => {
                if (!this.tablesById[table.table_id]) {
                    this.tablesById[table.table_id] = {};
                }
                this.tablesById[table.table_id].selected = true;
            });
        });
    }

    updateData() {
        this.bookingInfo = [];

        this.bookingsDataInput = this.bookingsStateService.selectBookingsData();

        if(this.bookingsStateService.isBookingOnline(this.bookingsDataInput.date) === false) {
            this.bookingsStateService.getBookingsToday(this.bookingsDataInput.date, 1)
            .then(newBookingData => {
                //check if the new bookings are into this.bookings yet
                newBookingData.forEach(booking => {
                    if(!this.bookings.find(b => b.id === booking.id)) this.bookings.push(booking);
                });    

                this.filterBookings();
                this.getSelectedTables();
            });
        }
    }
    
    swipeRight($event: any) {
        if (this.tabs?.selectedIndex && this.isSwipable) {
            this.tabs.selectedIndex! -= 1;
            this.log('SWIPE_RIGHT', this.tabs, this.tabs?.selectedIndex, {$event})
        }
    }

    swipeLeft($event: any) {
        if (this.tabs && (this.tabs?.selectedIndex || 0) < this.rooms.length && this.isSwipable) {
            this.log('SWIPE_LEFT', this.tabs, {$event})
            this.tabs.selectedIndex! += 1;
        }
    }

    selectedTabChange($event: MatTabChangeEvent) {
        this.tabIndex.set($event.index);
        this.bookingsStateService.selectedRoom = $event.index;
    }

    clickOnRoom() {
        this.log('ROOM_CLICK');
    }

    clickOnTable(table: any, room: any) {
        this.bookingsStateService.emitTableEvent({table, room});
    }

    ngOnDestroy() {
        this.bookingSubscription.unsubscribe();
    }
}
