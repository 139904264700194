import {
    Component,
    computed,
    EventEmitter,
    inject,
    Injector,
    Input,
    Output,
    signal
} from '@angular/core';

import {GeneralInputButton} from '@tilby/tilby-ui-lib/models';
import {TilbyInfoPanelComponent} from '@tilby/tilby-ui-lib/components/tilby-info-panel';
import { ConfigurationManagerService } from 'src/app/core/services';
import { $state, sessionManager } from 'app/ajs-upgraded-providers';
import { ActiveSaleService } from 'src/app/features';
import { ChangeSaleNameDialogService } from 'src/app/dialogs/change-sale-name-dialog';

@Component({
    selector: 'app-toolbar-utente',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    standalone: true,
    imports: [
        TilbyInfoPanelComponent
    ]
})
export class ToolbarUtenteComponent {
    // Injects
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly activeSaleService = inject(ActiveSaleService);
    private readonly state = inject($state);
    private readonly sessionManagerService = inject(sessionManager);
    private readonly injector = inject(Injector);

    // Inputs
    @Input() operatorName = '';
    @Input() shopName = '';

    // Outputs
    @Output() goTo = new EventEmitter<string>();
    @Output() closeLeftMenu = new EventEmitter<void>();

    // Configs
    private canExitUser = signal(this.configurationManagerService.getPreference('users.enable_user_logout') !== false);
    private canSwitchUser = signal(this.configurationManagerService.getPreference('users.enable_user_switch') !== false);
    private cantCloseSessions = signal(!this.configurationManagerService.isUserPermitted('sidenav.close_sessions'));
    private enableUserLogoutPin = signal(this.configurationManagerService.getPreference('users.enable_user_logout_pin') || false);

    protected deselectSalesFromMenuBar: boolean = this.configurationManagerService.getPreference('cashregister.deselect_sales_from_menu_bar') || false;

    btns: GeneralInputButton[] = [
        {
            isIt: signal(true),
            name: 'SIDENAV.MODULES.SETTINGS.USER',
            icon: signal('account_circle'),
            click: () => this.goTo.emit(this.configurationManagerService.isModuleAngular('settings.user') ? 'new.settings.user' : 'settings.user')
        },
        {
            isIt: this.canSwitchUser,
            name: 'SIDENAV.USER.SWITCH',
            icon: signal('cached'),
            click: () => this.switchUser()
        },
        {
            isIt: computed(() => this.canExitUser() && !this.enableUserLogoutPin()),
            name: 'SIDENAV.USER.LOGOUT',
            icon: signal('exit_to_app'),
            click: () => this.logoutActiveUserSession()
        },
        {
            isIt: computed(() => !this.enableUserLogoutPin()),
            isDisable: this.cantCloseSessions,
            name: 'SIDENAV.USER.DEEP_LOGOUT',
            icon: signal('highlight_off'),
            click: () => this.configurationManagerService.isUserPermitted('sidenav.close_sessions') && this.logoutCloseSessionsDeep()
        }
    ];

    private async switchUser() {
        if(this.deselectSalesFromMenuBar){
            const currentSale = this.activeSaleService.currentSale;

            if(currentSale.status === "open"){
                const res = await this.injector.get(ChangeSaleNameDialogService).openDialog({saleName: this.activeSaleService.currentSale.name});

                if (!res || (res.saleName === '')) {
                    return;
                }
                await this.activeSaleService.saveSale(res.saleName);
                await this.activeSaleService.loadSale(undefined);
            }
        }

        this.closeLeftMenu.emit();
        this.state.go('app.lockScreen');
    }

    private logoutActiveUserSession() {
        this.closeLeftMenu.emit();
        this.sessionManagerService.logoutActiveUserSession(true);
    }

    private logoutCloseSessionsDeep() {
        this.closeLeftMenu.emit();
        this.sessionManagerService.logoutCloseSessionsDeep(true);
    }
}
