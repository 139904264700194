import {
    inject,
    Injectable
} from "@angular/core";

import {
    StorageManagerService
} from "src/app/core";

import {
    User
} from "src/app/models";

const entityName = 'user_sessions';

@Injectable({
    providedIn: 'root'
})
export class UserSessionsEntity {
    private readonly storageManager = inject(StorageManagerService)

    public getOne(id: number) {
        return this.storageManager.getOne(entityName, id);
    }

    public getCollection(query?: any) {
        return this.storageManager.getCollection(entityName, query);
    }
    
    public deleteOne(id: number) {
        return this.storageManager.deleteOne(entityName, id);
    }

    public async getActiveSession(): Promise<User> {
        return this.getAllActiveSessions().then(sessions => sessions[0]);
    }

    public getAllActiveSessions(): Promise<User[]> {
        return this.storageManager.findAllByIndex(entityName, 1, 'active');
    }

    public saveOne(entity: User) {
        return this.storageManager.saveOne(entityName, entity);
    }

    public saveCollection(sessions: User[]) {
        return this.storageManager.saveCollection(entityName, sessions);
    }
}