import {
    CommonModule
} from "@angular/common";

import {
    Component,
    ContentChildren,
    ElementRef,
    Injectable,
    Injector,
    QueryList,
    Renderer2,
    ViewChild,
    ViewChildren,
    inject,
    runInInjectionContext
} from "@angular/core";

import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef
} from "@angular/material/dialog";

import {
    TranslateModule
} from "@ngx-translate/core";

import {
    BaseDialogService,
    NonNullableConfigData,
    TilbyDialogContentComponent,
    TilbyDialogToolbarComponent
} from "@tilby/tilby-ui-lib/components/tilby-dialog";

import {
    CustomForm,
    CustomFormControl,
    CustomFormControlProps,
    CustomFormGroup,
    InputType,
    TilbyMagicFormComponent
} from "@tilby/tilby-ui-lib/components/tilby-magic-form";

import {
    lastValueFrom
} from "rxjs";

import {
    FormsModule,
    ReactiveFormsModule,
    Validators
} from "@angular/forms";

import {
    countryCodesShort,
    subscribeInComponent
} from "@tilby/tilby-ui-lib/utilities";

import {
    Loader
} from "@googlemaps/js-api-loader";

import {
    MatFormFieldModule
} from "@angular/material/form-field";

import {
    MatInputModule
} from "@angular/material/input";

import {
    mobileCheck
} from 'src/utilities';

const {
    apiKeys
} = require('app/tilby.properties.json');

interface EditShopPreferenceData {
    shopname?: string,
    company_name?: string,
    vat_code?: string,
    address_street?: string,
    address_prov?: string,
    address_zip?: string,
    address_city?: string,
    address_country?: string,
    email?: string,
    phone?: string
}

interface EditShopPreferenceFields {
    shopname?: string,
    company_name?: string,
    vat_code?: string,
    address: AddressDetails,
    contacts: ContactDetails,
}

interface AddressDetails {
    address_street?: string,
    address_prov?: string,
    address_zip?: string,
    address_city?: string,
    address_country?: string,
    address_autocomplete?: string,
}

interface ContactDetails {
    email?: string,
    phone?: string
}

@Component({
    selector: 'app-edit-shop-preference-dialog',
    templateUrl: './edit-shop-preference-dialog.component.html',
    styleUrls: ['./edit-shop-preference-dialog.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        TilbyMagicFormComponent,
        TilbyDialogToolbarComponent,
        TilbyDialogContentComponent,
        MatInputModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule
    ],
})
export class EditShopPreferenceDialogComponent {
    protected readonly data: EditShopPreferenceData = inject(MAT_DIALOG_DATA);
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly injector = inject(Injector);
    private readonly renderer2 = inject(Renderer2);
    isMobile: boolean = mobileCheck();
    editShopPreferenceForm!: CustomFormGroup<CustomForm<EditShopPreferenceFields>>;

    @ViewChild('searchTextField', { static: true }) inputRef?: ElementRef<HTMLInputElement>;
    @ViewChildren('contentProjection') elementsToShow?: QueryList<ElementRef<HTMLElement>>;
    @ContentChildren('contentProjection', { read: ElementRef }) childTemplate?: QueryList<ElementRef<HTMLElement>>;

    loader = new Loader({
        apiKey: apiKeys.googleMaps,
        version: "weekly",
        libraries: ["places"]
    });

    defaultBounds!: google.maps.LatLngBounds;
    searchBox?: google.maps.places.SearchBox;

    ngOnInit() {
        const noHasAddress = this.data.address_street === '' && this.data.address_prov === '' && this.data.address_zip === '' && this.data.address_city === '' && this.data.address_country === '';
        const hasAddress = !noHasAddress;

        this.editShopPreferenceForm = new CustomFormGroup<CustomForm<EditShopPreferenceFields>>({
            shopname: new CustomFormControl(
                this.data.shopname,
                { validators: [Validators.required, Validators.maxLength(200)] },
                {
                    ...new CustomFormControlProps(),
                    label: 'DIALOG.EDIT_SHOP_PREFERENCE.FORM.SHOP_NAME',
                    inputType: 'text',
                    class: "tw-w-full"
                }
            ),
            company_name: new CustomFormControl(
                this.data.company_name,
                { validators: [Validators.required, Validators.maxLength(200)] },
                {
                    ...new CustomFormControlProps(),
                    label: 'DIALOG.EDIT_SHOP_PREFERENCE.FORM.COMPANY_NAME',
                    inputType: 'text',
                    class: "tw-w-full"
                }
            ),
            vat_code: new CustomFormControl(
                this.data.vat_code,
                {
                    validators: [
                        Validators.required,
                        Validators.pattern("^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$")
                    ]
                },
                {
                    ...new CustomFormControlProps(),
                    label: 'DIALOG.EDIT_SHOP_PREFERENCE.FORM.VAT_CODE',
                    inputType: 'text',
                    class: "tw-w-full"
                }
            ),
            address: new CustomFormGroup<CustomForm<AddressDetails>>({
                address_street: new CustomFormControl(
                    this.data.address_street,
                    { validators: [Validators.required, Validators.maxLength(200)] },
                    {
                        ...new CustomFormControlProps(),
                        label: 'DIALOG.EDIT_SHOP_PREFERENCE.FORM.ADDRESS_STREET',
                        inputType: this.checkAddress(hasAddress),
                        class: "tw-w-full sm:tw-w-1/3"
                    }
                ),
                address_zip: new CustomFormControl(
                    this.data.address_zip,
                    { validators: [Validators.required, Validators.maxLength(20)] },
                    {
                        ...new CustomFormControlProps(),
                        label: 'DIALOG.EDIT_SHOP_PREFERENCE.FORM.ADDRESS_ZIP',
                        inputType: this.checkAddress(hasAddress),
                        class: "tw-w-full sm:tw-w-1/3"
                    }
                ),
                address_city: new CustomFormControl(
                    this.data.address_city,
                    { validators: [Validators.required, Validators.maxLength(30)] },
                    {
                        ...new CustomFormControlProps(),
                        label: 'DIALOG.EDIT_SHOP_PREFERENCE.FORM.ADDRESS_CITY',
                        inputType: this.checkAddress(hasAddress),
                        class: "tw-w-full sm:tw-w-1/3"
                    }
                ),
                address_country: new CustomFormControl(
                    countryCodesShort.filter((country) => country.code === this.data.address_country)[0]?.name,
                    { validators: [Validators.required, Validators.maxLength(30)] },
                    {
                        ...new CustomFormControlProps(),
                        label: 'DIALOG.EDIT_SHOP_PREFERENCE.FORM.ADDRESS_COUNTRY',
                        inputType: this.checkAddress(hasAddress, 'autocomplete'),
                        inputChoices: countryCodesShort.map(({ code, name }) => ({ key: code, value: name })),
                        class: "tw-w-full sm:tw-w-1/3"
                    }
                ),
                address_prov: new CustomFormControl(
                    this.data.address_prov,
                    {
                        validators: [
                            Validators.required,
                            this.data.address_country === 'IT' ? Validators.maxLength(2) : Validators.maxLength(30)
                        ]
                    },
                    {
                        ...new CustomFormControlProps(),
                        label: 'DIALOG.EDIT_SHOP_PREFERENCE.FORM.ADDRESS_PROV',
                        inputType: this.checkAddress(hasAddress),
                        class: "tw-w-full sm:tw-w-1/3"
                    }
                ),
            },
                {},
                {
                    ...new CustomFormControlProps(),
                    label: 'DIALOG.EDIT_SHOP_PREFERENCE.FORM.ADDRESS_DETAILS',
                    classLabel: "tw-px-1 tw-pb-4 tw-text-[18px]",
                    class: "sm: tw-flex tw-flex-col"
                }
            ),
            contacts: new CustomFormGroup<CustomForm<ContactDetails>>(
                {
                    email: new CustomFormControl(
                        this.data.email,
                        { validators: [Validators.email] },
                        {
                            ...new CustomFormControlProps(),
                            label: 'DIALOG.EDIT_SHOP_PREFERENCE.FORM.EMAIL',
                            inputType: 'text',
                            class: "tw-w-full sm:tw-w-1/3"
                        }
                    ),
                    phone: new CustomFormControl(
                        this.data.phone,
                        { validators: [Validators.pattern("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$")] },
                        {
                            ...new CustomFormControlProps(),
                            label: 'DIALOG.EDIT_SHOP_PREFERENCE.FORM.PHONE',
                            inputType: 'text',
                            class: "tw-w-full sm:tw-w-1/3"
                        }
                    ),
                },
                {},
                {
                    ...new CustomFormControlProps(),
                    label: 'DIALOG.EDIT_SHOP_PREFERENCE.FORM.CONTACT_DETAILS',
                    classLabel: "tw-px-1 tw-pb-4 tw-text-[18px]",
                    class: `sm: tw-flex tw-flex-col ${this.isMobile ? 'tw-pb-[60px]' : ''}`
                }
            ),
        });

        if (noHasAddress) {
            this.editShopPreferenceForm.controls.address.controls.address_autocomplete = new CustomFormControl(noHasAddress ? '' : this.data.address_street, { validators: [Validators.required] }, { ...new CustomFormControlProps(), inputType: this.checkAddress(!hasAddress, 'contentProjection'), class: "sass tw-w-full sm:tw-w-1/3" })
        }
    }

    checkAddress(hasAddress: boolean, inputType: InputType = 'text') {
        return hasAddress ? inputType : 'hidden';
    }

    ngAfterViewInit() {
        this.initAutocomplete();

        runInInjectionContext(
            this.injector,
            () => {
                subscribeInComponent(
                    this.editShopPreferenceForm.controls.address.controls.address_country?.valueChanges!,
                    () => {
                        this.editShopPreferenceForm.controls.address.controls.address_prov?.clearValidators();
                        this.editShopPreferenceForm.controls.address.controls.address_prov?.addValidators([Validators.required, Validators.maxLength(this.editShopPreferenceForm.controls.address.controls.address_country?.value !== 'Italy' ? 30 : 2)]);
                        this.editShopPreferenceForm.controls.address.controls.address_prov?.updateValueAndValidity();
                    }
                )
            }
        )

        this.childTemplate?.forEach((c, i) =>
            this.renderer2.appendChild(
                this.elementsToShow?.get(i)?.nativeElement,
                c.nativeElement
            )
        );
    }

    async initAutocomplete() {
        await this.loader.load();

        this.searchBox = this.inputRef ? new google.maps.places.SearchBox(this.inputRef.nativeElement, {
            bounds: this.defaultBounds
        }) : undefined;

        const searchBox = new google.maps.places.SearchBox(this.inputRef?.nativeElement!);

        searchBox.addListener("places_changed", () => {
            const places = searchBox.getPlaces();

            if (places?.length == 0) {
                return;
            }

            const street_number = places?.[0].address_components!.find((obj: { types: string[], short_name: string, long_name: string }) => obj.types.includes("street_number"))?.short_name;
            const route = places?.[0].address_components!.find((obj: { types: string[], short_name: string, long_name: string }) => obj.types.includes("route"))?.short_name;
            const locality = places?.[0].address_components!.find((obj: { types: string[], short_name: string, long_name: string }) => obj.types.includes("locality"))?.short_name;
            const city = places?.[0].address_components!.find((obj: { types: string[], short_name: string, long_name: string }) => obj.types.includes("administrative_area_level_2"))?.short_name;
            const country = places?.[0].address_components!.find((obj: { types: string[], short_name: string, long_name: string }) => obj.types.includes("country"))?.short_name;
            const postal_code = places?.[0].address_components!.find((obj: { types: string[], short_name: string, long_name: string }) => obj.types.includes("postal_code"))?.short_name;

            const addressControls = this.editShopPreferenceForm.controls.address.controls;

            addressControls.address_autocomplete?.setValue(street_number ? route + ', ' + street_number : route);
            addressControls.address_city?.setValue(locality);
            addressControls.address_zip?.setValue(postal_code);
            addressControls.address_prov?.setValue(city);
            addressControls.address_country?.setValue(countryCodesShort.filter(c => c.code === country)[0].name);
            addressControls.address_street?.setValue(street_number ? route + ', ' + street_number : route);

            addressControls.address_city && (addressControls.address_city.customProps.inputType = this.checkAddress(true));
            addressControls.address_zip && (addressControls.address_zip.customProps.inputType = this.checkAddress(true));
            addressControls.address_prov && (addressControls.address_prov.customProps.inputType = this.checkAddress(true));
            addressControls.address_country && (addressControls.address_country.customProps.inputType = this.checkAddress(true, 'autocomplete'));
            addressControls.address_street && (addressControls.address_street.customProps.inputType = this.checkAddress(true));

            addressControls.address_autocomplete && (addressControls.address_autocomplete.customProps.inputType = this.checkAddress(false));

            this.editShopPreferenceForm.controls.address.updateValueAndValidity();
        });
    }

    protected confirm() {
        if(!this.editShopPreferenceForm.valid) {
            this.editShopPreferenceForm.markAllAsTouched();
            return;
        }

        const formValue = this.editShopPreferenceForm.value;

        this.matDialogRef.close({
            shopname: formValue.shopname,
            company_name: formValue.company_name,
            vat_code: formValue.vat_code,
            address_street: formValue.address!.address_street,
            address_prov: formValue.address!.address_prov,
            address_zip: formValue.address!.address_zip,
            address_city: formValue.address!.address_city,
            address_country: formValue.address!.address_country,
            email: formValue.contacts!.email,
            phone: formValue.contacts!.phone,
        });
    }
}

@Injectable({
    providedIn: "root",
})
export class EditShopPreferenceDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(data: EditShopPreferenceData) {
        const config: NonNullableConfigData<EditShopPreferenceData> = {
            ...this.switchMobileDesktopDimensions({ width: '60%' }, { fullScreenForMobile: true }),
            disableClose: true,
            data
        };
        return lastValueFrom(
            this.dialogRef.open(EditShopPreferenceDialogComponent, config).afterClosed()
        ).then((res: EditShopPreferenceData) => {
            return res;
        });
    }
}