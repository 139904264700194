import {
    Component,
    Injectable,
    OnInit,
    inject,
    signal
} from '@angular/core';

import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef
} from '@angular/material/dialog';

import {
    Sales,
    SalesDocuments
} from 'tilby-models';

import {
    BaseDialogService,
    NonNullableConfigData,
    TilbyDialogContentComponent,
    TilbyDialogTabsComponent,
    TilbyDialogToolbarComponent,
    TilbyDialogsTab
} from '@tilby/tilby-ui-lib/components/tilby-dialog';

import {
    CommonModule
} from '@angular/common';

import {
    lastValueFrom
} from 'rxjs';

import {
    ConfigurationManagerService,
    EnvironmentInfoService
} from 'src/app/core';

import {
    documentPrinter,
    RootScope
} from "app/ajs-upgraded-providers";

import {
    AlertDialogService,
    PrinterErrorFiscalDialogService,
} from 'src/app/dialogs';

import {
    dataURLToBlob, downloadFile
} from "../../shared/data-conversion-utils";

import * as pdfMake from 'pdfmake/build/pdfmake';

import {
    TDocumentDefinitions
} from "pdfmake/interfaces";

import { TranslateModule } from '@ngx-translate/core';

type SaleDocumentsViewerInput = {
    sale: Sales,
    confirmMode?: boolean
}

type SaleDocumentsViewerDialogInput = Required<SaleDocumentsViewerInput> & {
    saleDocuments: SalesDocuments[]
}

@Component({
    selector: 'app-sale-documents-viewer-dialog',
    templateUrl: './sale-documents-viewer-dialog.component.html',
    styleUrls: ['./sale-documents-viewer-dialog.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TilbyDialogContentComponent,
        TilbyDialogToolbarComponent,
        TilbyDialogTabsComponent,
        TranslateModule
    ]
})
export class SaleDocumentsViewerDialogComponent implements OnInit {
    private readonly alertDialogService = inject(AlertDialogService);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly environmentInfoService = inject(EnvironmentInfoService);
    private readonly documentPrinterService = inject(documentPrinter);
    private readonly printerErrorFiscalService = inject(PrinterErrorFiscalDialogService);
    private readonly $rootScope = inject(RootScope)

    private readonly dialogRef = inject(MatDialogRef);
    protected readonly data = inject(MAT_DIALOG_DATA) as SaleDocumentsViewerDialogInput;
    protected confirmMode = this.data.confirmMode;
    protected sale = this.data.sale;
    protected saleDocumentsToShow = this.data.saleDocuments;

    private canShare = this.environmentInfoService.canShare();
    private canDownloadFiles = this.environmentInfoService.canDownloadFiles();

    private printInProgress = false;

    selectedDocument?: SalesDocuments;

    protected customActions = this.confirmMode ? [] : [
        {
            isIt: signal(this.canDownloadFiles),
            name: '',
            icon: signal('file_download'),
            click: () => this.downloadFile()
        }, {
            isIt: () => this.isPrintable() && this.canDownloadFiles,
            name: '',
            icon: signal('picture_as_pdf'),
            click: () => this.downloadPdf()
        }, {
            isIt: signal(this.canShare),
            name: '',
            icon: signal('share'),
            click: () => this.share()
        }, {
            isIt: () => this.isPrintable(),
            name: '',
            icon: signal('print'),
            click: () => this.printDocument()
        }
    ];

    protected tabs: TilbyDialogsTab[] = this.saleDocumentsToShow.map((saleDocument, index) => ({
        title: this.getDocumentTypeLabel(saleDocument),
        id: index
    }));

    ngOnInit(): void {
        //Fix Documents visualization if necessary
        for (const saleDoc of this.saleDocumentsToShow) {
            if (!saleDoc.document_content) {
                continue;
            }

            switch (saleDoc.document_type) {
                case 'commercial_doc':
                case 'credit_note':
                case 'fiscal_receipt':
                case 'invoice':
                case 'receipt_invoice':
                case 'receipt':
                    if (saleDoc.document_content.includes('?????')) {
                        saleDoc.document_content = saleDoc.document_content.replace(/\?/g, ' ');
                    }

                    //Epson printers start each line of the document with 3 tabs.
                    saleDoc.document_content = saleDoc.document_content.replace(/^[\t]{3}/gm, '');
                    break;
                case 'fiscal_provider':
                    try {
                        saleDoc.document_content = JSON.stringify(JSON.parse(saleDoc.document_content), null, '\t');
                    } catch (err) { }
                    break;
                default:
                    break;
            }
        };

        this.selectDocument(0);
    }

    onTabChange(tab: TilbyDialogsTab) {
        this.selectDocument(tab.id);
    }

    cancel() {
        this.dialogRef.close(false);
    };

    confirm() {
        this.dialogRef.close(true);
    };

    selectDocument(index: number) {
        this.selectedDocument = this.saleDocumentsToShow[index];
    };

    isPrintable() {
        return !!this.selectedDocument?.document_content && this.selectedDocument?.document_type !== 'attachment' && !!this.selectedDocument?.document_content;
    }

    private async printDocument() {
        if (this.printInProgress) {
            return;
        }

        if (!this.configurationManagerService.isModuleEnabled('cashregister')) {
            await this.alertDialogService.openDialog({ data: { messageLabel: 'HISTORY.CASHREGISTER_MODULE_NOT_ENABLED' } }/*, { multiple: true }*/);
            return;
        }

        let printerDefId = parseInt(this.configurationManagerService.getPreference('fiscalprinter.def.id') || '0');

        if (!printerDefId) {
            await this.alertDialogService.openDialog({ data: { messageLabel: 'HISTORY.DOCUMENTS_VIEWER.NO_DEFAULT_PRINTER' } }/*, { multiple: true }*/);
            return;
        }

        this.printInProgress = true;

        try {
            await this.documentPrinterService.reprintDocument(this.selectedDocument, this.sale, printerDefId);
            this.$rootScope.$broadcast('history:sale-reprinted', { reprintedSale: structuredClone(this.sale) });
        } catch (error) {
            this.printerErrorFiscalService.show(error, { multiple: true, printerId: printerDefId });
        } finally {
            this.printInProgress = false;
        }
    }

    private async getDocumentToDownload() {
        let document = this.selectedDocument;

        if(!document) {
            return '';
        }

        const hasFiscalProvider = !!this.sale.sale_documents?.some(doc => doc.document_type === 'fiscal_provider');

        //Call the fiscal provider reprint function if we are dealing with a fiscal provider (except if we are printing the fiscal provider document itself)
        if(hasFiscalProvider && document.document_type !== 'fiscal_provider') {
            document = await this.documentPrinterService.reprintDocument(this.selectedDocument, this.sale, 'dummy_receipt');
        }

        return document?.document_content || '';
    }

    private async downloadFile() {
        if(!this.selectedDocument) {
            return;
        }

        let fileName = `${this.selectedDocument.document_type}_${this.selectedDocument.sale_id}`;
        let content: string | Blob = await this.getDocumentToDownload();
        let mime, extension;

        switch (this.selectedDocument?.document_type) {
            case 'fiscal_provider':
                mime = 'text/json';
                extension = 'json';
                break;
            case 'attachment':
                let metadata = this.selectedDocument.meta;

                if (typeof metadata !== 'object') {
                    metadata = {};
                }

                mime = metadata.mime_type || 'application/octet-stream';
                fileName = metadata.file_name || fileName;
                if (typeof content !== 'undefined') {
                    content = dataURLToBlob(content);
                }
                break;
            default:
                mime = 'text/plain';
                extension = 'txt';
                break;
        }

        downloadFile(content, `${fileName}${extension ? `.${extension}` : ''}`, mime);
    };

    private async getPdfDocument(): Promise<TDocumentDefinitions> {
        const document = await this.getDocumentToDownload();

        return {
            content: [
                document
            ],
            defaultStyle: {
                font: 'Monospace',
                fontSize: 10
            }
        };
    };
    private async share() {
        if(!this.canShare) {
            return;
        }

        const document = await this.getPdfDocument();
        const dataUri: string = await new Promise((resolve) => pdfMake.createPdf(document).getBase64(resolve));

        window.plugins.socialsharing.shareWithOptions({
            message: this.sale.name, // not supported on some apps (Facebook, Instagram)
            subject: this.sale.name, // fi. for email
            files: ['data:application/pdf;base64,' + dataUri], // an array of filenames either locally or remotely
        }, () => {}, (error: any) => {});
    };
    private async downloadPdf() {
        const document = await this.getPdfDocument();
        const filename = this.selectedDocument?.meta?.file_name || this.sale?.name || 'filename';

        pdfMake.createPdf(document).download(filename);
    };

    private getDocumentTypeLabel(saleDoc: SalesDocuments) {
        switch (saleDoc.document_type) {
            case 'fiscal_receipt':
            case 'receipt':
            case 'invoice':
            case 'e_invoice':
            case 'receipt_invoice':
            case 'summary_invoice':
            case 'summary_e_nrc':
            case 'summary_e_rc':
            case 'shipping_invoice':
            case 'credit_note':
            case 'commercial_doc':
            case 'refund_doc':
            case 'void_doc':
            case 'fiscal_provider':
                return `HISTORY.DOCUMENTS_VIEWER.${saleDoc.document_type.toUpperCase()}`;
            case 'attachment':
                return "HISTORY.DOCUMENTS_VIEWER.2_OTHER";
            default:
                return 'HISTORY.DOCUMENTS_VIEWER.DOCUMENT';
        }
    };
}

@Injectable({
    providedIn: "root",
})
export class SaleDocumentsViewerDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);
    private readonly alertDialogService = inject(AlertDialogService);

    public openDialog(config: NonNullableConfigData<SaleDocumentsViewerInput>): Promise<boolean | void> {
        const saleDocuments = structuredClone((config.data?.sale?.sale_documents || [])
            .filter(document => !['e_invoice', 'summary_e_rc', 'summary_e_nrc', 'commercial_doc_signed'].includes(document.document_type)))
            .sort((a, b) => {
                //Put attachments and fiscal_provider documents at the end of the list
                if (a.document_type === 'attachment') {
                    return 1;
                } else if (b.document_type === 'attachment') {
                    return -1;
                } else if (a.document_type === 'fiscal_provider') {
                    return 1;
                } else if (b.document_type === 'fiscal_provider') {
                    return -1;
                }

                return 0;
            });


        if (!saleDocuments.length) {
            return this.alertDialogService.openDialog({ data: { messageLabel: 'HISTORY.DOCUMENTS_VIEWER.NO_DOCUMENT_FOR_SELECTED_SALE' } });
        }

        const dialogConfig: NonNullableConfigData<SaleDocumentsViewerDialogInput> = {
            ...this.switchMobileDesktopDimensions({ height: 'fit-content' }),
            panelClass: 'no-padding-dialog-container',
            disableClose: true,
            data: {
                confirmMode: !!config.data?.confirmMode,
                sale: config.data?.sale,
                saleDocuments: saleDocuments
            }
        };

        return lastValueFrom(
            this.dialogRef.open(SaleDocumentsViewerDialogComponent, dialogConfig).afterClosed()
        );
    }
}