import angular from 'angular';

import {
    entities_rest_allowed,
    GeneralError,
    ResultApi,
    SlaveDevices,
    WEBSERVER_METHODS,
    WebServerBodyLogin,
    WebServerRequest,
    WebServerResponse,
    WebServerResponseLogin,
    WebServerResponseStatus,
    WebserverConflict,
    WebserverNotFoundError,
    WebserverWaiterVersionError
} from './webserver-models';

import {
    v4 as generateUuid
} from 'uuid';

const {
    tilbyVersion,
    minTilbyWaiterVersion
} = require('app/tilby.properties.json');

import hmacSHA512 from 'crypto-js/hmac-sha512';

import {
    ConfigurationManagerService,
    ConnectionService,
    DevicePreferences,
    EntityManagerService,
    EnvironmentInfoService,
    StorageManagerService
} from 'src/app/core';

import { CustomersCallService } from 'src/app/core/services/customers-call.service';
import {
    SaleTransactions,
    Sales
} from 'tilby-models';

import {
    ActiveSaleStoreService,
    SalePrintingUtilsService,
    SaleTransactionUtilsService,
    SaleUtilsService
} from 'src/app/features';

import _ from 'lodash';
import { PendingPrint } from 'src/app/shared/model/cashregister.model';
import { OpenDialogsService } from 'src/app/dialogs';

const shopPreferencesAllowed = [
    "cashregister.add_item_as_new",
    "cashregister.ask_shipping_address",
    "cashregister.count_nostock_as_unavailable",
    "cashregister.disable_manual_production_confirm",
    "cashregister.disable_split_sale_amount_covers",
    "cashregister.dynamic_item_price_check",
    "cashregister.enable_payment_rounding",
    "cashregister.enable_receipt_options",
    "cashregister.fast_payment_1_label",
    "cashregister.fast_payment_1_type",
    "cashregister.fast_payment_2_type",
    "cashregister.hide_zero_price_warnings",
    "cashregister.ingenico_17.payment_timeout",
    "cashregister.item_quantity_overrides",
    "cashregister.print_nonfiscal_sale_on_store",
    "cashregister.queue.max_value",
    "cashregister.queue.mode",
    "cashregister.queue.print_type",
    "cashregister.quick_coupons_list",
    "cashregister.reset_pricelist_on_close",
    "cashregister.skip_archive_confirmation",
    "customers.default_first_name",
    "customers.default_last_name",
    "day_start_time_24",
    "ddt_wine.bulk_categories",
    "ddt_wine.progressive",
    "ecommerce.activated",
    "general.address_city",
    "general.address_country",
    "general.address_prov",
    "general.address_street",
    "general.address_zip",
    "general.bcc_copy",
    "general.currency",
    "general.email",
    "general.facebook",
    "general.instagram",
    "general.map",
    "general.messenger",
    "general.phone",
    "general.receipt_url",
    "general.shop_target",
    "general.shopname",
    "general.snapchat",
    "general.telegram",
    "general.tiktok",
    "general.timezone",
    "general.twitter",
    "general.ui_language",
    "general.website",
    "general.wechat",
    "general.whatsapp",
    "items.option1_name",
    "items.option2_name",
    "items.option3_name",
    "orders.add_item_as_new",
    "orders.allow_street_only_shipping_addresses",
    "orders.ask_operator_for_each_order",
    "orders.automated_add_cover.type",
    "orders.automated_add_cover.value",
    "orders.automated_add_cover",
    "orders.default_exit",
    "orders.delivery_pricelist",
    "orders.disable_delivery_add_customer",
    "orders.disable_take_away_add_customer",
    "orders.drop_variations_on_park",
    "orders.exits",
    "orders.half_portion_discount_value",
    "orders.ingredients_removal_affects_price",
    "orders.open_tables_after_park",
    "orders.open_tables_after_send",
    "orders.print_attempts",
    "orders.reset_pricelist_on_close",
    "orders.skip_checkout_confirm",
    "orders.skip_creation_dialog",
    "orders.use_orders_table_view",
    "price_list_1_hide",
    "price_list_1_name",
    "price_list_10_hide",
    "price_list_2_hide",
    "price_list_2_name",
    "price_list_3_hide",
    "price_list_3_name",
    "price_list_4_hide",
    "price_list_4_name",
    "price_list_5_hide",
    "price_list_5_name",
    "price_list_6_hide",
    "price_list_6_name",
    "price_list_7_hide",
    "price_list_8_hide",
    "price_list_9_hide",
    "tables.hanging_alarm_after",
    "waiter.show_toast_message_error"
];

export class WebServerMessageManager {
    constructor(
        private $rootScope: any,
        private errorsLogger: any,
        private environmentInfo: EnvironmentInfoService,
        private entityManagerService: EntityManagerService,
        private connection: ConnectionService,
        private configurationManagerService: ConfigurationManagerService,
        private customersCallService: CustomersCallService,
        private activeSaleStore: ActiveSaleStoreService,
        private salePrintingUtils: SalePrintingUtilsService,
        private saleTransactionUtils: SaleTransactionUtilsService,
        private storageManager: StorageManagerService,
        private openDialogsService: OpenDialogsService,
        private saleUtilsService: SaleUtilsService
    ) {
    }

    private logger(...args: (string | boolean)[]) {
        this.errorsLogger.debug("[ WebServerMessageManager ]: ", ...args);
    }

    private logger_error(...args: string[]) {
        this.errorsLogger.err("[ WebServerMessageManager ]: ", ...args);
    }

    private paginate = (array: any[], max_pagination_default: number, per_page?: number, page?: number) => {
        let page_number = 0;
        let page_size = max_pagination_default;
        if (per_page != undefined && page != undefined) {
            if (page >= 0 && per_page >= 0) {
                page_number = page;
                // elements per page can not be superior to shop preferences max_pagination_default
                page_size = (per_page > max_pagination_default) ? max_pagination_default : per_page;
            }
        }
        let res: ResultApi = {
            totals: array.length,
            results: array.slice(page_number * page_size, page_number * page_size + page_size),
            per_page: page_size,
            pages: Math.ceil(array.length / page_size),
            page: page_number
        }
        return res;
    };

    /**
     *
     * @param entityId
     * @param entity_name
     * @description entityId = entity identificator => could be uuid or id, it depends on the entity
     * @returns
     */
    getCurrentEntity = async (entityId: any, entity_name: entities_rest_allowed) => {
        if (entity_name == "sales") {
            let builtSale = await this.activeSaleStore.loadSale(entityId, { returnBuiltSale: true, applyCovers: true });
            return builtSale[1];
        }
        return await this.entityManagerService[entity_name].fetchOneOffline(entityId);

    }

    /*
    * @param sale
    * @description add price list field on sale. it is always a number. If default or undefined, returns 0
    */
    getPriceList = async (sale: Sales) => {
        //@ts-ignore
        sale.price_list = await this.saleUtilsService.getSalePriceList(sale).then(x => !x || x == "default"? 0 : x);
    }
    /**
     * 
     * @param input {WebServerRequest}
     * @param max_pagination {number}
     * @returns 
     */
    getPendingPrints = async (input: WebServerRequest, max_pagination: number) => {
        // get all pending prints
        let pendingPrints: PendingPrint[] = [];
        // TODO: do we need a catch here?
        pendingPrints = await this.storageManager.getCollection('pending_prints').then((result: PendingPrint[]) => {
        // if there are any pending prints and a specific sales is requested, filter the results find by sale.uuid
            if (Array.isArray(result) && input.queryString["sale_uuid"]) {
                return result.filter((pendingPrint: PendingPrint) => pendingPrint.sale.uuid == input.queryString["sale_uuid"]);
            }
            // othwerwise return the result
            return result;
        });
        if (!pendingPrints) {
            pendingPrints = [];
        }
        if (input.queryString && input.queryString.pagination == "true") {
            return this.paginate(pendingPrints, max_pagination, +input.queryString.per_page, +input.queryString.page);
        }
        return this.paginate(pendingPrints, max_pagination, max_pagination);


    }

    getAllEntities = async (entityName: entities_rest_allowed) => {
        if (entityName == "shopPreferences") {
            return this.entityManagerService[entityName].fetchCollectionOffline({ id_in: shopPreferencesAllowed });
        }

        if (entityName == "sales") {
            return this.saleTransactionUtils.fetchOpenSales({ prepareSales: true, applyCoverToSales: true });
        }

        return this.entityManagerService[entityName].fetchCollectionOffline();
    }

    private async updateEntity(id: any, entity: any, entityName: entities_rest_allowed) {
        try {
            return await this.entityManagerService[entityName].putOneOfflineFirst(entity);
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    private async deleteEntity(id: any, entityName: entities_rest_allowed) {
        this.logger("deleting ", id);

        const currentEntity = await this.getCurrentEntity(id, entityName);

        if (!currentEntity) {
            throw new WebserverNotFoundError("Entity not found");
        }

        switch (entityName) {
            case 'sales': {
                await this.saleTransactionUtils.deleteSale(id);
                break;
            }
            default: {
                await this.entityManagerService[entityName].deleteOneOfflineFirst(currentEntity.id!);
                break;
            }
        }

        return {};
    }

    private async createEntity(entity: any, entityName: string) {
        try {
            switch (entityName) {
                case "sale_transactions": {
                    const { waiter_action, transaction } = entity as { waiter_action: any, transaction: SaleTransactions };

                    if (!waiter_action || !transaction) {
                        throw new WebserverConflict("Body not valid")
                    }

                    //If the transaction was already sent, return the current sale
                    const existingTransaction = await this.entityManagerService.saleTransactions.fetchCollectionOffline({ uuid: transaction.uuid }).then((st) => st[0]);

                    // this is used primaly for blocking double sends of the same object
                    if (existingTransaction) {
                        // if transactions already exists, it is no longer possible to resend the  print. So return sale
                        return this.getCurrentEntity(transaction.sale_uuid, "sales");
                    }

                    const saleCurrent = await this.getCurrentEntity(transaction.sale_uuid, "sales") as Sales | undefined;

                    if (!saleCurrent) {
                        throw new WebserverConflict("Sale do not exist");
                    }

                    if (saleCurrent.status != "open") {
                        throw new WebserverConflict("Sale is not open");
                    }

                    switch (waiter_action.action) {
                        case "print-order": {
                            if (saleCurrent.bill_lock == true) {
                                throw new WebserverConflict("Sale is not open");
                            }
                            const isToPrint = transaction.skip_printing != true;
                            await this.saleTransactionUtils.saveTransaction(transaction, { offlineMode: isToPrint });

                            if (isToPrint) {
                                await this.salePrintingUtils.sendTransactions(transaction.sale_uuid);
                            }
                            break;
                        }
                        case "send-exits": {
                            if (saleCurrent.bill_lock == true) {
                                throw new WebserverConflict("Sale is not open");
                            }
                            const saleTemp = await this.getCurrentEntity(transaction.sale_uuid, "sales");

                            await this.salePrintingUtils.sendExitToPrinter(saleTemp, waiter_action.parameters.exit);
                            break;
                        }
                        case "print-prebill": {
                            if (saleCurrent.bill_lock != true) {
                                // If the bill lock is active, allow the action to be executed but don't save the transaction.
                                // We expect that the changes made by this transaction will be lost.
                                await this.saleTransactionUtils.saveTransaction(transaction);
                            } else {
                                // log transaction bypass
                                this.logger("transaction passed, bill-lock a true", JSON.stringify(transaction))
                            }

                            const saleTemp = await this.getCurrentEntity(transaction.sale_uuid, "sales");

                            try {
                                const printerId = +(waiter_action.parameters?.printer_id) || undefined;

                                await this.salePrintingUtils.printNonFiscalSale(saleTemp, printerId);
                                this.openDialogsService.openSnackBarTilby('CASHREGISTER.ACTIVE_SALE.NON_FISCAL_SALE_PRINTED', 'MISC.OK', { duration: 3000 });
                            } catch (error: any) {
                                this.logger_error("error print sale", error);
                                // this.printerErrorFiscalService.openDialog({data: {error, options: {printerId: waiter_action["printer_id"]}}});
                            }
                            break;
                        }
                    }

                    return await this.getCurrentEntity(transaction.sale_uuid, "sales");
                }
                case "sales": {
                    return this.createSale(entity);
                }
            }

            throw new WebserverNotFoundError("Entity not found");
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    /**
     *
     * @param entity
     * @returns
     */
    private async createSale(entity: any) {
        try {
            const entityAlreadyExisting = await this.getCurrentEntity(entity.id, 'sales') as Sales | undefined;

            if (entityAlreadyExisting) {
                throw new WebserverConflict("Uuid already used");
            }

            const coverConfig = await this.saleUtilsService.getCoverConfiguration();
            await this.saleUtilsService.applyCoverToSale(entity, coverConfig);

            await this.entityManagerService.sales.postOneOfflineFirst(entity);

            return entity;
        } catch (error: any) {
            this.logger_error("error saving entity", error);
            throw error;
        }
    }
    async handleMessage(input: WebServerRequest, slave_device: SlaveDevices) {
        this.logger("got request", JSON.stringify(input))
        let body = null;
        let entityName = _.camelCase(input.entityName);

        if (input.body) {
            if (typeof input.body == "string" && input.body.length > 0) body = JSON.parse(input.body);
            if (typeof input.body == "object" && Object.keys(input.body).length > 0) body = input.body;
        }
        let res: WebServerResponse = {
            body: {
                results: [],
                totals: 0,
                per_page: 0,
                pages: 0,
                page: 0
            },
            code: 200,
            uuidRequest: input.uuidRequest
        }
        try {
            if (input.method == WEBSERVER_METHODS.GET) {
                if (entityName == "status") {
                    let statusRes: WebServerResponseStatus = {
                        isAppServerOnline: this.connection.isOnline(),
                        appServerVersion: tilbyVersion,
                        user: this.$rootScope.userActiveSession.username,
                        shop: this.$rootScope.userActiveSession.shop.name
                    }
                    res.body.results = [statusRes];
                    res.body.totals = 1;
                    res.body.per_page = 1;
                    res.body.pages = 1;
                    return res;
                }
                if (entityName == "pendingPrints") {
                    let max_pagination_preference = this.configurationManagerService.getShopPreference("local_server.max_pagination");
                    let max_pagination = 100;
                    if (max_pagination_preference) {
                        max_pagination = +max_pagination_preference;
                    }
                    res.body = await this.getPendingPrints(input, max_pagination);
                    return res;
                }
                if (!input.entityId) {
                    let start_date = new Date().getTime();
                    let max_pagination_preference = this.configurationManagerService.getShopPreference("local_server.max_pagination");
                    let max_pagination = 100;
                    if (max_pagination_preference) {
                        max_pagination = +max_pagination_preference;
                    }
                    res.body = await this.getAllEntities(entityName as entities_rest_allowed).then((entities: any[]) => {
                        let x = entities;
                        if (entityName == "sales" && input.queryString && input.queryString["table_id"]) {
                            switch (input.queryString["table_id"]) {
                                case "null":
                                    input.queryString["table_id"] = null;
                                    x = x.filter((sale: any) => sale.table_id == input.queryString["table_id"]);
                                    break;
                                case "not_null":
                                    x = x.filter((sale: any) => sale.table_id != null && sale.table_id != undefined);
                                    break;
                                default:
                                    x = x.filter((sale: any) => sale.table_id == input.queryString["table_id"]);
                            }

                        }
                        if (entityName == 'devicePreferences') {
                            x = x.filter((device_preference: DevicePreferences) => device_preference.device_uuid == slave_device.uuid) as DevicePreferences[];
                            x = x.map((device_preference: DevicePreferences) => {
                                let newId = device_preference.id.split(device_preference.device_uuid + ".").pop();
                                // Update the id property of the device
                                device_preference.id = newId ?? device_preference.id;
                                return { ...device_preference };
                            });
                        }
                        if (input.queryString && input.queryString.pagination == "true") {
                            return this.paginate(x, max_pagination, +input.queryString.per_page, +input.queryString.page)
                        }
                        return this.paginate(x, max_pagination, max_pagination, 0);
                    });
                    let end_date = new Date().getTime();
                    let final_date = start_date - end_date
                    this.logger("time getAll entities", `${final_date}`);
                } else {
                    let resCurrent = await this.getCurrentEntity(input.entityId!, entityName as entities_rest_allowed);
                    if (!resCurrent) {
                        throw new WebserverNotFoundError("Entity not found");
                    }
                    if (entityName == "sales") {
                        await this.getPriceList(resCurrent);
                    }
                    
                    res.body.results = [resCurrent];
                    res.body.totals = 1;
                    res.body.per_page = 1;
                    res.body.pages = 1;
                }
                return res;
            }
            if (input.method == WEBSERVER_METHODS.POST) {
                // save it
                // let saved = await this.createSale(body);
                let saved = await this.createEntity(body, input.entityName);
                res.body.results = [saved];
                res.body.totals = 1;
                res.body.per_page = 1;
                res.body.pages = 1;
                // send result
                return res;
            }
            if (input.method == WEBSERVER_METHODS.PUT) {
                throw new WebserverNotFoundError("Entity not found");
            }

            if (input.method == WEBSERVER_METHODS.DELETE) {
                // check if exists then delete
                let resCurrent = await this.getCurrentEntity(input.entityId!, entityName as entities_rest_allowed) as Sales | undefined;
                if (!resCurrent) {
                    throw new WebserverNotFoundError("Entity not found");
                }
                await this.deleteEntity(resCurrent.uuid, "sales");
                res.body.results = []
                res.body.totals = 1;
                res.body.per_page = 1;
                res.body.pages = 1;
                // send result
                return res;
            }
            return res
        } catch (error: any) {
            this.logger_error(JSON.stringify(input), JSON.stringify(error));
            throw error;
        }

    }

    async securityMiddleware(input: WebServerRequest) {
        try {
            // check if authorization header is there;
            if (input.headers.authorization && input.headers.authorization != "") {
                let token = input.headers.authorization.split('Bearer ');
                if (token.length == 2 && token[1]) {
                    // to optimize i should check by session_token with something like this
                    let slave_devices: SlaveDevices[] = await this.entityManagerService.slaveDevices.fetchCollectionOffline({ session_token: token[1] });
                    let d = Math.trunc(new Date().getTime() / 1000);
                    if (slave_devices.length == 1 && slave_devices[0].token_expire_at! > d) {
                        return slave_devices[0];
                    }
                }
            }

            throw new GeneralError("Missing Authorization", 401);
        } catch (error) {
            if (error instanceof GeneralError) {
                throw error;
            }
            this.logger_error(JSON.stringify(error))
            throw new GeneralError("Authorization not valid", 401);
        }
    }

    async sendResponse(res: WebServerResponse) {
        if (this.environmentInfo.isAndroid() || this.environmentInfo.isAppleMobile()) {
            this.logger("sending response", JSON.stringify(res));
            const webserver: any = (window as any)["tilbywebserver"];
            return webserver.sendResponse(
                res.uuidRequest,
                {
                    status: res.code,
                    body: JSON.stringify(res.body)
                }
            );
        }
        if (this.environmentInfo.isElectronApp()) {
            const webserver: any = (window as any).require('electron')
            return webserver.ipcRenderer.invoke('response-webserver', res);
        }
    }
    async errorHandler(error: any, input: WebServerRequest) {
        if (error instanceof GeneralError) {
            let res: WebServerResponse = {
                uuidRequest: input.uuidRequest,
                body: {
                    results: [error.message]
                },
                code: error.code
            }
            return await this.sendResponse(res);
        }
        let res: WebServerResponse = {
            uuidRequest: input.uuidRequest,
            body: {
                results: [JSON.stringify(error)]
            },
            code: 500
        }
        return await this.sendResponse(res);
    }
    handlePhoneCall(input: WebServerRequest) {
        try {
            if (!input.queryString && !input.queryString["phone"]) {
                throw new WebserverNotFoundError("Missing phone");
            }

            let phone: string = input.queryString["phone"];

            // check if phone contains @
            if (phone.includes("@")) {
                let phone_splitted = phone.split("@");
                if (phone_splitted[0].length == 0) {
                    throw new WebserverNotFoundError("Missing correct format: @ can not be at start");
                }
                phone = phone_splitted[0];
            }

            // check if phone is formatted like this: starts with + and is made only of numbers char. Can also not have + at the start
            let isStartingWithPlus = false;
            if (this.environmentInfo.isAppleMobile()) {
                isStartingWithPlus = phone.startsWith("2%B")
            } else {
                isStartingWithPlus = phone.startsWith("+");
            }
            if (isStartingWithPlus && phone.length > 1) {
                if (phone.substring(1).match(/^[0-9]*$/) == null) {
                    throw new WebserverNotFoundError("Missing correct format phone with +");
                }
            } else if (phone.match(/^[0-9]*$/) == null) {
                throw new WebserverNotFoundError("Missing correct format phone");
            }
            this.customersCallService.callCustomer(phone);

            let res: WebServerResponse = {
                uuidRequest: input.uuidRequest,
                body: {
                    results: []
                },
                code: 200
            };
            return res;
        } catch (error) {
            throw error;
        }
    }
    checkWaiterVersion(input: WebServerRequest) {
        if (input.headers.waiter_version == "") {
            throw new WebserverWaiterVersionError();
        } else {
            const waiter_version = input.headers.waiter_version.split(".");

            if (waiter_version.length != 3) {
                throw new WebserverWaiterVersionError();
            }
            const waiter_major = +waiter_version[0];
            const waiter_middle = +waiter_version[1];
            const waiter_minor = +waiter_version[2];
            const min_waiter_version = minTilbyWaiterVersion.split(".");
            const min_waiter_major = +min_waiter_version[0];
            const min_waiter_middle = +min_waiter_version[1];
            const min_waiter_minor = +min_waiter_version[2];
            if (waiter_major < min_waiter_major) {
                throw new WebserverWaiterVersionError();
            }
            if (waiter_middle < min_waiter_middle) {
                throw new WebserverWaiterVersionError();
            }
            if (waiter_minor < min_waiter_minor) {
                throw new WebserverWaiterVersionError();
            }
        }
        return true;
    }

    async controller(input: WebServerRequest) {
        try {
            if (input.entityName == "callerid") {
                let res = this.handlePhoneCall(input);
                return await this.sendResponse(res);
            }

            this.checkWaiterVersion(input);
            // check security if error throw is launched
            if (input.entityName == "login" && input.method == "POST") {
                let resLogin = await this.loginOrderApp(input);
                return await this.sendResponse(resLogin);
            }

            const slave_device = await this.securityMiddleware(input);

            // prepare answer for api
            let res = await this.handleMessage(input, slave_device);
            //send final response
            await this.sendResponse(res);
        } catch (error: any) {
            return this.errorHandler(error, input);
        }
    }

    private generateToken(code: string) {
        let uuidRandom = generateUuid();
        return hmacSHA512(code, uuidRandom).toString();
    }

    async loginOrderApp(input: WebServerRequest) {
        let inputBody: any;

        if (typeof input.body == "string" && input.body.length > 0) inputBody = JSON.parse(input.body);
        if (typeof input.body == "object" && Object.keys(input.body).length > 0) inputBody = input.body;

        let body: WebServerBodyLogin = {
            server: inputBody.server,
            device_ip: inputBody.device_ip,
            client_version: inputBody.client_version,
            code: inputBody.code,
            last_login_at: new Date().toISOString()
        };
        let slave_devices: SlaveDevices[] = await this.entityManagerService.slaveDevices.fetchCollectionOffline({ code: inputBody.code });
        let d = Math.trunc(new Date().getTime() / 1000);
        if (slave_devices.length == 1 && slave_devices[0].code_expire_at! > d) {
            if ([null, undefined, ""].includes(slave_devices[0].session_token)) {
                let s_d = Object.assign({}, slave_devices[0]);
                s_d["session_token"] = this.generateToken(body.code);
                s_d["token_expire_at"] = Math.trunc(new Date().getTime() / 1000) + 2592000;
                await this.updateEntity(slave_devices[0].uuid, s_d, "slaveDevices");
                let bodyLogin: WebServerResponseLogin = {
                    session_token: s_d["session_token"]!,
                    expire_at: s_d["token_expire_at"],
                    shop: this.$rootScope.userActiveSession.shop.name
                };
                let resLogin: WebServerResponse = {
                    uuidRequest: input.uuidRequest,
                    body: {
                        results: bodyLogin
                    },
                    code: 200
                }
                return resLogin;

            }
            throw new GeneralError("code already used", 401);
        }
        throw new GeneralError("code not found", 401);
    }

}

WebServerMessageManager.$inject = ["$rootScope", "errorsLogger", "environmentInfo", "entityManager", "connection", "checkManager", "customersCallService", "activeSaleStore", "salePrintingUtils", "saleTransactionUtils", "storageManager", "openDialogsService", "newSaleUtils"];
angular.module('core').service('WebServerMessageManager', WebServerMessageManager);
