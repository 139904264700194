import {
	Component,
	ViewChild,
	computed,
	inject
} from "@angular/core";

import {
	ConfigurationManagerService,
	ScreenOrientationService
} from "src/app/core";

import {
	SettingsUmListComponent
} from 'src/app/features/settings/settings-users-management/components/settings-users-management/settings-um-list/settings-um-list.component';

import {
	ButtonUmList
} from "src/app/features/settings/settings-users-management/models/settings-users-management.model";

import {
	restManager,
	RetailForceProvider
} from "app/ajs-upgraded-providers";

import {
	FormBuilder,
	Validators
} from "@angular/forms";

import {
	ShopUsers
} from "tilby-models";

import {
	AlertDialogService
} from "src/app/dialogs";

export type UserDevice = {
	shop_id: number;
	serial: string;
	name: string;
	created_at: Date;
	createdby_id: number;
}

@Component({
	selector: 'app-settings-um-devices',
	templateUrl: './settings-um-devices.component.html',
	styleUrls: ['./settings-um-devices.component.scss']
})
export class SettingsUmDevicesComponent {
	@ViewChild(SettingsUmListComponent) componentSettingUmList!: SettingsUmListComponent;

	private readonly alertDialogService = inject(AlertDialogService);
	private readonly fb = inject(FormBuilder);
	private readonly restManager = inject(restManager);
	private readonly configurationManagerService = inject(ConfigurationManagerService);
	private readonly screenOrientationService = inject(ScreenOrientationService);
	private readonly retailForceProvider = inject(RetailForceProvider);

	private cloudApiKey = this.configurationManagerService.getPreference('retailforce.cloud_api_key');
	private cloudApiSecret = this.configurationManagerService.getPreference('retailforce.cloud_api_secret');
	private identification = this.configurationManagerService.getPreference('retailforce.identification');
	private storeNumber = this.configurationManagerService.getPreference('retailforce.store_number');

	private canConfigureClient = !!(this.cloudApiKey && this.cloudApiSecret && this.identification && this.storeNumber);

	protected allDevices: UserDevice[] = [];
	protected customButtons: ButtonUmList[] = [];
	protected devices: UserDevice[] = this.allDevices;
	protected deviceSelected: UserDevice | null = null;
	protected filterText = '';
	protected hasBack = false;
	protected isLandscape = computed(() => !!this.screenOrientationService.getOrientation().includes('landscape'));
	protected isLoading = true;
	protected isRetailForceEnabled: boolean = false;
	protected itemsIcon = 'devices';
	protected retailForceLocal = this.configurationManagerService.getPreference('retailforce.local');
	protected settingUpRetailForce = false;
	protected users: Record<number, string> = {};

	protected retailForceForm = this.fb.group({
		ipAddress: ['', [Validators.required]],
		terminalNumber: ['', [Validators.required]],
		clientId: ['']
	});

	async ngOnInit() {
		this.customButtons.push({ icon: 'filter_list', callback: () => {}, type: 'filter', enabled: true });

		// Get all serial devices
		try {
			const devices = await this.restManager.getList('serial_devices');

			if (devices.length > 0) {
				this.allDevices = this.devices = devices;
			}
		} catch (error) {
			console.error("ERROR: ", error);
		}

		// Get shop users
		try {
			const result = await this.restManager.getList('shop_users') as ShopUsers[];

			if (Array.isArray(result)) {
				this.users = result.reduce((acc, user) => {
					acc[user.user_id!] = `${user.first_name} ${user.last_name}`

					return acc;
				}, {} as Record<number, string>);
			}
		} catch (error) {
			console.error(error);
		}
	}

	getUserInfo(device: UserDevice) {
		return this.users[device.createdby_id] || device.createdby_id;
	}

	buildFieldLegend1(item: UserDevice): string {
		return item.name;
	}

	buildFieldLegend2(item: UserDevice): string {
		return item.serial;
	}

	customFilterFunction(item: UserDevice): boolean {
		return item.name.toLowerCase().includes(this.filterText.toLowerCase())
	}

	async onDeviceSelected(device: UserDevice) {
		if (!this.isLandscape()) {
			this.hasBack = true;
		}

		this.deviceSelected = device;

		const deviceRFConfig = await this.retailForceProvider.getDeviceConfig(device.serial);

		this.retailForceForm.patchValue({
			ipAddress: deviceRFConfig.endpoint_url,
			terminalNumber: deviceRFConfig.terminal_number,
			clientId: deviceRFConfig.client_id
		});
	}

	canInitClient(): boolean {
		return this.canConfigureClient && this.retailForceForm.valid;
	}

	async initClient() {
		if(this.settingUpRetailForce) {
			return;
		}

		if (!this.canInitClient()) {
			return;
		}

		const targetDevice = this.deviceSelected;

		if (!targetDevice) {
			return;
		}

		const terminalNumber = this.retailForceForm.value.terminalNumber!;
		const endpointUrl = this.retailForceForm.value.ipAddress!;

		try {
			this.settingUpRetailForce = true;
			const serial = targetDevice.serial;

			this.retailForceProvider.setDeviceConfig(serial, {
				endpoint_url: endpointUrl,
				terminal_number: terminalNumber
			});

			this.retailForceProvider.setDeviceOverride(targetDevice.serial);

			const clientId = await this.retailForceProvider.configureProvider({
				cloudApiKey: this.cloudApiKey!,
				cloudApiSecret: this.cloudApiSecret!,
				identification: this.identification!,
				storeNumber: this.storeNumber!,
				terminalNumber: terminalNumber,
				endpointUrl: endpointUrl
			});

			const deviceRFConfig = this.retailForceProvider.setDeviceConfig(serial, {
				client_id: clientId.client_id,
				endpoint_url: endpointUrl,
				terminal_number: terminalNumber
			});

			this.retailForceForm.patchValue({
				ipAddress: deviceRFConfig.endpoint_url,
				terminalNumber: deviceRFConfig.terminal_number,
				clientId: deviceRFConfig.client_id
			});
		} catch (error) {
			this.alertDialogService.openDialog({
				data: {
					messageLabel: JSON.stringify(error)
				}
			});
		} finally {
			this.retailForceProvider.clearDeviceOverride();
			this.settingUpRetailForce = false;
		}
	}
}