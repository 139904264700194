import { Injectable } from "@angular/core";
import {PrepaidMovementsFields} from "../../../../features/customers/customers.model";
import {KeyValue} from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import {TilbyDatePipe} from "@tilby/tilby-ui-lib/pipes/tilby-date";
import { TilbyCurrencyPipe } from "@tilby/tilby-ui-lib/pipes/tilby-currency";

@Injectable()
export class GridCellFormatterService {

    constructor(private tilbyDatePipe: TilbyDatePipe, private tilbyCurrencyPipe: TilbyCurrencyPipe, private translateService: TranslateService) {
    }

    tilbyCurrency = (params:KeyValue<string, any>) => {
        let amount = params.value;
        return this.tilbyCurrencyPipe.transform(amount);
    }

    sclAmountTransformAndCurrency = (params:KeyValue<string, any>&{data:PrepaidMovementsFields}) => {
        const {amount, ticket_amount} = params.data;
        return this.tilbyCurrencyPipe.transform(amount||ticket_amount);
    }
    sclCreditTransformAndCurrency = (params:KeyValue<string, any>&{data:PrepaidMovementsFields}) => {
        const {credit, ticket_credit} = params.data;
        return this.tilbyCurrencyPipe.transform(credit+ticket_credit);
    }

    dateTimeFormat = (params:KeyValue<string, any>) => {
        return this.tilbyDatePipe.transform(params.value);
    }

    dateFormat = (params:KeyValue<string, any>) => {
        return this.tilbyDatePipe.transform(params.value, 'dd/MM/yyyy');
    }

    percentuageFormat = (params:KeyValue<string, any>) => {
        return params.value ? params.value + " %" : null;
    }

    // FIX : add shop_name in table prepaid_movements
    shopFormat = (params:KeyValue<string, any>) => {
        return params.value;
    }

    typeDocumentFormat = (params:KeyValue<string, any>) => {
        return params.value ? this.translateService.instant("HISTORY.DOCUMENTS_VIEWER." + params.value.toUpperCase()) : '';
    }

    boolanToYesNoFormat = (params: KeyValue<string, any>) => {
        let checkType = typeof params.value === 'boolean' ? params.value == true : params.value == 'true';
        return checkType ? this.translateService.instant("APPLICATION.YES") : this.translateService.instant("APPLICATION.NO");
    }

    tokenPermission = (params: KeyValue<string, any>) => {
        let translation: string =
        params.value === 'SclStaticRO' ? 'SETTINGS.USERS_MANAGEMENT.PERMISSIONS.SCL_STATIC_RO.TITLE' :
        params.value === 'SclStaticRW' ? 'SETTINGS.USERS_MANAGEMENT.PERMISSIONS.SCL_STATIC_RW.TITLE' :
        params.value === 'SclStaticECom' ? 'SETTINGS.USERS_MANAGEMENT.PERMISSIONS.SCL_STATIC_ECOM.TITLE' :
        params.value === 'SclStaticBookings' ? 'SETTINGS.USERS_MANAGEMENT.PERMISSIONS.SCL_STATIC_BOOKINGS.TITLE' :
        params.value === 'SclStaticStock' ? 'SETTINGS.USERS_MANAGEMENT.PERMISSIONS.SCL_STATIC_STOCK.TITLE' :
        params.value === 'SclStaticCustomers' ? 'SETTINGS.USERS_MANAGEMENT.PERMISSIONS.SCL_STATIC_CUSTOMERS.TITLE' :
        params.value === 'SclStaticStockCustomers' ? 'SETTINGS.USERS_MANAGEMENT.PERMISSIONS.SCL_STATIC_STOCK_CUSTOMERS.TITLE' :
        'SETTINGS.USERS_MANAGEMENT.PERMISSIONS.CUSTOM.TITLE';
        return this.translateService.instant(translation);
    }

    getCashMovementType = (movement_type: {value: string}) =>{
        let translation: string = movement_type.value === 'income' ? 'HISTORY.CASH_MOVEMENTS.INCOME' : 'HISTORY.CASH_MOVEMENTS.OUTCOME';

        return this.translateService.instant(translation);
    };

    getTopBarCashMovements = (movement_type: {value: string}) =>{
        let translation: string = movement_type.value === 'cash' ? 'HISTORY.TOPBAR_CASH_MOVEMENTS.CASHREGISTER' : 'HISTORY.TOPBAR_CASH_MOVEMENTS.OUT_CASHREGISTER';

        return this.translateService.instant(translation);
    }

    getStatusFileImported = (file_imported_status: {value: string}) => {
        return this.translateService.instant(`FILE_IMPORTER.FILTERS.${file_imported_status?.value?.toUpperCase()}`);
    }

    getTypeFileImported = (file_imported_type: {value: string}) => {
        return this.translateService.instant(`FILE_IMPORTER.IMPORT_TYPES.${file_imported_type?.value?.toUpperCase()}`);
    }

    getTable = (file_exported_table: {value: string}) => {
        return this.translateService.instant(`FILE_EXPORTER.TABLES.${file_exported_table?.value?.toUpperCase()}`);
    }

    suppliers = new Map<string, Function>([
        ["created_at", this.dateTimeFormat],
        ["updated_at", this.dateTimeFormat],
    ]);

    customers_prepaid = new Map<string, Function>([
        ["valid_from", this.dateTimeFormat],
        ["amount", this.sclAmountTransformAndCurrency],
        ["credit",this.sclCreditTransformAndCurrency],
        ["shop_uuid", this.shopFormat]
    ]);

    customers_fidelity = new Map<string, Function>([
        ["date", this.dateTimeFormat]
    ]);

    customers = new Map<string, Function>([
        ["tot_spent", this.tilbyCurrency],
        ["avg_spent",this.tilbyCurrency],
        ["credit",this.tilbyCurrency],
        ["discount_perc",this.percentuageFormat],
        ["birthdate",this.dateFormat],
        ["created_at", this.dateTimeFormat],
        ["updated_at", this.dateTimeFormat]
    ]);

    history_sales = new Map<string, Function>([
        ["closed_at",this.dateTimeFormat],
        ["open_at",this.dateTimeFormat],
        ["final_amount",this.tilbyCurrency],
        ["change",this.tilbyCurrency],
        ["type_document", this.typeDocumentFormat],
        ['is_summary', this.boolanToYesNoFormat],
        ['summary_emitted', this.boolanToYesNoFormat],
    ]);

    giftcards = new Map<string, Function>([
        ['active', this.boolanToYesNoFormat],
        ['created_at', this.dateTimeFormat],
        ['deleted_at', this.dateTimeFormat],
        ["initial_value", this.tilbyCurrency],
        ['is_divisible', this.boolanToYesNoFormat],
        ["last_value", this.tilbyCurrency],
        ['updated_at', this.dateTimeFormat],
        ['valid_since', this.dateTimeFormat],
        ['valid_until', this.dateTimeFormat]
    ]);

    token_static = new Map<string, Function>([
        ["client_id",this.tokenPermission],
        ["created_at", this.dateTimeFormat],
    ]);

    history_cash_movements = new Map<string, Function>([
        ["date",this.dateTimeFormat],
        ["amount",this.tilbyCurrency],
        ["movement_type",this.getCashMovementType],
        ["movement_account",this.getTopBarCashMovements]
    ]);
    file_imported = new Map<string, Function>([
        ['status', this.getStatusFileImported],
        ['dateToProcess', this.dateTimeFormat],
        ['typeInsert', this.getTypeFileImported],
    ]);
    file_exported = new Map<string, Function>([
        ['status', this.getStatusFileImported],
        ['created_at', this.dateTimeFormat],
        ['table', this.getTable]
    ]);

    getCustomerTypePromotions = (customer_type: {value: string}) => {
        return customer_type.value === 'null' ? this.translateService.instant('PROMOTIONS.DETAILS.NONE') : customer_type.value;
    };
    promotions = new Map<string, Function>([
        ["created_at", this.dateTimeFormat],
        ["updated_at", this.dateTimeFormat],
        ['deleted_at', this.dateTimeFormat],
        ['customer_type', this.getCustomerTypePromotions],
    ]);

    history_products_deleted = new Map<string, Function>([
        ["lastupdate_at", this.dateTimeFormat],
        ["vat_perc", this.percentuageFormat],
        ["price", this.tilbyCurrency],
        ["cost", this.tilbyCurrency],
        ["revenues", this.tilbyCurrency]
    ]);
}
