<section class="tw-overflow-auto tw-h-[calc(100vh-160px)]" tabindex="0">
    <table mat-table [dataSource]="dataSource" class="tw-text-[13px] tw-border-0">

      <ng-container matColumnDef="channel">
        <th mat-header-cell *matHeaderCellDef translate class="tw-border-solid tw-border-r tw-border-b tw-border-t-0 tw-border-l-0 tw-border-gray-200 tw-text-gray-400"> TABLES.DELIVERY.TABLE.HEADER.CHANNEL </th>
        <td mat-cell *matCellDef="let element" class="tw-border-solid tw-border-r tw-border-b tw-border-t-0 tw-border-l-0 tw-border-gray-200 ">
            <ng-container *ngIf="channelImage(element.channel) as channelImage">
                <img *ngIf="channelImage !== '-'" [src]="'assets/images/channels/' + channelImage + '.png'" style="max-width: 30px; max-height: 30px; background-color: blue">
                <span *ngIf="channelImage === '-'">{{ element.channel }}</span>
            </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="deliver_at">
        <th mat-header-cell *matHeaderCellDef translate class="tw-border-solid tw-border-x-0 tw-border-b tw-border-t-0 tw-border-gray-200 tw-text-gray-400"> TABLES.DELIVERY.TABLE.HEADER.DELIVERY_AT </th>
        <td mat-cell *matCellDef="let element" class="tw-border-solid tw-border-r-0 tw-border-b tw-border-t-0 tw-border-l-0 tw-border-gray-200 "> {{dateFormatted(element.deliver_at)}} </td>
      </ng-container>

      <ng-container matColumnDef="order_type">
        <th mat-header-cell *matHeaderCellDef translate class="tw-border-solid tw-border-x-0 tw-border-b tw-border-t-0 tw-border-gray-200 tw-text-gray-400"> TABLES.DELIVERY.TABLE.HEADER.TYPE </th>
        <td mat-cell *matCellDef="let element" class="tw-border-solid tw-border-r-0 tw-border-b tw-border-t-0 tw-border-l-0 tw-border-gray-200 ">{{getType(element.order_type)}} </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef translate class="tw-border-solid tw-border-x-0 tw-border-b tw-border-t-0 tw-border-gray-200 tw-text-gray-400"> TABLES.DELIVERY.TABLE.HEADER.DESCRIPTION </th>
        <td mat-cell *matCellDef="let element" class="tw-border-solid tw-border-r-0 tw-border-b tw-border-t-0 tw-border-l-0 tw-border-gray-200 ">{{element.description}} </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef translate class="tw-border-solid tw-border-x-0 tw-border-b tw-border-t-0 tw-border-gray-200 tw-text-gray-400"> TABLES.DELIVERY.TABLE.HEADER.CUSTOMER </th>
        <td mat-cell *matCellDef="let element" class="tw-border-solid tw-border-r-0 tw-border-b tw-border-t-0 tw-border-l-0 tw-border-gray-200 "> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="element">
        <th mat-header-cell *matHeaderCellDef translate class="tw-border-solid tw-border-x-0 tw-border-b tw-border-t-0 tw-border-gray-200 tw-text-gray-400"> TABLES.DELIVERY.TABLE.HEADER.ITEMS </th>
        <td mat-cell *matCellDef="let element" class="tw-border-solid tw-border-r-0 tw-border-b tw-border-t-0 tw-border-l-0 tw-border-gray-200 "> {{element.element}} </td>
      </ng-container>

      <ng-container matColumnDef="final_amount">
        <th mat-header-cell *matHeaderCellDef translate class="tw-border-solid tw-border-x-0 tw-border-b tw-border-t-0 tw-border-gray-200 tw-text-gray-400"> TABLES.DELIVERY.TABLE.HEADER.TOTAL </th>
        <td mat-cell *matCellDef="let element" class="tw-border-solid tw-border-r-0 tw-border-b tw-border-t-0 tw-border-l-0 tw-border-gray-200 "> 
          @if(showPrices){
            {{element.final_amount | tilbyCurrency}}
          } @else {
            ***
          }
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr
            mat-row
            (click)="clickSale(row)"
            *matRowDef="let row; columns: displayedColumns;"
        ></tr>
    </table>
  </section>

  <div class="tw-flex tw-justify-end tw-ml-8 tw-mr-8 tw-mt-8">
    <div>
        <button mat-fab color="primary" (click)="newTakeAwaySale()">
            <mat-icon>local_mall</mat-icon>
        </button>
    </div>
    <div class="tw-ml-3">
        <button mat-fab color="primary" (click)="newDeliverySale()">
            <mat-icon>directions_bike</mat-icon>
        </button>
    </div>
  </div>
