
import { Inject, Injectable, inject } from "@angular/core";
import { CustomForm, CustomFormControl, CustomFormControlProps, CustomFormGroup, CustomFormGroupProps, tilbyErrors } from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { AddressType, GeneralFormFields, OperativeModeType } from "../model/general-form-fields";
import { AbstractControl, ValidationErrors, Validators } from "@angular/forms";
import { ConfigurationManagerService, ToolbarEventsService } from "src/app/core";
import { restManager } from "app/ajs-upgraded-providers";
import {
    AlertDialogService,
    OpenDialogsService
} from "src/app/dialogs";
import { AlvoloInitBody } from 'tilby-models';
import { FORM_CONTROL_VALIDATORS } from "@tilby/tilby-ui-lib/utilities";
import { BehaviorSubject } from "rxjs";
import { SettingsAlvoloContentComponent } from "../components";
import { countryCodesShort } from "src/app/core/constants";
import { MapCoordinatesDialogService } from "src/app/dialogs/map-coordinates-dialog";
import { AlvoloStatus } from "../model/alvolo-status";
import { DevLogger } from "src/app/shared/dev-logger";

type Place = {
    address: string,
    locality: string,
    city: string,
    province: string,
    country: string,
    postal_code: string
}

@Injectable({
    providedIn: 'root',
})
export class SettingsAlvoloStateService {
    private readonly alertDialogService = inject(AlertDialogService);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly mapCoordinatesDialogService = inject(MapCoordinatesDialogService);
    private readonly toolbarEventsService = inject (ToolbarEventsService);
    private readonly openDialogService = inject(OpenDialogsService);

    shop_preference: GeneralFormFields<AddressType[]> | null = null;
    isInitialized = new BehaviorSubject<boolean | null>((this.configurationManagerService.getSetting('alvolo.general.alias') && this.configurationManagerService.getShopPreference("alvolo.shop")) ? true : false);
    shopSettingsKey = this.configurationManagerService.getSetting('alvolo.general.key');
    generalShopInformationsForm!:CustomFormGroup<CustomForm<any>>;
    shop!: AlvoloInitBody;
    pricelist_id!: number;
    settingsAlvoloContentComponent!: SettingsAlvoloContentComponent;
    priceList!: {index: number, name: string};
    creatingShop: boolean = false;
    alias!: string;
    place: BehaviorSubject<Place> = new BehaviorSubject<Place>({address: '', locality: '', city: '', province: '', country: '', postal_code: ''});
    $place = this.place.asObservable();
    shopStatus = new BehaviorSubject<AlvoloStatus>({
        payload: {
            sales_enabled: false,
            bookings_enabled: false
        },
        crc: ''
    });
    shopStatus$ = this.shopStatus.asObservable();
    alvolo_selfordering!: boolean;
    alvolo_takeaway_delivery!: boolean;
    initializationOperation = this.configurationManagerService.getShopPreference('alvolo.initialization.operation');


    constructor(
        @Inject(restManager) private restManagerService: any
    ){
        this.isInitialized.subscribe((isInitialized) => {
            if(isInitialized) this.getShopStatus();
        })
        this.getShopPreference();

        const modulesPermissions = this.configurationManagerService.getModulesStatus();

        this.alvolo_selfordering = modulesPermissions.enabledModules['alvolo_selfordering'] || false;
        this.alvolo_takeaway_delivery = modulesPermissions.enabledModules['alvolo_takeaway_delivery'] || false;
    }

    createGeneralShopInformationsForm() {
        this.generalShopInformationsForm = new CustomFormGroup<CustomForm<GeneralFormFields>>({
            operative_mode: new CustomFormGroup<CustomForm<OperativeModeType | string[]>>({
                delivery: new CustomFormControl(false, {}, {...new CustomFormControlProps(), readonly:!this.alvolo_takeaway_delivery, inputType:'checkbox' ,label: this.alvolo_takeaway_delivery ? 'SETTINGS.ALVOLO.GENERALS.OPERATIVE_MODE_TYPE.DELIVERY' : 'SETTINGS.ALVOLO.GENERALS.OPERATIVE_MODE_TYPE.DELIVERY_INACTIVE', id:'operative_mode_delivery', focusInitial: true, class:"tw-py-2"}, ),
                takeaway: new CustomFormControl(false, {}, {...new CustomFormControlProps(), readonly:!this.alvolo_takeaway_delivery, inputType:'checkbox' ,label: this.alvolo_takeaway_delivery ? 'SETTINGS.ALVOLO.GENERALS.OPERATIVE_MODE_TYPE.TAKEAWAY' : 'SETTINGS.ALVOLO.GENERALS.OPERATIVE_MODE_TYPE.TAKEAWAY_INACTIVE', id:'input-operative_mode_takeaway', focusInitial: true, class:"tw-py-2"}, ),
                pickup: new CustomFormControl(false, {}, {...new CustomFormControlProps(), readonly:!this.alvolo_selfordering, inputType:'checkbox' ,label: this.alvolo_selfordering ? 'SETTINGS.ALVOLO.GENERALS.OPERATIVE_MODE_TYPE.PICKUP' : 'SETTINGS.ALVOLO.GENERALS.OPERATIVE_MODE_TYPE.PICKUP_INACTIVE', id:'input-operative_mode_pickup', focusInitial: true, class:"tw-py-2"}, ),
                readonly: new CustomFormControl(false, {}, {...new CustomFormControlProps(), inputType:'checkbox' ,label:'SETTINGS.ALVOLO.GENERALS.OPERATIVE_MODE_TYPE.READONLY', id:'input-operative_mode_readonly', focusInitial: true, class:"tw-py-2"}, ),
                pay: new CustomFormControl(false, {}, {...new CustomFormControlProps(), inputType:'checkbox' ,label:'SETTINGS.ALVOLO.GENERALS.OPERATIVE_MODE_TYPE.PAY', id:'input-operative_mode_pay', focusInitial: true, class:"tw-py-2"}, )
            }, {validators: [Validators.required, this.checkOperativeModeCheckbox]}, {...new CustomFormGroupProps(), errors:[...tilbyErrors, {key: 'atLeastOneCheckboxShouldBeChecked', value: 'SETTINGS.ALVOLO.GENERALS.AT_LEAST_ONE_OPERATIVE_MODE'}], matErrorClass:'tw-w-full tw-pb-2', label:'SETTINGS.ALVOLO.GENERALS.OPERATIVE_MODE', classLabel:'tw-text-xl tw-py-2 tw-ml-2', class:"tw-w-full"}),
            company_name: new CustomFormControl('', { validators: [Validators.required] }, {...new CustomFormControlProps(), inputType:'text', label:'SETTINGS.ALVOLO.GENERALS.COMPANY_NAME', id:'input-company_name', class:'tw-w-1/3' }),
            signboard: new CustomFormControl('', { validators: [Validators.required] }, {...new CustomFormControlProps(), label:'SETTINGS.ALVOLO.GENERALS.SIGNBOARD', id:'input-signboard', class:'tw-w-1/3'}),
            short_description: new CustomFormControl('', {}, {...new CustomFormControlProps(), inputType:'textarea',  label:'SETTINGS.ALVOLO.GENERALS.SHORT_DESCRIPTION', id:'input-short_description', class:'tw-w-1/3',matElementClass:'tw-min-h-[19px]' }),
            long_description: new CustomFormControl('', {}, {...new CustomFormControlProps(), inputType:'textarea',  label:'SETTINGS.ALVOLO.GENERALS.LONG_DESCRIPTION', id:'input-long_description', class:'tw-w-1/3',matElementClass:'tw-min-h-[19px]'}),
            phone: new CustomFormControl('', { validators: [Validators.pattern("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$")]}, {...new CustomFormControlProps(), inputType:'text', label:'SETTINGS.ALVOLO.GENERALS.PHONE', id:'input-phone', class:'tw-w-1/3'}),
            mobile: new CustomFormControl('', { validators: [Validators.pattern("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$")]}, {...new CustomFormControlProps(), inputType:'text', label:'SETTINGS.ALVOLO.GENERALS.MOBILE', id:'input-mobile', class:'tw-w-1/3'}),
            email: new CustomFormControl('', { validators: [Validators.email]}, {...new CustomFormControlProps(), inputType:'email', label:'SETTINGS.ALVOLO.GENERALS.EMAIL', id:'input-email', class:'tw-w-1/3'}),
            skype: new CustomFormControl('', {}, {...new CustomFormControlProps(), inputType:'text', label:'SETTINGS.ALVOLO.GENERALS.SKYPE', id:'input-skype', class:'tw-w-1/3'}),
            whatsapp: new CustomFormControl('', { validators: [Validators.pattern("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$")] }, {...new CustomFormControlProps(), inputType:'text', label:'SETTINGS.ALVOLO.GENERALS.WHATSAPP', id:'input-whatsapp', class:'tw-w-1/3'}),
            facebook: new CustomFormControl('', {validators: [Validators.pattern("(?:https?:\/\/)?(?:www.)?facebook.com\/?([a-zA-Z0-9\.\_\-]+)?\/")]}, {...new CustomFormControlProps(), inputType:'text', label:'SETTINGS.ALVOLO.GENERALS.FACEBOOK', id:'input-facebook', class:'tw-w-1/3'}),
            twitter: new CustomFormControl('', {validators: [Validators.pattern("^(http\:\/\/|https\:\/\/)?(?:www\.)?twitter\.com\/(?:#!\/)?@?([^\?#]*)(?:[?#].*)?$")]}, {...new CustomFormControlProps(), inputType:'text', label:'SETTINGS.ALVOLO.GENERALS.TWITTER', id:'input-twitter', class:'tw-w-1/3'}),
            instagram: new CustomFormControl('', {validators: [Validators.pattern("(?:https?:\/\/)?(?:www.)?instagram.com\/?([a-zA-Z0-9\.\_\-]+)?\/")]}, {...new CustomFormControlProps(), inputType:'text', label:'SETTINGS.ALVOLO.GENERALS.INSTAGRAM', id:'input-instagram', class:'tw-w-1/3'}),
            addresses: new CustomFormGroup<CustomForm<AddressType>>({
                address: new CustomFormControl('', { validators: [Validators.required] }, {...new CustomFormControlProps(), inputType:'text', label:'SETTINGS.ALVOLO.GENERALS.ADDRESS_INFO.ADDRESS', id:'input-address', class:'tw-w-1/3'}),
                city: new CustomFormControl('', { validators: [Validators.required] }, {...new CustomFormControlProps(), inputType:'text', label:'SETTINGS.ALVOLO.GENERALS.ADDRESS_INFO.CITY', id:'input-city', class:'tw-w-1/3'}),
                country: new CustomFormControl('', { validators: [Validators.required] }, {...new CustomFormControlProps(), inputType:'autocomplete', inputChoices: countryCodesShort.map(({code,name})=>({key:code,value: name})), label:'SETTINGS.ALVOLO.GENERALS.ADDRESS_INFO.COUNTRY', id:'input-country', class:'tw-w-1/3'}),
                postcode: new CustomFormControl('', { validators: [Validators.required] }, {...new CustomFormControlProps(), inputType:'text', label:'SETTINGS.ALVOLO.GENERALS.ADDRESS_INFO.POSTAL_CODE', id:'input-postal-code', class:'tw-w-1/3'}),
                latitude: new CustomFormControl('', {}, {...new CustomFormControlProps(), inputType:'text', label:'SETTINGS.ALVOLO.GENERALS.ADDRESS_INFO.LATITUDE', id:'input-latitude', class:'tw-hidden'}),
                longitude: new CustomFormControl('', {}, {...new CustomFormControlProps(), inputType:'text', label:'SETTINGS.ALVOLO.GENERALS.ADDRESS_INFO.LONGITUDE', id:'input-longitude', class:'tw-hidden'}),
            }, {}, {...new CustomFormGroupProps(), label:'SETTINGS.ALVOLO.GENERALS.ADDRESS', classLabel:'tw-text-xl tw-py-2 tw-ml-2', class:"tw-w-full"}),
            map: new CustomFormControl(undefined, { validators: [Validators.required]}, {...new CustomFormControlProps(), inputType:'contentProjection',
                customActions: {suffix: {icon: 'edit', callback:() => {
                    this.mapCoordinatesDialogService.openDialog('SETTINGS.ALVOLO.GENERALS.SELECT_COORDINATES', (this.shop_preference && this.shop_preference?.addresses.length > 0) ? this.shop_preference?.addresses[0] : this.generalShopInformationsForm.controls.addresses.value.address, this.generalShopInformationsForm.controls.addresses.value.latitude, this.generalShopInformationsForm.controls.addresses.value.longitude).then((result) => {
                        if(result.place) {
                            const street_number = result.place.address_components.find((obj: {types: string[], short_name: string,  long_name: string}) => obj.types.includes("street_number"))?.short_name;
                            const route = result.place.address_components.find((obj: {types: string[], short_name: string,  long_name: string}) => obj.types.includes("route"))?.short_name;
                            const locality = result.place.address_components.find((obj: {types: string[], short_name: string,  long_name: string}) => obj.types.includes("locality"))?.short_name;
                            const city = result.place.address_components.find((obj: {types: string[], short_name: string,  long_name: string}) => obj.types.includes("administrative_area_level_2"))?.short_name;
                            const province = result.place.address_components.find((obj: {types: string[], short_name: string,  long_name: string}) => obj.types.includes("administrative_area_level_1"))?.short_name;
                            const country = result.place.address_components.find((obj: {types: string[], short_name: string,  long_name: string}) => obj.types.includes("country"))?.short_name;
                            const postal_code = result.place.address_components.find((obj: {types: string[], short_name: string,  long_name: string}) => obj.types.includes("postal_code"))?.short_name;

                            this.place.next({
                                address: street_number ? route + ', ' + street_number : route,
                                city,
                                country,
                                locality,
                                postal_code,
                                province,
                            })
                        }

                        // this.addresses.controls.address.setValue(route + ', ' + street_number);
                        // this.addresses.controls.city.setValue(locality);
                        // countryCodesShort.filter(({code,name})=> {
                        //     if(code === country) {
                        //         this.addresses.controls.country.setValue(name);
                        //     }
                        // })
                        // this.addresses.controls.postcode.setValue(postal_code);
                        
                        if(result.latitude && result.longitude) {
                            this.addresses.controls.latitude.setValue(result.latitude);
                            this.addresses.controls.longitude.setValue(result.longitude);

                            this.map.setErrors(null);
                            this.generalShopInformationsForm.markAsTouched();
                        } else {
                            if(!this.generalShopInformationsForm.controls.addresses.value.latitude && !this.generalShopInformationsForm.controls.addresses.value.longitude) {
                                this.map.markAsDirty();
                                this.map.setErrors({required: true});
                            }
                        }
                    })
            }}}, class:'tw-w-full'}),
            notification_addresses: new CustomFormControl(null, {}, {...new CustomFormControlProps(), label:'SETTINGS.ALVOLO.GENERALS.NOTIFICATION_ADDRESSES', id:'input-notification_addresses', class:'tw-w-full'}),
            days_to_show: new CustomFormControl(null, { validators: [FORM_CONTROL_VALIDATORS.cannotContainCharacters, this.checkValue] }, {...new CustomFormControlProps(), errors:[...tilbyErrors, {key: 'cannotContainCharacters', value: 'SETTINGS.ALVOLO.GENERALS.NO_CHARACTERS'}, {key: 'valueTooHigh', value: 'SETTINGS.ALVOLO.GENERALS.MAX_DAYS'}], inputType:'text', label:'SETTINGS.ALVOLO.GENERALS.DAYS_TO_SHOW', id:'input-days_to_show', class:'tw-w-1/3'}),
            pickup_code_validity: new CustomFormControl(null, { validators: [FORM_CONTROL_VALIDATORS.cannotContainCharacters] }, {...new CustomFormControlProps(), errors:[...tilbyErrors, {key: 'cannotContainCharacters', value: 'SETTINGS.ALVOLO.GENERALS.NO_CHARACTERS'}], inputType:'text', label:'SETTINGS.ALVOLO.GENERALS.PICKUP_CODE_VALIDITY', id:'input-pickup_code_validity', class:'tw-w-1/3'}),
            order_delay: new CustomFormControl(null, { validators: [FORM_CONTROL_VALIDATORS.cannotContainCharacters] }, {...new CustomFormControlProps(), errors:[...tilbyErrors, {key: 'cannotContainCharacters', value: 'SETTINGS.ALVOLO.GENERALS.NO_CHARACTERS'}], inputType:'text', label:'SETTINGS.ALVOLO.GENERALS.ORDER_DELAY', id:'input-order_delay', class:'tw-w-1/3'}),
            hashtag: new CustomFormControl(null, {}, {...new CustomFormControlProps(), inputType:'text', label:'SETTINGS.ALVOLO.GENERALS.HASTAG', id:'input-hastag', class:'tw-w-1/3'}),
            pickup_enable_guest: new CustomFormControl(false, {}, {...new CustomFormControlProps(), inputType:'checkbox' ,label:'SETTINGS.ALVOLO.GENERALS.PICKUP_ENABLE_GUEST', id:'input-pickup_enable_guest', class:'tw-w-1/3'}),
        });

        this.createToolbarButtons();

        return this.generalShopInformationsForm;
    }

    async getShopStatus() {
        await this.restManagerService.getOne("alvolo/api/shops/status").then((response: AlvoloStatus) => {
            if(response) this.shopStatus.next(response);
        }).catch((error: any) => {
            DevLogger.error("error: ", error)
        })
    }

    async editAlvoloSale(payload: any) {
        await this.restManagerService.put("alvolo/api/shops/sales", null, payload);

        setTimeout(() => {
            this.getShopStatus();
        }, 4000);
    }

    async editAlvoloBookings(payload: any) {
        await this.restManagerService.put("alvolo/api/shops/bookings", null, payload);

        setTimeout(() => {
            this.getShopStatus();
        }, 4000);
    }

    createToolbarButtons() {
        this.toolbarEventsService.buttons$.next({
            barButtons: [
                {
                    isIt: () => true,
                    isDisable: () => !this.shopIsInitialized() ? (this.creatingShop || false) : (this.generalShopInformationsForm.valid && !this.generalShopInformationsForm.touched),
                    name: 'saveGeneralForm',
                    icon: () => 'check',
                    click: () => this.confirm(),
                }
            ],
            panelButtons: []
        })
    }

    confirm () {
        if(this.generalShopInformationsForm.valid && this.generalShopInformationsForm.touched && this.checkContats() === null && this.checkOperativeModeCheckbox(this.generalShopInformationsForm.controls.operative_mode) === null){
            if(this.shopIsInitialized()) { 
                this.updateExistingShop(this.mapFields())
            } else {
                const initializationOperation = this.configurationManagerService.getShopPreference('alvolo.initialization.operation');
                if(initializationOperation === 'create') { 
                    this.createNewShop(this.mapFields());
                } else if(initializationOperation === 'connect'){
                    this.updateExistingShop(this.mapFields());
                    this.alvoloSync();
                    this.isInitialized.next(true);
                    this.toolbarEventsService.showButtonsBar$.next(false);
                    this.getShopStatus();
                }
            }
            this.generalShopInformationsForm.markAsUntouched();
        } else {
            this.generalShopInformationsForm.markAllAsTouched();
            !this.generalShopInformationsForm?.controls.operative_mode.valid && this.generalShopInformationsForm?.controls.operative_mode.markAsDirty();
            !this.generalShopInformationsForm?.controls.map.valid && this.generalShopInformationsForm?.controls.map.markAsDirty();
            if (this.checkContats()){ 
                this.alertDialogService.openDialog({ data: { messageLabel: 'SETTINGS.ALVOLO.GENERALS.AT_LEAST_ONE_CONTACT' } });
            }
        }
    }

    shopIsInitialized() {
        const alias = this.configurationManagerService.getSetting('alvolo.general.alias');
        const shop =  this.configurationManagerService.getShopPreference("alvolo.shop");
        return ((alias ?? this.alias) && shop);
    }

    mapFields(){
        const valueFromForm = this.generalShopInformationsForm.value;
        const service_time = this.getShopPreference()?.service_time;
        const bookings_time = this.getShopPreference()?.bookings_time;
        const bookings_days_to_show = this.getShopPreference()?.bookings_days_to_show;
        const bookings_delay = this.getShopPreference()?.bookings_delay;

        const mapKeys = (array:string[]) => {
            const arr: string[] = [];
            Object.entries(array).forEach(([key, value])=>{ value && arr.push(key)})
            return arr;
        };

        //START CONTACTS
        const contacts = [];

        if(valueFromForm.phone) {
            contacts.push({type: 'phone', value: valueFromForm.phone, main: true});
        }
        if(valueFromForm.mobile) {
            contacts.push({type: 'mobile', value: valueFromForm.mobile, main: true});
        }
        if(valueFromForm.email) {
            contacts.push({type: 'email', value: valueFromForm.email, main: true});
        }
        if(valueFromForm.skype) {
            contacts.push({type: 'skype', value: valueFromForm.skype, main: true});
        }
        if(valueFromForm.whatsapp) {
            contacts.push({type: 'whatsapp', value: valueFromForm.whatsapp, main: true});
        }
        //END CONTACTS

        //START SOCIAL
        const social = [];

        if(valueFromForm.facebook) {
            social.push({type: 'facebook', value: valueFromForm.facebook, main: true});
        }
        if(valueFromForm.twitter) {
            social.push({type: 'twitter', value: valueFromForm.twitter, main: true});
        }
        if(valueFromForm.instagram) {
            social.push({type: 'instagram', value: valueFromForm.instagram, main: true});
        }
        //END SOCIAL

        return  {
            operative_mode: mapKeys(valueFromForm.operative_mode) || [],
            company_name: valueFromForm.company_name,
            signboard: valueFromForm.signboard,
            addresses: [valueFromForm.addresses],
            short_description: [{lang: "it", value: valueFromForm.short_description}],
            long_description: [{lang: "it", value: valueFromForm.long_description}],
            contacts: contacts,
            social: social,
            opening_time: [
                {
                    date: null,
                    week_day: "",
                    start: "",
                    end: "",
                    capacity: null,
                    single_fraction_capacity: 0,
                    single_order_capacity: 0,
                    single_order_money_capacity: null,
                    single_order_min_money_capacity: null,
                    operative_mode: {delivery: false, takeaway: false},
                    hourly_fractions: 0,
                    max_orders_per_fraction: 0,
                    max_distance: 0,
                    delivery_options: {
                        delivery_price: {
                            pricelist_id: "",
                            price: 0,
                            vat_price: 0,
                            vat: 0
                        },
                        delivery_free_from: 0,
                        distance_based_delivery_price: {}
                    },
                    hidden_categories: {},
                    hidden_products: {}
                }
            ],
            service_time: service_time ? service_time : [
                {
                    date: null,
                    week_day: "",
                    start: "",
                    end: "23:59",
                    capacity: null,
                    single_fraction_capacity: 0,
                    single_order_capacity: 0,
                    single_order_money_capacity: null,
                    single_order_min_money_capacity: null,
                    operative_mode: [],
                    hourly_fractions: 0,
                    max_orders_per_fraction: 0,
                    max_distance: 0,
                    delivery_options: {
                        delivery_price: {
                            pricelist_id: "",
                            price: 0,
                            vat_price: 0,
                            vat: 0
                        },
                        delivery_free_from: 0,
                        distance_based_delivery_price: {}
                    },
                    hidden_categories: {},
                    hidden_products: {}
                }
            ],
            service_time_default_values: [],
            order_delay: valueFromForm.order_delay ? +valueFromForm.order_delay : null,
            available_payments: (this.shopSettingsKey && Array.isArray(this.shop_preference?.available_payments) && this.shop_preference?.available_payments.length !==0) ? this.shop_preference?.available_payments : ['cash'],
            notification_addresses: valueFromForm.notification_addresses !== "" ? [{type: 'all', values: [valueFromForm.notification_addresses]}] : [{type: 'all', values: ['']}],
            hashtag: [valueFromForm.hashtag],
            disable_lotteria_scontrini: null,
            digital_receipt_enabled: null,
            digital_receipt_mandatory: null,
            overwrite_enabled: true,
            reservations: [],
            days_to_show: valueFromForm.days_to_show ? +valueFromForm.days_to_show : null,
            pickup_code_validity: valueFromForm.pickup_code_validity ? +valueFromForm.pickup_code_validity : null,
            pickup_enable_guest: valueFromForm.pickup_enable_guest,
            bookings_time: (bookings_time && bookings_time?.length > 0) ? bookings_time : null,
            bookings_time_default_values: [],
            bookings_days_to_show: bookings_days_to_show ? bookings_days_to_show : null,
            bookings_delay: bookings_delay ? bookings_delay : null,
            loyalty: null,
            demo: null,
            partner: null,
            pos_type: "Tilby",
            operative_mode_settings: {
                delivery: null,
                takeaway: null,
                pickup: null,
                locked: null,
            }
        };
    }

    alvoloInitHandler(alias: string, priceList: {index: number, name: string}) {
        this.shop = {
            payload: {
                shop: {
                    alias: alias
                }
            }
        }
        this.priceList = priceList;
    }

    async updateExistingShop(shopInformationForm: GeneralFormFields<AddressType[]>) {
        await this.restManagerService.put('alvolo/api', 'shops', {payload: { shop: shopInformationForm }}).then((response: any) => {
            if(response){
                if(this.configurationManagerService.getShopPreference('alvolo.initialization.operation') === 'connect') {
                    this.configurationManagerService.setShopPreference("alvolo.shop", JSON.stringify(shopInformationForm));
                    this.openDialogService.openSnackBarTilby('SETTINGS.ALVOLO.ACTIVATE.ACTIVATED', 'MISC.OK', { duration: 3000 });  
                    this.alvoloSync();
                    this.isInitialized.next(true);
                    this.toolbarEventsService.showButtonsBar$.next(false);
                    this.getShopStatus();
                    this.configurationManagerService.deleteShopPreference('alvolo.initialization.operation');
                }else {
                    this.configurationManagerService.setShopPreference("alvolo.shop", JSON.stringify(shopInformationForm));
                    this.openDialogService.openSnackBarTilby('SETTINGS.ALVOLO.GENERALS.SAVED', 'MISC.OK', { duration: 3000 });  
                    this.generalShopInformationsForm.reset();
                }
            }
        })
        .catch((error: any) => this.alertDialogService.openDialog({ data: { messageLabel: 'SETTINGS.ALVOLO.ACTIVATE.ERROR_UPDATE_SHOP' } }))
        this.patchValueForm();
    }

    async createNewShop(shopInformationForm: GeneralFormFields<AddressType[]>) {
        this.creatingShop = true;
        await this.restManagerService.post('alvolo/api/shops', { payload: { shop: shopInformationForm }})
        .then((response: any) => {
            if(response){
                this.configurationManagerService.setShopPreference("alvolo.shop", JSON.stringify(shopInformationForm));
                this.openDialogService.openSnackBarTilby('SETTINGS.ALVOLO.ACTIVATE.CREATED', 'MISC.OK', { duration: 3000 });  
                this.alvoloSync();
                this.isInitialized.next(true);
                this.toolbarEventsService.showButtonsBar$.next(false);
                this.getShopStatus();
            }
        })
        .catch((error: any) => {
            this.alertDialogService.openDialog({ data: { messageLabel: 'SETTINGS.ALVOLO.ACTIVATE.ERROR_CREATE_SHOP' } });
        })
        .finally(() => this.creatingShop = false)
        this.patchValueForm();
    }

    async alvoloSync(){
        await this.restManagerService.getOne('alvolo/sync');
    }

    getShopPreference(){
        try {
            const shopPreference = this.configurationManagerService.getShopPreference("alvolo.shop");
            this.shop_preference = shopPreference ? JSON.parse(shopPreference) : "";
        } catch(e) {
            this.shop_preference = null;
        }
        return this.shop_preference;
    }

    checkOperativeModeCheckbox(control: AbstractControl): ValidationErrors | null {
        return control.value.delivery || control.value.takeaway || control.value.pay || control.value.pickup || control.value.readonly ? null : { atLeastOneCheckboxShouldBeChecked: true } as ValidationErrors;
    }

    checkContats(): ValidationErrors | null  {
        return this.generalShopInformationsForm.get('phone')?.value || this.generalShopInformationsForm.get('mobile')?.value ||  this.generalShopInformationsForm.get('email')?.value || this.generalShopInformationsForm.get('skype')?.value || this.generalShopInformationsForm.get('whatsapp')?.value ? null : { atLeastOneContactShouldBeInserted: true } as ValidationErrors;
    }

    patchValueForm() {
        this.getShopPreference();
        if(!this.shop_preference) return;
        this.generalShopInformationsForm.patchValue(this.mappingShopInformations(this.shop_preference));
    }

    mappingShopInformations(generalShopInformations: any){
        let phone = "";
        let mobile = "";
        let email = "";
        let skype = "";
        let whatsapp = "";

        generalShopInformations.contacts.forEach((contact: any) => {
            if(contact.type === 'phone' && contact.value){
                phone = contact.value
            }
            if(contact.type === 'mobile' && contact.value){
                mobile = contact.value
            }
            if(contact.type === 'email' && contact.value){
                email = contact.value
            }
            if(contact.type === 'skype' && contact.value){
                skype = contact.value
            }
            if(contact.type === 'whatsapp' && contact.value){
                whatsapp = contact.value   
            }
        })


        let facebook = "";
        let twitter = "";
        let instagram = "";

        generalShopInformations.social?.forEach((social: any) => {
            if(social.type === 'facebook' && social.value){
                facebook = social.value
            }

            if(social.type === 'twitter' && social.value){
                twitter = social.value
            }

            if(social.type === 'instagram' && social.value){
                instagram = social.value
            }
        })

        return {
            company_name: generalShopInformations.company_name,
            signboard: generalShopInformations.signboard,
            short_description: generalShopInformations.short_description[0].value,
            long_description: generalShopInformations.long_description[0].value,
            contacts: generalShopInformations.contacts[0].value,
            social: generalShopInformations.social,
            notification_addresses: generalShopInformations.notification_addresses[0].values[0] ? generalShopInformations.notification_addresses[0].values[0] : '',
            days_to_show: generalShopInformations.days_to_show ? generalShopInformations.days_to_show.toString() : null,
            pickup_code_validity: generalShopInformations.pickup_code_validity ? generalShopInformations.pickup_code_validity.toString() : '',
            pickup_enable_guest: generalShopInformations.pickup_enable_guest,
            order_delay: generalShopInformations.order_delay ? generalShopInformations.order_delay.toString() : '',
            operative_mode: {
                delivery: this.alvolo_takeaway_delivery ? generalShopInformations.operative_mode.includes( "delivery") : false,
                takeaway: this.alvolo_takeaway_delivery ? generalShopInformations.operative_mode.includes("takeaway") : false,
                pickup:  this.alvolo_selfordering ? generalShopInformations.operative_mode.includes("pickup") : false,
                readonly: generalShopInformations.operative_mode.includes("readonly"),
                pay: generalShopInformations.operative_mode.includes("pay"),
            },
            hashtag: generalShopInformations.hashtag && generalShopInformations.hashtag[0],
            disable_lotteria_scontrini: generalShopInformations.disable_lotteria_scontrini,
            digital_receipt_enabled: generalShopInformations.digital_receipt_enabled,
            digital_receipt_mandatory: generalShopInformations.digital_receipt_mandatory,
            overwrite_enabled: true,
            bookings_days_to_show: generalShopInformations.bookings_days_to_show ? generalShopInformations.bookings_days_to_show : null,
            bookings_delay: generalShopInformations.bookings_delay,
            demo: generalShopInformations.demo,
            partner: generalShopInformations.partner,
            pos_type: generalShopInformations.pos_type,
            phone, 
            mobile, 
            email,
            skype,
            whatsapp,
            facebook,
            twitter,
            instagram,
            available_payments: {
                cash: generalShopInformations.available_payments.includes("cash"),
                credit_card: generalShopInformations.available_payments.includes("credit_card"),
                debit_card: generalShopInformations.available_payments.includes("debit_card"),
                satispay: generalShopInformations.available_payments.includes("satispay"),
                paypal: generalShopInformations.available_payments.includes("paypal"),
                app_stripe: generalShopInformations.available_payments.includes("app_stripe"),
                app_paypal: generalShopInformations.available_payments.includes("app_paypal"),
                app_satispay: generalShopInformations.available_payments.includes("app_satispay"),
                app_nexi: generalShopInformations.available_payments.includes("app_nexi"),
                webapp_stripe: generalShopInformations.available_payments.includes("webapp_stripe"),
                webapp_paypal: generalShopInformations.available_payments.includes("webapp_paypal"),
                webapp_satispay: generalShopInformations.available_payments.includes("webapp_satispay"),
                webapp_nexi: generalShopInformations.available_payments.includes("webapp_nexi"),
            },
            addresses: {
                address: generalShopInformations.addresses[0].address,
                city: generalShopInformations.addresses[0].city,
                country: generalShopInformations.addresses[0].country,
                postcode: generalShopInformations.addresses[0].postcode,
                latitude: generalShopInformations.addresses[0].latitude,
                longitude: generalShopInformations.addresses[0].longitude
            },
            map: {
                latitude: generalShopInformations.addresses[0].latitude, 
                longitude: generalShopInformations.addresses[0].longitude
            }
        }
    }

    getElementRef(component: SettingsAlvoloContentComponent){
        this.settingsAlvoloContentComponent = component;
    }

    checkValue(control: AbstractControl): ValidationErrors | null {
        if(control.value) {
            if(control.value > 0 && control.value < 30){
                return null;
            } else {
                return { valueTooHigh: true } as ValidationErrors;
            }
        }
        return null
    }

    get operative_mode() {return this.generalShopInformationsForm.controls.operative_mode; }
    get company_name() {return this.generalShopInformationsForm.controls.company_name; }
    get signboard() {return this.generalShopInformationsForm.controls.signboard; }
    get short_description() {return this.generalShopInformationsForm.controls.short_description; }
    get long_description() {return this.generalShopInformationsForm?.controls.long_description; }
    get phone() {return this.generalShopInformationsForm?.controls.phone; }
    get mobile() {return this.generalShopInformationsForm?.controls.mobile; }
    get email() {return this.generalShopInformationsForm?.controls.email; }
    get skype() {return this.generalShopInformationsForm?.controls.skype; }
    get whatsapp() {return this.generalShopInformationsForm?.controls.whatsapp; }
    get facebook() {return this.generalShopInformationsForm?.controls.facebook; }
    get twitter() {return this.generalShopInformationsForm?.controls.twitter; }
    get instagram() {return this.generalShopInformationsForm?.controls.instagram; }
    get social() {return this.generalShopInformationsForm?.controls.social; }
    get notification_addresses() {return this.generalShopInformationsForm?.controls.notification_addresses; }
    get days_to_show() {return this.generalShopInformationsForm?.controls.days_to_show; }
    get pickup_code_validity() {return this.generalShopInformationsForm?.controls.pickup_code_validity; }
    get pickup_enable_guest() {return this.generalShopInformationsForm?.controls.pickup_enable_guest; }
    get order_delay() {return this.generalShopInformationsForm?.controls.order_delay; }
    get addresses() {return this.generalShopInformationsForm?.controls.addresses as CustomFormGroup<CustomForm<AddressType>>; }
    get bookings_time() {return this.generalShopInformationsForm?.controls.bookings_time; }
    get map() { return this.generalShopInformationsForm?.controls.map; }
}
