import {
    Component,
    OnInit,
    inject
} from '@angular/core';

import {
    $translate,
    errorsLogger,
    restManager,
    sessionManager,
    util,
} from 'app/ajs-upgraded-providers';

import {
    AlertDialogService,
    ConfirmDialogService,
    OpenDialogsService
} from 'src/app/dialogs';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import {
    ConfigurationManagerService,
    ConfigurationPreferences,
    EntityManagerService,
    EnvironmentInfoService,
    ModuleEvents,
    OauthService,
    ThemeModeService,
    ToolbarEventsService
} from 'src/app/core';

import { UiLanguage, uiLanguages } from 'src/app/core/constants/ui-languages';
import { EditFirstNameDialogService } from 'src/app/dialogs/edit-firstname-dialog';
import { EditLastNameDialogService } from 'src/app/dialogs/edit-lastname-dialog';
import { ServerlessUser } from 'tilby-models';
import { OnDestroyService } from 'src/app/core/services/on-destroy.service';
import { SelectThemeDialogService } from 'src/app/dialogs/select-theme-dialog';
import { UserSessionsManagerDialogService } from 'src/app/dialogs/user-sessions-manager-dialog/user-sessions-manager-dialog.component';

type UserData = {
    first_name?: string,
    last_name?: string,
    username?: string,
    email?: string,
    phone?: string,
    pin?: string | null,
    thumbnail?: string,
    uiLanguage?: UiLanguage
}

@Component({
  selector: 'app-settings-user',
  templateUrl: './settings-user.component.html',
  styleUrls: ['./settings-user.component.scss'],
  providers: [OnDestroyService]
})
export class SettingsUserComponent implements OnInit {
    private readonly _snackBar = inject(MatSnackBar);
    private readonly $translate = inject($translate);
    private readonly alertDialogService = inject(AlertDialogService);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly editFirstNameDialogService = inject(EditFirstNameDialogService);
    private readonly editLastNameDialogService = inject(EditLastNameDialogService);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly environmentInfo = inject(EnvironmentInfoService);
    private readonly errorsLoggerService = inject(errorsLogger);
    private readonly oauth = inject(OauthService);
    private readonly onDestroyService = inject(OnDestroyService);
    private readonly openDialogsService = inject(OpenDialogsService);
    private readonly restManagerService = inject(restManager);
    private readonly selectThemeDialogService = inject(SelectThemeDialogService);
    protected readonly themeModeService = inject(ThemeModeService);
    private readonly toolbarEventsService = inject(ToolbarEventsService);
    private readonly translate = inject(TranslateService);
    private readonly util = inject(util);
    private readonly userSessionsManager = inject(UserSessionsManagerDialogService)
    private readonly sessionManager = inject(sessionManager);

    userData: UserData = {};
    demoUsers = [
        'utente.demo',
        'demoitalia',
        'demospain',
        'demofrance',
        'demogermany',
        'demoaustria',
        'demoenglish'
    ];
    isChangeTheme = false;
    showThemeSwitch = !!this.configurationManagerService.getPreference('migration.show_theme_switch');
    canChangePassword = this.environmentInfo.canOpenExternalLinks();
    enableUserLogoutPin = !!this.configurationManagerService.getPreference('users.enable_user_logout_pin');

    async ngOnInit() {
        this.createToolbarButtons();
        this.setModuleTitle();

        this.toolbarEventsService.events.pipe(this.onDestroyService.takeUntilDestroy).subscribe(e => this.callbackToToolbarClick(e));
        const generalUiLanguage = this.configurationManagerService.getPreference('general.ui_language') || 'it';

        this.userData = (await this.getUserData()) || {};

        if(!this.userData.phone) {
            this.userData.phone = ' ';
        }

        this.userData.uiLanguage = Object.values(uiLanguages).find(uiLang => uiLang.id === generalUiLanguage);

        const userSession = await this.getUserSession();
        this.userData.username = userSession?.username;
        this.userData.thumbnail = userSession?.thumbnail;
    }

    setModuleTitle() {
        this.toolbarEventsService.moduleTitle.next('USER');
    }

    createToolbarButtons() {
        this.toolbarEventsService.buttons$.next({
            barButtons: [],
            panelButtons: []
        });
    }

    callbackToToolbarClick(event: Partial<ModuleEvents> & { id?: number }) {}

    async getUserData(): Promise<UserData | undefined> {
        try {
            return await this.restManagerService.getOne('sessions/current_user');
        } catch (err) {
            this.errorsLoggerService.err(err);
        }
    }

    async getUserSession() {
        try {
            return await this.entityManagerService.userSessions.getActiveSession();
        } catch (err) {
            this.errorsLoggerService.err(err);
        }
    }

    getFullName() {
        return this.userData && this.userData.first_name + ' ' + this.userData.last_name;
    }

    getAvatar() {
        return this.userData && this.userData.thumbnail ? this.userData.thumbnail : (this.userData?.first_name?.charAt(0).toUpperCase() || '') + (this.userData?.last_name?.charAt(0).toUpperCase() || '');
    }

    changePassword() {
        if(Object.keys(this.userData).length) {
            if(this.demoUsers.includes(this.userData.username!)) {
                this.alertDialogService.openDialog({
                    data: {
                        messageLabel: 'SETTINGS.USER_SETTINGS.MSG_USER_DEMO',
                        confirmLabel: 'MISC.OK'
                    }
                });
            } else {
                return this.util.openExternalLink(this.oauth.getTilbyLoginUrl(['clientId', 'redirectUri']) + '&select_shop=1');
            }
        }
    }



    async openThemeDialog() {
        const res = await this.selectThemeDialogService.openDialog({theme: this.themeModeService.isDarkTheme ? 'DARK' : 'LIGHT'});

        if(!res) {
            return;
        }

        if ((res.theme === 'DARK' && !this.themeModeService.isDarkTheme) || (res.theme === 'LIGHT' && this.themeModeService.isDarkTheme)) {
            this.isChangeTheme = true;
            this.configurationManagerService.setUserPreference('general.ui_theme', res.theme.toLowerCase());
            setTimeout(() => { this.isChangeTheme = false;}, 0);
        }
    }

    async openEditUserDialog(action: string) {
        const userData = await this.userSessionsManager.show({ disableNewUser: true, canDismiss: true, selectActiveUser: true });

        if (!userData) {
            return;
        }

        if(Object.keys(this.userData).length) {
            const actionsExcludedInDemo = ['first_name', 'last_name', 'email', 'phone', 'pin'];
            if(this.demoUsers.includes(this.userData.username!) && actionsExcludedInDemo.includes(action)) {
                this.alertDialogService.openDialog({
                    data: {
                        messageLabel: 'SETTINGS.USER_SETTINGS.MSG_USER_DEMO',
                        confirmLabel: 'MISC.OK'
                    }
                });
            } else {
                const payload = {
                    first_name: this.userData.first_name || '',
                    last_name: this.userData.last_name || '',
                    pin: this.userData.pin || null,
                }
                switch(action) {
                    case 'first_name': {
                        this.editFirstNameDialogService.openDialog(payload).then((res: ServerlessUser) => {
                            if(res) {
                                this.userData.first_name = res.first_name;
                                this._snackBar.open(
                                    this.translate.instant('SETTINGS.EDIT_USER_ACEESS_DIALOG.SUCCESS_MSG_FIRSTNAME'), undefined,
                                    {duration: 3000, horizontalPosition: 'left', verticalPosition: 'bottom'}
                                );
                            }
                        });
                    }
                    break;
                    case 'last_name': {
                        this.editLastNameDialogService.openDialog(payload).then((res: ServerlessUser) => {
                            if(res) {
                                this.userData.last_name = res.last_name;
                                this._snackBar.open(
                                    this.translate.instant('SETTINGS.EDIT_USER_ACEESS_DIALOG.SUCCESS_MSG_LASTNAME'), undefined,
                                    {duration: 3000, horizontalPosition: 'left', verticalPosition: 'bottom'}
                                );
                            }
                        });
                    }
                    break;
                    case 'language': {
                        const _data = {
                            uiLanguage: this.configurationManagerService.getPreferredLanguage()
                        };
                        this.openDialogsService.openEditUiLanguageDialog({ data: _data }).then(res => {
                            if(res) {
                                this.userData.uiLanguage = res;
                                this.translate.use(res.id);
                                this.$translate.use(res.id);
                                this._snackBar.open(
                                    this.translate.instant('SETTINGS.EDIT_USER_ACEESS_DIALOG.SUCCESS_MSG_LANGUAGE'), undefined,
                                    {duration: 3000, horizontalPosition: 'left', verticalPosition: 'bottom'}
                                );
                            }
                        });
                    }
                    break;
                    case 'email': {
                        this.openDialogsService.openEditEmailDialog({ data: { email: this.userData.email || '' }}).then(res => {
                            if (res) {
                                let error = false;
                                let message = '';
                                for(const key in res) {
                                    switch(key) {
                                        case 'message': {
                                            error = true;
                                            message = res[key];
                                        }
                                        break;
                                        case 'email': this.userData.email = res[key];
                                        break;
                                    }
                                }
                                if (error) {
                                    this.alertDialogService.openDialog({
                                        data: {
                                            messageLabel: message,
                                            confirmLabel: 'MISC.OK'
                                        }
                                    }).then(_ => this.openEditUserDialog('email'));
                                } else {
                                    this._snackBar.open(
                                        this.translate.instant('SETTINGS.EDIT_USER_ACEESS_DIALOG.SUCCESS_MSG_EMAIL'), undefined,
                                        {duration: 3000, horizontalPosition: 'left', verticalPosition: 'bottom'}
                                    );
                                }
                            }
                        });
                    }
                    break;
                    case 'phone': {
                        this.openDialogsService.openEditPhoneDialog({ data: { phone: this.userData.phone || '' }}).then(res => {
                            if (res) {
                                let error = false;
                                let message = '';
                                for(const key in res) {
                                    switch(key) {
                                        case 'message': {
                                            error = true;
                                            message = res[key];
                                        }
                                        break;
                                        case 'phone': this.userData.phone = res[key];
                                        break;
                                    }
                                }
                                if (error) {
                                    this.alertDialogService.openDialog({
                                        data: {
                                            messageLabel: message,
                                            confirmLabel: 'MISC.OK'
                                        }
                                    }).then(_ => this.openEditUserDialog('phone'));
                                } else {
                                    this._snackBar.open(
                                        this.translate.instant('SETTINGS.EDIT_USER_ACEESS_DIALOG.SUCCESS_MSG_PHONE'), undefined,
                                        {duration: 3000, horizontalPosition: 'left', verticalPosition: 'bottom'}
                                    );
                                }
                            }
                        });
                    }
                    break;
                    case 'pin': {
                        const _data = {
                            first_name: this.userData.first_name,
                            last_name: this.userData.last_name,
                            pin: this.userData.pin
                        };
                        this.openDialogsService.openEditPinDialog({ data: _data }).then((res: UserData) => {
                            if(res) {
                                this.userData.pin = res.pin;
                                this._snackBar.open(
                                    this.translate.instant('SETTINGS.EDIT_USER_ACEESS_DIALOG.SUCCESS_MSG_PIN'), undefined,
                                    {duration: 3000, horizontalPosition: 'left', verticalPosition: 'bottom'}
                                );
                            }
                        });
                    }
                    break;
                }
            }
        }
    }

    changeEmailPreferences() {
        if(Object.keys(this.userData).length) {
            if(this.demoUsers.includes(this.userData.username!)) {
                this.alertDialogService.openDialog({
                    data: {
                        messageLabel: 'SETTINGS.USER_SETTINGS.MSG_USER_DEMO',
                        confirmLabel: 'MISC.OK'
                    }
                });
            } else {
                if(this.userData.email) {
                    const email = this.userData.email;
                    const lang = this.configurationManagerService.getPreferredLanguage();
                    if(lang && lang.id) {
                        let url;
                        switch(lang.id) {
                            case 'it':
                                url = 'https://share.hsforms.com/1PBxgyHXOQuyb_o5gu0nhpg2n2jy?email=';
                                break;
                            case 'fr':
                                url = 'https://share.hsforms.com/1W1GodNqqR5mHNP4-EMz2GQ2n2jy?email=';
                                break;
                            case 'de':
                                url = 'https://share.hsforms.com/1GMiSie9lSEK_LKwUaZZwMQ2n2jy?email=';
                                break;
                            case 'en_GB':
                                url = 'https://share.hsforms.com/1384b9HA-QJiIaho9hD_E3Q2n2jy?email=';
                                break;
                            case 'en_US':
                                url = 'https://share.hsforms.com/1384b9HA-QJiIaho9hD_E3Q2n2jy?email=';
                                break;
                            case 'zh':
                                url = 'https://share.hsforms.com/1PBxgyHXOQuyb_o5gu0nhpg2n2jy?email=';
                                break;
                            case 'es':
                                url = 'https://share.hsforms.com/11sb99eqKTmSMRfFSyAp35A2n2jy?email=';
                                break;
                            case 'da':
                                url = 'https://share.hsforms.com/1PBxgyHXOQuyb_o5gu0nhpg2n2jy?email=';
                                break;
                            default:
                                url = 'https://share.hsforms.com/1PBxgyHXOQuyb_o5gu0nhpg2n2jy?email=';
                        }
                        if(url) {
                            this.util.openExternalLink(url + email);
                        }
                    }
                }
            }
        }
    }

    logout(){
        this.sessionManager.logoutActiveUserSession(true);
    }

    deepLogout(){
        this.sessionManager.logoutCloseSessionsDeep(true);
    }

    public async resetUserPreference() {
        const preferencesToReset: (keyof ConfigurationPreferences)[] = [
            'cashregister.fastpayment_1',
            'cashregister.fastpayment_2',
            'cashregister.fastpayment_3',
            'cashregister.nonfiscalsale_default_printer',
            'cashregister.storedpayment',
            'dialog.skip_archive.skip_question',
            'dialog.skip_delete.skip_question',
            'dialog.skip_printing.skip_question',
            'fiscalprinter.def.id'
        ];

        const answer = await this.confirmDialogService.openDialog({
            data: {
                messageLabel: 'DIALOG.RESET_SHOP_PREFERENCE.MESSAGE',
                cancelLabel: 'DIALOG.RESET_SHOP_PREFERENCE.CANCEL',
                confirmLabel: 'DIALOG.RESET_SHOP_PREFERENCE.CONFIRM'
            }
        });

        if (!answer) {
            return;
        }

        for (const preference of preferencesToReset) {
            this.configurationManagerService.deleteUserPreference(preference);
        }
    }
}
