import angular from 'angular';
import _ from 'lodash';

angular.module('application').factory('promptDialog', promptDialog);

promptDialog.$inject = ["$mdDialog", "$translate"];

function promptDialog($mdDialog, $translate) {
    promptDialogController.$inject = ["$scope", "$mdDialog", "options"];

    function promptDialogController($scope, $mdDialog, options) {
        Object.assign($scope, {
            disableCancel: !!options.disableCancel,
            options: {
                title: options.title || $translate.instant('APPLICATION.PROMPT_DIALOG.TITLE_LABEL'),
                label: options.label || $translate.instant('APPLICATION.PROMPT_DIALOG.NAME_LABEL'),
                type: options.type || 'text',
                message: options.message,
                minLength: _.isFinite(options.minLength) ? options.minLength : 0,
                maxLength: _.isFinite(options.maxLength) ? options.maxLength : 255,
                min: _.isFinite(options.min) ? options.min : undefined,
                max: _.isFinite(options.max) ? options.max : undefined,
                step: _.isFinite(options.step) ? options.step : undefined
            },
            value: options.defaultValue || "",
            validationStatus: {
                valid: true,
                value: options.defaultValue || "",
                message: null
            }
        });

        if(options.validationFunction) {
            $scope.validationStatus.valid = false;
        }

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.validateInput = async (value) => {
            if(_.isFunction(options.validationFunction)) {
                let validationResult = await options.validationFunction(value);

                if(validationResult) {
                    Object.assign($scope.validationStatus, {
                        message: validationResult.message || null,
                        valid: validationResult.valid,
                        value: value
                    });
                }

                return validationResult;
            }
        };

        $scope.answer = async () => {
            const value = $scope.value;

            if(options.validationFunction) {
                if($scope.validationStatus.value !== value) {
                    await $scope.validateInput(value);
                }

                if(!$scope.validationStatus.valid) {
                    return;
                }
            }

            $mdDialog.hide(value);
        };
    }

    const promptDialog = {
        show: function(options) {
            const localOptions = { ...(options || {}) };
            const dialogOptions = {};

            if (localOptions.blocking) {
                dialogOptions.clickOutsideToClose = false;
                dialogOptions.escapeToClose = false;
                localOptions.disableCancel = true;
            }

            return $mdDialog.show({
                controller: promptDialogController,
                template: require('./prompt-dialog.html'),
                locals: {
                    options: localOptions
                },
                multiple: true,
                ...dialogOptions
            });
        }
    };

    return promptDialog;
}