import angular from 'angular';

import {
    ConfigurationManagerService,
    ConnectionService,
    CoreStateService,
    EntityManagerService,
    EnvironmentInfoService,
    LoginViewService,
    OauthService,
    UserActiveSessionManagerService
} from 'src/app/core';

import {
    UserSessionsManagerDialogService
} from 'src/app/dialogs';
import { User } from 'src/app/models';

/**
 * @ngdoc service
 * @name sessionManagerService
 * @description
 * Manages user sessions, login, logout, and session data.
 */
class SessionManagerService {
    private sessionUpdateTimeout?: number;
    private sessionUpdatePromise: Promise<any> = Promise.resolve();

    static readonly $inject = [
        "$rootScope",
        "$translate",
        "coreState",
        "errorsLogger",
        "restManager",
        "checkManager",
        "oauth",
        "environmentInfo",
        "loginView",
        "connection",
        "entityManager",
        "util",
        "alertDialog",
        "confirmDialog",
        "userSessionsManager",
        "userActiveSession"
    ];

    constructor(
        private readonly $rootScope: any,
        private readonly translateService: any,
        private readonly coreState: CoreStateService,
        private readonly errorsLogger: any,
        private readonly restManager: any,
        private readonly configurationManagerService: ConfigurationManagerService,
        private readonly oauth: OauthService,
        private readonly environmentInfo: EnvironmentInfoService,
        private readonly loginView: LoginViewService,
        private readonly connection: ConnectionService,
        private readonly entityManager: EntityManagerService,
        private readonly util: any,
        private readonly alertDialog: any,
        private readonly confirmDialog: any,
        private readonly userSessionsManager: UserSessionsManagerDialogService,
        private readonly userActiveSession: UserActiveSessionManagerService,
    ) {}

    /**
     * Saves session data to the database.
     * @param {any} session The session object to save.
     * @returns {Promise<void>} A promise that resolves when the session data is saved.
     * @private
     */
    private async saveSessionData(session: any) {
        await this.entityManager.userSessions.saveOne(session);

        if (session.shop) {
            await this.entityManager.shopSession.saveOne(session.shop);
        }
    }

    /**
     * Logs out a user by invalidating their access token.
     * @param {number} userId The ID of the user to log out.
     * @param {string} accessToken The access token of the user.
     * @returns {Promise<void>} A promise that resolves when the user is logged out.
     * @private
     */
    private async logoutUser(userId?: number, accessToken?: string) {
        if (this.configurationManagerService.getSetting("skip_invalidate_token")) {
            return;
        }

        await this.oauth.logout(userId, accessToken);
    }

    /**
     * Logs out multiple users.
     * @param {any[]} users An array of user objects to logout.
     * @returns {Promise<void>} A promise that resolves when all users are logged out.
     * @private
     */
    private async logoutMultipleUsers(users: User[]) {
        if (this.configurationManagerService.getSetting("skip_invalidate_token")) {
            return;
        }

        await this.oauth.logoutAll(users);
    }

    /**
     * Sets the session data in the active session service and error logger.
     * @param {any} session The session object to set.
     * @private
     */
    private setSessionData(session: any): void {
        this.userActiveSession.setSession(session);
        this.restManager.setUser(session.oauthdata);

        try {
            this.errorsLogger.setContext(session);
        } catch (error) {
            this.errorsLogger.err(`[sessionManager] Cannot set context`);
        }
    }

    /**
     * Sets the user session with all data in case of correct login conditions.
     * @param {any} session The session data.
     * @param {any} oauthData The OAuth data.
     * @returns {Promise<void>} A promise that resolves when the session is set.
     */
    private async setUserSession(session: any, oauthData: any): Promise<void> {
        if (!session.id && session.user_id) {
            session.id = parseInt(session.user_id, 10);
            delete session.user_id;
        }

        session.active = 1;
        session.oauthdata = oauthData;

        this.setSessionData(session);

        await this.saveSessionData(session);
    }

    /**
     * Manages sessions for multi-login scenarios.
     * @param {any} accessToken The access token.
     * @returns {Promise<void>} A promise that resolves when the sessions are managed.
     */
    private async manageSessionsMultiLogin(accessToken: any): Promise<void> {
        try {
            const session = await this.restManager.getUserSession(accessToken.access_token);
            const shop = await this.entityManager.shopSession.getCollection();
            const oldUser = await this.entityManager.userSessions.getOne(session.id);

            if (!shop?.length) {
                if (oldUser) { // Weird case, do a complete logout for safety reasons
                    await this.deepLogoutForAccessTokenLogin();
                }

                await this.setUserSession(session, accessToken);
            } else {
                const currentShop = shop[0];

                if (currentShop.id === session?.shop?.id) {
                    // Backup shop runtime information
                    session.shop = { ...currentShop, ...session.shop };

                    if (!oldUser) {
                        await this.setUserSession(session, accessToken);
                        await this.entityManager.loadSettings(['userPreferences', 'shopPreferences'], true);
                    } else {
                        await this.logoutUser(oldUser.id, oldUser.oauthdata.access_token);
                        await this.setUserSession(session, accessToken);
                    }
                } else {
                    let answer: boolean | undefined;

                    try {
                        answer = await this.confirmDialog.show(this.translateService.instant('SESSION_MANAGER.SHOP_SESSION_EXISTS', { currentShop: currentShop.name, newShop: session.shop.name }));
                    } catch (error) { }

                    if (answer) {
                        await this.deepLogoutForAccessTokenLogin();
                        await this.setUserSession(session, accessToken);
                    } else {
                        // Using oauth directly because we are logging out a wrong login attempt, so the token is discarded regardless
                        await this.oauth.logout(session.id, accessToken.access_token);
                        await this.login();
                    }
                }
            }
        } catch (error: any) {
            if (error?.data?.error?.message === "Invalid shop") {
                await this.alertDialog.show(this.translateService.instant('SESSION_MANAGER.INVALID_SHOP'));
                this.logoutCloseSessionsDeep(false);
            } else {
                throw error;
            }
        }
    }

    /**
     * Deletes the active user session and associated data.
     * @returns {Promise<any>} A promise that resolves with the remaining user sessions.
     * @private
     */
    private async deleteActiveUserSessionAndData(): Promise<any> {
        // Wait for current session update to finish
        try {
            await this.sessionUpdatePromise;
        } catch (e) { }

        clearTimeout(this.sessionUpdateTimeout);

        // Get current user
        const user = await this.entityManager.userSessions.getActiveSession();

        // Cleanup current user related data from indexedDB
        await this.entityManager.userSettings.deleteCollectionByIndexOffline(user.id, 'user_id');
        await this.entityManager.userPreferences.deleteCollectionByIndexOffline(user.id, 'user_id');
        await this.entityManager.userSessions.deleteOne(user.id);

        // And from local storage
        localStorage.removeItem(`initEntity::userSettings::${user.id}`);
        localStorage.removeItem(`initEntity::userPreferences::${user.id}`);

        // Return the sessions on the device
        return this.entityManager.userSessions.getCollection();
    }

    /**
     * Logs in the user.
     * @param {boolean} [forceNew] Whether to force a new login.
     * @returns {Promise<void>} A promise that resolves when the login is complete.
     */
    async login(forceNew?: boolean): Promise<void> {
        if (this.connection.isOnline()) {
            try {
                const accessToken = await this.oauth.checkAccess();
                await this.manageSessionsMultiLogin(accessToken);
            } catch (error) {
                const sessions = await this.entityManager.userSessions.getCollection();
                this.$rootScope.stopWatchdog();
                this.$rootScope.hideAppLoader();

                if (!sessions || sessions.length === 0 || forceNew) {
                    try {
                        const code = await this.loginView.show();
                        this.$rootScope.showAppLoader();

                        const accessToken = await this.oauth.askAccessToken(code);
                        await this.manageSessionsMultiLogin(accessToken);

                        this.$rootScope.resetWatchdog();
                    } catch (error) {
                        if (!sessions || sessions.length === 0) {
                            if (this.environmentInfo.isElectronApp()) {
                                window.close(); // If the login phase is rejected in electron, close the app
                            } else {
                                await this.alertDialog.show(this.translateService.instant('SESSION_MANAGER.LOGIN_FAILED'));
                                return this.login();
                            }
                        } else {
                            return this.login();
                        }
                    }
                } else {
                    return this.switchUser();
                }
            }
        } else {
            this.$rootScope.stopWatchdog();

            await this.alertDialog.show(this.translateService.instant('SESSION_MANAGER.NO_CONNECTION'));
            this.$rootScope.resetWatchdog();
            return this.login(forceNew);
        }
    }

    /**
     * Checks if a user is logged in.
     * @returns {Promise<any>} A promise that resolves with the active session if logged in.
     */
    async isLoggedIn(): Promise<any> {
        const success = await this.entityManager.userSessions.getActiveSession();

        if (!success) {
            this.errorsLogger.info("[ sessionManager.isLoggedIn ] no active session");
            throw 'NO_ACTIVE_SESSION';
        }

        if (success.is_screen_locked) {
            throw 'SCREEN_LOCKED';
        }

        this.setSessionData(success);
        return success;
    }

    /**
     * Checks if a shop session exists.
     * @returns {Promise<any>} A promise that resolves with the shop session if it exists.
     */
    async hasShopSession(): Promise<any> {
        const shopSession = await this.entityManager.shopSession.getCollection().then(sessions => sessions[0]);

        if (!shopSession) {
            throw 'NO_SHOP_SESSION';
        }

        return shopSession;
    }

    /**
     * Refreshes the access token for the active session.
     * @returns {Promise<any>} A promise that resolves with the new OAuth data.
     */
    async refreshTokenActiveSession(): Promise<any> {
        const userSession = await this.entityManager.userSessions.getActiveSession();

        if (userSession) {
            const newOauthData = await this.oauth.refreshToken(userSession.oauthdata);
            userSession.oauthdata = newOauthData;
            await this.entityManager.userSessions.saveOne(userSession);

            return newOauthData;
        } else {
            this.errorsLogger.err("[ sessionManager.refreshTokenActiveSession ] no active session");
            throw 'NO_ACTIVE_SESSION';
        }
    }

    /**
     * Updates the last active session timestamp.
     */
    updateLastActiveSession() {
        try {
            const activeSession = this.userActiveSession.getSession();
    
            if(!activeSession) {
                return;
            }

            //Copy offline days permitted to local storage since we need it on app init (before configuration manager initialization)
            const offlineDaysPermitted = parseInt(this.configurationManagerService.getSetting('offline.max_days') || '3') || 3;
            localStorage.setItem('offline::daysPermitted', offlineDaysPermitted.toString());

            //Get current offline days
            let offlineDays = parseInt(localStorage.getItem('offline::days') || '0') || 0;
    
            if (this.connection.isOnline()) {
                //Reset offline days if online
                offlineDays = 0;
                localStorage.removeItem('offline::lastDay');

                //Update last online
                localStorage.setItem('lastOnline::shop', new Date().toISOString());
            } else {
                //Update last offline work day
                const offlineDay = this.util.getDayStartTime().toISOString();
                const offlineDayStr = localStorage.getItem('offline::lastDay');

                //Increment offline days if new day
                if(offlineDayStr !== offlineDay) {
                    //Update last offline day
                    localStorage.setItem('offline::lastDay', offlineDay);
                    offlineDays++;
                }
            }
    
            localStorage.setItem('offline::days', offlineDays.toString());
        } finally {
            this.sessionUpdateTimeout = window.setTimeout(() => this.updateLastActiveSession(), 60000);
        }
    }

    /**
     * Checks the last active session timestamp.
     * @returns {{shopExpired: boolean, shopLastOnline?: string}} An object containing session status information.
     */
    checkLastActiveSession(): { shopExpired: boolean; shopLastOnline?: string } {
        const maxOfflineDays = parseInt(localStorage.getItem('offline::daysPermitted') || '3') || 3;
        const offlineDays = parseInt(localStorage.getItem('offline::days') || '0') || 0;

        const sessionStatus = {
            shopExpired: offlineDays > maxOfflineDays
        };

        const shopLastOnline = localStorage.getItem('lastOnline::shop');

        if (shopLastOnline) {
            Object.assign(sessionStatus, {
                shopLastOnline: shopLastOnline
            });
        }

        return sessionStatus;
    }

    /**
     * Deactivates all active user sessions.
     * @returns {Promise<void>} A promise that resolves when all sessions are deactivated.
     */
    async deactivateActiveSessions() {
        const userActiveSessions = await this.entityManager.userSessions.getAllActiveSessions();

        for (const user of userActiveSessions) {
            user.active = 0;
        }

        await this.entityManager.userSessions.saveCollection(userActiveSessions);
    }

    /**
     * Activates or switches a user session.
     * @param {number} userId The ID of the user to activate.
     * @returns {Promise<void>} A promise that resolves when the session is activated.
     */
    async activeOrSwitchUserSession(userId: number) {
        const userActiveSessions = await this.entityManager.userSessions.getCollection();

        for (const user of userActiveSessions) {
            user.active = user.id === userId ? 1 : 0;
            delete user.is_screen_locked;
        }

        await this.entityManager.userSessions.saveCollection(userActiveSessions);
    }

    /**
     * Locks the screen.
     * @returns {Promise<void>} A promise that resolves when the screen is locked.
     */
    async lockScreen(): Promise<void> {
        const session = await this.entityManager.userSessions.getActiveSession();

        if(session.is_screen_locked) {
            return;
        }

        session.is_screen_locked = true;

        await this.saveSessionData(session);
        await this.switchUser();
    }

    /**
     * Switches to a different user.
     * @returns {Promise<void>} A promise that resolves when the user is switched.
     */
    async switchUser(): Promise<void> {
        this.$rootScope.hideAppLoader();

        const user = await this.userSessionsManager.show();

        try {
            if (user) {  // Already logged user
                await this.activeOrSwitchUserSession(user.id);
                const activeSession = this.userActiveSession.getSession();

                if (!activeSession || user.id !== activeSession.id) {
                    await this.coreState.restartApplication();
                } else {
                    this.$rootScope.$broadcast("unlock-screen");
                }
            } else { // New User
                const session = await this.entityManager.userSessions.getActiveSession();

                if (session) {
                    const answer = await this.confirmDialog.show(this.translateService.instant('SESSION_MANAGER.NEW_LOGIN_CONFIRM'));

                    if (!answer) {
                        throw 'CANCELED';
                    }
                }

                if (this.connection.isOnline()) {
                    await this.deactivateActiveSessions();
                    await this.login(true);

                    await this.coreState.restartApplication();
                } else {
                    await this.alertDialog.show(this.translateService.instant('SESSION_MANAGER.NEW_LOGIN_NO_CONNECTION'));

                    throw 'OFFLINE';
                }
            }
        } catch (error) {
            return this.switchUser();
        }
    }

    /**
     * Logs out the active user session.
     * @param {boolean} askConfirm Whether to ask for confirmation.
     * @returns {Promise<void>} A promise that resolves when the user is logged out.
     */
    async logoutActiveUserSession(askConfirm?: boolean): Promise<void> {
        let doLogout: boolean | undefined;

        if (askConfirm) {
            doLogout = await this.confirmDialog.show(this.translateService.instant('SESSION_MANAGER.LOGOUT_CONFIRM'));
        } else {
            doLogout = true;
        }

        if(!doLogout) {
            return;
        }

        try {
            const isPinRequired = this.configurationManagerService.getPreference('users.enable_user_logout_pin');

            if (isPinRequired) {
                await this.userSessionsManager.show({ disableNewUser: true, canDismiss: true, selectActiveUser: true });
            }

            await this.deleteActiveUserSessionAndData();
            await this.logoutUser();

            this.coreState.restartApplication();
        } catch (error) {
            this.errorsLogger.err("[ sessionManager.logoutActiveUserSession ] Cannot delete current user session");
        }
    }

    /**
     * Logs out and closes all sessions deeply.
     * @param {boolean} confirm Whether to ask for confirmation.
     * @returns {Promise<void>} A promise that resolves when all sessions are logged out.
     */
    async logoutCloseSessionsDeep(confirm: boolean): Promise<void> {
        let performLogout: boolean | undefined;

        if (confirm) {
            if (this.connection.isOnline()) {
                performLogout = await this.confirmDialog.show(this.translateService.instant('SESSION_MANAGER.DEEP_LOGOUT_CONFIRM'));

                if (performLogout) {
                    try {
                        await this.entityManager.syncAll();
                    } catch (error) {
                        performLogout = await this.confirmDialog.show(this.translateService.instant('SESSION_MANAGER.DEEP_LOGOUT_SYNC_ERROR_CONFIRM'));
                    }
                }
            } else {
                performLogout = await this.confirmDialog.show(this.translateService.instant('SESSION_MANAGER.DEEP_LOGOUT_OFFLINE_CONFIRM'));
            }
        } else {
            performLogout = true;
        }

        if (!performLogout) {
            return;
        }

        const isPinRequired = this.configurationManagerService.getPreference('users.enable_user_logout_pin');

        if (isPinRequired) {
            await this.userSessionsManager.show({ disableNewUser: true, canDismiss: true, selectActiveUser: true });
        }

        try {
            await this.sessionUpdatePromise;
        } catch (error) { }

        window.clearTimeout(this.sessionUpdateTimeout);
        this.$rootScope.showAppLoader();

        try {
            const userSessions = await this.entityManager.userSessions.getCollection();

            await this.logoutMultipleUsers(userSessions);
            await this.entityManager.shopSession.destroyStorage();
            await this.coreState.restartApplication();
        } catch (error) {
            this.$rootScope.hideAppLoader();
            this.alertDialog.show(this.translateService.instant('SESSION_MANAGER.LOGOUT_CRITICAL_ERROR'), { blocking: true });
        }
    }

    /**
     * Checks the access token from the URL.
     * @returns {any} The result of checking the access token.
     */
    checkAccessTokenFromUrl(): any {
        return this.oauth.checkAccessTokenFromUrl();
    }

    /**
     * Logs in with an access token.
     * @param {string} access_token The access token.
     * @returns {Promise<void>} A promise that resolves when the login is complete.
     */
    loginWithAccessToken(access_token: string): Promise<void> {
        return this.manageSessionsMultiLogin({ access_token: access_token });
    }

    /**
     * Performs a deep logout for access token login.
     * @returns {Promise<void>} A promise that resolves when the deep logout is complete.
     */
    async deepLogoutForAccessTokenLogin(): Promise<void> {
        try {
            await this.entityManager.shopSession.destroyStorage();
            this.userActiveSession.unsetSession();
        } catch (error) {
            this.alertDialog.show(this.translateService.instant('SESSION_MANAGER.LOGOUT_CRITICAL_ERROR'), { blocking: true });
            throw error;
        }
    }
}

// Register the service with Angular
angular.module('core').service('sessionManager', SessionManagerService);