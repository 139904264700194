import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SettingsUsersManagementService } from '../../../services/settings-users-management.services';
import { ButtonUmList } from '../../../models/settings-users-management.model';
import { ShopUsers, WebServerSlaveDevices } from 'tilby-models';
import { UserDevice } from '../tab-settings-um/settings-um-devices';

type Item = ShopUsers | WebServerSlaveDevices | UserDevice;

@Component({
    selector: 'app-settings-um-list',
    templateUrl: './settings-um-list.component.html',
    styleUrls: ['./settings-um-list.component.scss']
})
export class SettingsUmListComponent implements OnInit {
    EMPTY_ITEMS: string[] = Array(10).fill('');

    constructor(
        private settingsUsersManagementService: SettingsUsersManagementService) { }

    ngOnInit(): void {
    }

    @Input() enabledQrCodeGenerator: boolean = true;
    @Input() buttons: ButtonUmList[] = [];
    openFilter: boolean = false;
    @Input() filterText: string = '';
    @Input() items: Item[] = [];
    @Input() allItems: Item[] = [];
    @Input() itemsIcon: string = '';
    @Input() filterFunction: ((item: any) => boolean) | undefined;
    @Input() isLoading = false;
    selectedItem: Item | undefined;
    @Output() itemSelected = new EventEmitter<any>();


    @Input() fieldBuilderLegend1: ((item: any) => string) | undefined;
    @Input() fieldBuilderLegend2: ((item: any) => string) | undefined;

    onItemClick(item: ShopUsers | WebServerSlaveDevices) {
        this.itemSelected.emit(item);
        this.setSelectedItem(item);
    }

    setSelectedItem(item: Item) {
        this.selectedItem = item;
    }

    filter() {
        this.openFilter = !this.openFilter;
    }

    applyFilter() {
        if (typeof this.filterFunction === 'function') {
          this.items = this.allItems.filter(item => this.filterFunction!(item));
        } else {
          alert("Filter not configured!");
          this.items = [...this.allItems];
        }
      }

    buttonClickHandler(callback: () => void, type: string) {
        callback();
        if (type === 'filter') {
          this.filter();
        }
      }

    getDateDifference(updateDate: string): string {
        return this.settingsUsersManagementService.getDateDifference(updateDate);
    }
}
