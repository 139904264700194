import {AfterViewInit, ChangeDetectorRef, Component, Injector, OnInit, ViewChild, inject, effect} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable, tap } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {MatSidenav} from '@angular/material/sidenav';
import {$state, RootScope} from 'app/ajs-upgraded-providers';
import {mobileCheck} from 'src/utilities';
import {
    CentralToolbarComponent,
    ToolbarEventsService,
    RightSidenavService,
    RightSidenavServiceComponent,
    CoreStateService,
    ScreenOrientationService,
    ConfigurationManagerService
} from 'src/app/core/services';
import {DualService} from 'src/app/dual.service';
import {ToolbarEventsContextService} from "src/app/core/services/toolbar-events/toolbar-events-context.service";
import { ComponentPortal } from '@angular/cdk/portal';
import {SidenavMenuComponent} from "./sidenav-menu";
import { SearchBarComponent } from '../generalized-toolbar';
import { subscribeInComponent } from '@tilby/tilby-ui-lib/utilities';
import { OnDestroyService } from 'src/app/core/services/on-destroy.service';
import { ActiveSaleService } from 'src/app/features';
import { ChangeSaleNameDialogService } from 'src/app/dialogs/change-sale-name-dialog';

const SIDENAV_TRANSITION_TIME = 250;
//TOOLBAR
const MODULES_WITH_SEARCHBAR = ['customers','suppliers','history','cashregister', 'file_importer.showcase'];
const MODULES_WITH_CONTEXT_TOOLBAR = ['customers.details','suppliers.details','customers.new','suppliers.new', 'file_importer_add', 'file_importer_schedule'];
const MODULES_WITH_CONTEXT_TOOLBAR_DESKTOP_AND_MODILE_LANDSCAPE_ONLY = ['cashregister.content.payments'];
const MODULES_WITHOUT_TOOLBAR_BUTTONS = ['alvolo']
//
const CONTEXT_TABS_ROUTES = ['customers.details', 'promotions.details', 'promotions.new'];

@Component({
    selector: 'app-new-material-sidenav',
    templateUrl: './new-material-sidenav.component.html',
    styleUrls: ['./new-material-sidenav.component.scss'],
    providers: [OnDestroyService]
})
export class NewMaterialSidenavComponent implements OnInit, AfterViewInit {
    private readonly $rootScope = inject(RootScope);
    private readonly breakpointObserver = inject(BreakpointObserver);
    private readonly cd = inject(ChangeDetectorRef);
    private readonly dualService = inject(DualService);
    private readonly injector = inject(Injector);
    private readonly onDestroyService = inject(OnDestroyService);
    private readonly state = inject($state);
    public readonly rightSidenavService = inject(RightSidenavService<RightSidenavServiceComponent>);
    public readonly toolbarEventsContextService = inject(ToolbarEventsContextService);
    public readonly toolbarEventsService = inject(ToolbarEventsService);
    private readonly activeSaleService = inject(ActiveSaleService);
    protected readonly changeSaleNameDialogService = inject(ChangeSaleNameDialogService);
    protected readonly configurationManagerService = inject(ConfigurationManagerService);
    protected lockUIActions = CoreStateService.lockUIActions;

    protected deselectSalesFromMenuBar: boolean = this.configurationManagerService.getPreference('cashregister.deselect_sales_from_menu_bar') || false;

    //componentOutlet
    centralToolbarComponent$:Observable<ComponentPortal<CentralToolbarComponent>|null>= this.toolbarEventsService.centralToolbarComponent$.pipe(map(component=>component?new ComponentPortal<any>(component):null));
    //
    SIDENAV = {
        OPEN: true,
        CLOSE: false
    };
    @ViewChild('sidenavMenu') sidenavMenu?: MatSidenav;
    @ViewChild('sidenavFixed') sidenavFixed?: MatSidenav;
    @ViewChild('sidenavDetail') sidenavDetail?: MatSidenav;
    @ViewChild('appSidenavMenu') appSidenavMenu?: SidenavMenuComponent;

    currentRoute = '';
    protected isContextTabs = this.toolbarEventsContextService.isContextTabs;
    isRouteChanged$ = this.dualService.nextRoute$
        .pipe(
            tap(()=>this.rightSidenavService.isOpen = false),
            this.onDestroyService.takeUntilDestroy
        ).subscribe();
    currentModule?: string;
    oldTheme?: string;
    currentTheme?: string;
    isSidenavOpen = false;
    isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
        map(result => result.matches),
        shareReplay()
    );
    isMobile: boolean = mobileCheck();

    constructor(
    ) {
        subscribeInComponent(this.dualService.currentRoute$, (currentRoute) => {
            this.currentRoute = currentRoute;
            this.isContextTabs.set(CONTEXT_TABS_ROUTES.some(route => this.currentRoute.includes(route)));
            this.setToolbars(this.currentRoute);
        });
        effect(() => this.showContextToolbar(this.currentRoute)); // TRIGGERED BY DEVICE_ORIENTATION_CHANGE
    }

    //Component Init
    ngOnInit() {
    }

    ngAfterViewInit() {
        if (!this.isMobile) {
            this.sidenavFixed?.open();
            this.cd.detectChanges();
        }
    }

    ngOnDestroy(){
    }

    private setToolbars(currentRouteName:string){
        // TOOLBAR
        this.setSearchBarOnToolbar(currentRouteName);
        this.showToolbarButtons(currentRouteName);
        // CONTEXT-TOOLBAR
        this.showContextToolbar(currentRouteName);
        this.resetContextToolbar();
    }

    //START TOOLBAR METHODS

    private setSearchBarOnToolbar(currentRouteName:string){
        if(MODULES_WITH_SEARCHBAR.some(route=>currentRouteName.includes(route))){
            this.toolbarEventsService.centralToolbarComponent$.next(SearchBarComponent);
        }
        else this.toolbarEventsService.centralToolbarComponent$.next(undefined);
    }
    private showToolbarButtons(currentRouteName:string){
        if(MODULES_WITHOUT_TOOLBAR_BUTTONS.some(route=>currentRouteName.includes(route))){
            this.toolbarEventsService.showButtonsBar$.next(false);
        }
        else this.toolbarEventsService.showButtonsBar$.next(true);
    }

    private showContextToolbar(currentRouteName:string){
        const isMobilePotrait = this.injector.get(ScreenOrientationService).getOrientation().includes('portrait');
        const modulesWithContextToolbar=[...MODULES_WITH_CONTEXT_TOOLBAR,...(this.isMobile && isMobilePotrait?[]:MODULES_WITH_CONTEXT_TOOLBAR_DESKTOP_AND_MODILE_LANDSCAPE_ONLY)];
        if(modulesWithContextToolbar.some(route=>currentRouteName.includes(route))){
            this.toolbarEventsContextService.showToolbarContext$.next(true);
        }
        else this.toolbarEventsContextService.showToolbarContext$.next(false);
    }

    private resetContextToolbar(){
        this.toolbarEventsContextService.label = '';
        this.toolbarEventsContextService.buttons$.next({panelButtons: [], barButtons: []});
    }
    //END TOOLBAR METHODS

    closeMenuSidenav() {
        this.toggle(false);
    }

    toggle(status?: boolean) {
        this.isSidenavOpen = status ?? !this.isSidenavOpen;
        this.cd.detectChanges();

        //Toggle the sidenav
        this.sidenavMenu?.toggle(this.isSidenavOpen);

        //Reopen the fixed sidenav if the menu is being closed
        if(!this.isSidenavOpen) {
            this.sidenavFixed?.open();
        }
    }

    toggleSubMenuSettings(){
        this.toggle();
        this.appSidenavMenu?.navList?.invokeToggleSubmenuSettings();
    }

    toggleAutomaticPrintSettings(){
        this.toggle();
        this.appSidenavMenu?.navList?.invokeToggleSubmenuAutomaticPrinter();
    }

    async goTo(destination: string) {
        this.closeMenuSidenav();

        if(this.deselectSalesFromMenuBar && this.currentRoute === 'app.new.cashregister.content.showcase') {
            const currentSale = this.activeSaleService.currentSale;

            if((currentSale.status === "open" && currentSale.table_id) || currentSale.order_type === "delivery" || currentSale.order_type === "take_away"){
                await this.deselectSaleAction();
            }

            if(currentSale.status === "open" && !currentSale.table_id && currentSale.order_type !== "delivery" && currentSale.order_type !== "take_away"){
                const res = await this.injector.get(ChangeSaleNameDialogService).openDialog({saleName: this.activeSaleService.currentSale.name});

                if (!res || (res.saleName === '')) {
                    return;
                }
                await this.activeSaleService.saveSale(res.saleName);
                await this.activeSaleService.loadSale(undefined);
            }
        }

        setTimeout(()=>{
            if (this.currentModule !== 'app.' + destination) {
                this.$rootScope.showAppLoader();
                // setTimeout(() => this.router.navigate([destination]));
                setTimeout(() => this.state.go('app.' + destination));
            }
        },SIDENAV_TRANSITION_TIME);
    }

    async deselectSaleAction() {
        await this.activeSaleService.loadSale(undefined);
    }
}
