import { CommonModule } from "@angular/common";
import { ChangeDetectorRef, Component, Input, ViewChild, effect, inject, signal } from "@angular/core";
import { Calendar, CalendarOptions, DateSelectArg, EventApi, EventClickArg } from "@fullcalendar/core";
import { mobileCheck } from "@tilby/tilby-ui-lib/utilities";
import { FullCalendarComponent, FullCalendarModule } from "@fullcalendar/angular";
import { ResourceInput } from '@fullcalendar/resource';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import fcTimeGrid from '@fullcalendar/timegrid';
import fcList from '@fullcalendar/list';
import fcInteraction from '@fullcalendar/interaction';
import fcResourceTimeline from '@fullcalendar/resource-timeline';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';

import {
    ConfigurationManagerService,
    EntityManagerService,
} from "src/app/core";

import { AddEditNewBookingDialogService } from "src/app/dialogs/bookings/add-edit-new-booking-dialog/add-edit-new-booking-dialog.component";
import { BookingShifts, Bookings, Rooms } from "tilby-models";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment-timezone";
import { leanPMS, util } from "app/ajs-upgraded-providers";
import { TilbyDatePipe } from "@tilby/tilby-ui-lib/pipes/tilby-date";
import { BookingsDataInput, BookingsStateService } from "../../services/bookings.state.service";
import { Subject } from "rxjs";
const { licenseKeys } = require('app/tilby.properties.json');

@Component({
    selector: 'app-booking-timeline',
    templateUrl: './booking-timeline.component.html',
    styleUrls: ['./booking-timeline.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        FullCalendarModule
    ],
})
export class BookingTimelineComponent {

    @ViewChild('calendar') calendarComponent!: FullCalendarComponent;
    calendarApi!: Calendar;

    @Input() rooms: Rooms[] = [];
    bookings: Bookings[] = [];
    bookingShifts: BookingShifts[] = [];

    private readonly leanPMS = inject(leanPMS);
    private readonly util = inject(util);
    private readonly translateService = inject(TranslateService);
    private readonly checkManager = inject(ConfigurationManagerService);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly bookingsStateService = inject(BookingsStateService);
    private readonly changeDetector: ChangeDetectorRef = inject(ChangeDetectorRef);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly addEditNewBookingDialogService = inject(AddEditNewBookingDialogService);
    private readonly tilbyDatePipe = inject(TilbyDatePipe);

    today!: Date;
    isMobile = mobileCheck();
    resources: ResourceInput[] = [];
    slotDuration = this.checkManager.getPreference('bookings.slot_duration' as any) || 30;
    bookingsDataInput!: BookingsDataInput;

    currentEvents = signal<EventApi[]>([]);
    calendarVisible = signal(true);
    calendarOptions = signal<CalendarOptions>({
        plugins: [ resourceTimelinePlugin, fcTimeGrid, fcInteraction, fcResourceTimeline, fcList, momentTimezonePlugin ],
        initialView: 'resourceTimeline',
        schedulerLicenseKey: licenseKeys.fullCalendar,
        events: this.fetchEvents.bind(this),
        nowIndicator: true,
        weekends: true,
        editable: true,
        selectable: false,
        selectMirror: true,
        dayMaxEvents: true,
        eventStartEditable: false,
        eventDurationEditable: false,
        eventResourceEditable: false,
        droppable: false,
        select: this.handleDateSelect.bind(this),
        eventClick: this.handleEventClick.bind(this),
        eventsSet: this.handleEvents.bind(this),
        resources: this.fetchResources.bind(this),
        resourceOrder: 'name',
        allDayText: this.translateService.instant('BOOKINGS.SHOWCASE.CALENDAR_ALL_DAY_TEXT'),
        slotDuration: this.slotDuration * 60000,
        locale: moment.locale(),
        timeZone: this.configurationManagerService.getPreference('general.timezone') || 'UTC',
        headerToolbar: false,
        height: '100%',
        firstDay: 1,
        views: {
            resourceTimeline: {
                selectable: true,
                resourceGroupField: 'room',
                resourceAreaHeaderContent: this.translateService.instant('BOOKINGS.SHOWCASE.RESOURCES'),
                titleFormat: {
                    day: 'numeric',
                    weekday: 'long',
                    month: 'long',
                    year: 'numeric'
                },

            }
        },
        initialDate: this.bookingsStateService.selectBookingsData().date,
        allDayContent: this.translateService.instant('BOOKINGS.SHOWCASE.CALENDAR_ALL_DAY_TEXT'),
    });

    private onDestroy$ = new Subject<void>();

    constructor(){
        const todayString = this.tilbyDatePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
        this.today = TilbyDatePipe.date({outputFormat: 'date', date: todayString || ''});

        effect(() => {
            if (this.bookingsStateService.selectBookingsData()) {
                const updatedDate = this.bookingsStateService.selectBookingsData().date;

                if(this.bookingsStateService.isBookingOnline(updatedDate) === false) {
                    this.bookingsStateService.getBookingsToday(updatedDate, 2)
                    .then(newBookingData => {

                        //check if the new bookings are into this.bookings yet
                        newBookingData.forEach(booking => {
                            if(!this.bookings.find(b => b.id === booking.id)) this.bookings.push(booking);
                        });

                        let calendarApi = this.calendarComponent.getApi();
                        if(calendarApi) calendarApi.refetchEvents();
                    });
                }

                this.bookingsDataInput = this.bookingsStateService.selectBookingsData();
                let calendarApi = this.calendarComponent.getApi();
                if(calendarApi) calendarApi.gotoDate(this.bookingsDataInput.date);
            }
        }, { allowSignalWrites: true });

        effect(() => {
            if (this.bookingsStateService.isRefreshData()) {
                this.bookings = this.bookingsStateService.bookingData();
                this.calendarApi.refetchEvents();
                this.bookingsStateService.isRefreshData.set(false);
            }
        }, { allowSignalWrites: true });

        effect(() => {
            if(this.bookingsStateService.bookingData()) {
                this.bookings = this.bookingsStateService.bookingData();
                this.calendarApi.refetchEvents();
            }
        }, { allowSignalWrites: true });
    }

    ngOnInit() {
        this.getBookingShifts();
    }

    ngAfterViewInit() {
        this.calendarApi = this.calendarComponent.getApi();
    }

    async getBookingShifts() {
        this.bookingShifts = await this.entityManagerService.bookingShifts.fetchCollectionOffline();
    }

    getInitaliDate(): string {
        const todayString = this.tilbyDatePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
        this.today = TilbyDatePipe.date({outputFormat: 'date', date: todayString || ''});
        return this.today.toISOString();
    }

    handleDateSelect(selectInfo: DateSelectArg) {
        let options: any = {};

        if(selectInfo.start) {
            options.dateStart = selectInfo.start;

            if(moment.duration(moment(selectInfo.end).diff(selectInfo.start)).asMinutes() > this.slotDuration) {
                options.dateEnd = selectInfo.end;
            }
        }

        if(options) {
            if(selectInfo.resource) options.tableId = +selectInfo.resource.id;

            this.addEditNewBookingDialogService.openDialog({data: {bookings: this.bookings, bookingShifts: this.bookingShifts, rooms: this.rooms, options}})
            .then((result) => {
                if(!result) return;

                const resultDate = this.tilbyDatePipe.transform(TilbyDatePipe.date(result.booking.booked_for), 'yyyy-MM-dd');
                const bookingDate = TilbyDatePipe.date({outputFormat: 'date', date: resultDate || ''});

                this.bookingsStateService.addBooking(result.booking, bookingDate);
            });
        }
    }

    async handleEventClick(clickInfo: EventClickArg) {
        let bookingID = +clickInfo.event.id;

        const booking = (await this.entityManagerService.bookings.fetchOneOffline(bookingID)) || this.bookings.find((booking) => booking.id == bookingID);
        
        if(booking) {
            this.addEditNewBookingDialogService.openDialog({data: {bookings: this.bookings, bookingShifts: this.bookingShifts, rooms: this.rooms, options: {booking}}})
            .then((result) => {
                if(result.action == "delete") {
                    this.bookingsStateService.deleteBooking(booking);
                } else if(result.booking){
                    this.bookingsStateService.editBooking(result.booking);
                }
            })
        }
    }

    fetchResources(fetchInfo: any, successCallback: any, failureCallback: any) {
        let resources = [];

        resources.push({
            id: 0,
            title: this.translateService.instant('BOOKINGS.SHOWCASE.NO_RESOURCE')
        });

        this.rooms.forEach((room) => {
            if(room.tables)
                room.tables.forEach((table) => {
                    if(!table.name.startsWith('ph_')) {
                        resources.push({
                            id: table.id,
                            room: room.name,
                            title: table.name
                        });
                    }
                });
        });

        successCallback(resources);
    };

    async fetchEvents(fetchInfo: any, successCallback: any, failureCallback: any) {
        const isLeanPMSEnabled = await this.leanPMS.isSetup();

        let events = [];

        for (const booking of this.bookings) {
            let customerCaption = null;
            if (isLeanPMSEnabled && booking.pms_reservation_id) {
                customerCaption = booking.pms_reservation_name;
            } else {
                customerCaption = this.util.getCustomerCaption(booking.booking_customer);
            }

            const eventColor = this.getEventColor(booking);
            const bookingTemplate = {
                id: booking.id,
                title: String(booking.people) + (customerCaption ? ` - ${customerCaption}` : '') + '',
                backgroundColor: eventColor,
                borderColor: eventColor,
                start: booking.booked_for,
                end: moment(booking.booked_for).add(booking.duration, 'minutes').toDate()
            };

            if(booking.tables && booking.tables.length) {
                booking.tables.forEach((table) => {
                    events.push({...bookingTemplate, resourceId: this.padStart(table.table_id, 8, '0') });
                });
            } else {
                events.push({...bookingTemplate, resourceId: 0 });
            }
        }

        successCallback(events);
    }

    padStart(str: string = "", targetLength: number, padString: string) {
        if (str.length >= targetLength) return str;

        const padLength = targetLength - str.length;
        const padding = padString.repeat(Math.ceil(padLength / padString.length));

        return padding.slice(0, padLength) + str;
    }

    getEventColor(booking: Bookings) {
        switch(booking.status) {
            case 'provisional':
                return '#F9A825';
            case 'confirmed':
                return '#4CAF50';
            case 'arrived':
            case 'seated':
                return '#D50000';
            case 'departed':
            case 'noshow':
            default:
                return '#BDBDBD';
        }
    };

    handleEvents(events: EventApi[]) {
        this.currentEvents.set(events);
        this.changeDetector.detectChanges();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}