import {
    AfterViewInit,
    Component,
    ElementRef,
    inject,
    Injectable,
} from '@angular/core';

import {
    lastValueFrom
} from 'rxjs';

import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
} from '@angular/material/dialog';

import {
    BaseDialogService,
    NonNullableConfigData,
    TilbyDialogContentComponent,
    TilbyDialogToolbarComponent
} from "@tilby/tilby-ui-lib/components/tilby-dialog";

import {
    CustomForm,
    CustomFormControl,
    CustomFormControlProps,
    CustomFormGroup,
    TilbyMagicFormComponent
} from '@tilby/tilby-ui-lib/components/tilby-magic-form';

import {
    Validators
} from '@angular/forms';

import {
    BarcodeManagerService,
    EnvironmentInfoService
} from 'src/app/core';

import {
    CommonModule
} from '@angular/common';

import {
    MatButtonModule
} from '@angular/material/button';

import {
    TranslateModule
} from '@ngx-translate/core';

type PromptDialogInput = {
    title?: string;
    message?: string;
    type?: 'text' | 'number' | 'email';
    label?: string;
    minLength?: number;
    maxLength?: number;
    min?: number;
    max?: number;
    step?: number;
    value?: string;
    allowCamera?: boolean;
    disableCancel?: boolean;
};

@Component({
    selector: 'app-prompt-dialog',
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        TranslateModule,
        TilbyMagicFormComponent,
        TilbyDialogToolbarComponent,
        TilbyDialogContentComponent
    ],
    templateUrl: './prompt-dialog.component.html',
    styleUrls: ['./prompt-dialog.component.scss']
})
export class PromptDialog implements AfterViewInit {
    protected readonly data: PromptDialogInput = inject(MAT_DIALOG_DATA);
    private readonly barcodeManagerService = inject(BarcodeManagerService);
    private readonly environmentInfoService = inject(EnvironmentInfoService);
    private readonly el = inject(ElementRef);
    private readonly dialogRef = inject(MatDialogRef);

    protected form: CustomFormGroup<CustomForm<any>>

    protected readonly title = this.data.title || 'APPLICATION.PROMPT_DIALOG.TITLE_LABEL';

    ngAfterViewInit(): void {
        setTimeout(() => {
            const input = this.el.nativeElement.querySelector('#input-dialog-field input');

            if (input) {
                input.focus();
            }
        }, 200)
    }

    constructor() {
        this.form = new CustomFormGroup<CustomForm<any>>({
            inputValue: new CustomFormControl(
                this.data.value || undefined,
                {
                    validators: [
                        Validators.required,
                        ...(this.data.type === 'email' ? [Validators.email] : []),
                        ...(this.data.minLength ? [Validators.minLength(this.data.minLength)] : []),
                        ...(this.data.maxLength ? [Validators.maxLength(this.data.maxLength)] : []),
                        ...(this.data.min ? [Validators.min(this.data.min)] : []),
                        ...(this.data.max ? [Validators.max(this.data.max)] : []),
                    ]
                }, {
                    ...new CustomFormControlProps(),
                    id: 'input-dialog-field',
                    label: this.data.label || 'APPLICATION.PROMPT_DIALOG.NAME_LABEL',
                    inputType: this.data.type || 'text',
                    hint: this.data.maxLength ? () => `${this.form.get('inputValue')?.value?.length || 0} / ${this.data.maxLength}` : undefined,
                    ...(this.data.allowCamera && this.environmentInfoService.isCameraBarcodeSupported() && {
                        customActions: {
                            suffix: {
                                icon: "photo_camera",
                                callback: () => this.openCameraBarcode()
                            }
                        }
                    })
                }
            )
        });
    }

    confirm() {
        if(!this.form.valid) {
            return;
        }

        this.dialogRef?.close(this.form.get('inputValue')?.value);
    }

    private async openCameraBarcode() {
        const newValue = await this.barcodeManagerService.openCameraBarcode();

        this.form?.patchValue({ inputValue: newValue });
    }
}

@Injectable({
    providedIn: 'root',
})
export class PromptDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public async openDialog(config: NonNullableConfigData<PromptDialogInput>): Promise<any> {
        const dialogConfig: NonNullableConfigData<PromptDialogInput> = {
            ...this.switchMobileDesktopDimensions({ width: '800px' }),
            ...config,
            ...{ disableClose: !!config.data.disableCancel }
        };

        return lastValueFrom(this.dialogRef.open(PromptDialog, dialogConfig).afterClosed());
    }
}