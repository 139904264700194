import angular from 'angular';
import _ from 'lodash';
import moment from 'moment-timezone';
import { XMLNode } from 'app/libs/XmlNode';
import { MathUtils } from 'src/app/shared/utils';

angular.module('core').factory('util', util);
util.$inject = ["$injector", "$rootScope", "$window", "environmentInfo", "systemUtils"];

function util($injector, $rootScope, $window, environmentInfo, systemUtils) {
    var util = {
        getDayStartTime: function getDayStartTime() {
            var checkManager = $injector.get("checkManager");
            var timeInfo = _.split(checkManager.getPreference("day_start_time_24") || '6:00', ':');
            var hours = _.toInteger(timeInfo[0]);
            var minutes = _.toInteger(timeInfo[1]);

            if (!(hours >= 0 && hours < 24)) {
                hours = 0;
            }

            if (!(minutes >= 0 && minutes < 60)) {
                minutes = 0;
            }

            var now = moment();
            var dayStart = moment().hours(hours).minutes(minutes).startOf('minute');

            if(dayStart.isAfter(now)) {
                dayStart.subtract(1, 'day');
            }

            return dayStart.toDate();
        },
        getUrlParams: function(url) {
            var paramsSplitted;
            var paramsObj = {};

            var params = url.split('?');

            if (params.length >= 2) {
                paramsSplitted = params[1].split('&');

                _.each(paramsSplitted, function(param)  {
                    var p = param.split("=");
                    if (!_.isEmpty(p[0]) && !_.isEmpty(p[1])) {
                        paramsObj[p[0]] = decodeURIComponent(p[1]);
                    }
                });
            }

            return paramsObj;
        },
        round: (num, decimals) => MathUtils.round(num, decimals),
        createXMLNode: function(nodeName, attributes, value, options) {
            return new XMLNode(nodeName, attributes, value, options);
        },
        isUserAdmin: function isUserAdmin() {
            return _.includes(['admin', 'root'], $rootScope.userActiveSession.role);
        },
        fixedEncodeURIComponent: function(str) {
            return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
                return '%' + c.charCodeAt(0).toString(16);
            });
        },
        getVatRegexp: function getVatRegexp(requireCountryPrefix) {
            if(requireCountryPrefix) {
                return /^ATU[0-9]{8}|BE0[0-9]{9}|BG[0-9]{9,10}|CY[0-9]{8}L|CZ[0-9]{8,10}|DE[0-9]{9}|DK[0-9]{8}|EE[0-9]{9}|EL|GR[0-9]{9}|ES[0-9A-Z][0-9]{7}[0-9A-Z]|FI[0-9]{8}|FR[0-9A-Z]{2}[0-9]{9}|GB[0-9]{9}[0-9]{3}|[A-Z]{2}[0-9]{3}|HU[0-9]{8}|IE[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|LT[0-9]{9}|[0-9]{12}|LU[0-9]{8}|LV[0-9]{11}|MT[0-9]{8}|NL[0-9]{9}B[0-9]{2}|PL[0-9]{10}|PT[0-9]{9}|RO[0-9]{2,10}|SE[0-9]{12}|SI[0-9]{8}|SK[0-9]{10}|SM[0-9]+$/;
            } else {
                return /^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10}|(SM)[0-9]+)$/;
            }
        },
        getBicSwiftRegexp: function getBicSwiftRegexp() {
            return /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/;
        },
        getCurrentPriceList: () => $injector.get('newSaleUtils').getCurrentPriceList(),
        resetPriceList: (currentPriceList) => $injector.get('newSaleUtils').resetPriceList(currentPriceList),
        openExternalLink: (url) => systemUtils.openExternalLink(url),
        downloadZipFromLambda: function (file, fileName) {
            var a = document.createElement('a');
            document.body.appendChild(a);
            a.style = 'display: none';
            var fileURL = (window.URL || window.webkitURL).createObjectURL(file);
            a.href = fileURL;
            a.download = fileName;
            a.click();
        },
        downloadFile: function downloadFile(fileData, fileName, mimeType) {
            var a = document.createElement('a');
            document.body.appendChild(a);
            a.style = 'display: none';
            var file = new Blob([fileData], {type: mimeType});
            var fileURL = (window.URL || window.webkitURL).createObjectURL(file);
            a.href = fileURL;
            a.download = fileName;
            a.click();
            (window.URL || window.webkitURL).revokeObjectURL(file);
        },
        printFile: async (fileData, mimeType) => {
            const blob = new Blob([fileData], { type: mimeType });
            const dataURL = await util.blobToDataURL(blob);

            if(environmentInfo.isElectronApp()) {
                window.require('electron').ipcRenderer.invoke('open-pdf', { url: dataURL });
            } else if(environmentInfo.isMobileApp()) {
                //Cordova
            } else {
                var win = $window.open();
                win.document.write('<iframe src="' + dataURL + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
            }
        },
        blobToDataURL: function(data) {
            return new Promise((resolve, reject) => {
                let reader = new FileReader();

                reader.onload = () => {
                    resolve(reader.result);
                };

                reader.readAsDataURL(data);
            });
        },
        dataURLToBase64: function(dataURL){
            var BASE64_MARKER = ";base64,";
            var parts = dataURL.split(BASE64_MARKER);
            return parts[1];
        },
        dataURLToBlob: function(dataURL){
            var BASE64_MARKER = ";base64,";
            var parts, contentType, raw;

            if (dataURL.indexOf(BASE64_MARKER) === -1) {
                parts = dataURL.split(",");
                contentType = parts[0].split(":")[1];
                raw = decodeURIComponent(parts[1]);

                return new Blob([raw], {type: contentType});
            }

            parts = dataURL.split(BASE64_MARKER);
            contentType = parts[0].split(":")[1];
            raw = window.atob(parts[1]);

            var rawLength = raw.length;
            var uInt8Array = new Uint8Array(rawLength);

            for (var i = 0; i < rawLength; ++i) {
                uInt8Array[i] = raw.charCodeAt(i);
            }

            return new Blob([uInt8Array], {type: contentType});
        },
        base64ToDataURL: function(type, base64str){
            return "data:" + type + ";base64," + base64str;
        },
        getItemsFromIds: async (itemRows) => {
            const itemsIDList = Array.isArray(itemRows) ? itemRows.map((item) => item?.item_id) : [];

            return await $injector.get('entityManager').items.fetchMapFromIdsOffline(itemsIDList);
        },
        getCustomerCaption: function(customer) {
            if(_.isObject(customer)) {
                if (customer.company_name) {
                    return customer.company_name;
                } else if (customer.first_name && customer.last_name) {
                    return _.upperFirst(customer.first_name) + ' ' + _.upperFirst(customer.last_name);
                } else {
                    return null;
                }
            } else {
                return null;
            }
        },
        checkDateRangeOverlap: function(startA, endA, startB, endB) {
            return (startA < endB)  &&  (endA > startB);
        },
        minimizeApp: () => systemUtils.minimizeApp()
    };

    return util;
}
