import {Component, Injectable, inject, signal} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import { lastValueFrom } from 'rxjs';

import {
    BaseDialogService,
    NonNullableConfigData,
    TilbyDialogContentComponent,
    TilbyDialogToolbarComponent
} from "@tilby/tilby-ui-lib/components/tilby-dialog";

import { Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';

import {
    CustomForm,
    CustomFormControl,
    CustomFormControlProps,
    CustomFormGroup,
    TilbyMagicFormComponent
} from '@tilby/tilby-ui-lib/components/tilby-magic-form';

import {
    GeneralInputButton
} from '@tilby/tilby-ui-lib/models';

import {
    ActiveSaleService,
    DeleteSaleOptions
} from 'src/app/features';

import {
    AlertDialogService
} from '../alert-dialog';

import {
    ConfigurationManagerService
} from 'src/app/core';

@Component({
  selector: 'app-change-sale-name-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TilbyDialogContentComponent,
    TilbyDialogToolbarComponent,
    MatDialogModule,
    TilbyMagicFormComponent,
  ],
  templateUrl: './change-sale-name-dialog.component.html',
  styleUrls: ['./change-sale-name-dialog.component.scss']
})
export class ChangeSaleNameDialogComponent {

    private readonly data: {saleName: string} = inject(MAT_DIALOG_DATA);
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly activeSaleService = inject(ActiveSaleService);
    private readonly alertDialogService = inject(AlertDialogService);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    editSaleNameForm!: CustomFormGroup<CustomForm<{saleName: string}>>;

    private readonly canDeleteSale = this.configurationManagerService.isUserPermitted('cashregister.delete_sale');

    protected customActions: GeneralInputButton[] = [
        {
            name: "",
            click: () => this.confirm(),
            isIt: () => true,
            icon: signal("check")
        }
    ]

    constructor() {
        this.editSaleNameForm = new CustomFormGroup<CustomForm<{saleName: string}>>({
            saleName: new CustomFormControl(
                this.data.saleName,
                {
                    validators: [
                        Validators.required,
                        Validators.maxLength(30)
                    ]
                },
                {
                    ...new CustomFormControlProps(),
                    label: 'DIALOG.CHANGE_NAME_SALE.NAME_SALE',
                    inputType: 'text',
                    hint: () => `${this.editSaleNameForm?.controls.saleName?.value?.length || 0}/30`
                }
            )
        });

        if(this.canDeleteSale) {
            this.customActions.unshift({
                name: "",
                click: () => this.deleteSale(),
                isIt: () => true,
                icon: signal("delete")
            });
        }
    }

    get saleName(){
        return this.editSaleNameForm.controls.saleName;
    }

    async deleteSale(){
        let deleteOptions: DeleteSaleOptions | undefined;

        try {
            await this.activeSaleService.deleteSale(deleteOptions);

            this.matDialogRef.close();
        } catch (error: any) {
            this.alertDialogService.openDialog({ data: { messageLabel: error } });
        }
    }

    confirm(): void {
        this.matDialogRef.close(this.saleName.value);
    }
}

@Injectable({
    providedIn: 'root'
})
export class ChangeSaleNameDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(data?: {saleName: string}) {
        if(!data) {
            data={saleName:''};
        }
        const config:NonNullableConfigData<any>={...this.switchMobileDesktopDimensions({ height: '170px', width: '370px' }), disableClose:true, data };
        return lastValueFrom(this.dialogRef.open(ChangeSaleNameDialogComponent,config).afterClosed()).then((res : string | undefined) => {
            return {
                saleName : res
            }
        });
    }
}
