import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";

export class IconRegistry {

    constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
        //iconRegistry.setDefaultFontSetClass('material-icons-outlined'); // CHANGE BEHAVIOUR FOR ALL ICONS (<mat-icon fontIcon/> DOESN'T WORK => USE <mat-icon>icon_name</mat-icon> INSTEAD)

        iconRegistry.addSvgIcon('whatsapp', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/action/ic_whatsapp_24px.svg'));
        iconRegistry.addSvgIcon('analytics', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/analytics/Analytics-f-24.svg'));

        iconRegistry.addSvgIcon('ph_wall_h', sanitizer.bypassSecurityTrustResourceUrl('assets/images/tables/furnitures/ph_wall_h.svg'));
        iconRegistry.addSvgIcon('ph_wall_v', sanitizer.bypassSecurityTrustResourceUrl('assets/images/tables/furnitures/ph_wall_v.svg'));
        iconRegistry.addSvgIcon('ph_plant1', sanitizer.bypassSecurityTrustResourceUrl('assets/images/tables/furnitures/ph_plant1.svg'));
        iconRegistry.addSvgIcon('ph_plant2', sanitizer.bypassSecurityTrustResourceUrl('assets/images/tables/furnitures/ph_plant2.svg'));

        iconRegistry.addSvgIcon('ph_armchair_dx', sanitizer.bypassSecurityTrustResourceUrl('assets/images/tables/furnitures/01_Poltrona_destra.svg'));
        iconRegistry.addSvgIcon('ph_armchair_sx', sanitizer.bypassSecurityTrustResourceUrl('assets/images/tables/furnitures/01_Poltrona_sinistra.svg'));
        iconRegistry.addSvgIcon('ph_armchair_top', sanitizer.bypassSecurityTrustResourceUrl('assets/images/tables/furnitures/01_Poltrona_sopra.svg'));
        iconRegistry.addSvgIcon('ph_armchair_bottom', sanitizer.bypassSecurityTrustResourceUrl('assets/images/tables/furnitures/01_Poltrona_sotto.svg'));
        iconRegistry.addSvgIcon('ph_sofa_dx', sanitizer.bypassSecurityTrustResourceUrl('assets/images/tables/furnitures/02_Divano_destra.svg'));
        iconRegistry.addSvgIcon('ph_sofa_sx', sanitizer.bypassSecurityTrustResourceUrl('assets/images/tables/furnitures/02_Divano_sinistra.svg'));
        iconRegistry.addSvgIcon('ph_sofa_top', sanitizer.bypassSecurityTrustResourceUrl('assets/images/tables/furnitures/02_Divano_sopra.svg'));
        iconRegistry.addSvgIcon('ph_sofa_bottom', sanitizer.bypassSecurityTrustResourceUrl('assets/images/tables/furnitures/02_Divano_sotto.svg'));
        iconRegistry.addSvgIcon('ph_plant', sanitizer.bypassSecurityTrustResourceUrl('assets/images/tables/furnitures/03_Pianta.svg'));
        iconRegistry.addSvgIcon('ph_cashreg_counter', sanitizer.bypassSecurityTrustResourceUrl('assets/images/tables/furnitures/04_BancoCassa.svg'));
        iconRegistry.addSvgIcon('ph_cashreg_only', sanitizer.bypassSecurityTrustResourceUrl('assets/images/tables/furnitures/04_SoloCassa.svg'));

        iconRegistry.addSvgIcon('square', sanitizer.bypassSecurityTrustResourceUrl('assets/images/tables/shapes/01_Quadrato_4Posti.svg'));
        iconRegistry.addSvgIcon('circle4', sanitizer.bypassSecurityTrustResourceUrl('assets/images/tables/shapes/02_Circolare_4Posti.svg'));
        iconRegistry.addSvgIcon('circle8', sanitizer.bypassSecurityTrustResourceUrl('assets/images/tables/shapes/03_Circolare_8Posti.svg'));
        iconRegistry.addSvgIcon('oval_hor', sanitizer.bypassSecurityTrustResourceUrl('assets/images/tables/shapes/04_Ovale_6Posti.svg'));
        iconRegistry.addSvgIcon('oval_vert', sanitizer.bypassSecurityTrustResourceUrl('assets/images/tables/shapes/04_OvaleVerticale_6Posti.svg'));
        iconRegistry.addSvgIcon('rect_hor_6', sanitizer.bypassSecurityTrustResourceUrl('assets/images/tables/shapes/05_Rettangolare_6Posti.svg'));
        iconRegistry.addSvgIcon('rect_vert_6', sanitizer.bypassSecurityTrustResourceUrl('assets/images/tables/shapes/05_RettangolareVerticale_6Posti.svg'));
        iconRegistry.addSvgIcon('rect_hor_12', sanitizer.bypassSecurityTrustResourceUrl('assets/images/tables/shapes/06_Rettangolare_12Posti.svg'));
        iconRegistry.addSvgIcon('rect_vert_12', sanitizer.bypassSecurityTrustResourceUrl('assets/images/tables/shapes/06_RettangolareVerticale_12Posti.svg'));
        iconRegistry.addSvgIcon('counter_top', sanitizer.bypassSecurityTrustResourceUrl('assets/images/tables/shapes/07_Bancone_sopra.svg'));
        iconRegistry.addSvgIcon('counter_bottom', sanitizer.bypassSecurityTrustResourceUrl('assets/images/tables/shapes/07_Bancone_sotto.svg'));
        iconRegistry.addSvgIcon('counter_dx', sanitizer.bypassSecurityTrustResourceUrl('assets/images/tables/shapes/07_BanconeVerticale_destra.svg'));
        iconRegistry.addSvgIcon('counter_sx', sanitizer.bypassSecurityTrustResourceUrl('assets/images/tables/shapes/07_BanconeVerticale_sinistra.svg'));
        iconRegistry.addSvgIcon('chair_dx', sanitizer.bypassSecurityTrustResourceUrl('assets/images/tables/shapes/08_Sedia_destra.svg'));
        iconRegistry.addSvgIcon('chair_sx', sanitizer.bypassSecurityTrustResourceUrl('assets/images/tables/shapes/08_Sedia_sinistra.svg'));
        iconRegistry.addSvgIcon('chair_top', sanitizer.bypassSecurityTrustResourceUrl('assets/images/tables/shapes/08_Sedia_sopra.svg'));
        iconRegistry.addSvgIcon('chair_bottom', sanitizer.bypassSecurityTrustResourceUrl('assets/images/tables/shapes/08_Sedia_sotto.svg'));

        iconRegistry.addSvgIcon('weather-big-snow', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/weather/weather-big-snow.svg'));
        iconRegistry.addSvgIcon('weather-clear-night', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/weather/weather-clear-night.svg'));
        iconRegistry.addSvgIcon('weather-clear', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/weather/weather-clear.svg'));
        iconRegistry.addSvgIcon('weather-clouds-night', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/weather/weather-clouds-night.svg'));
        iconRegistry.addSvgIcon('weather-clouds', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/weather/weather-clouds.svg'));
        iconRegistry.addSvgIcon('weather-drizzle-day', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/weather/weather-drizzle-day.svg'));
        iconRegistry.addSvgIcon('weather-drizzle-night', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/weather/weather-drizzle-night.svg'));
        iconRegistry.addSvgIcon('weather-few-clouds-night', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/weather/weather-few-clouds-night.svg'));
        iconRegistry.addSvgIcon('weather-few-clouds', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/weather/weather-few-clouds.svg'));
        iconRegistry.addSvgIcon('weather-fog', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/weather/weather-fog.svg'));
        iconRegistry.addSvgIcon('weather-hail', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/weather/weather-hail.svg'));
        iconRegistry.addSvgIcon('weather-haze', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/weather/weather-haze.svg'));
        iconRegistry.addSvgIcon('weather-mist', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/weather/weather-mist.svg'));
        iconRegistry.addSvgIcon('weather-none-available', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/weather/weather-none-available.svg'));
        iconRegistry.addSvgIcon('weather-rain-day', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/weather/weather-rain-day.svg'));
        iconRegistry.addSvgIcon('weather-rain-night', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/weather/weather-rain-night.svg'));
        iconRegistry.addSvgIcon('weather-showers-day', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/weather/weather-showers-day.svg'));
        iconRegistry.addSvgIcon('weather-showers-night', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/weather/weather-showers-night.svg'));
        iconRegistry.addSvgIcon('weather-showers-scattered-day', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/weather/weather-showers-scattered-day.svg'));
        iconRegistry.addSvgIcon('weather-showers-scattered-night', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/weather/weather-showers-scattered-night.svg'));
        iconRegistry.addSvgIcon('weather-snow-rain', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/weather/weather-snow-rain.svg'));
        iconRegistry.addSvgIcon('weather-showers-scattered-day', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/weather/weather-showers-scattered-day.svg'));
        iconRegistry.addSvgIcon('weather-showers-scattered-night', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/weather/weather-showers-scattered-night.svg'));
        iconRegistry.addSvgIcon('weather-snow', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/weather/weather-snow.svg'));
        iconRegistry.addSvgIcon('weather-storm-day', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/weather/weather-storm-day.svg'));
        iconRegistry.addSvgIcon('weather-storm-night', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/weather/weather-storm-night.svg'));
        iconRegistry.addSvgIcon('weather-storm', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/weather/weather-storm.svg'));
        iconRegistry.addSvgIcon('weather-wind', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/weather/weather-wind.svg'));
        iconRegistry.addSvgIcon('alvolo', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/alvolo/alvolo_logo.svg'));
        iconRegistry.addSvgIcon('alvolo-dark', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/alvolo/alvolo_logo-dark.svg'));
        iconRegistry.addSvgIcon('pienissimo', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/pienissimo/pienissimo-logo.svg'));
        iconRegistry.addSvgIcon('lottery_code', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/hand_fingers_crossed.svg'));
        iconRegistry.addSvgIcon('thefork', sanitizer.bypassSecurityTrustResourceUrl('assets/images/channels/thefork.png'));
        iconRegistry.addSvgIcon('plateform', sanitizer.bypassSecurityTrustResourceUrl('assets/images/channels/plateform.png'));
        iconRegistry.addSvgIcon('lean', sanitizer.bypassSecurityTrustResourceUrl('assets/images/channels/lean.png'));
    }
}
