<div class="tw-flex">
    <mat-form-field class="tw-pr-2">
        <mat-select [(value)]="categoryFilter" (selectionChange)="searchItems.emit({ forceSearch: true, allCategories: true, categoryFilter: categoryFilter, typeFilter: typeFilter, stockFilter: stockFilter })">
            <mat-option [value]="'ALL'">{{'CASHREGISTER.SEARCH.FILTERS.CATEGORY.ALL' | translate}}</mat-option>
            <mat-option *ngFor="let category of visibleCategories" [value]="category.id">
                {{category.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
        <mat-form-field class="tw-pr-2">
            <mat-select [(value)]="typeFilter" (selectionChange)="searchItems.emit({ forceSearch: true, allCategories: true, categoryFilter: categoryFilter, typeFilter: typeFilter, stockFilter: stockFilter })">
                <mat-option [value]="'ALL'">{{'CASHREGISTER.SEARCH.FILTERS.TYPE.ALL' | translate}}</mat-option>
                <mat-option [value]="'NAME'">{{'CASHREGISTER.SEARCH.FILTERS.TYPE.NAME' | translate}}</mat-option>
                <mat-option [value]="'SKU'">{{'CASHREGISTER.SEARCH.FILTERS.TYPE.SKU' | translate}}</mat-option>
                <mat-option [value]="'BARCODE'">{{'CASHREGISTER.SEARCH.FILTERS.TYPE.BARCODE' | translate}}</mat-option>
                <mat-option [value]="'BRAND'">{{'CASHREGISTER.SEARCH.FILTERS.TYPE.BRAND' | translate}}</mat-option>
                <mat-option [value]="'SEASON'">{{'CASHREGISTER.SEARCH.FILTERS.TYPE.SEASON' | translate}}</mat-option>
            </mat-select>
        </mat-form-field>
    <mat-form-field>
        <mat-select [(value)]="stockFilter" (selectionChange)="searchItems.emit({ forceSearch: true, allCategories: true, categoryFilter: categoryFilter, typeFilter: typeFilter, stockFilter: stockFilter })">
            <mat-option [value]="'ALL'">{{'CASHREGISTER.SEARCH.FILTERS.STOCK.ALL' | translate}}</mat-option>
            <mat-option [value]="'ONLY_STOCK'">{{'CASHREGISTER.SEARCH.FILTERS.STOCK.ONLY_STOCK' | translate}}</mat-option>
            <mat-option [value]="'NO_STOCK'">{{'CASHREGISTER.SEARCH.FILTERS.STOCK.NO_STOCK' | translate}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>

<div *ngIf="isLoading" class="tw-flex tw-w-full tw-justify-center tw-mt-32">
    <mat-spinner class="tw-h-12 sm:tw-h-24"></mat-spinner>
</div>
<div class="tw-flex tw-py-4 tw-justify-center" *ngIf="!searchResults.results.length && !isLoading" translate>
    CASHREGISTER.SHOWCASE.SEARCH_NO_RESULTS
</div>
<div *ngIf="searchResults.results.length && !isLoading" style="height: calc(100% - 83px)">
    <cdk-virtual-scroll-viewport class="tw-h-full tw-w-full" [itemSize]="54" [minBufferPx]="screenHeight" [maxBufferPx]="screenHeight" *ngIf="searchResults.results.length && !isLoading">
        <div *cdkVirtualFor="let category of searchResults.results; let i = index" class="tw-pt-2 tw-pr-2">
            <div class="mat-primary tw-py-3 tw-pl-4 tw-border-t tw-border-l tw-rounded-tl-xl" style="color:black" [ngStyle]="{'background-color': '#' + (category.color || 'b2dfdb') }" [style.border-top-left-radius.px]="i === 0 && firstCategoryBorderRounded ? 10 : 0">{{ category.name }}</div>
            <div *ngFor="let item of category.items; trackBy: trackByFunction; let last = last" class="tw-h-[52px] tw-min-h-[52px] tw-pt-1">

            <tilby-item-element
                [item]="item"
                [showThumbnail]="showThumbnail"
                [showUnavailableStock]="showUnavailableStock"
                [priceIdentifier]="priceList"
                [priceWithCurrency]="true"
                [truncatePriceWithDots]="truncatePriceWithDots"
                [getStockStatus]="getItemStockStatus"
                [isHidePrices]="!showPrices"
                (itemTapped)="addItemToSale.emit(item)"
                (itemLongPressed)="showItem.emit(item)"
            >
            </tilby-item-element>
                <!-- <div class="tw-cursor-pointer tw-border tw-border-solid tw-border-gray-300 tw-rounded-lg tw-flex tw-flex-row tw-h-12 tw-font-medium tw-items-center tw-overflow-hidden" tilbyGestures (click)="addItemToSale.emit(item)" (longPress)="showItem.emit(item)">
                    <div class="tw-w-3 tw-h-full tw-flex-shrink-0" [ngStyle]="{ 'background-color': '#' + (item.color || 'BBDEFB') }"></div>
                    @if(showUnavailableStock || getItemStockStatus(item) !== 'stock-info-nostock') {
                        <div class="item-stock-status tw-flex-shrink-0" [ngClass]="getItemStockStatus(item)"></div>
                    }
                    <div class="tw-flex-auto tw-text-sm tw-ml-2">{{ item.name }}</div>
                    @if (item.thumbnail) {
                        <img class="tw-rounded-md tw-h-10 tw-w-10 tw-object-cover tw-ml-1 tw-pointer-events-none" [src]="item.thumbnail"/>
                    }
                    <div class="truncate tw-text-sm tw-mx-2 tw-text-right tw-flex-shrink-0">{{ $any(getItemPriceList(item)) | tilbyCurrency }}</div>
                </div> -->
            </div>
        </div>
    </cdk-virtual-scroll-viewport>
</div>
