import {
    Component,
    Input,
} from "@angular/core";
import { Sales } from "tilby-models";
import { CommonModule } from "@angular/common";
import { TableDeliveryComponent } from "../tables";

@Component({
    selector: 'app-delivery-take-away',
    templateUrl: './delivery-take-away.component.html',
    styleUrls: ['./delivery-take-away.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TableDeliveryComponent,
    ]
})
export class DeliveryTakeAwayComponent {
    @Input() sales: Sales[] = [];
    
}