import {CashMovements, Sales} from 'tilby-models';

type HistoryCustomFilterType = 'customer_name' | 'type_document' | 'printer' | 'payment_type' | 'sequential_number';

export class HistorySalesNormalizedData{
     static normalized(data: Object & { results: (Sales & Record<HistoryCustomFilterType, any>)[] }) {
        data.results?.forEach(element => {
            element.customer_name = element?.sale_customer ?
                                            ((element.sale_customer.first_name ? element.sale_customer.first_name + ' ' : '')
                                            + (element.sale_customer.last_name ? element.sale_customer.last_name + ' ' : '')
                                            + (((element.sale_customer.first_name || element.sale_customer.last_name) && element.sale_customer.company_name) ? ' - ' : '')
                                      + (element.sale_customer.company_name ? element.sale_customer.company_name : '')) : '';
            element.type_document = element?.sale_documents && element?.sale_documents[0]?.document_type || '';
            element.printer = element?.sale_documents && element?.sale_documents[0]?.printer_name || '';
            const paymentNames = element?.payments?.map(payment => payment.payment_method_name);
            element.payment_type = paymentNames?.length && paymentNames.length > 1 ? paymentNames.join(', ') : paymentNames?.[0];
            element.sequential_number = element?.sale_documents && element?.sale_documents[0]?.sequential_number || '';
        });
        return data;
    }
}

type HistoryCashMovementsCustomFilterType = 'movement_type' | 'movement_account';

export class HistoryCashMovementsNormalizedData{
    static normalized(data: Object & { results: (CashMovements & Record<HistoryCashMovementsCustomFilterType, any>)[] }) {
       data.results?.forEach(element => {
           element.movement_type = element?.type || '';
           element.movement_account = element?.account || '';
       });
       return data;
   }
}