<div id="container" class="tw-flex">
    <div [ngClass]="{'border-separator': isLandscape(), 'list': isLandscape(), 'list-full': !isLandscape() && !hasBack}">
        <app-settings-um-list *ngIf="isLandscape()"
            [enabledQrCodeGenerator]="true"
            [buttons]="customButtons"
            [items]="devices"
            [allItems]="allDevices"
            [itemsIcon]="itemsIcon"
            (itemSelected)="onDeviceSelected($event)"
            [fieldBuilderLegend1]="buildFieldLegend1"
            [fieldBuilderLegend2]="buildFieldLegend2"
            [filterFunction]="customFilterFunction"
            [isLoading]="isLoading"
        ></app-settings-um-list>
    </div>

    <div class="tw-flex tw-flex-col tw-w-full">
        @if(deviceSelected){
            <div id="section" class="tw-p-7">
                <div class="tw-flex tw-justify-between tw-w-full">
                    <div class="tw-flex tw-flex-col tw-gap-5">
                        <span class="tw-text-[1.2rem]">
                            <b>{{ 'SETTINGS.USERS_MANAGEMENT.DEVICES.DEVICE_NAME' | translate}}:</b> {{ deviceSelected.name }}
                        </span>
                        <span class="tw-text-[1.2rem]">
                            <b>{{ 'SETTINGS.USERS_MANAGEMENT.DEVICES.DEVICE_SERIAL' | translate}}:</b> {{ deviceSelected.serial }}
                        </span>
                    </div>
                    <div class="tw-flex tw-flex-col tw-gap-5">
                        <span class="tw-text-[1.2rem]">
                            <b>{{ 'SETTINGS.USERS_MANAGEMENT.DEVICES.CREATED_AT' | translate}}:</b> {{ deviceSelected.created_at | tilbyDate:'dd/MM/yyyy' }}
                        </span>
                        <span class="tw-text-[1.2rem]">
                            <b>{{ 'SETTINGS.USERS_MANAGEMENT.DEVICES.CREATED_BY' | translate}}:</b> {{ getUserInfo(deviceSelected) }}
                        </span>
                    </div>
                </div>
            </div>
        }

        @if(deviceSelected && retailForceLocal) {
            <div id="section" class="tw-p-7">
                <div class="tw-py-4">
                    <b class="tw-text-2xl">
                        {{ 'SETTINGS.USERS_MANAGEMENT.DEVICES.RETAILFORCE.TITLE' | translate }}
                    </b>
                </div>
                <div class="tw-container">
                    @if(settingUpRetailForce) {
                        <div class="tw-flex tw-w-full tw-justify-center tw-mt-5 tw-mb-5">
                            <mat-spinner class="tw-h-12 tw-w-12"></mat-spinner>
                        </div>
                    } @else {
                        <form [formGroup]="retailForceForm" class="tw-flex tw-justify-between tw-w-full tw-py-10 tw-gap-x-12">
                            <mat-form-field class="tw-w-1/3">
                                <mat-label translate>{{ 'SETTINGS.USERS_MANAGEMENT.DEVICES.RETAILFORCE.IP_ADDRESS' | translate }}</mat-label>
                                <input matInput id="ipAddress" formControlName="ipAddress" type="text" class="form-control" required>
                            </mat-form-field>
                            <mat-form-field class="tw-w-1/3">
                                <mat-label translate>{{ 'SETTINGS.USERS_MANAGEMENT.DEVICES.RETAILFORCE.TERMINAL_NUMBER' | translate }}</mat-label>
                                <input matInput id="terminalNumber" formControlName="terminalNumber" type="text" class="form-control" required>
                            </mat-form-field>
                            <mat-form-field class="tw-w-1/3">
                                <mat-label translate>{{ 'SETTINGS.USERS_MANAGEMENT.DEVICES.RETAILFORCE.CLIENT_ID' | translate }}</mat-label>
                                <input matInput id="clientId" formControlName="clientId" type="text" class="form-control" readonly [disabled]="true">
                            </mat-form-field>
                        </form>
                        <div>
                            <button mat-button class="tw-w-full" (click)="initClient()" [disabled]="!canInitClient()"><span translate>{{ 'SETTINGS.USERS_MANAGEMENT.DEVICES.RETAILFORCE.INIT' | translate }}</span></button>
                        </div>
                    }
                </div>
            </div>
        }
    </div>
</div>