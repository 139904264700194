import angular from 'angular';
import _ from 'lodash';

angular.module('digitalPayments').factory('cashlogy', cashlogy);

cashlogy.$inject = ["$mdDialog", "$translate", "$filter", "$interval", "checkManager", "cashlogyDriver"];

function cashlogy($mdDialog, $translate, $filter, $interval, checkManager, cashlogyDriver) {
    cashlogyController.$inject = ["$scope", "amount", "paymentMethod"];
    function cashlogyController($scope, amount, paymentMethod) {
        $scope.trSt = null;

        $scope.inProgress = true;
        $scope.message = $translate.instant('DIGITAL_PAYMENTS.CASHDRAWERS.CONNECTING', { drawer: 'Cashlogy'});

        $scope.getStatusUpdate = function() {
            var status = cashlogyDriver.getStatus();

            switch(status) {
                case 'UNINITIALIZED':
                    $scope.inProgress = true;
                    $scope.message = $translate.instant('DIGITAL_PAYMENTS.CASHDRAWERS.CONNECTING', { drawer: 'Cashlogy'});
                break;
                case 'INITIALIZING':
                    $scope.inProgress = true;
                    $scope.message = $translate.instant('DIGITAL_PAYMENTS.CASHDRAWERS.INITIALIZING', { drawer: 'Cashlogy'});
                break;
                case 'PAYING':
                    $scope.inProgress = false;
                    $scope.message = $translate.instant('DIGITAL_PAYMENTS.CASHDRAWERS.PAYING', { drawer: 'Cashlogy'});
                break;
            }
        };

        $scope.cancel = function() {
            $mdDialog.cancel('CANCELED');
        };

        $scope.confirm = function() {
            $mdDialog.hide($scope.trSt);
        };

        $scope.getStatusUpdate();

        var statusUpdate = $interval($scope.getStatusUpdate, 1000);

        try {
            cashlogyDriver.initialize(paymentMethod.schema_name);

            cashlogyDriver.performPayment(amount).then(function(result) {
                if(result.changeNotGiven) {
                    $scope.trSt = result;
                    $scope.message = $translate.instant('DIGITAL_PAYMENTS.CASHDRAWERS.CHANGE_NOT_GIVEN_ERROR');
                    $scope.changeNotGiven = true;
    
                    $scope.$applyAsync();
                } else {
                    $mdDialog.hide(result);
                }
            }, function(error) {
                $mdDialog.cancel(error);
            }).finally(function() {
                $interval.cancel(statusUpdate);
            });
        } catch(e) {
            throw e;
        }
    }

    var cashlogy = {
        payment: function(amount, options) {
            let paymentMethod = options.paymentMethod;

            if (!paymentMethod.schema_name) {
                return Promise.reject($translate.instant('DIGITAL_PAYMENTS.CASHDRAWERS.SCHEMA_NAME_NOT_FOUND', { drawer: 'Cashlogy' }));
            }

            return $mdDialog.show({
                controller: cashlogyController,
                template: require('./cashlogy.html'),
                locals: {
                    amount: amount,
                    paymentMethod: _.cloneDeep(paymentMethod)
                }
            }).then(function(transaction) {
                var transactionString = '';
                try { transactionString = JSON.stringify(transaction); } catch(e) {}

                var receiptTail = [];
                receiptTail.push("Dettaglio pagamento", "");
                receiptTail.push("Da pagare" + _.padStart($filter('sclCurrency')(transaction.toPay), 22, ' '));
                receiptTail.push("Pagato" + _.padStart($filter('sclCurrency')(transaction.paid), 25, ' '));
                receiptTail.push("Resto erogato" + _.padStart($filter('sclCurrency')(transaction.changeGiven), 18, ' '));
                receiptTail.push("Resto non erogato" + _.padStart($filter('sclCurrency')(transaction.changeNotGiven), 14, ' '));

                receiptTail = receiptTail.join('\n');

                const paymentData = {
                    unclaimed: false,
                    payment_data: transactionString,
                    tail: receiptTail
                };

                return checkManager.getPreference('cashregister.register_cashdrawers_paid_amount') ? { ...paymentData, amount: transaction.paid } : paymentData;
            }, function(error) {
                throw 'CASHLOGY.' + error;
            });
        }
    };

    return cashlogy;
}