import angular from 'angular';
import moment from 'moment-timezone';
import _ from 'lodash';

import {
    EpsonAlign,
    EpsonBarcode,
    EpsonCut,
    EpsonFont,
    EpsonHRI,
    EpsonSymbol,
    NFThermalPrinter
} from 'src/app/shared/model/nf-thermal-printer.model';

import {
    Items,
    NonfiscalDocumentsRules,
    Sales,
    SalesDocuments,
    SalesItems,
    SalesItemsPriceChanges,
    Vat
} from "tilby-models";

import {
    padCenter,
    stringToLines
} from 'src/app/shared/string-utils';

import {
    SalePaymentService,
    SaleUtilsService
} from 'src/app/features';

import {
    ConfigurationManagerService,
    ConfigurationPreferences,
    ConfigurationSettings
} from 'src/app/core';

import {
    FiscalUtilsService
} from 'app/modules/core/service/fiscal-utils/fiscal-utils';

import {
    MathUtils
} from '@tilby/tilby-ui-lib/utilities';

import {
    DocumentPrinterOptions
} from 'src/app/shared/model/document-printer.model';

import {
    keyBy
} from 'src/app/shared/utils';


// Typing for `blockOptions` based on the block type
interface TextBlockOptions {
    content: string | null;
}

interface ShopPreferenceBlockOptions {
    preference_id: keyof ConfigurationPreferences;
    prefix?: string;
    suffix?: string;
}

interface ShopSettingBlockOptions {
    preference_id: keyof ConfigurationSettings;
    prefix?: string;
    suffix?: string;
}

interface SaleFieldBlockOptions {
    field_id: string;
    prefix?: string;
    suffix?: string;
    skip_empty?: boolean;
}

interface DateTimeBlockOptions {
    format?: string;
    prefix?: string;
    suffix?: string;
}

interface SaleReferenceBlockOptions {
    format?: string;
    prefix?: string;
    suffix?: string;
}

interface ProgressiveBlockOptions {
    progressive_prefix?: string;
    prefix?: string;
    suffix?: string;
}

interface SaleContentBlockOptions {
    fields?: SaleContentFieldOptions[];
}

interface SaleContentFieldOptions {
    index?: number;
    field?: string;
    align?: string;
    length: number;
    pattern?: string;
    currency?: boolean;
    skip_empty?: boolean;
}

interface SaleTotalsBlockOptions {
    fields?: SaleTotalsFieldOptions[];
}

interface SaleTotalsFieldOptions {
    index?: number;
    field?: string;
    align?: string;
    prefix?: string;
    currency?: boolean;
    pattern?: string;
}

interface PriceChangesBlockOptions {
    print_currency?: boolean;
}

interface SalePaymentsBlockOptions {
    print_currency?: boolean;
}

interface SaleVatsBlockOptions {
    print_code?: boolean;
    print_value?: boolean;
    print_taxable?: boolean;
    print_tax?: boolean;
    print_total?: boolean;
    print_currency?: boolean;
}

interface SeparatorBlockOptions {
    rows?: number;
    pattern?: string;
}

interface BarcodeBlockOptions {
    barcode_type?: string;
    barcode_hri?: string;
    barcode_font?: string;
    width?: number;
    height?: number;
}

interface BarcodeFixedBlockOptions extends BarcodeBlockOptions {
    barcode_value?: string;
}

interface BarcodeSaleFieldBlockOptions extends BarcodeBlockOptions {
    field_id?: string;
}


export class DocumentBuilderService {
    private readonly defaultColumns = 46;

    static $inject = [
        "$filter",
        "$translate",
        "checkManager",
        "salePayment",
        "newSaleUtils",
        "fiscalUtils"
    ];

    constructor(
        private readonly $filter: any,
        private readonly $translate: any,
        private readonly configurationManagerService: ConfigurationManagerService,
        private readonly salePaymentService: SalePaymentService,
        private readonly saleUtilsService: SaleUtilsService,
        private readonly fiscalUtils: FiscalUtilsService
    ) {
    }

    //Common Block Functions START
    /**
     * Sets the text alignment for a block.
     * @param {NFThermalPrinter} driver - The thermal printer driver instance.
     * @param {NonfiscalDocumentsRules} block - The non-fiscal document rules.
     */
    private setBlockAlignment(driver: NFThermalPrinter, block: NonfiscalDocumentsRules) {
        let align;

        switch (block.align) {
            case 'left':
                align = EpsonAlign.LEFT;
                break;
            case 'center':
                align = EpsonAlign.CENTER;
                break;
            case 'right':
                align = EpsonAlign.RIGHT;
                break;
            default:
                align = EpsonAlign.LEFT;
                break;
        }

        driver.addTextAlign(align);
    };

    /**
     * Sets the text style (bold and size) for a block.
     * @param {NFThermalPrinter} driver - The thermal printer driver instance.
     * @param {NonfiscalDocumentsRules} block - The non-fiscal document rules.
     */
    private setBlockStyle(driver: NFThermalPrinter, block: NonfiscalDocumentsRules) {
        const bold = !!block.bold;
        const fontSize = block.font_size || 0;
        const size = (fontSize >= 1 && fontSize <= 8) ? fontSize : 1;

        driver.addTextStyle(undefined, undefined, bold, undefined);
        driver.addTextSize(size, size);
    };

    /**
     * Gets the VAT code from a VAT object.
     * @param {Vat} vat - The VAT object.
     * @returns {string} The VAT code.
     */
    private getVatCode(vat: Vat) {
        return vat.code || String.fromCharCode(vat.id + 0x41);
    }

    /**
     * Gets the alignment function based on the alignment type.
     * @param {string} align - The alignment type ('left', 'center', 'right').
     * @returns {Function} The alignment function.
     */
    private alignFunction(str: string, length: number, char?: string, align?: string) {
        switch (align) {
            case 'left':
                return str.padEnd(length, char || ' ');
            case 'center':
                return padCenter(str, length, char || ' ');
            case 'right':
                return str.padStart(length, char || ' ');
            default:
                return str.padEnd(length, char || ' ');
        }
    }

    /**
     * Adds generic text to the printer, handling line breaks if needed.
     * @param {NFThermalPrinter} driver - The thermal printer driver instance.
     * @param {string | null} text - The text to add.
     * @param {any} options - Additional options (e.g., printer column count).
     * @returns {string[]} An array of strings, each representing a line of text.
     */
    private addGenericText(driver: NFThermalPrinter, text: string | null, options: any): string[] {
        const contentLines = typeof text === 'string'
            ? stringToLines(text, options.columns)
            : [];

        for (const line of contentLines) {
            driver.addText(line + '\n');
        }

        return contentLines;
    };

    /**
     * Calculates the block lengths in a row, handling blocks with and without a defined length.
     * @param {SaleContentFieldOptions[]} blocks - An array of blocks.
     * @param {any} options - Additional options (e.g., printer column count).
     */
    private computeBlockLengths(blocks: SaleContentFieldOptions[], options: any) {
        const blocksWithLength: SaleContentFieldOptions[] = [];
        const blocksWithoutLength: SaleContentFieldOptions[] = [];

        for (const block of blocks) {
            if (Number.isInteger(block.length)) {
                blocksWithLength.push(block);
            } else {
                blocksWithoutLength.push(block);
            }
        }

        if (blocksWithoutLength.length) {
            const allocatedChars = blocksWithLength.reduce((sum, block) => sum + block.length, 0);
            const freeChars = options.columns - (allocatedChars % options.columns);

            const charsPerColumn = Math.floor(freeChars / blocksWithoutLength.length);
            const firstColumnLength = freeChars - (charsPerColumn * (blocksWithoutLength.length - 1));

            for (const block of blocksWithoutLength) {
                block.length = (block === blocksWithoutLength[0]) ? firstColumnLength : charsPerColumn;
            }
        }
    };

    //Common Block Functions END

    //Block Functions START
    /**
     * Handles the text block.
     * @param {NFThermalPrinter} driver - The thermal printer driver instance.
     * @param {TextBlockOptions} blockOptions - The specific options for the text block.
     * @param {any} options - Additional options.
     * @returns {string[]} An array of strings, each representing a line of text.
     */
    private handleTextBlock(driver: NFThermalPrinter, blockOptions: TextBlockOptions, options: any): string[] {
        return this.addGenericText(driver, blockOptions.content, options);
    };

    /**
     * Handles the shop preference block.
     * @param {NFThermalPrinter} driver - The thermal printer driver instance.
     * @param {ShopPreferenceBlockOptions} blockOptions - The specific options for the shop preference block.
     * @param {any} options - Additional options.
     * @returns {string[]} An array of strings, each representing a line of text.
     */
    private handleShopPreference(driver: NFThermalPrinter, blockOptions: ShopPreferenceBlockOptions, options: any): string[] {
        const preferenceValue = this.configurationManagerService.getShopPreference(blockOptions.preference_id);
        const finalText = String(blockOptions.prefix ?? '') + String(preferenceValue ?? '') + String(blockOptions.suffix ?? '');

        return this.addGenericText(driver, finalText, options);
    };

    /**
     * Handles the shop setting block.
     * @param {NFThermalPrinter} driver - The thermal printer driver instance.
     * @param {ShopSettingBlockOptions} blockOptions - The specific options for the shop setting block.
     * @param {any} options - Additional options.
     * @returns {string[]} An array of strings, each representing a line of text.
     */
    private handleShopSetting(driver: NFThermalPrinter, blockOptions: ShopSettingBlockOptions, options: any): string[] {
        const settingValue = this.configurationManagerService.getSetting(blockOptions.preference_id);
        const finalText = [blockOptions.prefix || '', settingValue || '', blockOptions.suffix || ''].join('');

        return this.addGenericText(driver, finalText, options);
    };

    /**
     * Handles the shop header.
     * @param {NFThermalPrinter} driver - The thermal printer driver instance.
     * @param {any} blockOptions - The specific options for the shop header block.
     * @param {any} options - Additional options.
     * @returns {string[]} An array of strings, each representing a line of text.
     */
    private handleShopHeader(driver: NFThermalPrinter, blockOptions: any, options: any): string[] {
        const headerLines = [];

        if(options.shop_header && typeof options.shop_header === 'string') {
            headerLines.push(...options.shop_header.split('\n'));
        } else {
            for (let i = 0; i < 13; i++) {
                const line = this.configurationManagerService.getPreference(`fiscalprinter.printer_header_${i}`);
    
                if (line) {
                    headerLines.push(line);
                }
            }
        }

        return this.addGenericText(driver, headerLines.join('\n'), options);
    }

    /**
     * Handles a sale field.
     * @param {NFThermalPrinter} driver - The thermal printer driver instance.
     * @param {Sales} sale - The sale object.
     * @param {SaleFieldBlockOptions} blockOptions - The specific options for the sale field block.
     * @param {any} options - Additional options.
     * @returns {string[]} An array of strings, each representing a line of text.
     */
    private handleSaleField(driver: NFThermalPrinter, sale: Sales, blockOptions: SaleFieldBlockOptions, options: any): string[] {
        const fields = (blockOptions.field_id || '').split(' ');
        const fieldsValues = fields.map((fieldId) => String(_.get(sale, fieldId) ?? ''));

        const finalText: string[] = [];

        if (!blockOptions.skip_empty || fieldsValues.some(Boolean)) { //Don't print line if skip_empty is set if there is no data to print
            finalText.push(blockOptions.prefix || '', ...fieldsValues, blockOptions.suffix || '');
        }

        return this.addGenericText(driver, finalText.filter(Boolean).join(' '), options);
    };

    /**
     * Handles the date and time.
     * @param {NFThermalPrinter} driver - The thermal printer driver instance.
     * @param {DateTimeBlockOptions} blockOptions - The specific options for the date and time block.
     * @param {any} options - Additional options.
     * @returns {string[]} An array of strings, each representing a line of text.
     */
    private handleDateTime(driver: NFThermalPrinter, blockOptions: DateTimeBlockOptions, options: any): string[] {
        return this.addGenericText(driver, `${blockOptions.prefix || ''}${moment().format(blockOptions.format || 'l LT')}${blockOptions.suffix || ''}`, options);
    };

    /**
     * Parses the progressive prefix, replacing placeholders.
     * @param {ProgressiveBlockOptions} blockOptions - The block options.
     * @param {any} options - Global options.
     * @returns {string} The formatted progressive prefix.
     */
    private parseProgressivePrefix(blockOptions: ProgressiveBlockOptions, options: any): string {
        let progressivePrefix = "";

        if (blockOptions.progressive_prefix) {
            //%p: printer prefix    %d: document prefix
            progressivePrefix = blockOptions.progressive_prefix.replace("%d", options.progressive_prefix || "").replace("%p", options.printer_prefix || "");
            options.progressivePrefix = progressivePrefix;
        }

        return progressivePrefix;
    };

    /**
     * Handles the sale reference.
     * @param {NFThermalPrinter} driver - The thermal printer driver instance.
     * @param {Sales} sale - The sale object.
     * @param {SaleReferenceBlockOptions} blockOptions - The specific options for the sale reference block.
     * @param {any} options - Additional options.
     * @returns {string[]} An array of strings, each representing a line of text.
     */
    private handleSaleReference(driver: NFThermalPrinter, sale: Sales, blockOptions: SaleReferenceBlockOptions, options: any): string[] {
        let finalText: string | null = null;

        const referenceSaleItem = sale.sale_items?.find((saleItem) => saleItem.reference_sequential_number && saleItem.reference_date);

        if (referenceSaleItem) {
            const reference = [referenceSaleItem.reference_sequential_number, moment(referenceSaleItem.reference_date).format(blockOptions.format || 'l')].join(' - ');
            finalText = [blockOptions.prefix, reference, blockOptions.suffix].map((str) => str || '').join(' ');
        }

        return this.addGenericText(driver, finalText, options);
    };

    /**
     * Handles the document progressive number.
     * @param {NFThermalPrinter} driver - The thermal printer driver instance.
     * @param {ProgressiveBlockOptions} blockOptions - The specific options for the progressive block.
     * @param {any} options - Additional options.
     * @returns {string[]} An array of strings, each representing a line of text.
     */
    private handleProgressive(driver: NFThermalPrinter, blockOptions: ProgressiveBlockOptions, options: any): string[] {
        const progressivePrefix = this.parseProgressivePrefix(blockOptions, options);

        const finalText = options.progressive
            ? [blockOptions.prefix, progressivePrefix, options.progressive, blockOptions.suffix].filter(Boolean).map(String).join('')
            : null;

        return this.addGenericText(driver, finalText, options);
    };

    /**
     * Gets a string representation of a currency amount.
     * @param {number} amount - The amount to format.
     * @param {string} [currency] - The currency to format in. If not set, the default currency is used.
     * @returns {string} The formatted currency string.
     */
    private getCurrencyString(amount?: number, currency?: string): string {
        return this.$filter('sclCurrency')(amount, currency);
    }

    /**
     * Handles the sale content, showing the items.
     * @param {NFThermalPrinter} driver - The thermal printer driver instance.
     * @param {Sales} sale - The sale object.
     * @param {SaleContentBlockOptions} blockOptions - The specific options for the sale content block.
     * @param {any} options - Additional options.
     * @returns {string[]} An array of strings, each representing a line of text.
     */
    private handleSaleContent(driver: NFThermalPrinter, sale: Sales, blockOptions: SaleContentBlockOptions, options: any): string[] {
        const subBlocks = [...blockOptions.fields || []].sort((a, b) => (a.index || 0) - (b.index || 0));

        const saleItems = sale.sale_items || [];
        const finalSaleItems: SalesItems[] = [];

        this.computeBlockLengths(subBlocks, options);

        const finalText: string[] = [];

        for (const saleItem of saleItems) {
            finalSaleItems.push(saleItem);

            const priceChanges = (saleItem.price_changes as SalesItemsPriceChanges[] || []).sort((a, b) => (a.index || 0) - (b.index || 0));

            for (const priceChange of priceChanges) {
                if (priceChange.amount != null) {
                    finalSaleItems.push({
                        name: ' ' + priceChange.description,
                        type: priceChange.type,
                        price: priceChange.amount,
                        vat_perc: saleItem.vat_perc,
                        quantity: 1
                    } as SalesItems);
                }
            }
        }

        const getSaleItemNetPrice = (saleItem: SalesItems, quantity: number) => ((saleItem.price * quantity) / ((100 + saleItem.vat_perc) / 100));

        for (const saleItem of finalSaleItems) {
            const originalItem: Items | undefined = options.itemsMap?.[saleItem.item_id!];
            let itemText = '';

            for (const subBlock of subBlocks) {
                const field = subBlock.field!;
                const currency = subBlock.currency ? undefined : '';

                let blockText;

                if (['sale', 'gift', 'refund'].includes(saleItem.type) || ['name', 'price'].includes(field)) {
                    switch (field) {
                        case 'name':
                            blockText = saleItem.name;
                            break;
                        case 'quantity':
                            blockText = String(saleItem.quantity);
                            break;
                        case 'net_price':
                            blockText = this.getCurrencyString(getSaleItemNetPrice(saleItem, saleItem.quantity), currency);
                            break;
                        case 'price':
                            blockText = this.getCurrencyString((saleItem.price * saleItem.quantity), currency);
                            break;
                        case 'vat':
                            blockText = this.getCurrencyString((saleItem.price * saleItem.quantity) - getSaleItemNetPrice(saleItem, saleItem.quantity), currency);
                            break;
                        case 'unit_net_price':
                            blockText = this.getCurrencyString(getSaleItemNetPrice(saleItem, 1), currency);
                            break;
                        case 'unit_price':
                            blockText = this.getCurrencyString(saleItem.price, currency);
                            break;
                        case 'unit_vat':
                            blockText = this.getCurrencyString((saleItem.price) - getSaleItemNetPrice(saleItem, 1), currency);
                            break;
                        case 'sku':
                            blockText = saleItem.sku;
                            break;
                        case 'code':
                            blockText = originalItem?.code;
                            break;
                        case 'barcode':
                            blockText = saleItem.barcode;
                            break;
                        case 'notes':
                            blockText = saleItem.notes;
                            break;
                        case 'reference_text':
                            blockText = saleItem.reference_text;
                            break;
                        case 'option_1':
                            blockText = originalItem?.option1_value;
                            break;
                        case 'option_2':
                            blockText = originalItem?.option2_value;
                            break;
                        case 'option_3':
                            blockText = originalItem?.option3_value;
                            break;
                        case 'option_4':
                            blockText = originalItem?.option4_value;
                            break;
                        case 'description':
                            blockText = originalItem?.description;
                            break;
                        case 'weight':
                            const weight = originalItem?.weight;
                            blockText = weight != null ? String(weight * saleItem.quantity) : "";
                            break;
                        case 'unit_weight':
                            blockText = originalItem?.weight;
                            break;
                        case 'unit':
                            blockText = originalItem?.unit;
                            break;
                        case 'vat_code':
                            const targetDepartment = options.departments[saleItem.department_id];
                            blockText = targetDepartment ? this.getVatCode(targetDepartment.vat) : "";
                            break;
                        case 'vat_perc':
                            blockText = (saleItem.vat_perc || 0).toFixed(options.vatDecimals);
                            break;
                        default:
                            break;
                    }
                }

                if (blockText) {
                    blockText = subBlock.pattern ? subBlock.pattern.replace('%s', String(blockText)) : String(blockText);
                    itemText += this.alignFunction(_.truncate(blockText, { length: subBlock.length }), subBlock.length, ' ', subBlock.align);
                } else if (!subBlock.skip_empty) {
                    itemText += ' '.repeat(subBlock.length);
                }
            }

            finalText.push(itemText);
        }

        return this.addGenericText(driver, finalText.join('\n'), options);
    };

    /**
     * Handles the sale totals.
     * @param {NFThermalPrinter} driver - The thermal printer driver instance.
     * @param {Sales} sale - The sale object.
     * @param {SaleTotalsBlockOptions} blockOptions - The specific options for the sale totals block.
     * @param {any} options - Additional options.
     * @returns {string[]} An array of strings, each representing a line of text.
     */
    private handleSaleTotals(driver: NFThermalPrinter, sale: Sales, blockOptions: SaleTotalsBlockOptions, options: any): string[] {
        const rowLength = options.columns;
        const subBlocks = [...blockOptions.fields || []].sort((a, b) => (a.index || 0) - (b.index || 0));
        const saleItems = sale.sale_items || [];
        const salePriceChanges = sale.price_changes || [];

        const finalText: string[] = [];

        for (const subBlock of subBlocks) {
            let blockText;

            const currency = subBlock.currency ? undefined : '';

            switch (subBlock.field) {
                case 'amount':
                    blockText = this.getCurrencyString(sale.amount, currency);
                    break;
                case 'final_amount':
                    blockText = this.getCurrencyString(sale.final_amount, currency);
                    break;
                case 'secondary_final_amount':
                    if (sale.secondary_final_amount != null) {
                        blockText = this.getCurrencyString(sale.secondary_final_amount, subBlock.currency ? sale.secondary_currency : '');
                    }
                    break;
                case 'final_net_amount':
                    blockText = this.getCurrencyString(sale.final_net_amount, currency);
                    break;
                case 'vat_amount':
                    blockText = this.getCurrencyString(((sale.final_amount || 0) - (sale.final_net_amount || 0)), currency);
                    break;
                case 'change':
                    blockText = this.getCurrencyString(sale.change, currency);
                    break;
                case 'price_changes':
                    if (!saleItems.length) {
                        return [];
                    }

                    const itemPriceChangesSum = saleItems.flatMap(item => item.price_changes || []).reduce((sum, change) => sum + (change.amount || 0), 0);
                    const salePriceChangesSum = salePriceChanges.reduce((sum, change) => sum + (change.amount || 0), 0);

                    blockText = this.getCurrencyString(itemPriceChangesSum + salePriceChangesSum, currency);
                    break;
                case 'weight':
                    const weight = saleItems.reduce((sum, saleItem) => sum + ((options.itemsMap[saleItem.item_id!]?.weight || 0) * saleItem.quantity), 0);

                    blockText = String(weight);
                    break;
                default:
                    break;
            }

            if (blockText) {
                if (subBlock.pattern) {
                    blockText = subBlock.pattern ? subBlock.pattern.replace('%s', blockText) : blockText;
                    finalText.push(this.alignFunction(_.truncate(blockText, { length: rowLength }), rowLength, ' ', subBlock.align));
                } else {
                    const prefix = subBlock.prefix || '';
                    blockText = prefix + String(blockText ?? '').padStart(rowLength - prefix.length, ' ');
                    finalText.push(this.alignFunction(blockText, rowLength, ' ', subBlock.align));
                }
            } else {
                finalText.push(' '.repeat(rowLength));
            }
        }

        return this.addGenericText(driver, finalText.join('\n'), options);
    };

    /**
     * Handles the price changes in the sale.
     * @param {NFThermalPrinter} driver - The thermal printer driver instance.
     * @param {Sales} sale - The sale object.
     * @param {PriceChangesBlockOptions} blockOptions - The specific options for the price changes block.
     * @param {any} options - Additional options.
     * @returns {string[]} An array of strings, each representing a line of text.
     */
    private handlePriceChanges(driver: NFThermalPrinter, sale: Sales, blockOptions: PriceChangesBlockOptions, options: any): string[] {
        const currency = blockOptions.print_currency ? undefined : ''; //setting currency to null makes the filter print the current currency
        const salePriceChanges = [...(sale.price_changes || [])].sort((a, b) => (a.index || 0) - (b.index || 0));

        const finalText: string[] = [];

        for (const priceChange of salePriceChanges) {
            const amount = this.getCurrencyString(priceChange.amount, currency);

            finalText.push(priceChange.description + amount.padStart(options.columns - priceChange.description.length, ' '));
        }

        return this.addGenericText(driver, finalText.join('\n'), options);
    };

    /**
     * Handles the sale payments.
     * @param {NFThermalPrinter} driver - The thermal printer driver instance.
     * @param {Sales} sale - The sale object.
     * @param {SalePaymentsBlockOptions} blockOptions - The specific options for the sale payments block.
     * @param {any} options - Additional options.
     * @returns {string[]} An array of strings, each representing a line of text.
     */
    private handleSalePayments(driver: NFThermalPrinter, sale: Sales, blockOptions: SalePaymentsBlockOptions, options: any): string[] {
        const currency = blockOptions.print_currency ? undefined : ''; //setting currency to null makes the filter print the current currency
        const payments = this.fiscalUtils.extractPayments(sale).sort((a, b) => a.amount - b.amount);

        const finalText: string[] = [];

        for (const payment of payments) {
            const description = payment.method_name;
            const amount = this.getCurrencyString(payment.amount, currency);

            finalText.push(description + amount.padStart(options.columns - description.length, ' '));
        }

        return this.addGenericText(driver, finalText.join('\n'), options);
    };

    /**
     * Handles the sale VATs.
     * @param {NFThermalPrinter} driver - The thermal printer driver instance.
     * @param {Sales} sale - The sale object.
     * @param {SaleVatsBlockOptions} blockOptions - The specific options for the sale VATs block.
     * @param {any} options - Additional options.
     * @returns {string[]} An array of strings, each representing a line of text.
     */
    private handleSaleVats(driver: NFThermalPrinter, sale: Sales, blockOptions: SaleVatsBlockOptions, options: any): string[] {
        const printerColumns = options.columns;
        const currency = blockOptions.print_currency ? undefined : ''; //setting currency to null makes the filter print the current currency

        const aggregateTaxes = this.fiscalUtils.extractTax(sale, options.departments);

        const columnsToPrint = [blockOptions.print_code, blockOptions.print_value, blockOptions.print_taxable, blockOptions.print_tax, blockOptions.print_total].reduce((sum, val) => val ? sum + 1 : sum, 0);
        const otherColumns = Math.floor(printerColumns / columnsToPrint);
        const firstColumn = MathUtils.round(printerColumns - (otherColumns * (columnsToPrint - 1)), 0);

        const finalText: string[] = [];

        for (const [vatId, aggrTax] of Object.entries(aggregateTaxes)) {
            let taxLine = "";

            if (aggrTax.taxable !== 0) {
                let columnSize = firstColumn;

                for (const columnToPrint of <(keyof SaleVatsBlockOptions)[]>['print_code', 'print_value', 'print_taxable', 'print_tax', 'print_total']) {
                    if (blockOptions[columnToPrint]) {
                        switch (columnToPrint) {
                            case 'print_code':
                                taxLine += this.getVatCode(options.vats[vatId]).padEnd(columnSize, ' ');
                                break;
                            case 'print_value':
                                taxLine += `${(options.vats[vatId].value || 0).toFixed(options.vatDecimals)}%`.padEnd(columnSize, ' ');
                                break;
                            case 'print_taxable':
                                taxLine += this.getCurrencyString(aggrTax.taxable, currency).padStart(columnSize, ' ');
                                break;
                            case 'print_tax':
                                taxLine += this.getCurrencyString(aggrTax.tax, currency).padStart(columnSize, ' ');
                                break;
                            case 'print_total':
                                taxLine += this.getCurrencyString(aggrTax.total, currency).padStart(columnSize, ' ');
                                break;
                        }

                        if (columnSize === firstColumn) {
                            columnSize = otherColumns;
                        }
                    }
                }
            }

            finalText.push(taxLine);
        }

        return this.addGenericText(driver, finalText.join('\n'), options);
    };

    /**
     * Handles the document title.
     * @param {NFThermalPrinter} driver - The thermal printer driver instance.
     * @param {Sales} sale - The sale object.
     * @param {Object} blockOptions - The specific options for the document title block (not used).
     * @param {any} options - Additional options.
     * @returns {string[]} An array of strings, each representing a line of text.
     */
    private handleDocumentTitle(driver: NFThermalPrinter, sale: Sales, blockOptions: {}, options: any): string[] {
        let mainDocumentType: string = options.documentType.replace('generic_', '');
        let subDocumentType: string = '';

        if (!['preliminary_receipt'].includes(mainDocumentType)) {
            if (!['receipt', 'invoice'].includes(mainDocumentType)) {
                mainDocumentType = 'receipt';
            }

            if (sale.final_amount && sale.final_amount < 0) {
                if ((sale.sale_items || []).every(item => item.refund_cause_id === 6)) {
                    subDocumentType = '_void';
                } else {
                    subDocumentType = '_refund';
                }
            }
        }

        const textLines: String[] = [this.$translate.instant(`PRINTERS.DOCUMENT_BUILDER.SALE_TYPES.${mainDocumentType.toUpperCase()}${subDocumentType.toUpperCase()}`)];

        if (options.isReprint) {
            textLines.push(this.$translate.instant(`PRINTERS.DOCUMENT_BUILDER.SALE_TYPES.REPRINT`));
        }

        return this.addGenericText(driver, textLines.join('\n'), options);
    };

    /**
     * Handles the document tail.
     * @param {NFThermalPrinter} driver - The thermal printer driver instance.
     * @param {Object} blockOptions - The specific options for the document tail block.
     * @param {any} options - Additional options.
     * @returns {string[]} An array of strings, each representing a line of text.
     */
    private handleDocumentTail(driver: NFThermalPrinter, blockOptions: {}, options: any): string[] {
        const tailText = this.addGenericText(driver, options.tail, options);

        if (options.tail_qr_code && options.tail_qr_code_size !== 0) {
            driver.addTextAlign(EpsonAlign.CENTER);
            driver.addText('\n');
            driver.addSymbol(options.tail_qr_code, EpsonSymbol.QRCODE_MODEL_2, undefined, options.tail_qr_code_size || 6);
        }

        return tailText;
    };

    /**
     * Handles the printer tail.
     * @param {NFThermalPrinter} driver - The thermal printer driver instance.
     * @param {Object} blockOptions - The specific options for the printer tail block (not used).
     * @param {any} options - Additional options.
     * @returns {string[]} An array of strings, each representing a line of text.
     */
    private handlePrinterTail(driver: NFThermalPrinter, blockOptions: {}, options: any): string[] {
        const tail = this.configurationManagerService.getPreference('fiscalprinter.tail');

        return this.addGenericText(driver, tail, options);
    };

    /**
     * Handles the separator.
     * @param {NFThermalPrinter} driver - The thermal printer driver instance.
     * @param {SeparatorBlockOptions} blockOptions - The specific options for the separator block.
     * @param {any} options - Additional options.
     * @returns {string[]} An array of strings, each representing a line of text.
     */
    private handleSeparator(driver: NFThermalPrinter, blockOptions: SeparatorBlockOptions, options: any): string[] {
        const pattern = blockOptions.pattern || '-';

        const finalText: string[] = [];

        for (let i = 0; i < (blockOptions.rows || 1); i++) {
            finalText.push(pattern.repeat(Math.floor(options.columns / pattern.length)));
        }

        return this.addGenericText(driver, finalText.join('\n'), options);
    };

    /**
     * Handles the paper cut.
     * @param {NFThermalPrinter} driver - The thermal printer driver instance.
     * @param {Object} blockOptions - The specific options for the cut block (not used).
     * @param {any} options - Additional options.
     */
    private handleCut(driver: NFThermalPrinter, blockOptions: {}, options: any) {
        driver.addCut(EpsonCut.FEED);
    };

    /**
     * Handles the logo.
     * @param {NFThermalPrinter} driver - The thermal printer driver instance.
     * @param {Object} blockOptions - The specific options for the logo block (not used).
     * @param {any} options - Additional options.
     */
    private handleLogo(driver: NFThermalPrinter, blockOptions: {}, options: any) {
        driver.addLogo();
    };

    /**
     * Handles the URL (not implemented).
     * @param {NFThermalPrinter} driver - The thermal printer driver instance.
     * @param {Object} blockOptions - The specific options for the URL block (not used).
     * @param {any} options - Additional options.
     */
    private handleUrl(driver: NFThermalPrinter, blockOptions: {}, options: any) {
    };

    /**
     * Handles the cash drawer opening.
     * @param {NFThermalPrinter} driver - The thermal printer driver instance.
     * @param {Object} blockOptions - The specific options for the cash drawer block (not used).
     * @param {any} options - Additional options.
     */
    private handleCashdrawer(driver: NFThermalPrinter, blockOptions: {}, options: any) {
        if (options.can_open_cash_drawer) {
            driver.addPulse();
        }
    };

    /**
     * Handles the barcode printing.
     * @param {NFThermalPrinter} driver - The thermal printer driver instance.
     * @param {string} barcodeValue - The barcode value.
     * @param {any} blockOptions - The barcode options.
     */
    private handleBarcode(driver: NFThermalPrinter, barcodeValue: string, blockOptions: BarcodeBlockOptions) {
        let barcodeType: EpsonBarcode | null = null;
        let symbolType: EpsonSymbol | null = null;
        let barcodeHri: EpsonHRI;
        let barcodeFont: EpsonFont;

        switch (blockOptions.barcode_type?.toUpperCase()) {
            case 'CODE39':
            default:
                barcodeType = EpsonBarcode.CODE39;
                break;
            case 'EAN13':
                barcodeType = EpsonBarcode.EAN13;
                break;
            case 'EAN8':
                barcodeType = EpsonBarcode.EAN8;
                break;
            case 'EAN128':
                barcodeType = EpsonBarcode.CODE128;
                break;
            case 'UPC':
                barcodeType = EpsonBarcode.UPC_A;
                break;
            case 'QRCODE':
                symbolType = EpsonSymbol.QRCODE_MODEL_2;
                break;
        }

        switch (blockOptions.barcode_hri?.toUpperCase()) {
            case 'NONE':
                barcodeHri = EpsonHRI.NONE;
                break;
            case 'ABOVE':
                barcodeHri = EpsonHRI.ABOVE;
                break;
            case 'BELOW':
            default:
                barcodeHri = EpsonHRI.BELOW;
                break;
            case 'BOTH':
                barcodeHri = EpsonHRI.BOTH;
                break;
        };

        switch (blockOptions.barcode_font?.toUpperCase()) {
            case 'FONT_A':
            default:
                barcodeFont = EpsonFont.FONT_A;
                break;
            case 'FONT_B':
                barcodeFont = EpsonFont.FONT_B;
                break;
            case 'FONT_C':
                barcodeFont = EpsonFont.FONT_C;
                break;
            case 'FONT_SPECIAL_A':
                barcodeFont = EpsonFont.FONT_SPECIAL_A;
                break;
            case 'FONT_SPECIAL_B':
                barcodeFont = EpsonFont.FONT_SPECIAL_B;
                break;
        }

        if (symbolType) {
            driver.addSymbol(barcodeValue, EpsonSymbol.QRCODE_MODEL_2, undefined, ((blockOptions.width || 1) * 2) || 6);
        }

        if (barcodeType) {
            driver.addBarcode(barcodeValue, barcodeType, barcodeHri, barcodeFont, blockOptions.width || 1, blockOptions.height || 66);
        }
    }

    /**
     * Handles the fixed barcode printing.
     * @param {NFThermalPrinter} driver - The thermal printer driver instance.
     * @param {BarcodeFixedBlockOptions} blockOptions - The specific options for the fixed barcode block.
     * @param {any} options - Additional options.
     */
    private handleBarcodeFixed(driver: NFThermalPrinter, blockOptions: BarcodeFixedBlockOptions) {
        const barcodeValue = String(blockOptions.barcode_value || '');

        if (barcodeValue) {
            this.handleBarcode(driver, barcodeValue, blockOptions);
        }
    }

    /**
     * Handles the barcode printing based on a sale field.
     * @param {NFThermalPrinter} driver - The thermal printer driver instance.
     * @param {Sales} sale - The sale object.
     * @param {BarcodeSaleFieldBlockOptions} blockOptions - The specific options for the sale field barcode block.
     */
    private handleBarcodeSaleField(driver: NFThermalPrinter, sale: Sales, blockOptions: BarcodeSaleFieldBlockOptions) {
        const barcodeValue = _.get(sale, blockOptions.field_id!);

        if (typeof barcodeValue === 'number' || typeof barcodeValue === 'string') {
            this.handleBarcode(driver, barcodeValue.toString(), blockOptions);
        }
    }

    //Block Functions END

    /**
     * Builds the document for the thermal printer.
     * @param {NFThermalPrinter} driver - The thermal printer driver instance.
     * @param {Sales} origSale - The original sale object.
     * @param {DocumentPrinterOptions} printerDocumentData - The document data for the printer.
     * @param {any} options - Additional options.
     * @returns {Promise<SalesDocuments>} A promise that resolves with the printed document data.
     */
    public async buildDocument(driver: NFThermalPrinter, origSale: Sales, printerDocumentData: DocumentPrinterOptions, options: any): Promise<SalesDocuments> {
        const resultText: string[] = [];
        const sale = structuredClone(origSale);
        const documentModel = printerDocumentData.document_template;

        const fiscalResources = await this.fiscalUtils.getPrinterConfigurationResources();

        const localOptions = {
            ...structuredClone(options || {}),
            columns: options.columns || this.defaultColumns,
            itemsMap: printerDocumentData.itemsMap || {},
            progressive: documentModel?.progressive,
            documentType: documentModel?.type,
            departments: keyBy(fiscalResources.departments, d => d.id),
            vats: keyBy(fiscalResources.vats, v => v.id),
            vatDecimals: Math.max(
                ...(fiscalResources.vats.map(vat => String(vat.value).split('.')[1]?.length || 0)),
                2
            )
        };

        sale.sale_items = this.fiscalUtils.extractSaleItems(sale);
        this.saleUtilsService.calculateSalePrices(sale);
        sale.change = this.salePaymentService.getSaleChange(sale) ?? undefined;

        if (sale.secondary_exchange_rate) {
            sale.secondary_final_amount = MathUtils.round((sale.final_amount || 0) * sale.secondary_exchange_rate);
        }

        const blocksByIndex = [...(documentModel?.rules || [])].sort((a, b) => (a.index || 0) - (b.index || 0));
        const meta: Record<string, any> = {};

        for (const block of blocksByIndex) {
            const blockOptions = block.options || {};

            this.setBlockAlignment(driver, block);
            this.setBlockStyle(driver, block);

            for (let i = 0; i < (block.margin_top || 0); i++) {
                resultText.push('');
            }

            driver.addText('\n'.repeat(block.margin_top || 0));

            let result: string[] = [];

            try {
                switch (block.type) {
                    case "barcode_fixed":
                        this.handleBarcodeFixed(driver, blockOptions);
                        break;
                    case "barcode_sale_field":
                        this.handleBarcodeSaleField(driver, sale, blockOptions);
                        break;
                    case "text":
                        result = this.handleTextBlock(driver, blockOptions, localOptions);
                        break;
                    case "shop_preference":
                        result = this.handleShopPreference(driver, blockOptions, localOptions);
                        break;
                    case "shop_setting":
                        result = this.handleShopSetting(driver, blockOptions, localOptions);
                        break;
                    case "shop_header":
                        result = this.handleShopHeader(driver, blockOptions, localOptions);
                        meta.header_lines = result.length;
                        break;
                    case "sale_field":
                        result = this.handleSaleField(driver, sale, blockOptions, localOptions);
                        break;
                    case 'sale_reference':
                        result = this.handleSaleReference(driver, sale, blockOptions, localOptions);
                        break;
                    case "date_time":
                        result = this.handleDateTime(driver, blockOptions, localOptions);
                        break;
                    case "progressive":
                        result = this.handleProgressive(driver, blockOptions, localOptions);
                        break;
                    case "sale_content":
                        result = this.handleSaleContent(driver, sale, blockOptions, localOptions);
                        break;
                    case "sale_totals":
                        result = this.handleSaleTotals(driver, sale, blockOptions, localOptions);
                        break;
                    case "sale_payments":
                        result = this.handleSalePayments(driver, sale, blockOptions, localOptions);
                        break;
                    case "sale_vats":
                        result = this.handleSaleVats(driver, sale, blockOptions, localOptions);
                        break;
                    case "sale_price_changes":
                        result = this.handlePriceChanges(driver, sale, blockOptions, localOptions);
                        break;
                    case "document_title":
                        result = this.handleDocumentTitle(driver, sale, blockOptions, localOptions);
                        break;
                    case "document_tail":
                        result = this.handleDocumentTail(driver, blockOptions, localOptions);
                        break;
                    case "printer_tail":
                        result = this.handlePrinterTail(driver, blockOptions, localOptions);
                        break;
                    case "separator":
                        result = this.handleSeparator(driver, blockOptions, localOptions);
                        break;
                    case "cut":
                        this.handleCut(driver, blockOptions, localOptions);
                        break;
                    case "logo":
                        this.handleLogo(driver, blockOptions, localOptions);
                        break;
                    case "from_url":
                        this.handleUrl(driver, blockOptions, localOptions);
                        break;
                    case "cashdrawer":
                        this.handleCashdrawer(driver, blockOptions, localOptions);
                        break;
                }

                resultText.push(...result);

                for (let i = 0; i < (block.margin_bottom || 0); i++) {
                    resultText.push('');
                }

                driver.addText('\n'.repeat(block.margin_bottom || 0));
            } catch (err) {
                console.error(err);
                //Nothing to do
            }
        }

        if (blocksByIndex.length) {
            driver.addCut();
        }

        return <SalesDocuments>{
            document_content: resultText.join('\n'),
            sequential_number: localOptions.progressive,
            sequential_number_prefix: localOptions.progressivePrefix,
            document_type: localOptions.documentType,
            meta: meta
        }
    }
}

angular.module('printers').service('DocumentBuilder', DocumentBuilderService);