import {Observable} from 'rxjs';
import {GridTraductions} from 'src/app/models/grid-traductions.model';
import {SuppliersFields} from '../../features/suppliers/suppliers-form.model';
import { FidelityMovementsFe, FidelityMovementsFields, PrepaidMovementsFe, PrepaidMovementsFields} from '../../features/customers/customers.model';
import { Customers, Giftcards, ChainPromotions, Sales, Suppliers as Supplier} from 'tilby-models';
import {AccountCashMovementFilter, CustomerNameFilter, PaymentTypeFilter, PrinterNameFilter, ResponseViewFilesStatusFilter, SequentialNumberFilter, TypeCashMovementFilter, TypeDocumentFilter} from './grid-custom-filter.model';
import {TilbyDatePipe} from "@tilby/tilby-ui-lib/pipes/tilby-date";
import { StaticTokenSession, CashMovements, ResponseViewFiles } from 'tilby-models';
import {CustomFormGroup} from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { CustomForm } from '@tilby/tilby-ui-lib/components/tilby-magic-form';

// Ordine Tabella
export class SuppliersFe implements Supplier {
    name = '';
    address_street = '';
    address_number = '';
    address_city = '';
    address_zip = '';
    address_prov = '';
    country = '';
    phone1 = '';
    phone2 = '';
    fax = '';
    email1 = '';
    email2 = '';
    email_pec = '';
    website = '';
    vat_code = '';
    notes = '';
    id = -1;
    created_at = TilbyDatePipe.date({outputFormat:'date'});
    createdby_id = -1;
    updated_at = TilbyDatePipe.date({outputFormat:'date'});
    updatedby_id = -1;
}

export class Suppliers extends SuppliersFe implements SuppliersFields {
    conto_vendita: boolean = false;
    id = -1;
    updated_at: Date = <Date><any>'2022-06-30T08:46:50.000Z';
}

//Ordine Da Mostrare In Tabella, Key nomi dell'oggetto FE, Value nomi oggetto BE. In attesa di completamento documentazione per capire i campi presenti (visibili e non)
export class CustomerFe implements Customers {
    avg_spent = -1;
    billing_city = '';
    billing_country = '';
    billing_number = '';
    billing_prov = '';
    billing_street = '';
    billing_zip = '';
    birthdate = TilbyDatePipe.date({outputFormat:'date'});
    company_name = '';
    created_at = TilbyDatePipe.date({outputFormat:'date'});
    createdby_id = -1;
    // credit = -1;
    custom_1 = '';
    custom_2 = '';
    custom_3 = '';
    custom_4 = '';
    custom_5 = '';
    custom_6 = '';
    custom_7 = '';
    custom_8 = '';
    custom_9 = '';
    custom_type = '';
    default_pricelist = -1;
    disable_mail_receipts = false;
    discount_perc = -1;
    email = '';
    email_pec = '';
    external_id = '';
    facebook = '';
    fidelity = '';
    first_name = '';
    gender = '';
    id = -1;
    instagram = '';
    last_name = '';
    linkedin = '';
    lottery_code = '';
    mobile = '';
    notes = '';
    payments_allowed = '';
    phone = '';
    sales_count = -1;
    sdi_code = '';
    send_commercials = false;
    shipping_city = '';
    shipping_country = '';
    shipping_number = '';
    shipping_prov = '';
    shipping_street = '';
    shipping_zip = '';
    shipping_city_1 = '';
    shipping_country_1 = '';
    shipping_number_1 = '';
    shipping_prov_1 = '';
    shipping_street_1 = '';
    shipping_zip_1 = '';
    shipping_city_2 = '';
    shipping_country_2 = '';
    shipping_number_2 = '';
    shipping_prov_2 = '';
    shipping_street_2 = '';
    shipping_zip_2 = '';
    shipping_city_3 = '';
    shipping_country_3 = '';
    shipping_number_3 = '';
    shipping_prov_3 = '';
    shipping_street_3 = '';
    shipping_zip_3 = '';
    shipping_city_4 = '';
    shipping_country_4 = '';
    shipping_number_4 = '';
    shipping_prov_4 = '';
    shipping_street_4 = '';
    shipping_zip_4 = '';
    shipping_city_5 = '';
    shipping_country_5 = '';
    shipping_number_5 = '';
    shipping_prov_5 = '';
    shipping_street_5 = '';
    shipping_zip_5 = '';
    shipping_city_6 = '';
    shipping_country_6 = '';
    shipping_number_6 = '';
    shipping_prov_6 = '';
    shipping_street_6 = '';
    shipping_zip_6 = '';
    shipping_city_7 = '';
    shipping_country_7 = '';
    shipping_number_7 = '';
    shipping_prov_7 = '';
    shipping_street_7 = '';
    shipping_zip_7 = '';
    shipping_city_8 = '';
    shipping_country_8 = '';
    shipping_number_8 = '';
    shipping_prov_8 = '';
    shipping_street_8 = '';
    shipping_zip_8 = '';
    shipping_city_9 = '';
    shipping_country_9 = '';
    shipping_number_9 = '';
    shipping_prov_9 = '';
    shipping_street_9 = '';
    shipping_zip_9 = '';
    source_channel = '';
    source_store = '';
    tax_code = '';
    tot_spent = -1;
    twitter = '';
    updated_at = TilbyDatePipe.date({outputFormat:'date'});
    updatedby_id = -1;
    uuid = '';
    vat_code = '';
}

//Ordine Da Mostrare In Tabella Key nomi dell'oggetto FE, Value nomi oggetto BE. In attesa di completamento documentazione per capire i campi presenti (visibili e non)
export class SalesFe implements Partial<Sales> {
    change = -1;
    channel = '';
    closed_at = TilbyDatePipe.date({outputFormat:'date'});
    covers = -1;
    customer_name = new CustomerNameFilter();
    external_id = '';
    final_amount = -1;
    is_summary = false;
    summary_emitted = false;
    name = '';
    notes = '';
    open_at = TilbyDatePipe.date({outputFormat:'date'});
    printed_prebills = 0;
    sale_number = -1;
    sale_parent_uuid = '';
    seller_id = -1;
    seller_name = '';
    uuid = '';
    room_name = '';
    table_name = '';
    type_document = new TypeDocumentFilter();
    printer = new PrinterNameFilter();
    payment_type = new PaymentTypeFilter();
    sequential_number = new SequentialNumberFilter();
}

type GiftcardFixDate = {
    [prop in keyof Giftcards]: prop extends 'created_at' | 'updated_at' | 'deleted_at' | 'valid_since' | 'valid_until' ? string : Giftcards[prop];
}
export class GiftcardsFe implements GiftcardFixDate {
    uuid = '';
    type_uuid = '';
    type_name = '';
    type_description = '';
    currency = '';
    origin_environment_only?: boolean | undefined;
    notes = '';
    origin_environment = '';
    origin_sale = '';
    created_by = -1;
    created_at = '';
    updated_by = -1;
    updated_at = '';
    deleted_by = -1;
    deleted_at = '';

    code = '';
    initial_value = -1;
    last_value = -1;
    is_divisible = false;
    valid_since = '';
    valid_until = '';
    active = false;
};

export class StaticTokenFe implements StaticTokenSession {
    client_id = '';
    created_at = '';
    description = '';
    trunc_token = '';
    username = '';
}

export type OperatorType = 'AND' | 'OR';
export type ApiFilterPrefix = ''|'_like';

export enum SCREEN_SIZE {
    XS,
    SM,
    MD,
    LG,
    XL
}

export interface InputItemData {
    itemData: ItemData[];
    headerTranslate: Map<string, string>;
}
export interface InputItemDataMagicForm<T extends Partial<Record<keyof T, any>>,Multi=never> {
    form: [Multi] extends [never]?CustomFormGroup<CustomForm<T>>:CustomFormGroup<{[key in keyof Multi]:CustomFormGroup<any>}>;
}

export interface ItemData {
    isChecked: boolean;
    colField: string;
    newValue: string
}

export interface ColumnDataExport {
    isChecked: boolean;
    colField: string;
}

export interface DataExport {
    data: ColumnDataExport[],
    type: string
}

export interface QueryPagination {
    pagination: boolean,
    per_page: number,
    page?: number,
    orderby_asc?: string,
    orderby_desc?: string,
    first_name?: string,
    last_name?: string
}

export interface QueryPaginationPrepaid extends QueryPagination {
    valid_from_since: string | null;
    valid_from_max: string | null;
    shop_uuid?: string | null;
    customer_uuid?: string;
}

export interface QueryPaginationFidelity extends QueryPagination {
    date_since: string | null;
    date_max: string | null;
    campaign_id: number | null;
    db_name: string | null;
    fidelity?: string;
}

export interface QueryPaginationHistorySales extends QueryPagination {
    closed_at_max?: string,
    closed_at_since?: string,
    orderby_desc?: string,
    page: 0,
    pagination: true,
    per_page: 50,
    status: string,

}

type Props_like<T extends Record<string, any>> = {
    [prop in string & keyof T as `${prop}_like`]: T[prop];
};

export type FilteredQuery = Props_like<TableRow> & QueryPagination & {x_query:string};

export type TableRow = TableRowUpdatable | TableRowFixed;
export type TableRowUpdatable = Supplier | Customers | PrepaidMovementsFields | FidelityMovementsFields | SaleItemsDeletedFe;
export type TableRowFixed = FidelityMovementsFe | PrepaidMovementsFe | SalesFe | GiftcardsFe | StaticTokenFe | CashMovementsFe | FileExporterFe | PromotionsFe;

export type KeysOfUnion<T> = T extends T ? keyof T: never;

export type GridPanel = 'columns' | 'filters';

export type RowModelType = 'clientSide' | 'infinite' | 'viewport' | 'serverSide' | undefined;

export interface TableData {

    rowData$: Observable<TableRow[]>|null; //ClientSide Data
    dataType: TableRow;
    headersTranslate: Map<string, string>;
    columnsFormatter?: Map<string, any>;
    columnsExcelFormatter?: Map<string, any>;
    filtersMenuDisabled?: Map<string, boolean> | boolean;
    sortingsMenuDisabled?: Map<string, boolean> | boolean;
    language?: GridTraductions;
    sortFilterQuery?:Record<string, string>;
    countSelectedRows?:number;
    extraQueryParams?:Record<string,any>;
    isCheckable?(k: TableRow): boolean;
}
export class CashMovementsFe implements Partial<CashMovements> {
    id = -1;
    date = TilbyDatePipe.date({outputFormat:'date'});
    description = "";
    amount = -1;
    payment_method_name? = "";
    sale_id? = -1;
    movement_type = new TypeCashMovementFilter();
    movement_account = new AccountCashMovementFilter();
}

interface ResponseViewFilesInterface {status: ResponseViewFilesStatusFilter }
export class FileImporterFe implements Pick<ResponseViewFiles, 'id'| 'name'| 'dateToProcess'| 'totalRows'| 'rowsProcessed'| 'totalErrors' | 'typeInsert'>, ResponseViewFilesInterface{
    id = -1;
    status = new ResponseViewFilesStatusFilter();
    name = "";
    dateToProcess = "";
    createdAt = "";
    typeInsert = "";
    totalRows = -1;
    rowsProcessed = -1;
    totalErrors = -1;
}

export class FileExporterFe implements Pick<ResponseViewFiles, 'id'>, ResponseViewFilesInterface{
    id = -1;
    status = new ResponseViewFilesStatusFilter();
    table = "";
    created_at = "";
    completed_at = "";
    createdby_id = 0;
    format = "";
}
export class Promotions implements ChainPromotions {
    name = '';
    active = false;
}
// type PromotionsFixDate = {
//     [prop in keyof Promotions]: prop extends 'created_at' | 'updated_at' | 'deleted_at' ? string : Promotions[prop];
// }
export class PromotionsFe {
    id = -1;
    status = '';
    name = '';
    items = -1;
    startData = '';
    endData = '';
    startTime = '';
    endTime = '';
    weekDays = '';
    fidelity_rule = '';
    customer_type = '';
}

export class SaleItemsDeletedFe {
    id = -1;
    lastupdate_at = TilbyDatePipe.date({outputFormat:'date'});
    sale_name = "";
    name = "";
    sku = "";
    category_name = "";
    department_name = "";
    vat_perc = "";
    price = "";
    cost = "";
    operator_name = "";
    quantity_difference = -1;
    revenues = -1;
    room_name = "";
    table_name = "";
    covers = -1;
    order_type = "";
}