export namespace RF {
	export type Vat = {
		vatPercent: number;
		vatPercent2?: number | null;
		vatPercents?: number[] | null;
		vatIdentification: number;
		caption?: string | null;
		caption_EN?: string | null;
		skipVatPercentageValidation: boolean;
		validFrom: string;
		validTo: string;
	};

	export type Document = {
		modelVersion?: string | null;
		uniqueClientId: string;
		uniqueCashRegisterId?: string | null;
		applicationVersion?: string | null;
		fiscalModuleVersion?: string | null;
		fiscalCountryModuleVersion?: string | null;
		softwareName?: string | null;
		retailForceReceiptId?: string | null;
		additionalFields?: { [key: string]: string } | null;
		automaticVatCalculation?: AutomaticVatCalculation;
		allowedVatDeviation?: number | null;
		printCount?: number | null;
		proformaPrintCount?: number | null;
		deliveryPrintCount?: number | null;
		terminalSlaveId?: string | null;
		footerGraphicIndex?: number | null;
		documentGuid: string;
		documentId: string;
		createDate: string;
		bookDate: string;
		accountingDate?: string | null;
		processStartDate?: string | null;
		documentNumber: string;
		documentNumberSeries?: string | null;
		cancellationDocument?: boolean;
		documentReference?: DocumentReference;
		isTraining: boolean;
		documentType: DocumentType;
		documentTypeCaption?: string | null;
		user: User;
		salesPerson?: User;
		allocationGroups?: string[] | null;
		partner?: Partner;
		notes?: string | null;
		customerCount?: number | null;
		paymentTerms?: PaymentTerms;
		servicePeriodStart?: string | null;
		servicePeriodEnd?: string | null;
		positionCount: number;
		documentIssueType: DocumentIssueType;
		coupon?: DocumentCoupon;
		serviceType?: string | null;
		isEcommerceTransaction?: boolean | null;
		taxPositions?: DocumentTaxPosition[] | null;
		fiscalResponse?: FiscalResponse;
		fiscalDocumentNumber: number;
		fiscalDocumentRevision: number;
		FiscalDocumentStartTime?: number | null;
		positions: (DocumentPositionText | DocumentPositionSubTotal | DocumentPositionItem | DocumentPositionBooking | DocumentPositionTotal | DocumentPositionSubItem)[];
		payments?: DocumentPayment[] | null;
		additionalHeader?: string[] | null;
		additionalFooter?: string[] | null;
		FiscalAdditionalFields?: { [key: string]: any } | null;
	};

	export type FiscalResponse = {
		fiscalCountry: FiscalCountry;
		fiscalisationDocumentNumber?: number;
		fiscalDocumentNumber: string;
		taxPositions?: DocumentTaxPosition[] | null;
		fiscalDocumentStartTime?: number | null;
		processStartTime?: number | null;
		errorDescription?: string | null;
		cashRegisterId?: string | null;
		signature: string;
		userMessage?: string | null;
		printMessage?: string | null;
		requestTime: string;
		requestCompletionTime: string;
		qrCode?: string | null;
		retailForceReceiptId?: string | null;
		AdditionalFields?: { [key: string]: any } | null;
	};

	export type AutomaticVatCalculation =
		| "noCalculation"
		| "netValueCalculation"
		| "grossValueCalculation";

	export type DocumentReference = {
		referenceType?: ReferenceType;
		storeNumber?: string | null;
		terminalNumber?: string | null;
		documentType?: DocumentType;
		documentNumber?: string | null;
		documentNumberSeries?: string | null;
		fiscalDocumentNumber: number;
		documentGuid: string;
		documentId?: string | null;
		documentBookDate?: string | null;
		returnReasonType?: ReturnReasonType;
		returnReasonText?: string | null;
	};

	export enum DocumentType {
		Receipt = 0,
		Invoice = 1,
		DeliveryNote = 2,
		PayOut = 10,
		PayIn = 11,
		ProformaInvoice = 12,
		CustomerOrder = 13,
		PreliminaryReceipt = 14,
		PaymentReceipt = 15,
		LongTermOrder = 80,
		OpeningBalance = 90,
		CashCheck = 98,
		EndOfDay = 99,
		Inventory = 100,
		Purchase = 101,
		NullReceipt = 1000,
		PrintingReceipt = 1001,
		MiscellaneousNonFiscal = 1100
	}

	export type User = {
		id: string;
		caption?: string | null;
		firstName?: string | null;
		lastName?: string | null;
		taxNumber?: string | null;
		dateOfEntry?: string | null;
	};

	export type Partner = {
		id: string;
		caption: string;
		isBusiness?: boolean | null;
		partnerType?: PartnerType;
		partnerClassification?: string | null;
		vatNumber?: string | null;
		taxNumber?: string | null;
		identificationType?: PartnerIdentificationType;
		partnerIdentification?: string | null;
		additionalIdentifications?: { [key: string]: string } | null;
		street: string;
		streetNumber: string;
		postalCode: string;
		city: string;
		community?: string | null;
		countryCode: string;
	};

	export type PaymentTerms = {
		dueDateDays?: number;
		discount?: number | null;
		discountDueDays?: number | null;
		latePaymentPenaltyRate?: number | null;
	};

	export type DocumentIssueType =
		| "paper"
		| "digitalReceipt"
		| "paperAndDigitalReceipt"
		| "notIssued";

	export type DocumentCoupon = {
		header?: DocumentCouponTextLine[] | null;
		graphic?: string | null;
		footer?: DocumentCouponTextLine[] | null;
	};

	export type DocumentTaxPosition = {
		vatIdentification: number;
		vatLabel?: string | null;
		vatPercent1: number;
		vatAmount: number;
		netAmount: number;
		grossAmount: number;
	};

	export type DocumentPositionText = {
		type: DocumentPositionType;
		text?: string | null;
		barcodeType?: BarcodeType;
		barcode?: string | null;
		positionNumber?: number;
		positionReference?: DocumentPositionReference;
		cancellationPosition?: boolean;
		additionalFields?: { [key: string]: string } | null;
		createDate?: string | null;
		externalIdentifier?: string[] | null;
		user?: User;
		salesPerson?: User;
		FiscalAdditionalFields?: { [key: string]: any } | null;
	};

	export type DocumentPositionSubTotal = {
		type: DocumentPositionType;
		baseValue?: number;
		value?: number;
		discounts?: Discount[] | null;
		caption?: string | null;
		positionNumber?: number;
		positionReference?: DocumentPositionReference;
		cancellationPosition?: boolean;
		additionalFields?: { [key: string]: string } | null;
		createDate?: string | null;
		externalIdentifier?: string[] | null;
		user?: User;
		salesPerson?: User;
		FiscalAdditionalFields?: { [key: string]: any } | null;
	};

	export type DocumentPositionItem = {
		itemCaption: string;
		itemShortCaption?: string | null;
		itemSerialNumber?: string | null;
		discounts?: Discount[] | null;
		type: DocumentPositionType;
		useSubItemVatCalculation?: boolean;
		subItems?: DocumentPositionSubItem[] | null;
		inHouse?: boolean;
		itemGroupId?: string | null;
		itemGroupCaption?: string | null;
		costPrice?: number | null;
		itemDateOfEntry?: string | null;
		quantity: number;
		quantityUnit: QuantityUnit;
		itemId: string;
		itemType: ItemType;
		baseNetValue: number;
		baseGrossValue: number;
		baseTaxValue: number;
		baseTaxValue2?: number | null;
		gtin?: string | null;
		businessTransactionType: BusinessTransactionType;
		vatIdentification: number;
		vatPercent: number;
		vatPercent2?: number | null;
		additionalTax?: AdditionalTax;
		netValue: number;
		grossValue: number;
		taxValue: number;
		taxValue2?: number | null;
		accountingIdentifier?: string | null;
		itemTaxType?: ItemTaxType;
		positionNumber?: number;
		positionReference?: DocumentPositionReference;
		cancellationPosition?: boolean;
		additionalFields?: { [key: string]: string } | null;
		createDate?: string | null;
		externalIdentifier?: string[] | null;
		user?: User;
		salesPerson?: User;
		FiscalAdditionalFields?: { [key: string]: any } | null;
	};

	export type DocumentPositionBooking = {
		type: DocumentPositionType;
		caption: string;
		identifier?: string | null;
		businessTransactionType: BusinessTransactionType;
		payOutType?: PayOutType;
		vatIdentification: number;
		vatPercent: number;
		vatPercent2?: number | null;
		additionalTax?: AdditionalTax;
		netValue: number;
		grossValue: number;
		taxValue: number;
		taxValue2?: number | null;
		accountingIdentifier?: string | null;
		itemTaxType?: ItemTaxType;
		positionNumber?: number;
		positionReference?: DocumentPositionReference;
		cancellationPosition?: boolean;
		additionalFields?: { [key: string]: string } | null;
		createDate?: string | null;
		externalIdentifier?: string[] | null;
		user?: User;
		salesPerson?: User;
		FiscalAdditionalFields?: { [key: string]: any } | null;
	};

	export type DocumentPositionTotal = {
		type: DocumentPositionType;
		rounding?: number | null;
		baseValue?: number;
		value?: number;
		discounts?: Discount[] | null;
		caption?: string | null;
		positionNumber?: number;
		positionReference?: DocumentPositionReference;
		cancellationPosition?: boolean;
		additionalFields?: { [key: string]: string } | null;
		createDate?: string | null;
		externalIdentifier?: string[] | null;
		user?: User;
		salesPerson?: User;
		FiscalAdditionalFields?: { [key: string]: any } | null;
	};

	export type DocumentPositionSubItem = {
		type: DocumentPositionType;
		quantity: number;
		quantityUnit: QuantityUnit;
		itemId: string;
		itemCaption?: string | null;
		itemType: ItemType;
		baseNetValue: number;
		baseGrossValue: number;
		baseTaxValue: number;
		baseTaxValue2?: number | null;
		gtin?: string | null;
		businessTransactionType: BusinessTransactionType;
		vatIdentification: number;
		vatPercent: number;
		vatPercent2?: number | null;
		additionalTax?: AdditionalTax;
		netValue: number;
		grossValue: number;
		taxValue: number;
		taxValue2?: number | null;
		accountingIdentifier?: string | null;
		itemTaxType?: ItemTaxType;
		positionNumber?: number;
		positionReference?: DocumentPositionReference;
		cancellationPosition?: boolean;
		additionalFields?: { [key: string]: string } | null;
		createDate?: string | null;
		externalIdentifier?: string[] | null;
		user?: User;
		salesPerson?: User;
		FiscalAdditionalFields?: { [key: string]: any } | null;
	};

	export enum DocumentPositionType {
		Item = 0,
		SubItem = 1,
		Text = 2,
		Booking = 3,
		SubTotal = 9,
		Total = 10,
	}

	export type DocumentPayment = {
		amount: number;
		currencyIsoCode: string;
		caption?: string | null;
		uniqueReadablePaymentIdentifier?: string | null;
		foreignAmount?: number;
		foreignAmountExchangeRate?: number;
		additionalFields?: { [key: string]: string } | null;
		paymentType: PaymentType;
		createDate?: string | null;
		vatIdentification?: number | null;
		vatPercent?: number | null;
		vatPercent2?: number | null;
		taxValue?: number | null;
		taxValue2?: number | null;
		externalIdentifier?: string[] | null;
		user?: User;
		salesPerson?: User;
		paymentTerminalReferenceId?: string | null;
		cardData?: DocumentPaymentCardData;
	};

	export type DocumentCouponTextLine = {
		text?: string | null;
		barcodeType?: BarcodeType;
		barcode?: string | null;
	};

	export type Discount = {
		discountValue: number;
		caption?: string | null;
		discountOrder: number;
		type: DiscountType;
		typeValue?: number;
		promotionKeys?: string[] | null;
		identifier?: string | null;
	};

	export type QuantityUnit = {
		id: string | null;
	};

	export enum ItemType {
		Article = 0,
		Service = 1
	}

	export type AdditionalTax = {
		additionalTaxType: AdditionalTaxType;
		additionalTaxTypeIdentification?: string | null;
		taxPercent?: number | null;
		taxValue?: number | null;
	};

	export enum BusinessTransactionType {
		Revenue = 0,
		Discount = 1,
		DepositOnEmpties = 2,
		SinglePurposeVoucher = 3,
		MultiPurposeVoucher = 4,
		Tip = 5,
		CompanyTip = 6,
		Deposit = 7,
		PayOut = 10,
		PayIn = 11,
		MoneyTransfer = 90,
		CashDifference = 91,
		GrantGenuine = 92,
		GrantUnreal = 93
	}

	export type AdditionalTaxType =
		| "alcohol"
		| "tobacco"
		| "motorVehicle"
		| "gasoline"
		| "other";

	export type DocumentPositionReference = {
		positionNumber?: number;
		referenceType?: ReferenceType;
		storeNumber?: string | null;
		terminalNumber?: string | null;
		documentType?: DocumentType;
		documentNumber?: string | null;
		documentNumberSeries?: string | null;
		fiscalDocumentNumber?: number;
		documentGuid?: string;
		documentId?: string | null;
		documentBookDate?: string | null;
		returnReasonType?: ReturnReasonType;
		returnReasonText?: string | null;
	};

	export enum BarcodeType {
		Ean8 = 1,
		Ean13 = 2,
		Code128 = 3,
		QrCode = 4
	}

	export type DocumentPaymentCardData = {
		receiptCopyCount?: number;
		receipt?: string[] | null;
		cardNumberMasked?: string | null;
		identificationToken?: string | null;
		merchantCopyCount?: number;
		merchantReceipt?: string[] | null;
	};

	export enum DiscountType {
		Allowance = 0,
		Discount = 1,
	}

	export enum PartnerType {
		Customer = 0,
	}

	export type PartnerIdentificationType =
		| "passport"
		| "officialIdDocument"
		| "residenceCertificate"
		| "other";

	export enum ReferenceType {
		Cancellation = 0,
		Predecessor = 1,
		Reprint = 2,
	}

	export type ReturnReasonType =
		| "userMistake"
		| "masterDataWrong"
		| "itemNotInStock"
		| "customerComplaint"
		| "customerReturn"
		| "customerDiscount"
		| "customerDataWrong"
		| "technicalMysteryShopping";

	export type PaymentType =
		| "cash"
		| "ecCard"
		| "creditCard"
		| "singlePurposeVoucher"
		| "multiPurposeVoucher"
		| "paymentProvider"
		| "deposit"
		| "noCash"
		| "none"
		| "crypto"
		| "mobilePhoneApps"
		| "bankAccount"
		| "customerCard"
		| "loyalty"
		| "cheque"
		| "creditNote";

	export type FiscalCountry =
		| "[0] = Germany"
		| "[1] = Austria"
		| "[2] = France"
		| "[3] = Bulgaria"
		| "[4] = Denmark"
		| "[5] = Sweden"
		| "[6] = Slovenia"
		| "[7] = Poland"
		| "[8] = Serbia"
		| "[9] = Italy"
		| "[10] = Croatia"
		| "[12] = Romania"
		| "[13] = Slovakia"
		| "[15] = Lithuania"
		| "[16] = Hungary"
		| "[17] = Portugal"
		| "[18] = Spain"
		| "[23] = Canada"
		| "[28] = Saudi Arabia"
		| "[99999] = NoFiscalisation";

	export type DocumentPositionBase = {
		type: DocumentPositionType;
		positionNumber?: number;
		positionReference?: DocumentPositionReference;
		cancellationPosition?: boolean;
		additionalFields?: { [key: string]: string } | null;
		createDate?: string | null;
		externalIdentifier?: string[] | null;
		user?: User;
		salesPerson?: User;
		FiscalAdditionalFields?: { [key: string]: any } | null;
	};

	export type PayOutType =
		| "[0] = CleaningMaterial"
		| "[1] = OfficeSupplies"
		| "[2] = PostalFee"
		| "[3] = Salary"
		| "[4] = Wages"
		| "[5] = BuyGoods"
		| "[6] = OtherCosts";

	export type ItemTaxType = "deliveries" | "services";

	export type FiscalClientStatus = {
		fiscalIdentification?: string | null;
		vatNumber?: string | null;
		fiscalCountry: FiscalCountry;
		fiscalSoftwareVersion?: string | null;
		online: boolean;
		cloudConnectionPossible: boolean;
		cloudMessagesQueued: number;
		cloudMessagesStoragePeriod: number;
		state: FiscalClientState;
		securityDeviceStates?: SecurityDeviceStateElement[] | null;
		storeNumber?: string | null;
		terminalNumber?: string | null;
		alert: boolean;
		isTest: boolean;
	}

	export enum FiscalClientState {
		notInitialized = 'notInitialized',
		initialized = 'initialized',
		decommissioned = 'decommissioned',
	}

	export type SecurityDeviceStateElement = {
		securityDeviceIdentifier?: string | null;
		state: SecurityDeviceState;
	}

	export enum SecurityDeviceState {
		Connected = 0,
		NotConnected = 1,
		Error = 2,
		NotInitialized = 10,
		Decommissioned = 11,
	}

	// Definizione del tipo LogEntryType (enum)
	export type LogEntryType =
		| "documentReprintOther"
		| "documentSuspend"
		| "documentResume"
		| "documentTrainingModeOn"
		| "documentTrainingModeOff"
		| "documentTypeSales"
		| "documentTypeReturn"
		| "documentTypeProforma"
		| "documentTypeDelivery"
		| "documentTypeTraining"
		| "documentTypeWithDrawal"
		| "documentTypePayOut"
		| "documentTypePayIn"
		| "documentTypeOpeningBalance"
		| "documentTypeNullReceipt"
		| "documentTypeInvoice"
		| "documentTypeOther"
		| "documentTypeVoid"
		| "documentTypeLongTermOrder"
		| "documentTypePaymentConfirmation"
		| "documentTypeCustomerOrder"
		| "documentTypeInventory"
		| "documentTypePurchase"
		| "documentReprintLongTermOrder"
		| "documentReprintPaymentConfirmation"
		| "documentAbandonLongTermOrder"
		| "documentTraceUnprintedLongTermOrder"
		| "documentCancelLine"
		| "documentVoidLine"
		| "documentUpdatePayment"
		| "documentUpdateLongTermOrder"
		| "documentUpdateLine"
		| "documentUpdateTable"
		| "drawerOpen"
		| "drawerClose"
		| "printerUnavailable"
		| "printerAvailable"
		| "userLogin"
		| "userLogout"
		| "userRightsChange"
		| "applicationStart"
		| "applicationShutDown"
		| "applicationUpdate"
		| "applicationUpdateMinor"
		| "applicationFiscalMiddlewareUpdate"
		| "applicationEmergencyModeOn"
		| "applicationEmergencyModeOff"
		| "applicationInitialize"
		| "applicationAddWorkstation"
		| "applicationDeleteWorkstation"
		| "masterDataItemPriceChange"
		| "masterDataItemPriceLookup"
		| "masterDataItemUpdateCompany"
		| "transferOwnership"
		| "dataIntegrityBackupDatabase"
		| "dataIntegrityRestoreDatabase"
		| "dataIntegrityPurgeDatabase"
		| "dataIntegrityPurgeEventLog"
		| "dataIntegrityDataRecovery"
		| "dataIntegrityForwardData"
		| "dataIntegrityConsolidationComplete"
		| "dataIntegrityFailure"
		| "dataSequenceChange"
		| "dataSequenceFailure"
		| "dataImportExternal"
		| "dataExportAccounting"
		| "dataExportGeneric"
		| "fiscalPeriodClosingDay"
		| "fiscalPeriodClosingMonth"
		| "fiscalPeriodClosingYear"
		| "fiscalReportClosingDay"
		| "fiscalArchiveYear"
		| "fiscalArchiveIntermediate"
		| "fiscalJournalExport"
		| "fiscalSignatureUpdateKey"
		| "fiscalAudit"
		| "fiscalAuditFileTransfer"
		| "other";

	export type AuditLogEntry = {
		recordId?: string;
		uniqueClientId?: string;
		logEntryType?: LogEntryType;
		message?: string | null;
		documentGuid?: string | null;
		user?: User;
		amount?: number | null;
		identifier?: string | null;
		recordDateTime?: string;
		signature?: string | null;
	}

	export type DocumentValidationError = {
		errorLevel?: 'error' | 'warning' | 'information';
		errorText?: string | null;
		errorSource?: string | null;
	}
}