<div class="tw-h-full tw-flex tw-flex-col tw-items-center tw-p-5 tw-overflow-auto">
    <section id="logo_alvolo">
        <img ngSrc="assets/images/alvolo/logo_alvolo.png" width="600" height="150">
    </section>
    <section id="description" class="tw-text-center tw-pt-7">
        <p translate="SETTINGS.ALVOLO.ACTIVATE.DESCRIPTION"></p>
    </section>
    <div class="tw-flex tw-w-full tw-pb-6">
        <section class="tw-w-1/2">
            <p translate="SETTINGS.ALVOLO.ACTIVATE.ACTIVATE_ALVOLO" class="tw-text-center"></p>
            <form [formGroup]="formGroupSettings">
                <section class="tw-pt-4 tw-pl-8 tw-pr-8 tw-pb-4 tw-flex tw-flex-col">
                    <mat-label class="tw-text-gray-400" for="alias">
                        <span translate="SETTINGS.ALVOLO.ACTIVATE.CHOOSE_ALIAS"></span>
                    </mat-label>
                    <div class="tw-flex">
                        <p for="alias">https://webapp.alvolo.app/</p>
                        <mat-form-field color="primary" class="the-dense-zone tw-w-full">
                            <input matInput id="alias" formControlName="alias" placeholder="Alias"/>
                        </mat-form-field>
                    </div>
                </section>
                <div class="tw-px-8">
                    <mat-error *ngIf="formGroupSettings.get('alias')?.hasError('required') && formGroupSettings.get('alias')?.touched" translate="SETTINGS.ALVOLO.ACTIVATE.ALIAS_MANDATORY">
                    </mat-error>
                    <mat-error *ngIf="formGroupSettings.get('alias')?.hasError('minlength') && formGroupSettings.get('alias')?.touched" translate="SETTINGS.ALVOLO.ACTIVATE.MAX_FOUR_CHARACTERS">
                    </mat-error>
                    <mat-error *ngIf="!formGroupSettings.get('alias')?.hasError('minlength') && formGroupSettings.get('alias')?.hasError('pattern') && formGroupSettings.get('alias')?.touched" translate="SETTINGS.ALVOLO.ACTIVATE.NO_SPECIAL_CHARACTERS">
                    </mat-error>
                </div>
                <section class="tw-pt-4 tw-pl-8 tw-pr-8 tw-pb-4">
                    <div class=" tw-flex tw-flex-row tw-justify-between tw-items-center">
                        <mat-label class="tw-text-gray-400" translate="SETTINGS.ALVOLO.ACTIVATE.CHOOSE_LIST"></mat-label>
                        <mat-form-field class="the-dense-zone tw-w-96">
                            <mat-select formControlName="list">
                                <mat-option  *ngFor="let list of priceLists" [value]="list">{{list.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </section>
                <div class="tw-px-8">
                    <mat-error *ngIf="formGroupSettings.get('list')?.hasError('required') && formGroupSettings.get('list')?.touched" translate="SETTINGS.ALVOLO.ACTIVATE.LIST_MANDATORY">
                    </mat-error>
                </div>
                <section *ngIf="showSubmitButton" class="tw-pt-4 tw-pl-8 tw-pr-8 tw-pb-4 tw-text-center">
                    <button mat-button class="tw-w-1/2" style="border: 1px solid lightgrey;" (click)="onSubmit()" 
                    [disabled]="formGroupSettings.invalid" translate="SETTINGS.ALVOLO.ACTIVATE.ACTIVATE"></button>
                </section>
                <section *ngIf="!showSubmitButton">
                    <mat-spinner class="primary tw-mx-auto" value="60"></mat-spinner>
                </section>
            </form>
        </section>
        <section class="tw-w-1/2">
            <p translate="SETTINGS.ALVOLO.ACTIVATE.ACTIVATE_EXISTING_ALVOLO" class="tw-text-center"></p>
            <form [formGroup]="formGroupSettingsConnect">
                <section class="tw-pt-4 tw-pl-8 tw-pr-8 tw-pb-4 tw-flex tw-flex-col">
                    <mat-label class="tw-text-gray-400" for="alias_connect">
                        <span translate="SETTINGS.ALVOLO.ACTIVATE.CHOOSE_ALIAS"></span>
                    </mat-label>
                    <div class="tw-flex">
                        <p for="alias_connect">https://webapp.alvolo.app/</p>
                        <mat-form-field color="primary" class="the-dense-zone tw-w-full">
                            <input matInput id="alias_connect" formControlName="alias_connect" placeholder="Alias"/>
                        </mat-form-field>
                    </div>
                </section>
                <div class="tw-px-8">
                    <mat-error *ngIf="formGroupSettingsConnect.get('alias_connect')?.hasError('required') && formGroupSettingsConnect.get('alias_connect')?.touched" translate="SETTINGS.ALVOLO.ACTIVATE.ALIAS_MANDATORY">
                    </mat-error>
                    <mat-error *ngIf="formGroupSettingsConnect.get('alias_connect')?.hasError('minlength') && formGroupSettingsConnect.get('alias_connect')?.touched" translate="SETTINGS.ALVOLO.ACTIVATE.MAX_FOUR_CHARACTERS">
                    </mat-error>
                    <mat-error *ngIf="!formGroupSettingsConnect.get('alias_connect')?.hasError('minlength') && formGroupSettingsConnect.get('alias_connect')?.hasError('pattern') && formGroupSettingsConnect.get('alias_connect')?.touched" translate="SETTINGS.ALVOLO.ACTIVATE.NO_SPECIAL_CHARACTERS">
                    </mat-error>
                </div>
                <section class="tw-pt-4 tw-pl-8 tw-pr-8 tw-pb-4 tw-flex tw-flex-col">
                    <mat-label class="tw-text-gray-400" for="shop_key">
                        <span translate="SETTINGS.ALVOLO.ACTIVATE.SHOP_KEY"></span>
                    </mat-label>
                    <div class="tw-flex">
                        <mat-form-field color="primary" class="the-dense-zone tw-w-full">
                            <input matInput id="shop_key" formControlName="shop_key" placeholder="Shop key"/>
                        </mat-form-field>
                    </div>
                </section>
                <section class="tw-pt-4 tw-pl-8 tw-pr-8 tw-pb-4 tw-flex tw-flex-col">
                    <mat-label class="tw-text-gray-400" for="token">
                        <span translate="SETTINGS.ALVOLO.ACTIVATE.TOKEN"></span>
                    </mat-label>
                    <div class="tw-flex">
                        <mat-form-field color="primary" class="the-dense-zone tw-w-full">
                            <input matInput id="token" formControlName="token" placeholder="Token"/>
                        </mat-form-field>
                    </div>
                </section>
                <section class="tw-pt-4 tw-pl-8 tw-pr-8 tw-pb-4 tw-flex tw-flex-col">
                    <mat-label class="tw-text-gray-400" for="api_endpoint">
                        <span translate="SETTINGS.ALVOLO.ACTIVATE.API_ENDPOINT"></span>
                    </mat-label>
                    <div class="tw-flex">
                        <mat-form-field color="primary" class="the-dense-zone tw-w-full">
                            <input matInput id="api_endpoint" formControlName="api_endpoint" placeholder="Api Endpoint"/>
                        </mat-form-field>
                    </div>
                </section>
                <section class="tw-pt-4 tw-pl-8 tw-pr-8 tw-pb-4">
                    <div class=" tw-flex tw-flex-row tw-justify-between tw-items-center">
                        <mat-label class="tw-text-gray-400" translate="SETTINGS.ALVOLO.ACTIVATE.CHOOSE_LIST"></mat-label>
                        <mat-form-field class="the-dense-zone tw-w-96">
                            <mat-select formControlName="list">
                                <mat-option  *ngFor="let list of priceLists" [value]="list">{{list.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </section>
                <div class="tw-px-8">
                    <mat-error *ngIf="formGroupSettingsConnect.get('list')?.hasError('required') && formGroupSettingsConnect.get('list')?.touched" translate="SETTINGS.ALVOLO.ACTIVATE.LIST_MANDATORY">
                    </mat-error>
                </div>
            </form>

            <section *ngIf="showSubmitButtonConnect" class="tw-pt-4 tw-pl-8 tw-pr-8 tw-pb-4 tw-text-center">
                <button mat-button class="tw-w-1/2" style="border: 1px solid lightgrey;" (click)="connectAlvolo()"
                [disabled]="formGroupSettingsConnect.invalid" translate="SETTINGS.ALVOLO.ACTIVATE.CONNECT"></button>
            </section>
            <section *ngIf="!showSubmitButtonConnect">
                <mat-spinner class="primary tw-mx-auto" value="60"></mat-spinner>
            </section>
        </section>
    </div>
</div>