<div class="tw-flex tw-flex-row tw-pl-5 tw-pt-2 tw-justify-between">
    <div class="tw-flex tw-items-center">
        <button mat-icon-button mat-dialog-close title="">
            <mat-icon class="tw-font-medium">close</mat-icon>
        </button>
        <div class="tw-text-base tw-font-semibold" title="">{{title}}</div>
    </div>
    <div class="tw-flex tw-items-center tw-pr-5">
        <button mat-icon-button (click)="deleteItem()" title="">
            <mat-icon class="tw-font-medium material-symbols-outlined">delete</mat-icon>
        </button>
        <button mat-button cdkFocusInitial color="primary" (click)="answer()" [disabled]="!!rowItemManagerForm?.invalid" title=""
                class="tw-font-medium">
            <span translate>{{'SETTINGS.EDIT_USER_ACEESS_DIALOG.CONFIRM'}}</span>
        </button>
    </div>
</div>
<tilby-dialog-content style="max-height: 100%;" class="tw-overflow-hidden tw-pt-2">
    @if (!isMobilePortrait()) {
        <div class="tw-flex tw-flex-row tw-h-full">
            <div class="tw-basis-3/5">
                <form autocomplete="off" #rowItemManagerForm="ngForm" class="tw-h-full">
                    <div class="tw-flex tw-flex-col tw-gap-3 tw-h-full tw-overflow-y-auto">
                        <div class="tw-flex tw-flex-wrap">
                            <div #formContainerOptionsOne>
                            </div>
                        </div>
                        <div class="tw-flex-1">
                            <div class="tw-flex tw-flex-col">
                                <div class="tw-flex tw-flex-wrap tw-h-full">
                                    @for(variation of variations; track variation) {
                                        <div class="tw-pb-5 tw-basis-1/2">
                                            <div class="tw-pl-2 tw-pt-1 tw-pb-2 tw-font-bold">
                                                {{ variation.name }}
                                                @if(variation.required) {
                                                    <span>{{'ORDERS.ORDER_ITEM_MANAGER.REQUIRED_VARIATION' | translate}}*</span>
                                                }
                                            </div>
                                            <mat-radio-group color="primary" [(ngModel)]="variation.variation_value_id" [name]="'variation_' + variation.id" [required]="!!variation.required">
                                                @if(!variation.required) {
                                                    <mat-radio-button class="tw-flex tw-flex-col" [value]="undefined">{{'ORDERS.ORDER_ITEM_MANAGER.NONE' | translate}}</mat-radio-button>
                                                }
                                                @for(variationValue of variation.variation_values; track variationValue) {
                                                    <mat-radio-button class="tw-flex tw-flex-col" [value]="variationValue.id">
                                                        <span>{{ variationValue.value }}</span>
                                                        @if(showPrices){
                                                            <span> {{getPriceDifferenceValueString(variationValue)}}</span>
                                                        } @else {
                                                            <span>***</span>
                                                        }
                                                    </mat-radio-button>
                                                }
                                            </mat-radio-group>
                                        </div>
                                    }
                                </div>
                            </div>
                          </div>
                        <div #formContainerOptionsTwo></div>
                    </div>
                </form>
            </div>
            <div class="tw-basis-2/5 tw-pl-6">
                <ng-container *ngTemplateOutlet="contentIngredients"></ng-container>
            </div>
        </div>
    } @else {
        <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="start" class="tw-h-full">
            <mat-tab [label]="'ORDERS.ORDER_ITEM_MANAGER.TABS_LABEL.GENERAL'|translate">
                <form autocomplete="off" #rowItemManagerForm="ngForm" class="tw-h-full">
                    <div class="tw-flex tw-flex-col tw-h-full tw-pt-4">
                        <div class="tw-flex tw-flex-wrap" #formContainerOptionsOne>
                        </div>
                        @for(variation of variations; track variation) {
                            <div>
                                <div>{{variation.name}}</div>
                                <mat-form-field class="tw-w-full">
                                    <mat-label>
                                        <span>{{ variation.name }}</span>
                                        @if(variation.required) {
                                            <span translate>ORDERS.ORDER_ITEM_MANAGER.REQUIRED_VARIATION</span>
                                        }
                                    </mat-label>
                                    <mat-select name="variation" [(ngModel)]="variation.variation_value_id" [required]="!!variation.required" [name]="'variation' + $index">
                                        @if(!variation.required) {
                                            <mat-option [value]="undefined"></mat-option>
                                        }
                                        @for(variationValue of variation.variation_values; track variationValue) {
                                            <mat-option [value]="variationValue.id">
                                                {{ variationValue.value }}
                                                @if(showPrices){
                                                    <span> {{getPriceDifferenceValueString(variationValue)}}</span>
                                                } @else {
                                                    <span>***</span>
                                                }
                                            </mat-option>
                                        }
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        }
                        <div #formContainerOptionsTwo></div>
                    </div>
                </form>
            </mat-tab>
            <mat-tab [label]="'ORDERS.ORDER_ITEM_MANAGER.TABS_LABEL.INGREDIANTS'|translate">
                <ng-container *ngTemplateOutlet="contentIngredients"></ng-container>
            </mat-tab>
        </mat-tab-group>
    }
</tilby-dialog-content>

<ng-template #optionsOne>
    <mat-form-field [ngClass]="isMobilePortrait() ? 'tw-w-full' : 'tw-pr-2'">
        <mat-label translate>ORDERS.ORDER_ITEM_MANAGER.UNIT_PRICE</mat-label>
        <input matInput type="number" [ngStyle]="{'font-size': hideAsterisk ? '0px' : '16px'}" [(ngModel)]="rowItem.price" name="price" min="0" required [disabled]="disableChangePrice" #priceCtrl="ngModel" class="hidden-value" (input)="onPriceChange($event)">
        @if(!showPrices) {
            @if(hideAsterisk){
                <span class="hidden-value-text">***</span>
            }
        }
        @if(priceCtrl.hasError('required')) {
            <mat-error translate>NG_MESSAGES.REQUIRED_FIELD</mat-error>
        } @else if(priceCtrl.hasError('min')) {
            <mat-error translate>NG_MESSAGES.NUMBER_NOT_VALID</mat-error>
        }
    </mat-form-field>
    <mat-form-field [ngClass]="isMobilePortrait() ? 'tw-w-full' : 'tw-pr-2'">
        <mat-label translate>ORDERS.ORDER_ITEM_MANAGER.DEPARTMENT</mat-label>
        <mat-select name="department" [(ngModel)]="rowItem.department_id" required #departmentCtrl="ngModel">
            @for(department of departments; track department) {
                <mat-option [value]="department.id">{{ department.name }}</mat-option>
            }
        </mat-select>
        @if(departmentCtrl.hasError('required')) {
            <mat-error translate>NG_MESSAGES.REQUIRED_FIELD</mat-error>
        }
    </mat-form-field>
    <mat-form-field [ngClass]="isMobilePortrait() ? 'tw-w-full' : 'tw-pr-2'">
        <mat-label translate>ORDERS.ORDER_ITEM_MANAGER.QUANTITY</mat-label>
        <input matInput type="number" [(ngModel)]="newQuantity" name="quantity" min="0" required #quantityCtrl="ngModel">
        @if(quantityCtrl.hasError('required')) {
            <mat-error translate>NG_MESSAGES.REQUIRED_FIELD</mat-error>
        }
    </mat-form-field>
    @if(saleHasTable) {
        <mat-form-field [ngClass]="{'tw-w-full' : isMobilePortrait()}">
            <mat-label translate>ORDERS.ORDER_ITEM_MANAGER.EXIT_LABEL</mat-label>
            <mat-select name="type" [(ngModel)]="rowItem.exit" #exitCtrl="ngModel">
                @for(exit of exits; track exit) {
                    <mat-option [value]="exit.value">{{ exit.name }}</mat-option>
                }
            </mat-select>
        </mat-form-field>
    }
</ng-template>

<ng-template #optionsTwo>
    <div [ngClass]="{'tw-pr-2': !isMobilePortrait()}">
        <mat-form-field class="tw-w-full tw-pt-2">
            <mat-label translate>ORDERS.ORDER_ITEM_MANAGER.NOTE</mat-label>
            <input matInput type="textarea" [(ngModel)]="rowItem.notes" name="notes" #notesCtrl="ngModel">
        </mat-form-field>
    </div>
    @if(halfPortionEnabled) {
        <div class="tw-flex">
            <span translate class="tw-min-w-[150px]">ORDERS.ORDER_ITEM_MANAGER.HALF_PORTION</span>
            <mat-slide-toggle class="tw-pb-3" [(ngModel)]="rowItem.half_portion" name="half_portion" [disabled]="disableChangePrice" #halfPortionCtrl="ngModel"></mat-slide-toggle>
        </div>
    }
    <mat-form-field class="tw-pt-2 tw-w-[200px]" [ngClass]="isMobilePortrait() ? 'tw-pr-0' : 'tw-pr-2'">
        <mat-label translate>ORDERS.ORDER_ITEM_MANAGER.UNBUNDLE</mat-label>
        <input matInput type="number" [(ngModel)]="unbundledQuantity" name="unbundledQuantity" #unbundledQuantityCtrl="ngModel" min="0" [max]="newQuantity - 1">
        @if(unbundledQuantityCtrl.hasError('min') || unbundledQuantityCtrl.hasError('max')) {
            <mat-error translate>NG_MESSAGES.NUMBER_NOT_VALID</mat-error>
        }
    </mat-form-field>
</ng-template>

<ng-template #contentIngredients>
    @if (!isMobilePortrait()) {
        <div class="tw-flex tw-justify-center">
            {{'ORDERS.ORDER_ITEM_MANAGER.EDIT_INGREDIENTS' | translate}}
        </div>
    }
    <mat-form-field class="tw-w-full tw-pt-3">
        <mat-label translate>ORDERS.ORDER_ITEM_MANAGER.FILTER_INGREDIENTS</mat-label>
        <input matInput type="text" [(ngModel)]="filterText" name="filterText" min="0" #filterTextCtrl="ngModel" placeholder="">
    </mat-form-field>
    <cdk-virtual-scroll-viewport class="tw-w-full tw-h-[calc(100%-81px)]" [itemSize]="20" [minBufferPx]="screenHeight" [maxBufferPx]="screenHeight" >
        <div *cdkVirtualFor="let ingredient of filteredIngredients()" >
            <div class="order-item-manager-ingredient" [ngClass]="{'no-ingredient': ingredient.quantity === 0, 'removed': ingredient.quantity === -1}" tilbyGestures (swipeLeft)="decrementIngredientQuantity(ingredient)" (swipeRight)="incrementIngredientQuantity(ingredient)">
                <div class="order-item-manager-ingredient-badge" [ngSwitch]="ingredient.quantity">
                    @switch(ingredient.quantity) {
                        @case (-1) {
                            <span>-</span>
                        }
                        @case (0) {
                            <span></span>
                        }
                        @case (1) {
                            <span>+</span>
                        }
                        @default {
                            <span>x{{ ingredient.quantity }}</span>
                        }
                    }
                </div>
                <div class="order-item-manager-ingredient-name">
                <strong>{{ ingredient.name }}</strong>
                @if(ingredient.$type === 'ingredient') {
                    <span>*</span>
                }
                @if(showPrices){
                    <span class="ingredient-price">{{ getPriceDifference(ingredient) }}</span>
                } @else {
                    <span class="ingredient-price">***</span>
                }
                </div>
            </div>
        </div>
    </cdk-virtual-scroll-viewport>
</ng-template>
