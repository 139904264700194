import {
    Component,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output
} from '@angular/core';

import { DomSanitizer } from "@angular/platform-browser";

import {mobileCheck} from 'src/utilities';

import {
    ConfigurationManagerService,
    ConnectionService,
    ThemeModeService,
    ToolbarEventsService
} from 'src/app/core';

import {OpenDialogsService} from 'src/app/dialogs';
import { OnDestroyService } from 'src/app/core/services/on-destroy.service';
import { ActiveSaleStoreService } from 'src/app/features';
import { $state } from 'app/ajs-upgraded-providers';
import { DualService } from 'src/app/dual.service';

@Component({
    selector: 'app-left-toolbar',
    templateUrl: './left-toolbar.component.html',
    styleUrls: ['./left-toolbar.component.scss'],
    host: {class: 'tw-flex tw-items-center'}
})
export class LeftToolbarComponent implements OnInit {
    private readonly connectionService = inject(ConnectionService);
    private readonly domSanitizer = inject(DomSanitizer);
    private readonly onDestroyService = inject(OnDestroyService);
    private readonly openDialogsService = inject(OpenDialogsService);
    private readonly toolbarEventsService = inject(ToolbarEventsService);
    protected readonly themeModeService = inject(ThemeModeService);
    protected readonly configurationManagerService = inject(ConfigurationManagerService);
    protected readonly state = inject($state);
    protected readonly dualService = inject(DualService);

    protected isOffline = this.connectionService.isOffline;
    protected isTableNameOnTopBar : boolean = this.configurationManagerService.getPreference('cashregister.table_name_on_topbar') || false;

    @Input() menuIcon = false;
    @Output() menuClickChange = new EventEmitter<void>();

    protected isMobile = mobileCheck();
    protected logoLight = this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/tilby/Tilby_a.svg');
    protected logoDark = this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/tilby/Tilby_a-dark.svg');
    protected moduleTitle?: string;
    protected regularTitle: boolean = true;
    protected saleUpdates$ = ActiveSaleStoreService.saleUpdates$;
    protected currentModule = this.state.current;

    constructor(
    ) {
        this.toolbarEventsService.moduleTitle.pipe(this.onDestroyService.takeUntilDestroy).subscribe(moduleTitle => {
            this.moduleTitle = "TOPBAR.TITLE."+moduleTitle;

            let nextRouteDifferent = false;
            this.dualService.nextRoute$.subscribe(nextRoute => {
                if(nextRoute && !nextRoute.includes('cashregister')){
                    nextRouteDifferent = true;
                }
            });

            if(!this.isTableNameOnTopBar || nextRouteDifferent){
                return;
            }

            this.saleUpdates$.subscribe(data => {
                if(!data.currentSale){
                    this.moduleTitle = "TOPBAR.TITLE."+moduleTitle;
                    return;
                }
    
                const currentSale = data.currentSale;
    
                switch(currentSale.order_type){
                    case 'normal':
                        if(currentSale.room_name && currentSale.table_name){
                            this.moduleTitle = "";
                            this.moduleTitle = currentSale.room_name + " - " + currentSale.table_name;
                        } else {
                            this.moduleTitle = "TOPBAR.TITLE.CASHREGISTER";
                        }
                        break;
    
                    case 'take_away':
                        if(currentSale.deliver_at){
                            const deliver_at = `${new Date(currentSale.deliver_at).getHours()}:${new Date(currentSale.deliver_at).getMinutes().toString().padStart(2, '0')}`;
                            this.moduleTitle = "";
                            this.moduleTitle = 'Asporto' + " - " + deliver_at;
                        }
                        break;
                    case 'delivery':
                        let deliver_at;
                    
                        if(currentSale.deliver_at){
                            deliver_at = `${new Date(currentSale.deliver_at).getHours()}:${new Date(currentSale.deliver_at).getMinutes().toString().padStart(2, '0')}`;
                        }
                        this.moduleTitle = "";
                        this.moduleTitle = (currentSale.channel ? currentSale.channel[0].toUpperCase() + currentSale.channel.slice(1) : 'Delivery') + ", " + deliver_at + (currentSale.external_id ? " - " + currentSale.external_id.slice(0, 3) + "..." : "");
                        break;
                    default:
                        this.moduleTitle = "";
                        break;
                }
            });
        })
    }

    ngOnInit(): void {
    }

    menuClick($event: MouseEvent) {
        $event.stopPropagation();
        this.menuClickChange.emit();
    }

    openOfflineDialog() {
        return this.openDialogsService.openOfflineDialog();
    }
}
